import React from "react";
import Paragraph from "../../components/common/Typography/Paragraph";
import Heading from "../../components/common/Typography/Heading";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import { Link, useParams } from "react-router-dom";
const HeadingsWithParagraphsList = () => {
  const { slug } = useParams();
  const data = [
    {
      slug: "architectural",
      Mainheading: "Understanding architectural services",
      Mainparagraphs: [
        <>
          Architectural services are crucial for the design and construction of
          safe, functional, and visually appealing buildings, catering to both
          residential and commercial needs. Architects ensure that their designs
          adhere to important Australian standards such as
          <span className="text-primaryblue"> AS1428 for accessibility</span> ,
          and{" "}
          <span className="text-primaryblue">
            AS/NZS 1170 for structural integrity
          </span>
          . By engaging an architect, clients benefit from expert planning that
          meets regulatory requirements while incorporating innovative solutions
          tailored to their specific needs.
        </>,
        "The role of an architect extends beyond aesthetics; it involves comprehensive project management from initial concept through to completion. They work closely with clients to create designs that fulfill functional requirements while considering sustainability through the use of energy-efficient materials and methods. Architects are well-versed in navigating complex building codes, zoning laws, and obtaining necessary permits, significantly reducing the risk of costly mistakes during construction.",
        "Additionally, hiring a licensed architect provides peace of mind, as their expertise ensures compliance with legal standards and enhances the overall quality of the building. Architects serve as advocates for their clients, facilitating communication between all parties involved in the project, including engineers and contractors. This collaboration is vital for achieving a successful outcome that is not only visually striking but also safe, durable, and environmentally responsible.",
      ],
      listHeading: "Core services in architecture",
      list: [
        {
          inlineHeading: "Residential design",
          inlineParagraph: "Custom designs for homes and renovations.",
          mapLink: "/services/?id=65",
        },
        {
          inlineHeading: "Commercial architecture",
          inlineParagraph: "Planning for offices and retail spaces.",
          mapLink: "/services/?id=65",
        },
        {
          inlineHeading: "Interior design",
          inlineParagraph:
            "Enhancing functionality and aesthetics of interiors.",
          mapLink: "/services/?id=65",
        },
        {
          inlineHeading: "Landscape architecture",
          inlineParagraph: "Designing outdoor spaces and gardens.",
          mapLink: "/services/?id=65",
        },
        {
          inlineHeading: "Drafting & Planning",
          inlineParagraph: "Detailed architectural drawings and plans.",
          mapLink: "/services/?id=65",
        },
        {
          inlineHeading: "Building permits",
          inlineParagraph: "Assistance with planning approvals.",
          mapLink: "/services/?id=65",
        },
        {
          inlineHeading: "Heritage conservation",
          inlineParagraph: "Preserving historic buildings.",
          mapLink: "/services/?id=65",
        },
        {
          inlineHeading: "Project management",
          inlineParagraph: "Overseeing construction from start to finish.",
          mapLink: "/services/?id=65",
        },
      ],
    },
    {
      slug: "air-conditioning-and-heating",
      Mainheading: "Understanding air conditioning & heating",
      Mainparagraphs: [
        <>
          Air conditioning and heating systems are fundamental for year-round
          indoor comfort in homes and businesses, playing a crucial role in
          temperature regulation, humidity control, and ventilation. These
          systems ensure consistent climate control, supporting everyday comfort
          and health. Compliance with Australian standards like
          <span className="text-primaryblue">
            {" "}
            AS/NZS 1668.2 ventilation{" "}
          </span>{" "}
          , and{" "}
          <span className="text-primaryblue">
            AS/NZS 3000 electrical installations
          </span>
          is mandatory for ensuring safety, efficiency, and optimal performance.
        </>,
        "Routine maintenance is critical to keeping these systems running smoothly, enhancing energy efficiency, and preventing costly breakdowns. Regular inspections help maintain indoor air quality by reducing allergens, managing humidity levels, and ensuring proper ventilation. This proactive approach not only extends the lifespan of the system but also minimizes energy consumption, reducing both environmental impact and utility costs.",
        "Homeowners can enhance energy savings by integrating smart thermostats, high-efficiency units, and zoning systems to provide precise temperature control. Sustainable solutions like evaporative cooling and solar-assisted heating offer additional cost savings while supporting eco-friendly climate control. Trades Poster’s interactive map allows residents to find licensed HVAC experts and access guides on energy-saving tips, maintenance strategies, and compliance.",
      ],
      listHeading: "Core services in air conditioning & heating ",
      list: [
        {
          inlineHeading: "Installation & replacement",
          inlineParagraph: "Setting up or replacing systems.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Ducted systems",
          inlineParagraph: "Full service for ducted heating and cooling.",
          mapLink: "/services/?id=67",
        },
        {
          inlineHeading: "Split systems",
          inlineParagraph: "Installation and servicing of split units.",
          mapLink: "/services/?id=68",
        },
        {
          inlineHeading: "Evaporative cooling",
          inlineParagraph: "Efficient cooling solutions.",
          mapLink: "/services/?id=69",
        },
        {
          inlineHeading: "Gas heating",
          inlineParagraph: "Installation and maintenance of gas heaters.",
          mapLink: "/services/?id=70",
        },
        {
          inlineHeading: "Thermostat upgrades",
          inlineParagraph: "Smart thermostat installations.",
          mapLink: "/services/?id=71",
        },
        {
          inlineHeading: "Zoning systems",
          inlineParagraph: "Creating separate climate zones.",
          mapLink: "/services/?id=72",
        },
        {
          inlineHeading: "Heat pumps",
          inlineParagraph: "Installation and servicing.",
          mapLink: "/services/?id=73",
        },
        {
          inlineHeading: "Air quality solutions",
          inlineParagraph: "Purifiers and filters for better air.",
          mapLink: "/services/?id=74",
        },
        {
          inlineHeading: "Commercial hvac",
          inlineParagraph: "Custom solutions for businesses.",
          mapLink: "/services/?id=75",
        },
        {
          inlineHeading: "Emergency repairs",
          inlineParagraph: "Quick fixes for system failures.",
          mapLink: "/services/?id=76",
        },
      ],
    },
    {
      slug: "bricklaying",
      Mainheading: "Understanding bricklaying",
      Mainparagraphs: [
        <>
          Bricklaying is at the foundation of building safe, durable, and
          aesthetic structures, making it a key trade in residential
          construction. It encompasses various elements, including walls,
          driveways, chimneys, and retaining walls, which require a solid
          understanding of materials, techniques, and compliance with Australian
          standards like
          <span className="text-primaryblue">
            {" "}
            AS 3700 Masonry Structures
          </span>{" "}
          , and{" "}
          <span className="text-primaryblue">AS/NZS 4456 Masonry Units</span>.
        </>,
        "In residential settings, brickwork adds both structural strength and visual appeal to homes. Bricklayers work with a variety of materials, including clay bricks, concrete blocks, and stone, ensuring each element meets safety and design requirements. The proper selection of materials, accurate mortar mixing, and precise alignment are essential for creating strong, long-lasting structures. Key aspects of residential brickwork include the use of weather-resistant bricks, ensuring appropriate moisture barriers, and employing correct techniques for load-bearing walls.",
        "Regular maintenance, like repointing and crack repairs, is critical for preserving the integrity of brick structures. Neglecting minor issues can lead to major structural damage, impacting safety and increasing repair costs. Sustainable innovations, such as recycled bricks, eco-friendly mortars, and energy-efficient designs, are becoming more common, offering environmentally responsible solutions that reduce costs and enhance energy efficiency.",
        "For homeowners, understanding the basics of bricklaying can be beneficial, whether planning new construction, renovations, or landscaping enhancements. Trades Poster’s interactive tools help users locate certified local bricklayers and offer step-by-step guides on brickwork maintenance, regulatory requirements, and cost-effective solutions.",
      ],
      listHeading: "Core services in bricklaying ",
      list: [
        {
          inlineHeading: "Residential walls",
          inlineParagraph: "Building internal and external walls.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Blockwork",
          inlineParagraph: "Constructing supportive and load-bearing walls.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Retaining walls",
          inlineParagraph: "Stabilizing slopes and preventing soil erosion.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Repointing",
          inlineParagraph: "Restoring old mortar joints.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Brick restoration",
          inlineParagraph: "Repairing and preserving heritage brickwork.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Paving",
          inlineParagraph: "Laying driveways, paths, and patios.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Chimney repairs",
          inlineParagraph: "Ensuring the safety and function of chimneys.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "cabinet-making",
      Mainheading: "Understanding cabinet making",
      Mainparagraphs: [
        <>
          Cabinet making is central to creating functional and aesthetic
          interiors in residential spaces, focusing on the design and
          installation of custom cabinetry and joinery. This trade covers a wide
          range of elements, from kitchen cabinet sand wardrobes to storage
          units and custom furniture, requiring precision and compliance with
          Australian standards like
          <span className="text-primaryblue">
            AS 4386 Domestic Kitchen Assemblies
          </span>{" "}
          , and{" "}
          <span className="text-primaryblue">
            AS/NZS 2047 Windows and External Glazed Doors
          </span>
          .
        </>,
        "In homes, custom cabinetry enhances both storage and style, offering tailored solutions to fit specific spaces. Cabinet makers use materials like solid timber, laminate, plywood, and veneer to create pieces that match the homeowner's preferences and functional needs. Key aspects of cabinet making include ensuring proper measurements, selecting quality materials, and achieving accurate fittings to enhance both durability and aesthetics.",
        "Regular maintenance, such as checking for loose hinges, worn finishes, and water damage, is essential for prolonging the lifespan of cabinetry. Sustainable practices, like using recycled wood or low-VOC finishes, contribute to environmentally friendly construction while reducing costs.",
      ],
      listHeading: "Core services in cabinet making  ",
      list: [
        {
          inlineHeading: "Kitchen cabinets",
          inlineParagraph: "Custom-built cabinets and pantries.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Wardrobes",
          inlineParagraph: "Built-in and walk-in wardrobes.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Bathroom vanities",
          inlineParagraph: "Tailored storage solutions for bathrooms.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Custom shelving",
          inlineParagraph: "Fitted shelving for living spaces and garages.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Home offices",
          inlineParagraph: "Desks, shelves, and storage units.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Entertainment units",
          inlineParagraph: "TV stands, media units, and bookcases.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Laundry cabinets",
          inlineParagraph: "Storage solutions for laundry rooms.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "carpentry",
      Mainheading: "Understanding carpentry services",
      Mainparagraphs: [
        <>
          Carpentry is a fundamental aspect of construction, renovation, and
          property maintenance for both residential and commercial projects. It
          encompasses a wide array of tasks, ranging from structural framing to
          detailed finishing work. At its core, carpentry provides the essential
          framework that ensures the structural integrity of buildings, while
          also contributing to their functionality and visual appeal. Carpentry
          services must adhere to rigorous standards, such as{" "}
          <span className="text-primaryblue">
            AS 1684 for timber-framed construction{" "}
          </span>
          , ensuring compliance with Australian building codes. Beyond
          compliance, professional carpentry offers long-term durability through
          the use of quality materials and skilled craftsmanship, making it
          crucial for both new constructions and renovations.
        </>,
        "Whether building an outdoor deck, installing skirting boards, or crafting custom cabinetry, professional carpentry delivers a seamless blend of safety, reliability, and aesthetics. Moreover, carpentry supports sustainable construction by integrating eco-friendly materials and designs, contributing to energy efficiency and the overall lifespan of structures. Engaging a licensed carpenter not only ensures quality but also adds value by achieving precise finishes that elevate the appearance and functionality of homes and commercial spaces.",
      ],
      listHeading: "Core services in carpentry ",
      list: [
        {
          inlineHeading: "Framing",
          inlineParagraph: "Timber frameworks for buildings.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Decking",
          inlineParagraph: "Timber decks, pergolas, and verandas.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Doors & windows",
          inlineParagraph: "Install and repair doors, windows, and trims.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Flooring",
          inlineParagraph: "Timber, laminate, and engineered floors.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Skirting",
          inlineParagraph: "Interior trims, baseboards, and cornices.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Cabinetry",
          inlineParagraph: "Custom cabinets, shelving, and built-ins.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Roofing",
          inlineParagraph: "Timber roofs, rafters, and trusses.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Renovations",
          inlineParagraph: "Home upgrades, refits, and extensions.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Fencing",
          inlineParagraph: "Timber fences, gates, and screens.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Formwork",
          inlineParagraph: "Timber molds for concrete.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Stairs",
          inlineParagraph: "Indoor/outdoor stairs and handrails.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Partitions",
          inlineParagraph: "Internal walls and bulkheads.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "carpet-and-flooring",
      Mainheading: "Understanding carpet & flooring ",
      Mainparagraphs: [
        "Carpet and flooring services are essential for transforming residential and commercial spaces, offering a range of solutions that enhance both comfort and aesthetics. Whether it’s the warmth of carpet, the elegance of hardwood, the resilience of vinyl, or the versatility of laminate, each flooring option serves a unique purpose. For instance, carpet is fevered for bedrooms and living areas due to its softness and warmth, while vinyl and tiles are popular in kitchens and bathrooms for their water resistance. Timber and engineered wood add a classic appeal to homes, while commercial spaces often opt for durable and easy-to-maintain surfaces. Choosing the right flooring requires consideration of foot traffic, room function, and budget, ensuring the right balance of style and performance.",
        <>
          Professional installation is critical, as it ensures adherence to
          important Australian standards, such as{" "}
          <span className="text-primaryblue">
            AS 1884 for resilient floor coverings
          </span>
          ,{" "}
          <span className="text-primaryblue">
            AS/NZS 4586 for slip resistance{" "}
          </span>
          , and <span className="text-primaryblue">AS 3958 for tiling</span>.
          These standards not only guarantee quality workmanship but also
          enhance safety and durability. For example, slip-resistant surfaces
          are crucial in high-traffic areas to prevent accidents, while
          correctly laid tiles prevent structural issues like cracking and
          uneven surfaces. Compliance with these standards is necessary to meet
          building regulations, minimize liability, and ensure flooring
          longevity. Qualified installers understand how to properly prepare
          subfloors, use suitable adhesives, and achieve the right level of
          finish, reducing maintenance needs and extending the lifespan of the
          flooring.
        </>,
        "Proper flooring installation also contributes significantly to energy efficiency and overall comfort within a property. Well-installed carpet or insulated flooring can help maintain indoor temperatures, reducing heating and cooling costs. Additionally, flooring materials that absorb sound, such as carpet and cork, improve acoustic performance, making interiors quieter and more comfortable for occupants. By investing in high-quality materials and professional installation, homeowners and business owners can enhance the value, functionality, and sustainability of their properties, making carpet and flooring services a vital aspect of both new builds and renovations.",
      ],
      listHeading: "Core services in carpet & flooring",
      list: [
        {
          inlineHeading: "Carpet",
          inlineParagraph: "Installing wool, nylon, and polyester carpets.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Timber",
          inlineParagraph: "Laying hardwood, engineered wood, and bamboo.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Laminate",
          inlineParagraph: "Durable installation for homes and businesses.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Vinyl",
          inlineParagraph: "Sheets, tiles, and planks for wet and busy areas.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Hybrid",
          inlineParagraph: "Waterproof, scratch-resistant surfaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Tile",
          inlineParagraph:
            "Ceramic, porcelain, and stone for kitchens and baths.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Epoxy",
          inlineParagraph: "Coatings for garages and commercial spaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Polished concrete",
          inlineParagraph: "Sleek, modern concrete finishes.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Sanding & polishing",
          inlineParagraph: "Restoring timber floor shine.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial",
          inlineParagraph: "Carpeting and hard floors for offices.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Carpet repair",
          inlineParagraph: "Fixing and re-stretching damaged carpets.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Underlay",
          inlineParagraph: "Insulation and comfort under floors.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "caulking",
      Mainheading: "Understanding caulking services",
      Mainparagraphs: [
        "Caulking services are critical for sealing joints, cracks, and gaps in various structures, preventing the intrusion of air, moisture, dust, and pests. It plays a vital role in maintaining the integrity of buildings, as it stops drafts, reduces humidity issues, and minimizes the risk of mold growth. Common areas requiring caulking include windows, doors, baseboards, siding, bathrooms, and kitchens. The versatility of caulking makes it suitable for a wide range of surfaces, including concrete, brick, wood, metal, and tile.",
        "Proper caulking not only contributes to better energy efficiency but also enhances insulation and structural longevity. It helps retain indoor temperatures by sealing gaps, reducing energy consumption and lowering heating and cooling costs. In wet areas like bathrooms, kitchens, and basements, moisture-resistant caulking prevents leaks, protects against water damage, and minimizes the chance of mildew. By maintaining a strong barrier, caulking also helps preserve building materials, reducing the need for frequent repairs or replacements.",
        "Professional caulking ensures a neat, durable, and reliable finish that improves both the functionality and appearance of surfaces. High-quality materials and expert techniques create long-lasting seals that can withstand varying conditions, from temperature changes to heavy use. Whether it's new construction, renovation, or routine maintenance, professional caulking enhances overall building performance, making it an essential part of both residential and commercial projects.",
      ],
      listHeading: "Core services in caulking",
      list: [
        {
          inlineHeading: "Interior",
          inlineParagraph: "Sealing gaps around doors, windows, and skirting.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Exterior",
          inlineParagraph: "Sealing joints around windows, doors, and facades.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Wet areas",
          inlineParagraph: "Caulking bathrooms, kitchens, and laundries.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Expansion joints",
          inlineParagraph: "Sealing in concrete slabs and pathways.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Window/door frames",
          inlineParagraph: "Filling gaps to block drafts.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Pool & spa",
          inlineParagraph: "Sealing joints to prevent leaks.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial",
          inlineParagraph: "Caulking in retail and industrial spaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Silicone replacement",
          inlineParagraph: "Removing and replacing old caulk.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "cleaning-services",
      Mainheading: "Understanding cleaning  services",
      Mainparagraphs: [
        "Cleaning services are essential for maintaining clean, hygienic, and organized environments across a wide range of settings, including residential homes, offices, and commercial properties. From routine maintenance to deep cleaning, these services ensure spaces remain free of dust, allergens, and debris. Professional cleaners use industry-standard methods and equipment to thoroughly clean surfaces, furnishings, and hard-to-reach areas, contributing to a healthier living or working environment. Regular cleaning not only promotes well-being but also extends the lifespan of materials and surfaces, reducing wear and damage over time.",
        <>
          Professional cleaning services adhere to important Australian
          standards to ensure safety and effectiveness. Key standards include{" "}
          <span className="text-primaryblue">
            AS/NZS 3733 for carpet cleaning maintenance
          </span>{" "}
          and{" "}
          <span className="text-primaryblue">
            AS/NZS 4815 for preventing cross-contamination during office
            cleaning
          </span>
          . Additionally,{" "}
          <span className="text-primaryblue">
            AS/NZS 4146 governs laundry and linen hygiene
          </span>
          , while{" "}
          <span className="text-primaryblue">
            AS 4801 focuses on occupational health and safety management for
            cleaners
          </span>
          . Compliance with these standards ensures thorough sanitation, better
          indoor air quality, and reduced health risks, particularly in
          high-touch areas like bathrooms, kitchens, and shared workspaces.
        </>,
        "Engaging a licensed cleaning professional guarantees effective, reliable, and consistent results. Whether it’s for routine cleaning, carpet shampooing, window washing, or specialized services like mold remediation, licensed cleaners are trained to tackle various tasks efficiently. They use advanced equipment and high-quality products that deliver superior outcomes compared to DIY methods. In addition to creating a safe and welcoming environment, professional cleaning services enhance comfort, aesthetics, and property value, making them an integral part of both residential and commercial upkeep.",
      ],
      listHeading: "Core services in cleaning",
      list: [
        {
          inlineHeading: "Residential",
          inlineParagraph: "House cleaning of all rooms.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial",
          inlineParagraph: "Offices and retail spaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "End of lease",
          inlineParagraph: "Deep cleaning for move-outs.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Carpet/upholstery",
          inlineParagraph: "Steam cleaning carpets and furniture.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Windows",
          inlineParagraph: "Interior and exterior cleaning.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Pressure washing",
          inlineParagraph: "Driveways, decks, and exteriors.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Deep cleaning",
          inlineParagraph: "Kitchens, bathrooms, high-traffic areas.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Post-construction",
          inlineParagraph: "Clearing debris and dust.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Tile & grout",
          inlineParagraph: "Restoring tiles and grout.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Move-in",
          inlineParagraph: "Prepping homes for new occupants.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "concrete-and-paving",
      Mainheading: "Understanding cleaning services",
      Mainparagraphs: [
        "Concrete and paving services play a crucial role in building strong, durable, and visually appealing surfaces across various projects, including residential, commercial, and industrial settings. These services cover everything from pathways, driveways, patios, and slabs to larger-scale structures like parking lots and foundations. Concrete is valued for its strength and versatility, while paving provides both functional and aesthetic enhancements to outdoor spaces. Selecting the right type of concrete or paving material is vital, as it affects durability, appearance, and maintenance needs.",
        <>
          Professional concrete and paving services ensure compliance with
          Australian standards like{" "}
          <span className="text-primaryblue">
            AS 3600 for concrete structures
          </span>{" "}
          and <span className="text-primaryblue">AS 3727 for pavements</span>,
          which govern installation practices and structural integrity. Proper
          installation not only meets safety requirements but also extends the
          lifespan of surfaces. Factors like subgrade preparation, correct
          reinforcement, and proper curing methods contribute to the durability
          and performance of concrete and paved areas. Compliance with these
          standards is essential to prevent issues like cracking, settling, or
          water pooling, ensuring that the finished surface withstands everyday
          use and harsh weather conditions.
        </>,
        "Hiring licensed professionals for concrete and paving work delivers long-lasting, safe, and reliable results. Experts are skilled in various techniques, from stamped and exposed aggregate concrete to different paving styles like brick, stone, or concrete pavers. They use high-quality materials and advanced equipment to achieve precise, level finishes that enhance both functionality and aesthetics. Additionally, well-installed concrete and paving contribute to drainage management, slip resistance, and overall property value, making them a vital component of both new construction and renovation projects.",
      ],
      listHeading: "Core services in concrete & paving",
      list: [
        {
          inlineHeading: "Concrete driveways",
          inlineParagraph: "Durable with finishes like exposed aggregate.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Patios & pathways",
          inlineParagraph: "Concrete or paved for homes and businesses.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Concrete slabs",
          inlineParagraph: "For homes, extensions, and sheds.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Stamped/stenciled",
          inlineParagraph: "Decorative concrete for driveways and paths.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Exposed aggregate",
          inlineParagraph: "Textured, non-slip outdoor surfaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Paving",
          inlineParagraph:
            "Brick, stone, or concrete pavers for various areas.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Retaining walls",
          inlineParagraph: "Concrete or block for landscaping.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Polished floors",
          inlineParagraph: "Sleek concrete for interiors.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Concrete sealing",
          inlineParagraph: "For durability and stain resistance.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial paving",
          inlineParagraph: "Car parks, walkways, and entrances.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "conveyancing",
      Mainheading: "Understanding conveyancing services",
      Mainparagraphs: [
        "Conveyancing services are a crucial part of the property buying and selling process, ensuring the legal transfer of ownership is handled correctly and efficiently. Whether it involves residential homes, commercial properties, or industrial real estate, conveyancers manage every legal aspect of the transaction. This includes preparing and reviewing contracts, conducting title searches, and checking for any restrictions or encumbrances on the property. They also ensure that all necessary conditions are met before the transfer, protecting the interests of both buyers and sellers.",
        "Professional conveyancers play an essential role in navigating complex property laws and regulations, which vary across Australian states and territories. They are responsible for ensuring compliance with legal requirements, such as verifying property boundaries, confirming zoning laws, and managing stamp duty obligations. By coordinating with real estate agents, mortgage lenders, and local authorities, conveyancers help to streamline the settlement process, ensuring that all paperwork is accurate and complete. Their expertise reduces the likelihood of disputes or legal issues arising after the transaction is finalized.",
        "Engaging a licensed conveyancer provides peace of mind by minimizing potential risks, such as hidden liabilities, outstanding debts attached to the property, or incomplete documentation. They also manage financial aspects like deposit payments, balance settlements, and the transfer of funds. Professional conveyancing is invaluable in providing a smooth, legally sound transaction, saving clients time and effort while safeguarding their investment. Whether you are a first-time buyer or a seasoned investor, having a qualified conveyancer ensures your property transfer is completed seamlessly and with full legal protection.",
      ],
      listHeading: "Core services in conveyancing",
      list: [
        {
          inlineHeading: "Residential transactions",
          inlineParagraph:
            "Sale/purchase of homes, apartments, and townhouses.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial conveyancing",
          inlineParagraph:
            "Transfers for offices, retail, and commercial spaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Off-the-plan",
          inlineParagraph:
            "Assisting buyers with contracts and compliance for future properties.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Title transfers",
          inlineParagraph:
            "Managing transfers between family, related parties, or entities.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Property settlements",
          inlineParagraph:
            "Coordinating transactions with all involved parties.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "First home assistance",
          inlineParagraph: "Guiding new buyers through grants and legal steps.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Subdivision",
          inlineParagraph:
            "Handling legal processes for land subdivision and title creation.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Refinancing & discharge",
          inlineParagraph:
            "Managing legalities for refinancing or mortgage discharge.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Vendor statements",
          inlineParagraph:
            "Preparing section 32 disclosures for property sales.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Contract review",
          inlineParagraph:
            "Reviewing or drafting contracts to ensure legal compliance.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "blinds-and-curtains",
      Mainheading: "Understanding curtains, blinds & awnings ",
      Mainparagraphs: [
        "Curtains, blinds, and awnings are vital components of both residential and commercial interiors and exteriors, offering a range of benefits beyond aesthetics. They provide effective light control, allowing you to adjust natural light levels and minimize glare in living or work spaces. By ensuring privacy, these window treatments help create a secure and comfortable environment. Additionally, they contribute to energy efficiency by insulating windows, reducing heat loss in winter and heat gain in summer, which can lower energy bills and improve overall indoor comfort.",
        <>
          Professional installation of curtains, blinds, and awnings is
          essential for proper function and safety. Installers ensure that these
          fittings comply with relevant Australian standards, such{" "}
          <span className="text-primaryblue">
            as AS 2663 for internal window coverings
          </span>
          , which addresses the correct measurement and installation to prevent
          accidents, and{" "}
          <span className="text-primaryblue">
            AS 4055 for external structures like awnings
          </span>
          , which ensures they can withstand wind loads and weather conditions.
          Expert installers can recommend the right products for specific needs,
          such as motorized blinds for convenience or UV-protective awnings for
          outdoor areas, and guarantee that they are securely fitted and
          aligned.
        </>,
        "Regular maintenance is crucial to extend the lifespan and maintain the performance of curtains, blinds, and awnings. This includes cleaning to prevent dust buildup, checking mechanisms for wear, and resealing or treating outdoor awnings for weather resistance. Proper care ensures these fittings remain efficient and visually appealing over time. Investing in high-quality products and professional services not only enhances the appearance of a property but also maximizes the functional benefits, making curtains, blinds, and awnings a valuable addition to any home or commercial space.",
      ],
      listHeading: "Core services in curtains, blinds & awnings",
      list: [
        {
          inlineHeading: "Custom curtains",
          inlineParagraph: "Made-to-measure for perfect fit and style.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Roller blinds",
          inlineParagraph: "Indoor and outdoor installations.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Roman blinds",
          inlineParagraph: "Tailored options for any window size.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Venetian blinds",
          inlineParagraph: "Aluminium, timber, or PVC installations.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Vertical blinds",
          inlineParagraph: "Ideal for sliding doors and large windows.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Motorized blinds",
          inlineParagraph: "Automated for convenience.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Awnings",
          inlineParagraph:
            "Retractable, fixed, or motorized for outdoor spaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Plantation shutters",
          inlineParagraph: "Custom-fit for interior/exterior use.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Outdoor blinds",
          inlineParagraph: "Café, zip blinds, and alfresco enclosures.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Repairs & maintenance",
          inlineParagraph: "Servicing all window coverings.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial fit-outs",
          inlineParagraph: "Solutions for offices and retail spaces.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "decking-and-gazebos",
      Mainheading: "Understanding decking, gazebos & outdoor structures",
      Mainparagraphs: [
        "Decking, gazebos, and other outdoor structures such as carports and verandas are essential for enhancing the usability, aesthetics, and overall value of residential and commercial properties. Decking creates a seamless transition between indoor and outdoor spaces, providing a versatile area for entertainment or relaxation. Gazebos and verandas offer sheltered spaces, protecting against the elements while adding architectural appeal. These structures make outdoor areas more enjoyable and functional, whether for hosting gatherings, creating a quiet retreat, or providing convenient covered parking.",
        <>
          Professional installation of decking and outdoor structures is crucial
          to ensure safety, stability, and compliance with Australian building
          codes. For example,{" "}
          <span className="text-primaryblue">
            AS 1684 governs timber-framed construction
          </span>
          , specifying requirements for structural soundness, while{" "}
          <span className="text-primaryblue">
            AS 5601 addresses safety considerations for structures near gas
            installations
          </span>
          , such as outdoor kitchens or barbecues. Proper installation by
          licensed specialists ensures that materials are securely fixed,
          load-bearing capacities are met, and all safety features are
          incorporated, reducing the risk of damage or accidents.
        </>,
        "Using high-quality materials and construction techniques also enhances the durability and longevity of these outdoor additions. Timber decks can be treated to resist weather and insect damage, while metal or composite structures offer low-maintenance options. Regular maintenance, like resealing or inspecting for structural integrity, further prolongs their lifespan. By investing in professional services, property owners can maximize the functionality and aesthetic appeal of their outdoor spaces, creating inviting areas that increase the property's value and usability year-round.",
      ],
      listHeading: "Core services in decking, gazebos & outdoor structures",
      list: [
        {
          inlineHeading: "Timber decking",
          inlineParagraph:
            "Hardwood, softwood, and treated timber installations.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Composite decking",
          inlineParagraph: "Eco-friendly, low-maintenance decks.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Pool decking",
          inlineParagraph: "Slip-resistant decks for pools and spas.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Pergolas & gazebos",
          inlineParagraph: "Custom outdoor structures for shade and style.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Verandas",
          inlineParagraph: "Shaded spaces attached to homes.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Carports",
          inlineParagraph: "Durable shelters for vehicle protection.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Patios",
          inlineParagraph: "Paved or decked areas for outdoor entertainment.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Roofed decks",
          inlineParagraph: "Covered decks and gazebos for weather resistance.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Deck extensions",
          inlineParagraph: "Expanding or renovating existing decks.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Deck repairs",
          inlineParagraph: "Restoring and fixing damaged decks.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Balustrades",
          inlineParagraph: "Safety railings in timber, glass, or metal.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial structures",
          inlineParagraph: "Outdoor areas for seating and dining.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "demolition-and-excavation",
      Mainheading: "Understanding demolition & excavation",
      Mainparagraphs: [
        "Demolition and excavation services are the first steps in many construction projects, involving the safe removal of existing structures and the preparation of land for new developments. These services are essential for clearing sites, whether it’s tearing down residential homes, commercial buildings, or industrial facilities. Demolition may include the controlled dismantling of structures, while excavation involves digging, grading, and soil removal to create a stable foundation for future construction. This process requires precision and expertise to ensure the land is ready for building.",
        <>
          Safety and environmental protection are key priorities in demolition
          and excavation work. Compliance with Australian standards, such as{" "}
          <span className="text-primaryblue">
            AS 2601 for the demolition of structures
          </span>
          , ensures that projects are carried out using best practices to
          minimize risk. This includes measures for dust control, noise
          reduction, and the safe disposal of debris.{" "}
          <span className="text-primaryblue">
            AS 3798governs site preparation
          </span>
          , emphasizing proper handling of soil, drainage, and compaction to
          prevent future structural issues. Adhering to these standards not only
          safeguards workers and nearby communities but also protects the
          environment from contamination or erosion.
        </>,
        "Proper handling of hazardous materials, such as asbestos or contaminated soil, is another critical aspect of demolition and excavation services. Licensed professionals are trained to identify and safely remove these materials, following strict regulatory guidelines to prevent exposure and environmental harm. By hiring experienced and compliant contractors, property owners can ensure that their site is prepared correctly, reducing delays and potential liabilities. Whether it’s for a new residential development or a large commercial project, professional demolition and excavation set the stage for safe and efficient construction.",
      ],
      listHeading: "Core services in demolition & excavation",
      list: [
        {
          inlineHeading: "Residential demolition",
          inlineParagraph:
            "Full or partial removal of homes and small structures.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial demolition",
          inlineParagraph: "Clearing retail spaces, warehouses, and offices.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Industrial demolition",
          inlineParagraph:
            "Removing factories and heavy industrial facilities.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Concrete breaking",
          inlineParagraph:
            "Demolishing and removing slabs, driveways, and foundations.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Land clearing",
          inlineParagraph:
            "Preparing land by clearing vegetation and obstacles.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Excavation",
          inlineParagraph:
            "Digging foundations, trenches, and footings for construction.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Bulk earthworks",
          inlineParagraph:
            "Large-scale land leveling and infrastructure excavation.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Trenching",
          inlineParagraph:
            "Digging trenches for utilities like water, gas, and sewage.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Asbestos removal",
          inlineParagraph: "Safe removal and disposal of hazardous materials.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Waste removal",
          inlineParagraph: "Clearing debris and waste from demolition sites.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "electrical",
      Mainheading: "Understanding electrical",
      Mainparagraphs: [
        "Electrical services are crucial for the safe and efficient operation of power systems in residential, commercial, and industrial settings. These services cover the installation, maintenance, and repair of electrical components, ensuring that power is distributed effectively throughout a property. Electricians handle tasks such as wiring, circuit breaker installation, and setting up lighting fixtures, all while adhering to strict safety protocols. Whether it's a new home build or an office renovation, proper electrical work is essential to prevent hazards like short circuits, electrical fires, or system failures.",
        <>
          Professional electricians play a vital role in ensuring compliance
          with Australian standards, such as{" "}
          <span className="text-primaryblue">
            AS/NZS 3000, also known as the Wiring Rules
          </span>
          . These standards outline best practices for electrical installations,
          focusing on safety, functionality, and reliability. By following these
          regulations, electricians minimize the risk of accidents and ensure
          that electrical systems are up to code. This compliance is especially
          critical in commercial and industrial environments, where electrical
          systems are more complex and pose greater safety risks if not properly
          managed.
        </>,
        "Beyond basic wiring and lighting, modern electrical services also include appliance installation, energy management, and smart home integration. Electricians can install energy-efficient lighting, solar panels, or automated systems that help reduce power consumption and lower energy costs. They also perform regular maintenance checks to identify and fix potential issues before they escalate, ensuring the longevity and safety of electrical systems. By relying on professional electricians, property owners can rest assured that their electrical needs are met safely, efficiently, and in compliance with all relevant standards.",
      ],
      listHeading: "Core services in electrical",
      list: [
        {
          inlineHeading: "Repairs",
          inlineParagraph:
            "Fixing faults, short circuits, and malfunctioning outlets.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Wiring & rewiring",
          inlineParagraph: "Installing new or upgrading old wiring for safety.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Lighting",
          inlineParagraph: "Indoor, outdoor, and LED system installations.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Switchboard upgrades",
          inlineParagraph: "Enhancing electrical capacity and safety.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Safety inspections",
          inlineParagraph: "Identifying hazards and ensuring compliance.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Ceiling fans",
          inlineParagraph: "Installing for cooling and air circulation.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Smoke alarms",
          inlineParagraph: "Setting up to meet safety regulations.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Appliance installation",
          inlineParagraph: "Installing kitchen, HVAC, and other equipment.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Solar panels",
          inlineParagraph:
            "Installing panels and inverters for renewable energy.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial electrical",
          inlineParagraph:
            "Large-scale work for offices and industrial spaces.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "engineering-services",
      Mainheading: "Understanding engineering ",
      Mainparagraphs: [
        "Engineering services are fundamental to the successful planning, design, and construction of buildings and infrastructure. These services encompass a wide range of disciplines, including structural, civil, mechanical, and electrical engineering, all of which play a crucial role in creating safe, efficient, and sustainable environments. From residential projects to large-scale commercial and industrial developments, engineers ensure that every aspect of a construction project is carefully evaluated and executed to meet both functional and aesthetic goals.",
        <>
          Engineers adhere to strict Australian standards to guarantee safety,
          durability, and compliance. For example,{" "}
          <span className="text-primaryblue">
            AS 3600outlines the requirements for concrete structures
          </span>
          , ensuring they are designed to withstand loads and environmental
          factors over time. Similarly,
          <span className="text-primaryblue">
            {" "}
            AS/NZS 3000 governs electrical installations, providing guidelines
            to maintain safety and efficiency in power systems
          </span>
          . By following these standards, engineers help prevent potential
          risks, such as structural failures or electrical hazards, while
          optimizing the performance and longevity of buildings and
          infrastructure.
        </>,
        "Engaging a qualified engineer brings significant benefits, including compliance with regulations, expert problem-solving, and the use of advanced technologies to streamline the construction process. Engineers conduct detailed site assessments, perform load calculations, and develop innovative solutions to address project-specific challenges. Their expertise ensures that projects are completed on time and within budget while maintaining structural integrity and functionality. Whether it’s designing a complex bridge, optimizing a building's energy efficiency, or ensuring the safety of a high-rise structure, professional engineering services are vital for achieving successful outcomes.",
      ],
      listHeading: "Core services in engineering",
      list: [
        {
          inlineHeading: "Structural engineering",
          inlineParagraph: "Designing safe building frameworks.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Civil engineering",
          inlineParagraph: "Developing roads, drainage, and infrastructure.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Electrical engineering",
          inlineParagraph: "Installing building electrical systems.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Mechanical engineering",
          inlineParagraph: "Designing HVAC, elevators, and mechanical systems.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Geotechnical engineering",
          inlineParagraph: "Soil analysis and foundation planning.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Hydraulic engineering",
          inlineParagraph: "Water, wastewater, and stormwater system design.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Project management",
          inlineParagraph: "Managing engineering elements of construction.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Compliance & certification",
          inlineParagraph: "Ensuring code and safety standard adherence.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "fence-and-gates",
      Mainheading: "Understanding fence & gate",
      Mainparagraphs: [
        "Fencing and gate services are crucial for establishing security, privacy, and aesthetic appeal for a wide range of properties, including residential homes, commercial facilities, and industrial sites. Well-designed fences and gates create clear property boundaries and offer protection against intruders, making them a valuable investment for safety-conscious property owners. In addition to enhancing security, they can also improve curb appeal, contributing to the overall property value and providing a welcoming and well-defined exterior.",
        <>
          Beyond aesthetics and security, fences and gates are often essential
          for safety and regulatory compliance. For example, pool fencing must
          meet strict Australian standards, such as{" "}
          <span className="text-primaryblue">
            AS 1926.1, which outlines the requirements for child-resistant
            barriers to prevent drowning incidents
          </span>
          . General fencing standards, such as{" "}
          <span className="text-primaryblue">
            AS 1725, ensure that structures are built to withstand environmental
            conditions and everyday use
          </span>
          . Professional installers are knowledgeable about these regulations
          and can design and construct fences and gates that not only meet legal
          requirements but also suit the property's specific needs.
        </>,
        "Engaging a professional fencing and gate service guarantees a high-quality installation that prioritizes durability and functionality. Experts assess site conditions, choose appropriate materials, and ensure that fences and gates are installed securely and level, preventing issues like sagging or instability over time. They also provide valuable advice on maintenance and repairs to extend the lifespan of these structures. Whether it’s for a stylish front yard fence, a secure industrial gate, or a safety-compliant pool barrier, professional services ensure that fences and gates perform their intended functions effectively and safely.",
      ],
      listHeading: "Core services in fence & gates",
      list: [
        {
          inlineHeading: "Residential fencing",
          inlineParagraph:
            "Timber, Colorbond, vinyl, and wrought iron options.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Pool fencing",
          inlineParagraph: "Safe, compliant barriers for pools.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial fencing",
          inlineParagraph: "Chain link, steel, and security fences.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Automatic gates",
          inlineParagraph: "Electric gates for homes and businesses.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Sliding & swing gates",
          inlineParagraph: "Manual or automated solutions.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Temporary fencing",
          inlineParagraph: "For construction or events.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Gate & garage repairs",
          inlineParagraph: "Fixing gates, motors, and garage doors.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Rural fencing",
          inlineParagraph: "Barbed wire, electric, and timber for farms.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Privacy screens",
          inlineParagraph: "Custom outdoor privacy solutions.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Custom fencing",
          inlineParagraph: "Tailored designs for specific needs.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Garage doors",
          inlineParagraph: "Roller, sectional, and automated installations.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "glazing-and-screens",
      Mainheading: "Understanding glazing & screens",
      Mainparagraphs: [
        "Glazing and screen services are essential for the installation, repair, and maintenance of glass elements in various settings, including residential homes, commercial buildings, and industrial facilities. These services include everything from fitting energy-efficient windows and shower screens to installing large glass facades and safety glass panels. High-quality glass enhances natural light, improves insulation, and adds to the overall aesthetic of a space, making glazing a vital component in modern construction and design.",
        <>
          Safety and compliance are paramount in glazing work. Professionals
          adhere to Australian standards such as{" "}
          <span className="text-primaryblue">
            AS 1288 for safe glass installation{" "}
          </span>{" "}
          and {""}
          <span className="text-primaryblue">
            AS 2047 for window installation
          </span>
          , ensuring structural integrity and minimizing the risk of injury or
          damage.{" "}
          <span className="text-primaryblue">
            AS 2208 specifies the use of safety glazing materials
          </span>{" "}
          in buildings to prevent shattering and protect occupants, while{" "}
          <span className="text-primaryblue">
            AS/NZS 4666 outlines requirements for insulating glass units
          </span>{" "}
          ensuring energy efficiency and durability in double glazing. Adhering
          to these standards ensures that glass installations are not only
          aesthetically pleasing but also secure and long-lasting.
        </>,
        "Beyond installation, glazing and screen specialists provide repair and maintenance services to keep glass features in optimal condition. This includes resealing windows, replacing damaged glass, and servicing sliding doors and fly screens. Regular maintenance helps extend the lifespan of glass elements and ensures continued performance. Whether for a simple window replacement or a complex architectural glass project, professional glazing services deliver clarity, security, and an enhanced aesthetic for any property.",
      ],
      listHeading: "Core services in glazing & screens",
      list: [
        {
          inlineHeading: "Window glazing",
          inlineParagraph:
            "Clear, tinted, frosted, or double-glazed windows for energy efficiency.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Shower screens",
          inlineParagraph:
            "Framed, semi-frameless, or frameless glass for modern bathrooms.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Bathroom screens",
          inlineParagraph:
            "Glass partitions for bathtubs, wet rooms, and privacy.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial glazing",
          inlineParagraph:
            "Shopfronts, office partitions, and large glass panels.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Safety & security glass",
          inlineParagraph: "Toughened, laminated, or bullet-resistant glass.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Splashbacks",
          inlineParagraph:
            "Durable glass splashbacks for kitchens and bathrooms.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Glass balustrades & stairs",
          inlineParagraph: "Safe, stylish balustrades and stairway glazing.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Mirror installation",
          inlineParagraph:
            "Custom mirrors for homes, gyms, and commercial spaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Glass repairs",
          inlineParagraph: "Fixing or replacing cracked or damaged glass.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Pool fencing",
          inlineParagraph:
            "Compliant glass barriers for safety and aesthetics.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Architectural glazing",
          inlineParagraph: "Skylights and feature walls for unique designs.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "garden-and-landscaping",
      Mainheading: "Understanding garden & landscaping",
      Mainparagraphs: [
        "Garden and landscaping services play a vital role in transforming outdoor areas into beautiful, functional spaces that enhance the overall aesthetic and usability of a property. These services encompass everything from designing lush gardens and building walkways to installing water features and outdoor entertainment areas. By thoughtfully planning landscapes, professionals create environments that are not only visually appealing but also cater to the needs of homeowners, businesses, or the public, promoting outdoor living and enjoyment.",
        <>
          The construction aspect of landscaping often involves creating
          hardscapes such as patios, retaining walls, and pathways, as well as
          integrating irrigation systems to maintain healthy plant growth. These
          features require precise engineering to ensure proper drainage, soil
          stability, and long-term durability. Professionals adhere to
          Australian standards like{" "}
          <span className="text-primaryblue">AS 4419for soil quality</span>,
          <span className="text-primaryblue">
            AS 2155 for retaining wall construction
          </span>
          , and{" "}
          <span className="text-primaryblue">
            AS 3500 for efficient irrigation systems
          </span>
          . Compliance with these standards ensures sustainable landscaping
          practices that promote environmental health and resource efficiency.
        </>,
        <>
          Maintenance services are equally important for preserving the beauty
          and functionality of outdoor spaces. Regular tasks like lawn mowing,
          pruning, mulching, and seasonal planting keep gardens vibrant
          year-round. Experts follow{" "}
          <span className="text-primaryblue">
            AS 4970 to protect existing trees during development{" "}
          </span>{" "}
          and
          <span className="text-primaryblue">
            AS/NZS 1158 for outdoor lighting, ensuring safe and well-illuminated
            pathways
          </span>
          . By employing sustainable practices and using quality materials,
          garden and landscaping specialists maximize the property’s value while
          creating inviting, durable, and environmentally responsible outdoor
          areas.
        </>,
      ],
      listHeading: "Core services in garden & landscaping",
      list: [
        {
          inlineHeading: "Landscape design",
          inlineParagraph:
            "Planning gardens with hardscapes, plants, and aesthetic elements.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Garden maintenance",
          inlineParagraph:
            "Mowing, weeding, pruning, fertilizing, and pest control.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Hardscaping",
          inlineParagraph:
            "Building pathways, patios, decks, retaining walls, and pergolas.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Soft scaping",
          inlineParagraph:
            "Planting trees, shrubs, and flowers for vibrant gardens.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Irrigation systems",
          inlineParagraph:
            "Designing efficient watering systems for lawns and plants.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Turf installation",
          inlineParagraph:
            "Laying natural or synthetic grass for lawns and sports fields.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Retaining walls",
          inlineParagraph:
            "Constructing walls to manage erosion and create levelled spaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Pool landscaping",
          inlineParagraph:
            "Designing poolside areas with plants and functional features.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Mulching & soil prep",
          inlineParagraph:
            "Adding mulch and preparing soil to promote healthy growth.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Outdoor lighting",
          inlineParagraph:
            "Installing lighting for safety, visibility, and ambiance.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "handyman",
      Mainheading: "Understanding handyman services",
      Mainparagraphs: [
        <>
          Handyman services cover a wide range of repair, maintenance, and
          improvement tasks for residential, commercial, and industrial
          properties. From fixing leaky faucets and patching drywall to
          assembling furniture and installing light fixtures, handymen provide
          practical solutions to everyday issues. These versatile professionals
          are equipped to handle various jobs efficiently, saving property
          owners time and effort. To ensure safety and quality, they follow
          relevant Australian standards, such as{" "}
          <span className="text-primaryblue">
            AS/NZS 3000 for minor electrical
          </span>
          , repairs and{" "}
          <span className="text-primaryblue">AS 3500 for plumbing work</span>.
        </>,
        <>
          Beyond simple fixes, handymen often assist with small-scale renovation
          projects like painting, tiling, or carpentry. Adhering to{" "}
          <span className="text-primaryblue">
            AS 2311 for painting and maintenance{" "}
          </span>{" "}
          and{" "}
          <span className="text-primaryblue">
            AS 3661 for tiling and slip resistance in wet areas
          </span>
          , they ensure all work meets the necessary safety and performance
          requirements. Many handymen are skilled in multiple trades, making
          them ideal for minor upgrades that don’t require specialized
          contractors. Whether installing shelves, replacing doors, or
          performing general upkeep, handyman services play a crucial role in
          maintaining a property's functionality and aesthetics.
        </>,
        <>
          Hiring a licensed handyman ensures that tasks are completed safely and
          up to standard, with proper compliance to Australian regulations. For
          instance, when repairing or replacing windows or glass doors, handymen
          adhere to{" "}
          <span className="text-primaryblue">
            AS 1288for safe glass handling and installation
          </span>
          . Licensed professionals are knowledgeable about the best practices
          for each job, minimizing risks and potential damage. They also provide
          advice on preventive maintenance to avoid future issues, making them a
          valuable resource for property owners looking to keep their spaces in
          top condition. Let me know
        </>,
      ],
      listHeading: "Core services in handyman work",
      list: [
        {
          inlineHeading: "General repairs",
          inlineParagraph:
            "Fixing doors, windows, cabinets, shelves, and more.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Plumbing fixes",
          inlineParagraph:
            "Repairing leaks, replacing faucets, and unblocking drains.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Electrical repairs",
          inlineParagraph:
            "Replacing switches, installing lights, and repairing outlets.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Carpentry",
          inlineParagraph:
            "Installing shelves, fixing frames, and assembling furniture.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Painting & patching",
          inlineParagraph: "Interior/exterior painting and wall patching.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Tiling & grouting",
          inlineParagraph:
            "Replacing tiles and regrouting bathrooms or kitchens.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Furniture assembly",
          inlineParagraph: "Assembling flat-pack or custom furniture.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Gutter cleaning",
          inlineParagraph: "Clearing gutters to ensure proper drainage.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Picture & tv mounting",
          inlineParagraph:
            "Installing wall mounts for TVs, mirrors, and frames.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Fence repairs",
          inlineParagraph:
            "Fixing boards, replacing panels, and painting fences.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "inspection-and-surveyors",
      Mainheading: "Understanding inspections & surveyors ",
      Mainparagraphs: [
        "Inspection and surveying services are essential for evaluating the condition, safety, and compliance of properties in various stages, whether buying, selling, or developing real estate. These services provide a detailed assessment to ensure that properties adhere to legal and safety standards, protecting both buyers and owners from future liabilities. Common types of inspections include building assessments, pest inspections, land surveys, and compliance checks, all of which contribute to informed decision-making in property transactions.",
        <>
          Building inspections, guided by{" "}
          <span className="text-primaryblue">
            AS 4349, assess structural integrity
          </span>
          , identify potential defects, and ensure the property meets safety
          standards. For land surveys,{" "}
          <span className="text-primaryblue">
            AS 5488 provides guidelines for obtaining accurate subsurface
            utility information
          </span>
          , essential for development projects that require knowledge of
          underground infrastructure. Additionally{" "}
          <span className="text-primaryblue">
            , AS 3660.1 outlines requirements for termite management
          </span>
          , which is crucial for maintaining the structural safety of
          timber-based buildings. Compliance with these standards helps mitigate
          risks and ensures the property is safe and sound for occupancy or
          development.
        </>,
        <>
          Surveyors also conduct geotechnical site investigations, following{" "}
          <span className="text-primaryblue">
            AS 1726, to analyse soil conditions{" "}
          </span>{" "}
          and assess site suitability for construction. For properties with
          potential asbestos risks,{" "}
          <span className="text-primaryblue">
            AS 4964 provides guidelines for conducting thorough asbestos surveys
          </span>
          , ensuring proper identification and management. By engaging licensed
          inspectors and surveyors, property owners and developers can be
          confident that all work is done in accordance with Australian
          regulations, minimizing hazards and ensuring the property’s long-term
          value and safety.
        </>,
      ],
      listHeading: "Core services in inspections & surveying",
      list: [
        {
          inlineHeading: "Building inspections",
          inlineParagraph: "Pre-purchase checks for structural integrity.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Land surveys",
          inlineParagraph: "Measuring boundaries and topography.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Pest inspections",
          inlineParagraph: "Detecting termites and pest issues.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Dilapidation reports",
          inlineParagraph:
            "Documenting building conditions pre- and post-construction.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Boundary surveys",
          inlineParagraph: "Confirming property lines.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Compliance inspections",
          inlineParagraph: "Ensuring code and safety compliance.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Subdivision surveys",
          inlineParagraph: "Preparing land for subdivisions and new titles.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Asbestos inspections",
          inlineParagraph: "Identifying and advising on asbestos removal.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Strata surveys",
          inlineParagraph: "Surveying for titles and common area management.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Structural inspections",
          inlineParagraph: "Assessing key structural components.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "security-and-automation",
      Mainheading: "Understanding home security & automation",
      Mainparagraphs: [
        "Home security and automation services play a crucial role in enhancing the safety, convenience, and efficiency of residential properties. These services include the installation of intruder alarms, surveillance cameras, and advanced smart home devices that can control lighting, heating, and appliances remotely. By integrating these systems, homeowners can monitor and manage their property from anywhere, ensuring peace of mind and increased control over daily operations.",
        <>
          Modern security and automation solutions not only focus on protecting
          against intrusions but also offer features that improve energy
          efficiency and streamline everyday tasks. Smart thermostats, automated
          lighting systems, and remote-controlled blinds are designed to
          optimize energy use, reducing utility bills and minimizing
          environmental impact. Compliance with Australian standards, such as{" "}
          <span className="text-primaryblue">AS 2201 for intruder alarms</span>
          {""} and{" "}
          <span className="text-primaryblue">AS 3786 for smoke alarms</span>,
          ensures that these systems operate effectively and provide the highest
          level of safety.
        </>,
        <>
          Professional installation is essential to ensure these systems are set
          up correctly and in line with safety regulations. Standards{" "}
          <span className="text-primaryblue">
            like AS/NZS 3000 govern the safe wiring of electrical components
          </span>
          , while
          <span className="text-primaryblue">
            {" "}
            AS 1288 applies to smart glass installations
          </span>
          , and
          <span className="text-primaryblue">
            {" "}
            AS 2688 outlines security protocols for restricted access systems
          </span>{" "}
          . Adhering to these standards guarantees that all components work
          seamlessly and securely, making homes smarter, safer, and more
          efficient.
        </>,
      ],
      listHeading: "Core services in home security & automation",
      list: [
        {
          inlineHeading: "Intruder alarms",
          inlineParagraph:
            "Installing and monitoring systems to prevent break-ins.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "CCTV cameras",
          inlineParagraph:
            "Indoor and outdoor surveillance for 24/7 monitoring.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Smart locks",
          inlineParagraph:
            "Keyless entry with remote access and added security features.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Home automation",
          inlineParagraph:
            "Integrating smart devices for lighting, appliances, and climate control.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Intercom systems",
          inlineParagraph:
            "Audio and video intercoms for secure entry management.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Access control",
          inlineParagraph:
            "Managing entry points with keycards, codes, or biometric systems.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Smart lighting",
          inlineParagraph:
            "Remote-controlled or scheduled lighting for convenience and security.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Security lighting",
          inlineParagraph: "Motion-sensor and floodlights to deter intruders.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Smoke & fire alarms",
          inlineParagraph:
            "Installing alarms for early fire detection and safety compliance.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Energy management",
          inlineParagraph:
            "Smart thermostats and systems to save energy and reduce costs.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "insulation-services",
      Mainheading: "Understanding insulation services",
      Mainparagraphs: [
        "Insulation services play a key role in enhancing energy efficiency and improving indoor comfort across residential, commercial, and industrial spaces. By reducing heat transfer, insulation helps regulate indoor temperatures, making it easier to maintain a comfortable environment year-round. Proper insulation lowers heating and cooling costs by minimizing energy loss, contributing to more sustainable and cost-effective energy use.",
        <>
          In addition to energy savings, insulation also helps control moisture
          levels and improves air quality within a property. Effective moisture
          control can prevent issues like mold growth, which protects the
          building’s structure and enhances indoor health. Professional
          installers follow Australian standards, such as{" "}
          <span className="text-primaryblue">
            AS 4859.1 for insulation materials
          </span>
          , ensuring that products meet thermal performance and durability
          requirements. Adhering to these standards guarantees that insulation
          will provide the intended energy efficiency benefits while maintaining
          safety.
        </>,
        <>
          Licensed insulation specialists ensure compliance with a range of
          standards, including{" "}
          <span className="text-primaryblue">
            AS 1530.3 for fire resistance
          </span>{" "}
          and
          <span className="text-primaryblue">
            {" "}
            AS 3999 for bulk insulation installation. Reflective foil insulation
            installations are guided by AS/NZS 4200
          </span>{" "}
          , and
          <span className="text-primaryblue">
            {" "}
            AS 4072.1 outlines fire protection for wall, floor, and ceiling
            penetrations
          </span>
          , ensuring that insulation materials are both effective and safe.
          Professional installation by a licensed provider ensures that
          insulation is installed correctly and adheres to these codes,
          maximizing its benefits and protecting the property’s overall
          integrity.
        </>,
      ],
      listHeading: "Core services in insulation",
      list: [
        {
          inlineHeading: "Ceiling insulation",
          inlineParagraph:
            "Installing batts, rolls, or foam to retain indoor temperature.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Wall insulation",
          inlineParagraph:
            "Insulating walls to boost thermal efficiency and reduce noise.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Floor insulation",
          inlineParagraph:
            "Underfloor insulation to prevent heat loss and dampness.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Roof insulation",
          inlineParagraph:
            "Adding insulation to roofs for better energy efficiency.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Soundproofing",
          inlineParagraph:
            "Acoustic insulation for noise reduction in walls and floors.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Pipe & duct insulation",
          inlineParagraph:
            "Insulating HVAC systems and pipes to prevent energy loss.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Spray foam",
          inlineParagraph:
            "Sealing gaps in hard-to-reach areas with spray foam.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Foil insulation",
          inlineParagraph:
            "Reflective foil to block radiant heat in roofs and attics.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Insulation removal & replacement",
          inlineParagraph: "Removing old insulation and replacing it.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial insulation",
          inlineParagraph: "Large-scale insulation for commercial properties.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "interior-design",
      Mainheading: "Understanding interior design services",
      Mainparagraphs: [
        "Interior design services are dedicated to improving the aesthetic appeal, functionality, and comfort of indoor spaces in residential, commercial, and industrial environments. These services cover a wide array of tasks, including space planning, color coordination, furniture selection, and decorative styling. By carefully balancing form and function, professional interior designers create cohesive and visually pleasing spaces tailored to each client’s preferences and practical needs.",
        <>
          An essential aspect of interior design is ensuring that spaces are not
          only beautiful but also functional and safe. Professional designers
          follow Australian standards such as{" "}
          <span className="text-primaryblue">
            AS 1428, which ensures accessibility for all, including people with
            disabilities
          </span>
          , and{" "}
          <span className="text-primaryblue">
            AS/NZS 2107, which focuses on acoustic quality, enhancing comfort
            and usability
          </span>
          . These standards are especially crucial for commercial spaces, where
          access and noise control can significantly impact the user experience.
        </>,
        "Additionally, interior designers consider elements like lighting, ergonomics, and sustainable materials to create spaces that are both stylish and environmentally conscious. With expert knowledge and creativity, licensed interior designers help transform spaces into environments that reflect personal style, align with functional requirements, and comply with all relevant safety and accessibility regulations",
      ],
      listHeading: "Core services in interior design",
      list: [
        {
          inlineHeading: "Residential design",
          inlineParagraph: "Custom interiors for homes.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial design",
          inlineParagraph: "Stylish, functional spaces for businesses.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Space planning",
          inlineParagraph: "Optimizing layout and flow.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Color consultation",
          inlineParagraph: "Enhancing mood with color schemes.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Furniture selection",
          inlineParagraph: "Choosing and arranging pieces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Lighting design",
          inlineParagraph: "Planning efficient, ambient lighting.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Kitchen & bath design",
          inlineParagraph: "Functional layouts for key areas.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Sustainable design",
          inlineParagraph: "Eco-friendly materials and systems.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "3D visualization",
          inlineParagraph: "Previewing designs in 3D.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Decor & styling",
          inlineParagraph: "Final touches with accessories and art.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "locksmiths",
      Mainheading: "Understanding locksmith services",
      Mainparagraphs: [
        "Locksmith services are vital for securing residential, commercial, and industrial properties, providing a range of solutions to meet various security needs. Services include lock installation and repair, rekeying, and advanced security system installation, all designed to protect property and assets effectively. Professional locksmiths not only help secure entrances and sensitive areas but also respond swiftly in emergencies, like lockouts or urgent security upgrades.",
        <>
          These services adhere to Australian standards, such as{" "}
          <span className="text-primaryblue">
            AS 4145 for locksets and security hardware
          </span>
          , ensuring that all installations meet durability, quality, and safety
          requirements. Locksmiths also offer expertise in keyless entry,
          electronic locks, and access control systems, providing tailored
          security options that suit different property types and levels of
          security.
        </>,
        "Licensed locksmiths ensure both compliance and reliability while enhancing property safety through high-quality, tested materials and efficient solutions that align with Australian regulations. Engaging a professional locksmith provides peace of mind, knowing your security system is both robust and compliant.",
      ],
      listHeading: "Core services in locksmithing",
      list: [
        {
          inlineHeading: "Lock installation",
          inlineParagraph: "Installing deadbolts, digital, and mortise locks.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Lock repair",
          inlineParagraph: "Fixing damaged locks to restore security.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Rekeying",
          inlineParagraph: "Changing lock configurations to disable old keys.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Master key systems",
          inlineParagraph:
            "Creating systems for multi-unit or office buildings.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Key cutting",
          inlineParagraph: "Duplicating keys for various uses.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Safe services",
          inlineParagraph: "Installing and opening safes.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Digital locks",
          inlineParagraph: "Setting up electronic and keyless entry systems.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Security upgrades",
          inlineParagraph: "Adding deadbolts or access controls.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Emergency lockouts",
          inlineParagraph: "24/7 lockout assistance.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Window & gate locks",
          inlineParagraph:
            "Installing and repairing locks on windows and gates.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "painting-and-decorating",
      Mainheading: "Understanding painting & decoration services",
      Mainparagraphs: [
        "Painting and decoration services are essential for enhancing the visual appeal, ambiance, and overall value of residential, commercial, and industrial properties. These services cover a wide range of tasks, including interior and exterior painting, wallpaper installation, and the application of decorative finishes. Professional painters bring both technical expertise and a keen sense of aesthetics to create spaces that reflect the client’s vision, whether the goal is to make a home feel more welcoming, a commercial space more engaging, or an industrial property more polished and protected. Quality paintwork and careful color selection can also significantly improve mood and productivity, especially in work environments.",
        <>
          Beyond aesthetics, painting and decoration services focus on
          durability, safety, and compliance with relevant industry standards.
          Each step in the process, from surface preparation to the application
          of finishes, is conducted with precision to ensure longevity and
          quality results. Professionals adhere to standards like{" "}
          <span className="text-primaryblue">
            AS 2311 for general painting practices
          </span>
          , which encompass the proper methods for surface preparation, paint
          application, and achieving consistent finishes. For projects involving
          older buildings or potentially hazardous materials,{" "}
          <span className="text-primaryblue">
            AS 4361.1 provides guidelines for the safe handling of lead-based
            and other hazardous paints
          </span>
          , ensuring safety for both workers and occupants. High-quality,
          standard-compliant finishes help protect surfaces from wear, damage,
          and environmental factors.
        </>,
        "Decoration services often extend to specialized techniques, such as textured finishes, faux effects, and feature walls, which add character and dimension to a room. Skilled decorators may also apply wallpapers, murals, or specialty coatings to achieve distinctive looks that reflect personal style or branding elements in commercial spaces. By working closely with clients, professional painters and decorators help bring creative ideas to life, transforming ordinary spaces into inspiring and functional environments tailored to individual needs.",
      ],
      listHeading: "Core services in painting & decoration",
      list: [
        {
          inlineHeading: "Interior painting",
          inlineParagraph: "Walls, ceilings, and trims with quality finishes.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Exterior painting",
          inlineParagraph:
            "Weather-resistant paint for building exteriors, fences, and decks.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Wallpapering",
          inlineParagraph:
            "Installing or removing wallpaper for stylish interiors.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Decorative finishes",
          inlineParagraph:
            "Textures, faux finishes, and feature walls for unique looks.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Spray painting",
          inlineParagraph:
            "Smooth finishes for large areas like garages and fences.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Color consultation",
          inlineParagraph: "Expert advice on color schemes.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Feature walls",
          inlineParagraph: "Bold accent walls for focal points.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Ceiling & trim painting",
          inlineParagraph: "Detailed ceiling and trim work.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Fence & deck painting",
          inlineParagraph: "Protective finishes for outdoor woodwork.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Surface prep",
          inlineParagraph: "Sanding, priming for strong paint adhesion.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "moving-and-storage",
      Mainheading: "Understanding moving & storage services",
      Mainparagraphs: [
        "Moving and storage services provide tailored solutions for residential, commercial, and interstate relocations. Professional movers handle every aspect of the process, from secure packing and careful transportation to timely delivery. Storage facilities offer both short- and long-term options in secure, climate-controlled environments for items that need temporary or extended safekeeping. These services follow established guidelines to prioritize safety, efficiency, and customer satisfaction, ensuring a smooth and hassle-free experience.",
        "In addition to basic moving and storage, many providers offer specialty services for unique or high-value items, such as antiques, artwork, or sensitive electronics. Professional movers use specialized equipment and protective materials to handle these items with extra care, minimizing the risk of damage. Some companies also offer packing and unpacking assistance, furniture assembly, and even setup services, allowing clients to settle into their new space quickly and comfortably. This comprehensive support simplifies the moving process, accommodating a range of needs to make each relocation as efficient and stress-free as possible.",
      ],
      listHeading: "Core services in moving & storage",
      list: [
        {
          inlineHeading: "Local moving",
          inlineParagraph: "Safe local household moves.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Interstate moving",
          inlineParagraph: "Secure long-distance relocations.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial moving",
          inlineParagraph: "Office moves with minimal downtime.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Furniture removals",
          inlineParagraph: "Disassembly, transport, reassembly.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Packing services",
          inlineParagraph: "Full packing/unpacking support.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Specialty items",
          inlineParagraph: "Careful moving of delicate/large items.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Storage",
          inlineParagraph: "Secure short- or long-term storage.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Supplies",
          inlineParagraph: "Packing boxes, wrap, and tape.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Loading/unloading",
          inlineParagraph: "Safe handling at both ends.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Last-minute moves",
          inlineParagraph: "Quick response for urgent relocations.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "mold-and-asbestos",
      Mainheading: "Understanding mould & asbestos services",
      Mainparagraphs: [
        "Mould and asbestos services are critical for maintaining safe, healthy environments in residential, commercial, and industrial properties. These services involve identifying, safely removing, and preventing the reoccurrence of these hazardous materials, which can seriously impact occupant health and property integrity if left untreated.",
        <>
          Mould growth is common in damp areas and can spread quickly,
          especially in regions with high humidity. Beyond the obvious aesthetic
          issues, mould can damage building materials and release spores that
          lower indoor air quality, leading to respiratory issues, allergies,
          and worsening of conditions like asthma. Proper mould removal services
          assess the extent of contamination, thoroughly clean and disinfect
          affected areas, and recommend preventative steps to reduce future
          mould growth. Professionals follow{" "}
          <span className="text-primaryblue">
            AS 3666.2 standards, which cover air quality and ventilation
            guidelines to prevent mould-related issues, ensuring that buildings
            meet health and safety requirements for indoor environments
          </span>
          .
        </>,
        <>
          Asbestos, commonly found in insulation, roofing, and older building
          materials, can pose severe health risks if disturbed, as it releases
          fibbers that can be inhaled and lead to lung disease, including
          cancer. Asbestos services involve testing, safe removal, disposal, and
          managing containment to protect both property occupants and removal
          specialists. Following{" "}
          <span className="text-primaryblue">
            AS 4964 standards for asbestos identification and testing,
            professionals ensure accurate assessment and safe handling
            procedures
          </span>
          . This compliance is especially crucial in older properties or those
          undergoing renovation, where asbestos may be present in unexpected
          places.
        </>,
        "Licensed mould and asbestos specialists use advanced equipment and protective measures to eliminate these hazards effectively. They also educate property owners on maintenance and preventative measures to limit future issues, offering lasting protection for both health and property investment. By following stringent Australian standards and utilizing expertise in hazardous material management, these services not only restore property safety but also provide peace of mind for residents and property owners.",
      ],
      listHeading: "Core services in mould & asbestos removal",
      list: [
        {
          inlineHeading: "Mould inspection",
          inlineParagraph: "Identifying mould type, extent, and air quality.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Mould removal",
          inlineParagraph: "Cleaning and disinfecting affected areas.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Asbestos testing",
          inlineParagraph:
            "Sampling materials for asbestos in older buildings.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Asbestos removal",
          inlineParagraph: "Safely removing asbestos from structures.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Disposal",
          inlineParagraph:
            "Proper disposal of asbestos at licensed facilities.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Air quality check",
          inlineParagraph: "Monitoring air post-remediation.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Preventive treatments",
          inlineParagraph: "Applying mould-resistant treatments in damp areas.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Encapsulation",
          inlineParagraph:
            "Sealing asbestos when immediate removal isn’t feasible.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Dehumidification",
          inlineParagraph: "Reducing humidity to prevent mould.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Clearance certificates",
          inlineParagraph: "Certifying safety post-remediation.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "pest-control",
      Mainheading: "Understanding pest control services",
      Mainparagraphs: [
        <>
          Pest control services are designed to manage and eliminate harmful
          pests, such as termites, rodents, ants, and other insects that can
          threaten health, damage property, and disrupt the surrounding
          environment. To ensure safe and effective pest management,
          professional pest control services adhere to stringent Australian
          standards. These include{" "}
          <span className="text-primaryblue">
            AS 3660.1 for termite management in new buildings
          </span>
          , and{" "}
          <span className="text-primaryblue">
            AS/NZS 4341, which provides guidelines for safe fumigation of
            enclosed spaces,
          </span>{" "}
          helping to protect indoor environments from chemical exposure.
        </>,
        <>
          Australian standards also emphasize safe handling and storage of pest
          control chemicals, such as those set out in{" "}
          <span className="text-primaryblue">AS 2507</span>, which prevent
          contamination and ensure pesticides are used responsibly.{" "}
          <span className="text-primaryblue">AS 1604 </span> governs the
          treatment of timber to protect it from pest damage, while{" "}
          <span className="text-primaryblue">AS 2187 </span> focuses on the safe
          application of pesticides in both residential and commercial settings.
          These standards are critical for maintaining structural integrity and
          safety in pest-prone areas.
        </>,
        "By relying on licensed professionals who follow these codes, homeowners and businesses benefit from services that are effective, safe, and aligned with best practices. Pest control providers offer not only immediate solutions but also long-term prevention methods, ensuring a healthier, more protected environment.",
      ],
      listHeading: "Core services in pest control",
      list: [
        {
          inlineHeading: "Termite treatment",
          inlineParagraph: "Detection, prevention, and barrier installation.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Rodent control",
          inlineParagraph: "Traps, bait stations, and exclusion methods.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Insect control",
          inlineParagraph:
            "Sprays and treatments for ants, cockroaches, and spiders.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Bed bug treatment",
          inlineParagraph: "Heat and insecticide treatments for furniture.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Flea & tick control",
          inlineParagraph: "Treatment for homes, gardens, and pet areas.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Wasp & bee removal",
          inlineParagraph: "Safe removal and relocation of nests.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Bird control",
          inlineParagraph: "Nets, spikes, and deterrents to prevent nesting.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Fumigation",
          inlineParagraph: "Gas-based treatment for enclosed spaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "End-of-lease pest control",
          inlineParagraph: "Lease-compliant pest treatments.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Pre-construction barrier",
          inlineParagraph: "Termite protection for new builds.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "plastering",
      Mainheading: "Understanding plastering services",
      Mainparagraphs: [
        "Plastering services provide the essential groundwork for smooth, resilient finishes on walls, ceilings, and other surfaces, serving as a foundation in both new construction and renovation projects. By creating an even, stable base, plastering enhances the look and durability of interiors, ensuring they are ready for final treatments like painting or tiling. These services are vital for achieving clean lines, seamless textures, and strong structural integrity, contributing to the overall quality and longevity of the space.",
        <>
          In Australia, plasterers adhere to strict standards to maintain
          consistency, safety, and quality in their work.{" "}
          <span className="text-primaryblue">
            AS 2589, a key standard, regulates plasterboard installation for
            walls and ceilings
          </span>
          , while{" "}
          <span className="text-primaryblue">
            AS/NZS 2311 provides guidelines for preparing both interior and
            exterior surfaces for painting
          </span>
          , ensuring they are primed for finishing touches. These standards are
          complemented by AS 3959, which covers fire-resistant construction,
          making it crucial for fire-rated plasterboard applications, especially
          in areas where fire safety is paramount.{" "}
          <span className="text-primaryblue">
            AS 4745 further supports quality by establishing guidelines for
            applying decorative plaster, allowing for unique finishes that still
            meet durability standards.
          </span>
        </>,
        "These comprehensive codes enable plastering services to deliver high-quality results that are safe, resilient, and aesthetically pleasing. From creating smooth surfaces for practical use to achieving decorative finishes with lasting appeal, licensed plasterers ensure that each project meets rigorous Australian compliance standards. This commitment to quality ensures lasting value, making plastering an indispensable service for both residential and commercial projects.",
      ],
      listHeading: "Core services in plastering",
      list: [
        {
          inlineHeading: "Wall plastering",
          inlineParagraph:
            "Smooths interior walls for painting or wallpapering.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Ceiling plastering",
          inlineParagraph:
            "Finishes ceilings with plasterboard and decorative elements.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Drywall installation",
          inlineParagraph:
            "Installs and finishes drywall for walls and ceilings.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Repairs & patching",
          inlineParagraph: "Fixes holes, cracks, and damaged plaster surfaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Skim coating",
          inlineParagraph:
            "Applies a thin plaster layer for a refined surface.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Rendering",
          inlineParagraph: "Adds durability and aesthetics to exterior walls.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Cornice installation",
          inlineParagraph:
            "Installs decorative cornices at wall and ceiling joints.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Partition walls",
          inlineParagraph: "Builds plasterboard walls to divide spaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Plaster moulding",
          inlineParagraph:
            "Crafts decorative mouldings and architectural details.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Fire-resistant plastering",
          inlineParagraph: "Uses fire-rated plasterboard for safety.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "plumbing",
      Mainheading: "Understanding plumbing",
      Mainparagraphs: [
        "Plumbing is essential for keeping homes and businesses safe, efficient, and compliant. It encompasses water supply, drainage, and gas systems, adhering to Australian Standards such as AS/NZS 3500, AS 5601, and AS/NZS 2845.1. Regular maintenance helps prevent costly breakdowns and ensures lasting performance.",
      ],
      listHeading: "Core services in plumbing",
      list: [
        {
          inlineHeading: "General repairs",
          inlineParagraph: "Fixing leaks, taps, and pipes.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Drainage solutions",
          inlineParagraph: "Clearing blockages and managing wastewater.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Hot water systems",
          inlineParagraph: "Installation, maintenance, and repair.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Gas fitting",
          inlineParagraph: "Safe installation of gas lines and appliances.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Roof plumbing",
          inlineParagraph: "Managing gutters and drainage systems.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Emergency plumbing",
          inlineParagraph: "Quick response for urgent issues.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Renovation plumbing",
          inlineParagraph: "Installing new fixtures and systems.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Sewer & backflow",
          inlineParagraph: "Maintaining clean water flow.",
          mapLink: "/services/?id=66",
        },
      ],
    },

    {
      slug: "pools-and-spas",
      Mainheading: "Understanding pool & spa services",
      Mainparagraphs: [
        "Pool and spa services involve the complete process of installing, maintaining, repairing, and renovating swimming pools, spas, and hot tubs. These services ensure that each pool and spa meets strict standards for safety, cleanliness, and functionality, providing users with reliable and enjoyable spaces. From managing water quality to maintaining structural elements, these services are essential for both new installations and ongoing care.",
        <>
          In Australia, pool and spa compliance is guided by a range of
          standards that promote safety and quality.{" "}
          <span className="text-primaryblue">
            AS 1926.1 regulates safety barriers and gates to prevent
            unauthorized access
          </span>
          , an essential requirement for households with children.{" "}
          <span className="text-primaryblue">
            AS 2610 provides specifications for the safe construction and
            operation of spas
          </span>
          , while{" "}
          <span className="text-primaryblue">
            AS/NZS 1838 sets standards for vinyl-liner swimming pools
          </span>
          , ensuring durability and performance.{" "}
          <span className="text-primaryblue">
            AS 3979 outlines maintenance standards for hydrotherapy pools
          </span>
          ,which require specialized care, and{" "}
          <span className="text-primaryblue">
            AS/NZS 4801 mandates safety management
          </span>{" "}
          practices for contractors working on these projects.
        </>,
        "Licensed pool and spa specialists adhere closely to these codes, offering services such as water testing, equipment maintenance, surface repairs, and thorough inspections. Regular maintenance helps detect minor issues early, prevents costly repairs, and keeps pools and spas compliant with health and safety guidelines, safeguarding users and enhancing the longevity of each feature. Hiring professionals who follow these standards provides property owners with confidence that their pools and spas are safe, clean, and built to last. Through expert care and compliance, these services offer the peace of mind needed to enjoy pool and spa spaces fully, creating secure and relaxing environments for long-term use.",
      ],
      listHeading: "Core services in pool & spa",
      list: [
        {
          inlineHeading: "Pool installation",
          inlineParagraph: "In-ground and above-ground pools.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Spa installation",
          inlineParagraph: "Setup of spas, hot tubs, and Jacuzzis.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Pool maintenance",
          inlineParagraph: "Cleaning, chemical balancing, and skimming.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Spa maintenance",
          inlineParagraph: "Water treatment and filter cleaning.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Pool repairs",
          inlineParagraph: "Fixing structures, liners, and pumps.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Spa repairs",
          inlineParagraph: "Repairing jets, heaters, and controls.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Pool renovations",
          inlineParagraph: "Resurfacing and feature upgrades.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Spa upgrades",
          inlineParagraph: "New jets, lighting, and covers.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Safety inspections",
          inlineParagraph: "Compliance for fencing and barriers.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Heating systems",
          inlineParagraph:
            "Installation and repair of heaters and solar systems.",
          mapLink: "/services/?id=66",
        },
      ],
    },

    {
      slug: "rendering",
      Mainheading: "Understanding rendering services",
      Mainparagraphs: [
        "Rendering services cover the process of applying plaster, cement, or acrylic layers to both interior and exterior walls to create a strong, weather-resistant, and aesthetically pleasing finish. Rendering enhances insulation, strengthens wall durability, and improves the overall appearance of buildings. From initial application to ongoing maintenance, these services ensure that rendered surfaces are functional and compliant.",
        <>
          In Australia, rendering services must follow strict standards to meet
          safety and quality guidelines.{" "}
          <span className="text-primaryblue">
            AS 3700 sets the requirements for masonry construction
          </span>
          , including wall rendering, while AS 2311 outlines guidelines for
          surface preparation and coating to ensure durable finishes.{" "}
          <span className="text-primaryblue">
            AS 4858 specifies standards for waterproof membrane systems
          </span>{" "}
          used in areas prone to moisture, such as bathrooms. Additionally,{" "}
          <span className="text-primaryblue">
            AS 3959 provides fire-resistant rendering{" "}
          </span>{" "}
          requirements for buildings in bushfire-prone zones, and{" "}
          <span className="text-primaryblue">
            AS/NZS 4284 regulates external finishes{" "}
          </span>{" "}
          for building facades.
        </>,
        "Licensed rendering specialists adhere to these codes, offering services such as surface preparation, applying protective coatings, and conducting safety inspections. Routine maintenance and timely repairs prevent early wear, keep rendered surfaces compliant, and extend the lifespan of wall finishes.",
      ],
      listHeading: "Core services in rendering",
      list: [
        {
          inlineHeading: "Cement rendering",
          inlineParagraph: "Durable cement-sand mix for strong finishes.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Acrylic rendering",
          inlineParagraph:
            "Flexible, crack-resistant render with quick drying.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Textured finishes",
          inlineParagraph:
            "Options like roughcast or smooth for visual appeal.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Bagging",
          inlineParagraph:
            "Thin cement layer for a rustic look on brick walls.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Lime rendering",
          inlineParagraph:
            "Breathable finish for historic or heritage buildings.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Colored rendering",
          inlineParagraph: "Pigmented render mix for long-lasting color.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Polished rendering",
          inlineParagraph:
            "Smooth, decorative finish similar to Venetian plaster.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Foam cladding rendering",
          inlineParagraph:
            "Render on foam cladding for insulation and aesthetics.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Repair & restoration",
          inlineParagraph: "Fixing cracks and restoring damaged render.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Insulated rendering",
          inlineParagraph:
            "Enhances thermal efficiency and reduces energy use.",
          mapLink: "/services/?id=66",
        },
      ],
    },

    {
      slug: "renovation-and-restoration",
      Mainheading: "Understanding renovation & restoration services",
      Mainparagraphs: [
        "Renovation and restoration services play a crucial role in enhancing, preserving, and transforming properties to meet both modern standards and aesthetic preferences. Renovations are typically aimed at updating spaces by adding new features, improving functionality, or modernizing the overall look, while restoration seeks to bring properties back to their original state, especially in cases of historic or architecturally significant buildings. Each approach follows strict Australian standards to ensure every project is safe, durable, and suited to the unique needs of each property.",
        <>
          Key standards guide these services, such as{" "}
          <span className="text-primaryblue">
            AS 1684 for residential building requirements
          </span>
          ,which ensures that structural updates and modifications are both safe
          and durable. For projects requiring accessibility improvements,
          <span className="text-primaryblue">
            AS 1428 provides essential guidelines for creating inclusive
          </span>
          , accessible environments that cater to all abilities. Standards like{" "}
          <span className="text-primaryblue">
            AS 3600govern structural repairs involving concrete
          </span>
          , ensuring strength and stability in substantial renovations.
          Waterproofing standards are covered under AS 3740, which is especially
          important for wet areas like bathrooms, where water damage prevention
          is critical to the property’s longevity.
        </>,
        <>
          To further ensure quality and safety,{" "}
          <span className="text-primaryblue">
            AS 4349 outlines requirements for building inspections
          </span>
          , which are essential both before and after renovation or restoration
          work. These inspections help verify that all aspects of the project
          meet safety and compliance standards, giving property owners
          confidence that their renovated or restored spaces are built to last.
          Following these codes not only enhances the value of the property but
          also ensures that spaces remain functional, safe, and visually
          appealing for years to come.
        </>,
      ],
      listHeading: "Core services in renovation & restoration",
      list: [
        {
          inlineHeading: "Home renovations",
          inlineParagraph: "Upgrading kitchens, bathrooms, and living spaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial renovations",
          inlineParagraph: "Modernizing offices and retail spaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Heritage restoration",
          inlineParagraph:
            "Preserving heritage buildings with traditional methods.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Structural repairs",
          inlineParagraph: "Reinforcing beams, walls, and foundations.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Bathroom renovations",
          inlineParagraph: "Updating layouts, fixtures, and finishes.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Kitchen renovations",
          inlineParagraph:
            "Remodelling with new cabinets, counters, and appliances.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Roof restoration",
          inlineParagraph: "Repairing or replacing roofing materials.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Facade restoration",
          inlineParagraph:
            "Refreshing building exteriors for a restored or modern look.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Water damage restoration",
          inlineParagraph: "Fixing leaks and dampness to prevent mold.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Accessibility upgrades",
          inlineParagraph: "Modifying spaces to meet accessibility standards.",
          mapLink: "/services/?id=66",
        },
      ],
    },

    {
      slug: "roofing",
      Mainheading: "Understanding roofing services",
      Mainparagraphs: [
        <>
          Roofing services encompass the installation, repair, maintenance, and
          replacement of roofs for residential, commercial, and industrial
          buildings. These services are essential for reinforcing structural
          integrity, ensuring safety, and enhancing the building’s overall
          appearance. A professionally installed or maintained roof protects
          against weather and environmental factors, all while adhering to
          Australian standards, such as{" "}
          <span className="text-primaryblue">
            AS 1562.1 for the proper installation of metal
          </span>
          , tile, and shingle roofs, and{" "}
          <span className="text-primaryblue">
            AS/NZS 4200.2 for effective roof insulation and sarking
          </span>
          .
        </>,
        <>
          Roofing standards are comprehensive, covering aspects like secure
          installation, thermal efficiency, and moisture management.{" "}
          <span className="text-primaryblue">
            AS 1562.1 addresses installation requirements for various roofing
            materials
          </span>
          , ensuring durability and security. For thermal performance,{" "}
          <span className="text-primaryblue">
            AS/NZS 4200.2 specifies requirements for sarking and insulation
          </span>
          , which are vital for regulating indoor temperatures and protecting
          against condensation.{" "}
          <span className="text-primaryblue">
            AS 2050 provides guidelines for securing roof tiles, while AS 4046.8
            covers the installation of skylights and roof windows{" "}
          </span>{" "}
          to ensure these additions are safely integrated into the roof
          structure.
        </>,
        <>
          Further compliance is supported by{" "}
          <span className="text-primaryblue">
            AS/NZS 3500, which outlines plumbing and drainage standards
          </span>
          , including requirements for guttering and downpipe installations to
          manage water flow effectively. Adhering to these codes ensures that
          all roofing projects meet the highest standards of safety, durability,
          and functionality, providing long-term protection and value to the
          property.
        </>,
      ],
      listHeading: "Popular services in roofing",
      list: [
        {
          inlineHeading: "Installation",
          inlineParagraph: "New roofs in metal, tile, or shingles.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Replacement",
          inlineParagraph: "Upgrading old roofs.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Repairs",
          inlineParagraph: "Fixing leaks and damage.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Restoration",
          inlineParagraph: "Cleaning and recoating.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Guttering",
          inlineParagraph: "Installing and repairing drainage systems.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Insulation",
          inlineParagraph: "Boosting energy efficiency.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Cleaning",
          inlineParagraph: "Removing debris and moss.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Skylights",
          inlineParagraph: "Adding light and ventilation.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Ventilation",
          inlineParagraph: "Improving air flow.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Metal roofing",
          inlineParagraph: "Durable options like Colorbond.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "scaffolding",
      Mainheading: "Understanding scaffolding services",
      Mainparagraphs: [
        "Scaffolding services provide essential, temporary structures to support workers, materials, and tools for construction, maintenance, or repair projects. These structures ensure safe access to elevated areas, stability during work, and efficiency in completing tasks. Professional scaffolding services are designed to meet strict safety requirements, providing secure platforms for various tasks while complying with Australian standards.",
        <>
          Key standards include{" "}
          <span className="text-primaryblue">
            AS/NZS 1576, which outlines design and construction requirements for
            scaffolding
          </span>
          , ensuring structural stability and safety.{" "}
          <span className="text-primaryblue">
            AS/NZS 4576 provides guidelines for the safe use, assembly, and
            maintenance of scaffolding on construction sites
          </span>
          , promoting safe practices for workers. Additionally,{" "}
          <span className="text-primaryblue">
            AS 1657 addresses safety standards for fixed platforms, walkways,
            and ladders connected with scaffolding, adding another layer of
            safety to prevent accidents
          </span>
          .
        </>,
        <>
          To further support safe scaffolding operations,{" "}
          <span className="text-primaryblue">
            AS/NZS 4801 covers occupational health and safety management systems
            relevant to the industry
          </span>
          . Compliance with these codes is essential to protect workers and
          ensure that scaffolding structures are reliable and durable,
          minimizing risks and enhancing productivity across all stages of
          construction and maintenance work.
        </>,
      ],
      listHeading: "Popular services in scaffolding",
      list: [
        {
          inlineHeading: "Scaffold hire",
          inlineParagraph: "Rentals for various projects.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Installation",
          inlineParagraph: "Safe scaffold setup and removal.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Mobile scaffolding",
          inlineParagraph: "Wheeled, portable units.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Suspended scaffolding",
          inlineParagraph: "Platforms for high-rise access.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Tube & fitting",
          inlineParagraph: "Flexible scaffolds for complex builds.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Modular systems",
          inlineParagraph: "Prefab, quick-assembly scaffolds.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Inspections",
          inlineParagraph: "Routine safety checks.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Heavy-duty",
          inlineParagraph: "Reinforced scaffolds for large loads.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Staircase access",
          inlineParagraph: "Scaffolds with built-in stairs.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Event setups",
          inlineParagraph: "Temporary stages and seating.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "shopfitting",
      Mainheading: "Guide to shopfitting services",
      Mainparagraphs: [
        "Shopfitting services involve the design, construction, and installation of fixtures, fittings, and furniture in commercial spaces such as retail stores, cafes, and restaurants. These services ensure that interiors are both functional and visually appealing, supporting a brand’s identity and enhancing customer experience. A professional shopfitter collaborates closely with businesses to maximize space efficiency, align with branding, and create an inviting atmosphere for customers.",
        <>
          Compliance with Australian standards is central to shopfitting,{" "}
          <span className="text-primaryblue">
            with AS 1428 ensuring accessibility in commercial spaces
          </span>{" "}
          so that all customers, including those with mobility needs, can access
          the premises. For structural stability{" "}
          <span className="text-primaryblue">
            AS 1684provides guidelines for timber framing within fit-outs
          </span>
          , while{" "}
          <span className="text-primaryblue">
            AS 3740 addresses waterproofing requirements
          </span>
          , particularly essential in wet areas within hospitality settings.
        </>,
        <>
          Additional standards like{" "}
          <span className="text-primaryblue">
            AS/NZS 3000 for electrical installations
          </span>{" "}
          and{" "}
          <span className="text-primaryblue">
            AS 4586 for slip resistance on floors ensure overall safety and
            functionality within commercial environments
          </span>
          . Together, these codes help shopfitting projects meet the highest
          standards for durability, safety, and accessibility, creating a
          professional and customer-friendly space built to last.
        </>,
      ],
      listHeading: "Popular services in shopfitting",
      list: [
        {
          inlineHeading: "Retail fit-outs",
          inlineParagraph: "Shelving, displays, and signage.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Cafe & restaurant fit-outs",
          inlineParagraph: "Custom counters, seating, decor.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial fit-outs",
          inlineParagraph: "Offices and showrooms for efficiency.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Custom joinery",
          inlineParagraph: "Tailored furniture and cabinetry.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "POS installations",
          inlineParagraph: "Checkout counters and kiosks.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Lighting",
          inlineParagraph: "Ambient and accent lighting.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Partitioning",
          inlineParagraph: "Private areas and meeting rooms.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Ceilings & flooring",
          inlineParagraph: "Suspended ceilings, tiles, carpets.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Signage & branding",
          inlineParagraph: "In-store signage and graphics.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Security",
          inlineParagraph: "Cameras, alarms, access controls.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "skip-bins",
      Mainheading: "Comprehensive guide to skip bin services",
      Mainparagraphs: [
        "Skip bin services offer essential waste disposal solutions for residential, commercial, and construction projects. These services help manage various types of waste, from general household waste to construction debris and green waste, ensuring that removal is efficient, cost-effective, and environmentally friendly. Professional providers offer a wide range of bin sizes to suit the needs of any project, ensuring timely and hassle-free waste management.",
        <>
          Australian standards play a key role in guiding skip bin services to
          ensure safety and compliance.{" "}
          <span className="text-primaryblue">
            AS 3816 provides waste management guidelines
          </span>{" "}
          for proper handling, storage, and disposal, while{" "}
          <span className="text-primaryblue">
            AS/NZS 5021 covers standards for the containers and transportation
            of waste materials
          </span>
          . For specialized waste, such as electronic waste, AS 5377 ensures
          proper recycling and disposal, while{" "}
          <span className="text-primaryblue">
            AS/NZS 3831 addresses the safe handling of hazardous waste
          </span>
          , reducing the risk to both workers and the environment.
        </>,
        <>
          Furthermore, proper waste segregation is essential, and{" "}
          <span className="text-primaryblue">
            AS 4123.7 outlines standards for bin design
          </span>{" "}
          commonly used in modern staircase designs. Additionally,{" "}
          <span className="text-primaryblue">
            AS 4586 sets slip resistance standards for staircase
          </span>{" "}
          and signage to promote effective waste separation. These standards
          ensure that skip bin services operate safely and efficiently,
          providing businesses and homeowners with a reliable solution for
          responsible waste management that aligns with both local regulations
          and Australian best practices.
        </>,
      ],
      listHeading: "Essential skip bin hire services",
      list: [
        {
          inlineHeading: "Residential",
          inlineParagraph:
            "Bins for home renovations, clean-outs, and garden projects.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial",
          inlineParagraph:
            "Bins for regular waste removal in offices and retail spaces.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Construction",
          inlineParagraph:
            "Large-capacity bins for construction and demolition debris.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Green waste",
          inlineParagraph:
            "Bins for garden waste like grass, leaves, and branches.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Mixed waste",
          inlineParagraph: "Bins for general and green waste clean-ups.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Heavy waste",
          inlineParagraph: "Bins for concrete, bricks, and soil.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Roll-on roll-off",
          inlineParagraph:
            "Large bins for major construction and industrial sites.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Delivery & pickup",
          inlineParagraph: "Timely service to fit project schedules.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Recycling",
          inlineParagraph:
            "Bins for eco-friendly waste like plastics, glass, and metals.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Hazardous waste",
          inlineParagraph: "Safe bins for disposing of hazardous materials.",
          mapLink: "/services/?id=66",
        },
      ],
    },
    {
      slug: "solar-energy",
      Mainheading: "Complete guide to solar power services",
      Mainparagraphs: [
        "Solar power is an increasingly popular solution for reducing energy costs and reliance on traditional power sources, while contributing to a sustainable future. Solar power services involve the installation, maintenance, and repair of systems that convert sunlight into electricity, providing homeowners, businesses, and industries with an eco-friendly energy alternative. When considering solar power for your property, it’s important to ensure that the system is designed, installed, and maintained in line with Australian standards to guarantee both safety and efficiency.",
        <>
          The process begins with designing and installing a photovoltaic (PV)
          system that suits your energy needs.{" "}
          <span className="text-primaryblue">
            AS/NZS 5033provides the standards for safe installation of solar
            systems
          </span>
          , covering everything from panel placement to electrical connections.
          Once installed, solar power systems need to be connected to the grid,
          if applicable, and this process is regulated by{" "}
          <span className="text-primaryblue">
            AS 4777, which outlines requirements for inverters and grid
            connections
          </span>{" "}
          to ensure that the system works reliably and safely.{" "}
          <span className="text-primaryblue">
            AS/NZS 3000 focuses on the safe wiring and electrical integration{" "}
          </span>{" "}
          of the solar system, reducing the risk of electrical hazards.
        </>,
        <>
          Proper installation also includes ensuring the structural safety of
          the panels.{" "}
          <span className="text-primaryblue">
            AS/NZS 1170.2 provides wind load guidelines for mounting systems to
            prevent damage in extreme weather conditions.
          </span>{" "}
          Additionally, installers are required to follow Clean Energy Council
          Guidelines, ensuring that they are accredited and qualified to perform
          the installation according to industry best practices. By following
          these regulations and standards, solar systems are designed to last
          longer, work more efficiently, and provide a safer, more reliable
          energy source for your property.
        </>,
      ],
      listHeading: "Popular solar energy services",
      list: [
        {
          inlineHeading: "Panel installation",
          inlineParagraph: "Roof or ground-mounted setups.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "System design",
          inlineParagraph: "Custom solutions based on energy needs.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Battery storage",
          inlineParagraph: "Storing excess energy for later use.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Inverters",
          inlineParagraph: "Converting DC to AC power.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Grid-connected",
          inlineParagraph: "Linking solar systems to the grid.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Off-grid",
          inlineParagraph: "Independent solar systems for remote locations.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Hybrid systems",
          inlineParagraph:
            "Combining panels, batteries, and grid for optimal use.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Maintenance",
          inlineParagraph: "Regular system checks and cleaning.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Repairs",
          inlineParagraph: "Fixing panels, inverters, or components.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Commercial solutions",
          inlineParagraph: "Large-scale solar for businesses.",
          mapLink: "/services/?id=66",
        },
      ],
    },

    {
      slug: "staircases",
      Mainheading: "Complete guide to staircase services",
      Mainparagraphs: [
        "Staircase services encompass a wide range of solutions, including the design, construction, installation, repair, and renovation of staircases for residential, commercial, and industrial properties. These services focus on creating staircases that are safe, functional, and aesthetically pleasing. Staircases are essential structural elements in buildings, providing access between different levels while also contributing to the overall interior design. Professional staircase builders ensure compliance with Australian building codes, prioritizing safety and accessibility in every design.",
        <>
          Adhering to standards like{" "}
          <span className="text-primaryblue">
            AS 1657 ensures the safe design and installation of stairways
          </span>
          , walkways, and ladders, while{" "}
          <span className="text-primaryblue">
            AS 1428 focuses on creating accessible designs
          </span>
          , making stairs and handrails easy to navigate for individuals with
          mobility challenges. In addition,{" "}
          <span className="text-primaryblue">
            AS/NZS 1170.1 provides structural safety standards for load-bearing
            capacity
          </span>
          , ensuring that staircases are strong enough to handle everyday use.
          These standards are crucial to ensuring the stability and durability
          of staircases in both private and public spaces.
        </>,
        <>
          Other standards, such as{" "}
          <span className="text-primaryblue">
            AS 2047, ensure the safety and durability of glass balustrades
          </span>{" "}
          commonly used in modern staircase designs. Additionally,{" "}
          <span className="text-primaryblue">
            AS 4586 sets slip resistance standards for staircase
          </span>{" "}
          surfaces to prevent accidents and ensure safe use in both residential
          and commercial settings. These regulations and guidelines are
          essential in creating staircases that are not only visually appealing
          but also safe, functional, and compliant with Australian safety
          standards.
        </>,
      ],
      listHeading: "Popular staircase services",
      list: [
        {
          inlineHeading: "Custom design",
          inlineParagraph: "Tailored staircases (spiral, L-shaped).",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Timber",
          inlineParagraph: "Oak, pine, or hardwood staircases.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Metal",
          inlineParagraph: "Steel or iron staircases.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Glass balustrades",
          inlineParagraph: "Modern glass railings.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Floating",
          inlineParagraph: "Minimalist, cantilevered staircases.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Renovation",
          inlineParagraph: "Upgrading existing staircases.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Outdoor",
          inlineParagraph: "Durable outdoor staircases.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Spiral",
          inlineParagraph: "Space-saving stair designs.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Handrails & balustrades",
          inlineParagraph: "Safety and decorative railings.",
          mapLink: "/services/?id=66",
        },
        {
          inlineHeading: "Accessibility",
          inlineParagraph: "Compliant stairs and ramps.",
          mapLink: "/services/?id=66",
        },
      ],
    },

    {
      slug: "stonemasonry",
      Mainheading: "Complete guide to stone work services",
      Mainparagraphs: [
        "Stone work plays a crucial role in construction by providing durable, functional, and aesthetically appealing surfaces for various applications, including kitchen benchtops, bathroom countertops, BBQ areas, and more. Materials such as granite, marble, and engineered stone are commonly used for these purposes due to their strength, longevity, and visual appeal. Stone work enhances both the appearance and functionality of spaces, making it a popular choice for residential and commercial properties that require durable, low-maintenance surfaces.",
        <>
          Professionals specializing in stone work ensure that all installations
          meet Australian standards, guaranteeing safety and quality.{" "}
          <span className="text-primaryblue">
            AS 3700 outlines standards for masonry and stone structures
          </span>
          , ensuring proper construction techniques and load-bearing capacity.{" "}
          <span className="text-primaryblue">
            AS/NZS 1170.1 addresses the structural safety of stone installations
          </span>
          , ensuring they can bear expected loads. Additionally,{" "}
          <span className="text-primaryblue">
            AS 3958.1 sets guidelines for the correct installation of stone
            surfaces
          </span>
          , such as countertops, ensuring they are stable and durable for
          long-term use.
        </>,
        <>
          <span className="text-primaryblue">
            Safety is paramount when working with stone materials, particularly
            engineered stone, which may contain crystalline silica. Crystalline
            silica is a serious health hazard for workers, especially in the
            stone cutting and grinding process. As a result, engineered stone
            containing high levels of silica is now subject to a ban in
            Australia. Starting from 1 July 2024, Australia has prohibited the
            use, supply, and manufacture of engineered stone products that
            contain more than 80% crystalline silica, to protect workers from
            silicosis, a potentially fatal lung disease caused by silica dust
            exposure.
          </span>
        </>,
        <>
          <span className="text-primaryblue">
            This ban is enforced by Safe Work Australia and applies to products
            such as engineered stone slabs, benchtops, and panels
          </span>
          . To mitigate risks associated with silica exposure, professionals
          must follow strict safety measures. This includes using Personal
          Protective Equipment (PPE), such as respirators, gloves, and
          protective clothing, to minimize exposure to silica dust during the
          installation process. For more information on the silica ban, refer to
          Safe Work Australia{" "}
          <span className="text-primaryblue">
            (SafeWork Australia - Engineered Stone Ban).
          </span>
        </>,
        <>
          In addition to health and safety, compliance with{" "}
          <span className="text-primaryblue">
            AS 1428 ensures that stone features
          </span>
          , such as ramps and handrails, are accessible to individuals with
          mobility challenges. This standard is particularly important for
          public and commercial spaces, ensuring that stone work remains both
          functional and inclusive.
        </>,
        "By adhering to these safety protocols and standards, stone work services provide high-quality, durable surfaces while minimizing health risks, ensuring safety, and guaranteeing compliance with Australian regulations.",
      ],
      listHeading: "Core services in stone work",
      list: [
        {
          "inlineHeading": "Wall construction",
          "inlineParagraph": "Building retaining and garden walls with stone.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Stone cladding",
          "inlineParagraph": "Applying veneers for enhanced finishes.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Heritage restoration",
          "inlineParagraph": "Restoring historic buildings with matching stones.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Stone paving",
          "inlineParagraph": "Installing stone pathways, patios, and driveways.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Stone fireplaces",
          "inlineParagraph": "Custom stone fireplaces for interiors.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Granite & marble benchtops",
          "inlineParagraph": "Installing stone benchtops.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Repair & repointing",
          "inlineParagraph": "Fixing cracks and repointing walls.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Stone steps & staircases",
          "inlineParagraph": "Designing stone steps and staircases.",
          "mapLink": "/services/?id=66"
        }
      ]
      ,
    },

    {
      slug: "tiling",
      Mainheading: "Complete guide to tiling services",
      Mainparagraphs: [
        "Tiling services play an essential role in both residential and commercial properties, offering solutions for the installation, repair, and maintenance of tiles on floors, walls, and other surfaces. Tiles are a practical and aesthetically pleasing choice due to their durability, water resistance, and ease of maintenance. They are particularly suitable for high-moisture areas like kitchens and bathrooms, as well as high-traffic zones, where performance and longevity are key. Professional tilers ensure that all tile installations adhere to Australian standards, providing reliable, long-lasting results.",
        <>
          To guarantee proper installation and safety, tiling professionals
          follow Australian standards such as{" "}
          <span className="text-primaryblue">
            AS 3958.1, which governs the installation of ceramic tiles
          </span>
          . These standard covers everything from surface preparation to the
          correct adhesives and grouting techniques. In wet areas,{" "}
          <span className="text-primaryblue">
            AS 3740 ensures proper waterproofing by setting guidelines
          </span>{" "}
          for applying membranes under tiles, helping prevent water damage over
          time. Additionally,{" "}
          <span className="text-primaryblue">
            AS 4586 provides slip resistance standards
          </span>
          , ensuring that tiled surfaces—whether indoors or outdoors—are safe to
          walk on, particularly in areas prone to moisture.
        </>,
        <>
          For durability and strength,{" "}
          <span className="text-primaryblue">
            AS 4459 specifies testing guidelines for tiles
          </span>
          , ensuring they can withstand moisture and wear.{" "}
          <span className="text-primaryblue">
            AS 1428 provides important accessibility guidelines
          </span>
          , ensuring that tiles used for ramps or pathways comply with slip
          resistance requirements, making them safe and accessible for all
          users. By adhering to these standards, tiling services not only meet
          aesthetic expectations but also ensure safety and long-term
          functionality.
        </>,
      ],
      listHeading: "Core services in tiling",
      list: [
        {
          "inlineHeading": "Floor tiling",
          "inlineParagraph": "For kitchens, bathrooms, and commercial spaces.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Wall tiling",
          "inlineParagraph": "Splashbacks and feature walls.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Bathroom tiling",
          "inlineParagraph": "Waterproof tiling for wet areas.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Kitchen splashbacks",
          "inlineParagraph": "Easy-to-clean tiles.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Outdoor tiling",
          "inlineParagraph": "Non-slip tiles for outdoor areas.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Tile repair & replacement",
          "inlineParagraph": "Fixing damaged tiles.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Mosaic tiling",
          "inlineParagraph": "Decorative designs.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Grout cleaning & sealing",
          "inlineParagraph": "Hygiene and durability.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Waterproofing",
          "inlineParagraph": "Preventing leaks in wet areas.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Commercial tiling",
          "inlineParagraph": "Tiling for high-traffic spaces.",
          "mapLink": "/services/?id=66"
        }
      ]
      ,
    },

    {
      slug: "waterproofing",
      Mainheading: "Understanding waterproofing services",
      Mainparagraphs: [
        "Waterproofing services play a crucial role in protecting buildings from water damage by applying coatings, membranes, and sealants to prevent water penetration in key areas such as wet zones, roofs, basements, and balconies. Proper waterproofing is essential to maintaining the structural integrity of buildings, preventing costly repairs, and ensuring long-lasting durability. With a wide range of applications, it is a necessary part of construction and renovation projects, particularly in high-moisture areas.",
        <>
          Professional waterproofing services adhere to Australian standards
          like <span className="text-primaryblue">AS 3740 for wet areas</span>{" "}
          and{" "}
          <span className="text-primaryblue">
            AS 4654.2 for external above-ground waterproofing
          </span>
          , ensuring the job is done right. These standards focus on the correct
          installation of membranes and sealants to prevent water damage in
          areas such as bathrooms, showers, terraces, and rooftops. They also
          ensure that waterproofing materials are durable, high-performance, and
          suited to the specific conditions of each space.
        </>,
        <>
          By following these compliance standards, waterproofing not only
          protects buildings from leaks but also provides peace of mind.
          Standards like{" "}
          <span className="text-primaryblue">
            AS 4858 for membrane durability
          </span>
          , <span className="text-primaryblue">AS 1558 for sealing joints</span>
          , and{" "}
          <span className="text-primaryblue">
            AS 3600 for concrete waterproofing
          </span>{" "}
          help maintain the safety and longevity of the structure. Ensuring
          proper waterproofing is essential for preventing costly repairs and
          preserving the value of the property over time.
        </>,
      ],
      listHeading: "Core services in waterproofing",
      list: [
        {
          "inlineHeading": "Bathroom",
          "inlineParagraph": "Prevents leaks in showers and bathrooms.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Balcony",
          "inlineParagraph": "Seals against water damage.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Roof",
          "inlineParagraph": "Stops leaks on roofs.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Basement",
          "inlineParagraph": "Blocks groundwater.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Retaining wall",
          "inlineParagraph": "Prevents seepage.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Shower",
          "inlineParagraph": "Ensures leak-proof areas.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Deck",
          "inlineParagraph": "Protects from water damage.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Concrete",
          "inlineParagraph": "Guards against moisture.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Pool",
          "inlineParagraph": "Seals pools from leaks.",
          "mapLink": "/services/?id=66"
        },
        {
          "inlineHeading": "Leak repairs",
          "inlineParagraph": "Fixes leaks in balconies and roofs.",
          "mapLink": "/services/?id=66"
        }
      ]
      ,
    },
  ];
  const categoryData = data.filter((item) => item.slug === slug);

  return (
    <>
      {categoryData.map((item, index) => {
        return (
          <>
            <div
              key={index}
              className="mt-10 sm:mt-20 mx-[5%] xl:mx-auto xl:w-[900px] 2xl:w-[1075px]"
            >
              <Heading variant={"h2"} className={"text-start text-offblack"}>
                {item.Mainheading}
              </Heading>
              {item.Mainparagraphs && (
                <div className="mt-5">
                  {item.Mainparagraphs.map((para, index) => {
                    return (
                      <>
                        <Paragraph
                          key={index}
                          variant={"MainParagraph"}
                          className={"mt-3 text-offblack"}
                        >
                          {para}
                        </Paragraph>
                      </>
                    );
                  })}
                </div>
              )}
              <div className="mx-[3%] xl:mx-auto xl:w-[700px] 2xl:w-[800px] xl:ml-[60px]">
                <Heading variant={"h3"} className={"text-offblack mt-5"}>
                  {item.listHeading}
                </Heading>
                {item.list && (
                  <ul className="flex flex-col space-y-2 mt-5 w-auto md:w-[650px]">
                    {item.list.map((item, index) => {
                      return (
                        <>
                          <li className={"flex items-center"} key={index}>
                            <div className="flex items-center w-fit shrink-0">
                              <i className="fi fi-sr-bullet text-offblack mr-3 pt-[3px] xl:pt-1 2xl:pt-1.5"></i>
                              <Link
                                to={item.mapLink}
                                className="hover:text-primaryblue"
                              >
                                <SecondaryHeading
                                  variant={"MainParagraphHeading"}
                                  className={"font-semibold"}
                                >
                                  {item.inlineHeading}
                                </SecondaryHeading>
                              </Link>
                            </div>
                            <div>
                              <Paragraph
                                variant={"MainParagraph"}
                                className={"text-offblack ml-2"}
                              >
                                {item.inlineParagraph}
                              </Paragraph>
                            </div>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default HeadingsWithParagraphsList;
