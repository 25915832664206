import React from "react";
import {
  initial_values,
  validationSchema,
} from "../../../FormValidations/AccountManagementSchema";
import { useFormik } from "formik";
import CustNotificationsSettings from "../../../sections/CustomerDashboard/Settings/CustNotificationsSettings";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";

const CustomerNotifications = () => {
  const formik = useFormik({
    initialValues: initial_values,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {},
  });
  return (
    <>
      <DashboardMainContainer className={"lg:px-5 lg:pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center"}>
          Customer notification preferences
        </Heading>
        <div className="mt-[60px] min-h-screen">
          <form onSubmit={formik.handleSubmit} className="w-full">
            <CustNotificationsSettings />
          </form>
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default CustomerNotifications;
