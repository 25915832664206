import React from "react";
import MapView from "../../components/common/Map/MapView";
import Heading from "../../components/common/Typography/Heading";

const ServicesOnMap = () => {
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] mt-10 sm:mt-40 lg:mt-40">
        <Heading
          variant={"h2"}
          className={"flex justify-start text-start text-offblack"}
        >
          Popular services around you
        </Heading>
      </div>
      <div className="mx-[5%] xl:mx-[2%] 2xxl:mx-[5%] 3xl:mx-auto 3xl:w-[1748px] h-[500px] md:h-[600px] lg:h-[700px] xl:h-[800px] 2xl:h-[1000px] mt-7 sm:mt-10">
        <MapView />
      </div>
    </>
  );
};

export default ServicesOnMap;
