import React from "react";
import SpecialText from "../../../../components/common/Typography/SpecialText";
import MainButton from "../../../../components/common/MainButton/MainButton";
import Pro from "../../../../components/common/Tags/Pro";
import Pf from "../../../../assets/img/User.png";
import { Link } from "react-router-dom";

const FollowingLayout = () => {
  const data = [
    {
      profile: Pf,
      tag: true,
      company: "Maya's services",
      location: "Duffy, ACT, 2002",
      link: "/poster-profile",
      follow: true,
    },
    {
      profile: Pf,
      tag: false,
      company: "Maya's services",
      location: "Duffy, ACT, 2002",
      link: "/poster-profile",
      follow: true,
    },
    {
      profile: Pf,
      tag: true,
      company: "Maya's services",
      location: "Duffy, ACT, 2002",
      link: "/poster-profile",
      follow: false,
    },
    {
      profile: Pf,
      tag: true,
      company: "Maya's services",
      location: "Duffy, ACT, 2002",
      link: "/poster-profile",
      follow: true,
    },
    {
      profile: Pf,
      tag: true,
      company: "Maya's services",
      location: "Duffy, ACT, 2002",
      link: "/poster-profile",
      follow: true,
    },
    {
      profile: Pf,
      tag: false,
      company: "Maya's services",
      location: "Duffy, ACT, 2002",
      link: "/poster-profile",
      follow: true,
    },
    {
      profile: Pf,
      tag: true,
      company: "Maya's services",
      location: "Duffy, ACT, 2002",
      link: "/poster-profile",
      follow: false,
    },
    {
      profile: Pf,
      tag: true,
      company: "Maya's services",
      location: "Duffy, ACT, 2002",
      link: "/poster-profile",
      follow: true,
    },
    {
      profile: Pf,
      tag: true,
      company: "Maya's services",
      location: "Duffy, ACT, 2002",
      link: "/poster-profile",
      follow: true,
    },
    {
      profile: Pf,
      tag: false,
      company: "Maya's services",
      location: "Duffy, ACT, 2002",
      link: "/poster-profile",
      follow: true,
    },
    {
      profile: Pf,
      tag: true,
      company: "Maya's services",
      location: "Duffy, ACT, 2002",
      link: "/poster-profile",
      follow: false,
    },
    {
      profile: Pf,
      tag: true,
      company: "Maya's services",
      location: "Duffy, ACT, 2002",
      link: "/poster-profile",
      follow: true,
    },
  ];
  return (
    <>
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center justify-center 2xl:w-[912px] mx-auto">
      {data.map((e) => {
        return (
          <>
            <div
              className={`bg-white lg:bg-pastalblue lg:w-[440px] lg:h-[230px] rounded-xl p-5 aspect-[440/230]`}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="relative size-12">
                    <img
                      className="object-center rounded-full size-12 cursor-pointer"
                      src={e.profile}
                      alt={"no-profile"}
                    />
                    {e.tag && (
                      <div className="absolute -mt-3 left-[30%] mr-2">
                      <Pro className={"bg-primaryblue text-white"}/>
                      </div>
                    )}
                  </div>
                  <div className="ml-3">
                    <div>
                      <Link to={e.link}>
                        <SpecialText variant={"ProfileName"}>
                          {e.company}
                        </SpecialText>
                      </Link>
                    </div>
                    <div className="mt-1">
                      <SpecialText variant={"OccupationLocation"}>
                        {e.location}
                      </SpecialText>
                    </div>
                  </div>
                </div>
                <div>
                  {e.follow ? (
                    <MainButton variant={"outlineextrasmall"}>
                      Unfollow
                    </MainButton>
                  ) : (
                    <MainButton variant={"outlineextrasmall"}>
                      Follow
                    </MainButton>
                  )}
                </div>
              </div>
              <div className="flex items-center space-x-4 lg:space-x-0 lg:justify-between sticky top-[100vh]">
                <div className="lg:w-[120px] lg:h-[90px] aspect-[120/90]">
                  <img
                    src={require("../../../../assets/img/default-image.jpg")}
                    className="w-full h-full rounded-xl object-center"
                    alt="portfolio"
                  />
                </div>
                <div className="lg:w-[120px] lg:h-[90px] aspect-[120/90]">
                  <img
                    src={require("../../../../assets/img/default-image.jpg")}
                    className="w-full h-full rounded-xl object-center"
                    alt="portfolio"
                  />
                </div>
                <div className="lg:w-[120px] lg:h-[90px] aspect-[120/90]">
                  <img
                    src={require("../../../../assets/img/default-image.jpg")}
                    className="w-full h-full rounded-xl object-center"
                    alt="portfolio"
                  />
                </div>
              </div>
            </div>
          </>
        );
      })}

    </div>
    </>
  );
};

export default FollowingLayout;
