import React from "react";
import Heading from "../../components/common/Typography/Heading";

const HireWithConf = () => {
  const data = [
    {
      heading: "No Taskers",
      para: "We feature only professional trade people, ensuring no weekend warriors or taskers.",
    },
    {
      heading: "Trade-Specific",
      para: "Our platform is dedicated exclusively to trade services, designed to meet the specific needs of trade businesses.",
    },
    {
      heading: "Pay your way",
      para: "Our platform is dedicated exclusively to trade services, designed to meet the specific needs of trade businesses.",
    },
    {
      heading: "Privacy Protected",
      para: "Businesses cannot contact customers first, only customers can initiate contact with businesses.",
    },
  ];
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] mt-10 sm:mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          Hire with confidence
        </Heading>
        <div className="h-[500px] sm:h-auto 2xl:h-[700px] sm:aspect-[1264/700] mt-7 sm:mt-10">
          <img
            src={require("../../assets/img/about-us.jpg")}
            className="w-full h-full rounded-3xl object-cover"
            alt=""
          />
        </div>
      </div>
      {/* <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-10 mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px]">
        {data.map((e, index) => {
          return (
            <>
              <div key={index} className="w-full">
                <div className="bg-freshgreen bg-opacity-50 py-1 px-3 w-fit mt-5">
                  <SecondaryHeading
                    variant={"MainParagraphHeading"}
                    className={"text-offblack"}
                  >
                    {e.heading}
                  </SecondaryHeading>
                </div>
                 
                <Paragraph
                  variant={"MainParagraph"}
                  className={"text-offblack mt-3"}
                >
                  {e.para}
                </Paragraph>
              </div>
            </>
          );
        })}
      </div> */}
    </>
  );
};

export default HireWithConf;
