import React from "react";
import Snippet from "../SnippetIcon/Snippet";
import SpecialText from "../Typography/SpecialText";

const TextArea = ({
  className,
  label,
  placeholder,
  variant,
  snippet,
  star,
  SnippetText,
  limitation,
  ...restProps
}) => {
  return (
    <>
    <div className="flex items-center justify-between">
      <Snippet
        variant={"label"}
        label={label}
        text={SnippetText}
        snippet={snippet}
        star={star}

      />
      {
        limitation &&
    <SpecialText variant={"FootNoteDisclaimer"}>
        0/500
    </SpecialText>
      }
    </div>
      {variant === "large" && (
        <textarea
          {...restProps}
          name={restProps?.name}
          placeholder={placeholder}
          className={`focus:border focus:border-primaryblue placeholder-lightgray placeholder:font-ptsans placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] text-offblack rounded-lg block p-3 min-h-[140px] w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] ${className}`}
        />
      )}
      {variant === "general" && (
        <textarea
          {...restProps}
          name={restProps?.name}
          placeholder={placeholder}
          className={`focus:border focus:border-primaryblue placeholder-lightgray placeholder:font-ptsans placeholder:font-normal placeholder:normal-case placeholder:leading-[1.4] placeholder:tracking-[0px] placeholder:text-[11px] placeholder:sm:text-[11px] placeholder:md:text-[12px] placeholder:lg:text-[12px] placeholder:xl:text-[12px] placeholder:2xl:text-[13px] placeholder:3xl:text-[13px] font-ptsans font-normal normal-case text-[11px] leading-[1.4] tracking-[0px] sm:text-[11px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[13px] 3xl:text-[13px] text-offblack rounded-lg block p-3 w-full min-h-[140px] ${className}`}
        />
      )}
    </>
  );
};

export default TextArea;
