import React, { useEffect, useState } from "react";
import Heading from "../components/common/Typography/Heading";
import Banner from "../components/common/Banners/Banner";
import MainButton from "../components/common/MainButton/MainButton";
import Login from "../sections/Login/Login";
import { SignUp as SignUpRegister } from "../sections/SignUp/SignUp";
import BeforeAfter from "../sections/HowItWorks/BeforeAfter";
import TradesSentricFeatures from "../sections/HowItWorks/TradesSentricFeatures";
import LastSection from "../sections/HowItWorks/LastSection";
import ImageWithTopText from "../components/common/Generics/ImageWithTopText";
import FullLengthSlider from "../sections/AllServicesSlider/FullLengthSlider";

const HowItWorks = () => {
  const [SignUp, setSignUp] = useState(false);
  const [SignIn, setSignIn] = useState(false);
  useEffect(() => {
    if (SignUp) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [SignUp]);
  return (
    <>
      <Banner
        headingClasses={
          "text-center sm:text-start text-white xl:w-[900px] 2xl:w-[1000px] 3xl:w-[1200px]"
        }
        heading={"The Trades Poster Difference – How It Works"}
        className={"bg-experiance"}
      >
        {/* <div className="flex items-center justify-center sm:justify-start gap-x-2 mt-10">
          <MainButton variant={"large"} onClick={() => setSignUp(true)}>
            Begin Your Journey
          </MainButton>
        </div> */}
        {SignIn && (
          <Login
            SignIn={SignIn}
            setSignIn={setSignIn}
            SignUpState={SignUp}
            setSignUpState={setSignUp}
          />
        )}
        {SignUp && (
          <SignUpRegister
            SignUpState={SignUp}
            setSignUpState={setSignUp}
            SignIn={SignIn}
            setSignIn={setSignIn}
          />
        )}
      </Banner>
      <ImageWithTopText
        classNames={
          "mx-[5%] xl:mx-auto h-[500px] sm:h-auto xl:w-[1150px] 2xl:w-[1440px] 2xl:h-[900px] sm:aspect-[1440/900]"
        }
        TopText={
          "Getting started is easy"
        }
      />
      <ImageWithTopText
        classNames={
          "mx-[5%] xl:mx-auto h-[500px] sm:h-auto xl:w-[1150px] 2xl:w-[1440px] 2xl:h-[700px] sm:aspect-[1440/700]"
        }
        TopText={
          "Businesses post, Customers browse"
        }
      />
      <ImageWithTopText
        classNames={
          "mx-[5%] xl:mx-auto h-[500px] sm:h-auto xl:w-[1100px] 2xl:w-[1264px] 2xl:h-[700px] sm:aspect-[1264/700]"
        }
        TopText={
          "Talk directly to businesses and get started"
        }
      />
      <TradesSentricFeatures />
      <BeforeAfter />
      <LastSection />
      <FullLengthSlider />
    </>
  );
};

export default HowItWorks;
