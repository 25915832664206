import React from "react";
import Heading from "../../../components/common/Typography/Heading";
import BarChart from "../../../components/Analytics/BarChart";

const FollowingChart = () => {
  const data1 = [500, 1500, 1800];
  const data2 = [400, 1200, 1900];
  const labels = ["January", "March", "April"];
  return (
    <>
       <div className="rounded-3xl lg:h-[480px] 2xl:h-[500px] w-full lg:w-1/2 lg:bg-white pt-5 lg:px-5 lg:pb-10">
        <Heading variant={"h6"} className={"text-offblack"}>
          Followers
        </Heading>
        <div className="flex justify-center lg:mx-auto mt-20 md:mt-60 xl:mt-[190px]">
          <BarChart data1={data1} data2={data2} labels={labels} height={500} />
        </div>
      </div>
    </>
  );
};

export default FollowingChart;
