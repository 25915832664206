import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import CircularButton from "../../../../components/common/MainButton/CircularButton";
import "swiper/css";
import { Grid } from "swiper/modules";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import DashboardAdsCard from "../../../../components/common/AdsCard/DashboardAdsCard";
const PostsSlider = ({ drafts }) => {
  const id = useMemo(() => Math.round(Math.random() * 1000), []);
  const Images = [
    {
      original: require("../../../../assets/img/P1.jpeg"),
      thumbnail: require("../../../../assets/img/P1.jpeg"),
    },
    {
      original: require("../../../../assets/img/P2.jpeg"),
      thumbnail: require("../../../../assets/img/P2.jpeg"),
    },
    {
      original: require("../../../../assets/img/P3.jpeg"),
      thumbnail: require("../../../../assets/img/P3.jpeg"),
    },
    {
      original: require("../../../../assets/img/P4.jpeg"),
      thumbnail: require("../../../../assets/img/P4.jpeg"),
    },
    {
      original: require("../../../../assets/img/P5.jpeg"),
      thumbnail: require("../../../../assets/img/P5.jpeg"),
    },
    {
      original: require("../../../../assets/img/P77.jpg"),
      thumbnail: require("../../../../assets/img/P77.jpg"),
    },
  ];
  const swiperRef = useRef();
  return (
    <>
      <div>
        {drafts?.length === 0 || drafts === undefined ? (
          <>
            <Swiper
              className="w-[300px] sm:w-[400px] md:w-[400px] lg:w-[413px] xl:w-[832px] 2xl:w-[992px] sm:mt-20 mx-auto"
              // slidesPerView={2}
              breakpoints={{
                400: {
                  slidesPerView: 1,
                },
                1024: {
                  slidesPerView: 1,
                },
                1280: {
                  slidesPerView: 2,
                },
              }}
              grid={{
                rows: 2,
                fill: "row",
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Grid]}
              spaceBetween={32}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              <>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
              </>
            </Swiper>
            {/* {drafts?.length > 1 && ( */}
            <div className="flex items-center justify-end gap-x-2 mt-5">
              <CircularButton
                variant={"prev30"}
                onClick={() => swiperRef.current?.slideNext()}
              ></CircularButton>
              <CircularButton
                variant={"next30"}
                onClick={() => swiperRef.current?.slidePrev()}
              ></CircularButton>
            </div>
            {/* )} */}
          </>
        ) : (
          <>
            <Swiper
              className="w-[300px] sm:w-[400px] md:w-[400px] lg:w-[413px] xl:w-[832px] 2xl:w-[992px] sm:mt-20 mx-auto"
              // slidesPerView={2}
              breakpoints={{
                400: {
                  slidesPerView: 1,
                },
                1024: {
                  slidesPerView: 1,
                },
                1280: {
                  slidesPerView: 2,
                },
              }}
              grid={{
                rows: 2,
                fill: "row",
              }}
              pagination={{
                clickable: true,
              }}
              modules={[Grid]}
              spaceBetween={32}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              <>
              <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <DashboardAdsCard
                    additionalActions={true}
                    editButton={true}
                    uploadButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("../../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
              </>
            </Swiper>
            {/* {drafts?.length > 1 && ( */}
            <div className="flex items-center justify-end gap-x-2 mt-5">
              <CircularButton
                variant={"prev30"}
                onClick={() => swiperRef.current?.slideNext()}
              ></CircularButton>
              <CircularButton
                variant={"next30"}
                onClick={() => swiperRef.current?.slidePrev()}
              ></CircularButton>
            </div>
            {/* )} */}
          </>
        )}
      </div>
    </>
  );
};

export default PostsSlider;
