import React from 'react'
import Heading from '../Typography/Heading'

const Banner = ({heading, className, children, headingClasses}) => {
  return (
    <>
    <div className={`${className} w-[100%] h-screen sm:h-[1000px] lg:h-screen relative bg-no-repeat bg-center object-cover bg-cover`}>
        <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] h-full pt-48 xl:pt-48 2xl:pt-48 text-center sm:text-start">
          <Heading variant={"h1"} className={`${headingClasses}`}>
            {heading}
          </Heading>
          <>{children}</>
        </div>
      </div>
    </>
  )
}

export default Banner