import React from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import TextFiled from "../../../components/common/InputField/TextFiled";
import { useSelector } from "react-redux";
import useAuth from "../../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";
import SpecialText from "../../../components/common/Typography/SpecialText";

const CustAccount = () => {
  const { auth } = useAuth();
  const navigate = useNavigate();

  const ServieTagList = useSelector((tags) => tags.ServiceTagsReducer);
  const ListCategory = useSelector((cat) => cat.BusinessCategoryReducer)?.map(
    (item) => {
      return {
        value: item.id,
        label: item.businessCategoryName,
      };
    }
  );
  const BusinessServiceHandler = (e) => {
    let list = [];
    for (let i = 0; i < e.target.value.length; i++) {
      let tags = ServieTagList?.filter(
        (item) => item.businessCategoryId === e.target.value[i]
      )?.map((d) => {
        return d.tagName;
      });

      list = [...list, ...tags];
    }
  };
  return (
    <>
      <form>
        <div className="grid grid-cols-12 w-fit mx-auto">
          <div className="col-span-12">
            <div>
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"First name"}
                placeholder={"Enter your first name"}
                star={true}
              />
            </div>
            <div className="mt-5">
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"Last name"}
                placeholder={"Enter your surname or an initial here."}
                snippet={true}
                SnippetText={
                  "For our records and to personalize your experience, please provide either your full last name or simply its initials."
                }
              />
            </div>
            <div className="mt-5">
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"Location"}
                placeholder={"Type your Suburb or post code"}
                star={true}
              />
            </div>
            <div className="mt-5">
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"Email"}
                placeholder={"Enter your email"}
              />
            </div>
            <div className="mt-5">
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"Mobile"}
                placeholder={"Enter your mobile"}
                star={false}
              />
            </div>

            <div className="flex items-center justify-end mt-10">
              <MainButton variant={"small"}>Save</MainButton>
            </div>
          </div>
        </div>
        <div className="mt-10 mx-auto w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] border-t border-t-mediumgray pt-10">
          <SpecialText variant={"FootNoteDisclaimer"}>
            Privacy Disclaimer and Consent
          </SpecialText>
          <SpecialText variant={"FootNoteDisclaimer"} className={"mt-3"}>
            Trades Poster is committed to respecting and protecting your
            privacy. We only collect personal information necessary to deliver
            our services and conduct our business operations. We comply with all
            Australian privacy laws in handling your personal information.
          </SpecialText>
        </div>
        <div className="grid grid-cols-12 mt-10 max-w-fit mx-auto">
          <div className="col-span-12 w-full">
            <div className="flex justify-center">
              <MainButton
                variant={"specialredbuttonmedium"}
                onClick={() =>
                  navigate("/customer-dashboard/account-deactivation")
                }
              >
                Deactivate
              </MainButton>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default CustAccount;
