import React from "react";
import Mobile from "../../sections/CustomerDashboard/Dashboard/Mobile";
import Heading from "../../components/common/Typography/Heading";
import DashboardMainContainer from "../../components/common/Containers/DashboardMainContainer";
const CustMobile = () => {
  return (
    <>
      <DashboardMainContainer className={"lg:px-5 lg:pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center"}>
        Update mobile number
        </Heading>
        <div className="flex justify-center mt-[60px] min-h-screen">
          <Mobile />
        </div>
      </DashboardMainContainer>
    </>
  );
}; 
export default CustMobile;
