import React, { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger"; // Make sure to import ScrollTrigger from GSAP
import Heading from "../../components/common/Typography/Heading";

const ImageZoomEffect = () => {
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const timeline = gsap.timeline();

    timeline
      .to("svg", {
        scrollTrigger: {
          id: "Cover",
          trigger: ".hero",
          scrub: true,
          pin: ".hero",
          start: "top top",
        },
        scale: 6,
      })
      .to(".hero__image", {
        scrollTrigger: {
          id: "HERO-CONTENT",
          trigger: ".hero",
          start: "top -1%",
          toggleClass: "active",
        },
      });
  }, []);

  return (
    <>
      <div className="hero__inner hero">
        <div className="hero__images">
          <img
            src={require("../../assets/img/latest-main-banner.jpg")}
            alt=""
            className="hero__image"
          />
          <div className="w-[900px] 2xl:w-[1000px]  absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
            <Heading variant={"h1"} className={"text-white max-w-full"}>
            Boost Your Trade Business with Pro Features
            </Heading>
          </div>
        </div>
        <div className="hero__cover mx-auto">
          <svg
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            // viewBox="0 0 1920 1080"
            xmlSpace="preserve"
            preserveAspectRatio="xMidYMid meet"
            style={{ width: "100%", height: "100%" }}
          >
            <path
              className="st0"
              fill="#E8F0FF"
              d="M1236.4,540c0,152.7-123.8,276.4-276.4,276.4S683.6,692.7,683.6,540S807.3,263.6,960,263.6 S1236.4,387.3,1236.4,540z M1920,0H0v1080h1920V0z"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default ImageZoomEffect;
