import React from "react";
import { businessCategories } from "../Home/BusinessCategories";
import Card from "../../components/common/ReviewCard/Card";
import Heading from "../../components/common/Typography/Heading";
import MovingLeft from "../../components/common/FullLengthSwipers/MovingLeft";
import MovingRight from "../../components/common/FullLengthSwipers/MovingRight";

const SatisfiedClients = () => {
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] mt-10 sm:mt-40">
        <Heading variant={"h2"} className={"text-start text-offblack"}>
        Customer reviews and experiences
        </Heading>
      </div>
      <div className="mt-5 sm:mt-20">
        <MovingLeft>
          {businessCategories
            .sort((a, b) =>
              a.businessCategoryName.localeCompare(b.businessCategoryName)
            )
            .map((item, index) => (
              <div className="sliderImg" key={index}>
                <div className="cursor-pointer m-2 sm:m-4">
                  <Card />
                </div>
              </div>
            ))}
        </MovingLeft>
        <MovingRight>
          {businessCategories
            .sort((a, b) =>
              a.businessCategoryName.localeCompare(b.businessCategoryName)
            )
            .map((item, index) => (
              <div className="sliderImg" key={index}>
                <div className="cursor-pointer m-2 sm:m-4">
                  <Card />
                </div>
              </div>
            ))}
        </MovingRight>
      </div>
    </>
  );
};

export default SatisfiedClients;
