import React, { useState } from "react";
import InteractiveText from "../../components/common/Typography/InteractiveText";
import Heading from "../../components/common/Typography/Heading";
import { useParams } from "react-router-dom";

const CommonFAQs = () => {
  const { slug } = useParams();
  const data = [
    {
      slug: "architectural",
      heading: "Architectural FAQs",
      faqs: [
        {
          question: "What does an architect do?",
          answer:
            "Architects design buildings and manage projects to ensure they are safe, functional, and visually appealing. They also ensure compliance with building codes and regulations, collaborating with clients and other professionals throughout the design and construction process.",
        },
        {
          question: "Can architects help with building permits?",
          answer:
            "Yes, architects assist in the planning and approval process for building permits. Their expertise in local zoning laws and building codes helps streamline applications, ensuring compliance and preventing potential delays in project approvals.",
        },
        {
          question: "How long does it take to design a building?",
          answer:
            "The design timeline varies based on project size and complexity, typically taking 2 to 6 months. Factors such as client feedback, regulatory approvals, and project specifications can influence this timeline, making effective communication essential.",
        },
      ],
    },
    {
      slug: "air-conditioning-and-heating",
      heading: "Air conditioning & heating FAQs",
      faqs: [
        {
          question: "How often should I service my HVAC system?",
          answer:
            "Service your HVAC system every 6-12 months, ideally before the start of summer and winter seasons. Regular servicing helps maintain optimal efficiency, extends the system's lifespan, and reduces energy costs. It also helps identify potential issues early, preventing costly repairs or breakdowns during peak seasons.",
        },
        {
          question: "Can I install an air conditioner myself?",
          answer:
            "No, HVAC systems require professional installation to ensure safety, efficiency, and compliance with local regulations. Incorrect installation can lead to poor performance, increased energy consumption, and potential safety hazards, such as electrical issues or refrigerant leaks. Hiring a licensed technician ensures proper setup, safe operation, and valid warranties.",
        },
        {
          question: "What’s the average lifespan of an HVAC unit?",
          answer:
            "Typically, HVAC systems last 10-15 years with proper maintenance, although some high-quality units can last up to 20 years. Regular servicing, filter changes, and timely repairs can help maximize the unit’s lifespan. Factors like usage, local climate, and system type also influence how long an HVAC system will last.",
        },
      ],
    },
    {
      slug: "bricklaying",
      heading: "Bricklaying FAQs",
      faqs: [
        {
          question: "What are the signs of brickwork damage?",
          answer:
            "Cracks, loose bricks, or water stains signal potential damage. Regular checks help catch these issues early, ensuring safety and longevity. Additional signs include bulging walls, discoloured mortar, or moss growth on damp surfaces, all of which suggest moisture intrusion or structural weakening.",
        },
        {
          question: "Can brickwork be done in rainy weather?",
          answer:
            "No, rain can weaken mortar during curing, affecting brickwork stability. It's best to schedule work in dry conditions for proper setting and strength. Wet weather can also cause uneven settling and mortar washout, leading to long-term defects in the structure.",
        },
      ],
    },
    {
      slug: "cabinet-making",
      heading: "Cabinet making FAQs",
      faqs: [
        {
          question: "What materials are best for kitchen cabinets?",
          answer:
            "Popular choices include solid wood, plywood, and laminate. Each offers unique benefits in terms of durability, aesthetics, and budget considerations. Solid wood provides a classic look with excellent longevity, while plywood offers good strength and moisture resistance. Laminate is cost-effective and easy to maintain, making it ideal for high-use areas.",
        },
        {
          question: "How long does it take to install custom cabinetry?",
          answer:
            "Installation times vary based on size and complexity, but typically range from 1-2 weeks for kitchens and 1 week for smaller units like wardrobes. The process includes measuring, fitting, and final adjustments. Delays may occur due to custom designs, availability of materials, or unforeseen adjustments needed on-site.",
        },
        {
          question: "What’s the average lifespan of custom cabinets?",
          answer:
            "Well-maintained cabinets can last 15-20 years or more. Regular care, like cleaning, tightening hinges, and resealing finishes, can extend their lifespan. Cabinets made with high-quality materials and hardware tend to last longer, while routine inspections can prevent issues like warping or water damage.",
        },
      ],
    },
    {
      slug: "carpentry",
      heading: "Carpentry FAQs",
      faqs: [
        {
          question: "How long does it take to build a deck?",
          answer:
            "The timeline depends on size, complexity, and weather conditions. On average, it takes 1-3 weeks for small to medium decks, but larger or more detailed decks may require up to 4-5 weeks for completion, including finishing touches.",
        },
        {
          question: "What’s the best wood for flooring?",
          answer:
            "Hardwoods like oak, blackbutt, and spotted gum are popular for their durability and resistance to wear. For a more affordable option, pine can be used, but it may need more frequent maintenance.",
        },
        {
          question: "Do I need permits for a pergola?",
          answer:
            "Yes, permits are typically required for outdoor structures like pergolas. Permit requirements vary by local council and can depend on factors such as size, height, and location. It’s best to consult your local council before starting construction.",
        },
        {
          question: "What’s involved in roof carpentry?",
          answer:
            "Roof carpentry includes constructing the timber framework for roofs, installing rafters, trusses, and eaves. It may also involve adding insulation, which can extend project duration depending on roof size and design.",
        },
      ],
    },
    {
      slug: "carpet-and-flooring",
      heading: "Carpet & flooring  FAQs",
      faqs: [
        {
          question: "How often should carpets be replaced?",
          answer:
            "With proper care and regular cleaning, carpets generally last 7-10 years. High-traffic areas may need replacing sooner, while well-maintained carpets in low-traffic areas can last longer. Look for signs like matting, wear, or persistent odours as indicators for replacement.",
        },
        {
          question: "Is vinyl flooring waterproof?",
          answer:
            "Yes, vinyl flooring is highly water-resistant, with certain types (like luxury vinyl planks) being fully waterproof. This makes it suitable for kitchens, bathrooms, and other moisture-prone areas. It’s also easy to clean and maintain, adding to its appeal for wet areas.",
        },
        {
          question: "Can epoxy flooring be used outdoors?",
          answer:
            "Yes, specially formulated epoxy is designed for outdoor use on surfaces like patios, driveways, and pool surrounds. It is resistant to UV rays, weather, and chemicals, making it durable and suitable for outdoor applications when applied correctly.",
        },
      ],
    },
    {
      slug: "caulking",
      heading: "Caulking FAQs",
      faqs: [
        {
          question: "How often should caulking be replaced?",
          answer:
            "The frequency depends on factors like wear, exposure, and the type of caulk used. Typically, caulking should be replaced every 5-10 years, but high-traffic or heavily exposed areas may require more frequent attention. Regular inspections help identify gaps, cracks, or wear that need sealing.",
        },
        {
          question: "Can caulking prevent water damage?",
          answer:
            "Yes, caulking is essential in wet areas like bathrooms, kitchens, and around windows, where it helps prevent water penetration. Proper caulking stops leaks, protects against mold, and reduces the risk of structural damage caused by moisture buildup.",
        },
        {
          question: "What’s the best caulk for exterior use?",
          answer:
            "Polyurethane caulk is preferred for exteriors due to its durability, flexibility, and resistance to weather conditions. It adheres well to various surfaces, making it ideal for sealing windows, doors, and siding.",
        },
        {
          question: "Is caulking effective for energy savings?",
          answer:
            "Yes, caulking seals gaps and cracks around windows, doors, and other openings, reducing drafts and improving energy efficiency. It helps maintain indoor temperatures, reducing the workload on heating and cooling systems, and lowering energy bills.",
        },
        {
          question: "Can caulking prevent pests?",
          answer:
            "Absolutely. Caulking effectively seals entry points where insects and rodents can enter, especially around windows, doors, and foundations. Regularly checking and maintaining caulked areas helps keep pests out and protects the building's integrity.",
        },
      ],
    },
    {
      slug: "cleaning-services",
      heading: "Cleaning FAQs",
      faqs: [
        {
          question: "How often should I deep clean my home?",
          answer:
            "Deep cleaning is recommended every 3-6 months to maintain a healthy environment. The frequency depends on factors like number of occupants, pets, allergies, and general foot traffic. High-traffic areas or homes with pets and children may benefit from more frequent deep cleaning to remove built-up dirt, allergens, and germs.",
        },
        {
          question: "Is pressure washing safe for all surfaces?",
          answer:
            "Not always; certain surfaces like wood, older bricks, or painted walls may require lower pressure or special cleaning agents to avoid damage. Pressure washing is highly effective for driveways, concrete, and stone but should be adjusted based on the material to prevent issues like surface etching or paint stripping.",
        },
        {
          question: "What’s included in end-of-lease cleaning?",
          answer:
            "End-of-lease cleaning typically involves a comprehensive deep clean of all rooms, including carpets, windows, kitchen appliances, bathrooms, and other surfaces. It often includes steam cleaning of carpets, scrubbing bathrooms, and ensuring walls, floors, and windows are spotless to meet rental property standards and maximize the chances of bond recovery.",
        },
      ],
    },
    {
      slug: "concrete-and-paving",
      heading: "Concrete & paving FAQs",
      faqs: [
        {
          question: "How long does concrete need to cure before use?",
          answer:
            "Concrete generally requires 24-48 hours for initial curing, during which it hardens enough for light foot traffic. However, it takes about 28 days to reach full strength, allowing it to support heavy loads like vehicles or equipment. During this time, proper moisture control is crucial to prevent cracking.",
        },
        {
          question: "Is exposed aggregate slippery when wet?",
          answer:
            "No, exposed aggregate has a rough, textured surface, making it slip-resistant even in wet conditions. This makes it a popular choice for driveways, pathways, and pool decks, as it enhances safety without compromising on aesthetics.",
        },
        {
          question: "What’s the best sealant for concrete?",
          answer:
            "Acrylic sealants are commonly used for driveways due to their protective coating and quick drying time, while penetrating sealers are preferred for polished or indoor floors as they offer better durability and resistance to stains without altering the surface's appearance.",
        },
        {
          question: "How often should concrete be resealed?",
          answer:
            "Resealing is typically recommended every 2-3 years, depending on surface exposure and use. High-traffic areas or outdoor surfaces may require more frequent resealing to maintain protection against moisture, stains, and UV damage.",
        },
        {
          question: "Can concrete be installed in cold weather?",
          answer:
            "Yes, but it requires special precautions like heating the mix, using curing blankets, or adding accelerators to speed up the curing process. Cold temperatures slow curing, so these measures help maintain strength and prevent cracking during installation.",
        },
      ],
    },
    {
      slug: "conveyancing",
      heading: "Conveyancing FAQs",
      faqs: [
        {
          question: "How long does conveyancing take?",
          answer:
            "Conveyancing typically takes 4-8 weeks, but the exact timeframe depends on the complexity of the transaction and how quickly all parties are ready for settlement. Factors like delays in receiving mortgage approvals, property searches, or unexpected legal issues can extend the process.",
        },
        {
          question: "Is a conveyancer the same as a lawyer?",
          answer:
            "No, a conveyancer and a lawyer have different roles. A conveyancer is a licensed professional who specializes in property transactions, handling tasks like title transfers, contract reviews, and settlements. In contrast, a lawyer can provide broader legal services and handle more complex matters, such as disputes or litigation related to property.",
        },
        {
          question: "Do I need a conveyancer for a private sale?",
          answer:
            "Yes, it is advisable to hire a conveyancer even for a private sale. They ensure that all legal aspects are properly managed, including the preparation and review of contracts, compliance with property laws, and accurate transfer of ownership. This minimizes the risk of errors and legal complications that could arise during or after the transaction.",
        },
      ],
    },
    {
      slug: "blinds-and-curtains",
      heading: "Curtains, blinds & awnings FAQs",
      faqs: [
        {
          question: "Are motorized blinds energy-efficient?",
          answer:
            "Yes, motorized blinds can significantly improve energy efficiency by helping regulate indoor temperatures. They can be programmed to open or close at optimal times, reducing heat gain in the summer and retaining warmth in the winter, which lowers overall energy consumption and utility costs.",
        },
        {
          question: "How often should I clean my blinds and curtains?",
          answer:
            "It’s recommended to clean blinds and curtains every 6-12 months to maintain their condition and hygiene. Dust and allergens can accumulate over time, impacting air quality, so regular cleaning helps keep your living space healthy. Certain materials, like delicate fabrics, may require professional cleaning for the best results.",
        },
        {
          question: "Can awnings withstand strong winds?",
          answer:
            "Yes, awnings are designed to be durable and, when installed correctly, can withstand strong winds. They comply with AS 4055, which outlines standards for wind load requirements, ensuring that awnings remain secure and stable under specified conditions. However, retractable awnings should be closed during severe weather to prevent damage.",
        },
      ],
    },
    {
      slug: "decking-and-gazebos",
      heading: "Decking & gazebo FAQs",
      faqs: [
        {
          question: "How long does it take to build a deck?",
          answer:
            "Building a deck generally takes 1-3 weeks, depending on factors like the size, design complexity, and weather conditions. Smaller, straightforward projects may be completed quickly, while larger decks with custom features, like built-in seating or stairs, can take longer. Professional builders can provide a more accurate timeline based on your specific requirements.",
        },
        {
          question: "Is composite decking better than timber?",
          answer:
            "It depends on your priorities. Composite decking is popular for being low-maintenance, resistant to rot, and highly durable, making it ideal for long-term use. However, it tends to be more expensive upfront. Timber decking, on the other hand, offers a natural, classic appearance and can be more budget-friendly, but it requires regular staining and maintenance to protect against the elements. Choosing between them depends on your aesthetic preference, maintenance willingness, and budget.",
        },
        {
          question: "Do I need council approval for a carport?",
          answer:
            "Yes, in most cases, council approval is required for building a carport. Local regulations vary, so it’s essential to check with your local council to understand the specific requirements, permits, and zoning rules. Approval may depend on factors like the carport's size, location on the property, and proximity to boundaries or neighboring structures. Failing to obtain the necessary permits could result in fines or having to remove the structure.",
        },
      ],
    },
    {
      slug: "demolition-and-excavation",
      heading: "Demolition & excavation  FAQs",
      faqs: [
        {
          question: "How long does it take to demolish a house?",
          answer:
            "The timeframe for demolishing a house typically ranges from 1-2 weeks, depending on factors like the size of the property, structural complexity, and the presence of hazardous materials like asbestos. Pre-demolition work, such as permit approvals and utility disconnections, can also affect the timeline.",
        },
        {
          question: "Is a permit needed for demolition?",
          answer:
            "Yes, a permit is required for most demolition projects to ensure compliance with local regulations and safety standards. The specific requirements vary by region, so it’s essential to check with your local council or regulatory authority to understand what documentation and approvals are necessary before starting.",
        },
        {
          question: "Can asbestos be removed during demolition?",
          answer:
            "Yes, asbestos can be safely removed during demolition, but it must be handled by licensed professionals who follow strict safety protocols. These specialists use protective gear and containment measures to prevent asbestos fibers from contaminating the air, ensuring the safety of workers and the surrounding community. Proper disposal of asbestos is also regulated and must be carried out at approved facilities.",
        },
      ],
    },
    {
      slug: "electrical",
      heading: "Electrical FAQs",
      faqs: [
        {
          question:
            "How often should electrical safety inspections be conducted?",
          answer:
            "Electrical safety inspections are recommended every 1-2 years to ensure all systems are functioning safely and efficiently. Inspections are also crucial before buying or selling a property to identify any potential hazards or needed upgrades. Regular checks help prevent electrical fires and ensure compliance with current safety standards.",
        },
        {
          question: "Can I do my own electrical repairs?",
          answer:
            "No, you should never attempt to perform electrical repairs yourself. Only licensed electricians are qualified to handle electrical work, as they are trained to ensure both safety and compliance with Australian regulations. DIY electrical work can be extremely dangerous and may void insurance coverage or lead to significant legal penalties.",
        },
        {
          question: "Is rewiring necessary for old homes?",
          answer:
            "Yes, rewiring is often necessary for older homes to meet modern safety standards and accommodate today’s higher electrical demands. Outdated wiring systems may pose a risk of electrical fires, short circuits, or overloaded circuits. Licensed electricians can assess the condition of the existing wiring and recommend necessary upgrades to ensure the home's electrical system is safe and reliable.",
        },
      ],
    },
    {
      slug: "engineering-services",
      heading: "Engineering FAQs",
      faqs: [
        {
          question: "What does a structural engineer do?",
          answer:
            "A structural engineer specializes in analysing and designing the structural integrity of buildings, bridges, and other infrastructure. They ensure that a structure can safely support the intended loads and withstand environmental factors like wind, earthquakes, or soil conditions. Structural engineers also assess existing structures for damage or needed reinforcements.",
        },
        {
          question: "Can engineers handle project management?",
          answer:
            "Yes, many engineers also provide project management services to oversee the execution of their designs. This includes coordinating with architects, contractors, and other stakeholders to ensure the project is completed on time, within budget, and to the specified standards. Their technical expertise allows for efficient problem-solving and quality assurance throughout the construction process.",
        },
        {
          question: "How long does an engineering assessment take?",
          answer:
            "The duration of an engineering assessment depends on the size and complexity of the project. For small residential assessments, it may take 2-4 weeks, while larger or more complex projects, such as commercial buildings or infrastructure, may require 6-8 weeks or longer. The timeline includes site visits, data collection, analysis, and report preparation.",
        },
      ],
    },
    {
      slug: "fence-and-gates",
      heading: "Fencing & gate FAQs",
      faqs: [
        {
          question: "Do I need council approval for a new fence?",
          answer:
            "Yes, council approval may be required depending on the height, location, and purpose of the fence. For example, fences over a certain height (typically 1.8 meters) or those installed near property boundaries may need permits. It's best to consult your local council to understand specific regulations and avoid fines or having to modify non-compliant structures.",
        },
        {
          question: "How tall can a residential fence be?",
          answer:
            "The maximum height for a residential fence is generally 1.8-2.0 meters, but this can vary based on local zoning laws and specific neighbourhood rules. For front yard fences, the height may be restricted further, often to around 1.2-1.5 meters, to ensure visibility and safety. Always check with your local council to confirm what is allowed in your area.",
        },
        {
          question: "Are electric gates safe?",
          answer:
            "Yes, electric gates are safe when installed by licensed professionals. These gates come with built-in safety features, such as sensors and automatic stop functions, to prevent injuries or damage if an obstruction is detected. Additionally, regular maintenance is essential to keep these safety systems in proper working order and to ensure the gate operates smoothly.",
        },
      ],
    },
    {
      slug: "glazing-and-screens",
      heading: "Glazing & screen FAQs",
      faqs: [
        {
          question: "Is double glazing worth the cost?",
          answer:
            "Yes, double glazing is a valuable investment as it significantly improves insulation, reduces external noise, and enhances energy efficiency. By keeping indoor temperatures stable, it can lower heating and cooling costs over time, making it especially beneficial in extreme climates or high-traffic areas.",
        },
        {
          question:
            "Can broken windows be repaired, or should they be replaced?",
          answer:
            "It depends on the extent of the damage. Minor cracks or chips in non-critical areas can sometimes be repaired using specialized techniques. However, if the damage compromises the window's structural integrity, safety, or energy efficiency, replacement is usually the safer and more cost-effective option, especially for safety glass or double-glazed units.",
        },
      ],
    },
    {
      slug: "garden-and-landscaping",
      heading: "Landscaping FAQs",
      faqs: [
        {
          question: "How often should gardens be maintained?",
          answer:
            "Regular garden maintenance is recommended every 1-2 weeks, especially during the growing season when plants and lawns require frequent attention. Tasks like mowing, weeding, pruning, and fertilizing can help keep the garden healthy and looking its best. In cooler months, maintenance can be less frequent, but periodic care is still needed to prepare plants for seasonal changes.",
        },
        {
          question: "Do I need council approval for landscaping?",
          answer:
            "It depends on the scope and nature of the landscaping project. Simple garden work typically doesn’t require approval, but major landscaping projects, such as building retaining walls, excavating soil, or installing poolside structures, may need permission from your local council. Always check local regulations to ensure compliance before starting large-scale landscaping work.",
        },
        {
          question: "Can I install synthetic turf instead of natural grass?",
          answer:
            "Yes, synthetic turf is a popular low-maintenance alternative to natural grass, especially in areas with poor soil quality or limited water access. It requires minimal upkeep, stays green year-round, and can be a practical solution for high-traffic areas or spaces where natural grass struggles to thrive. However, it’s important to consider factors like heat retention and the environmental impact before choosing synthetic turf.",
        },
      ],
    },
    {
      slug: "handyman",
      heading: "Handyman FAQs",
      faqs: [
        {
          question: "Can handymen do electrical work?",
          answer:
            "Yes, but only minor tasks like replacing switches, installing light fixtures, or fixing outlets. Major electrical work must be performed by a licensed electrician to ensure safety and compliance with regulations.",
        },
        {
          question: "Do handymen supply their materials?",
          answer:
            "Generally, handymen bring their own tools and some basic supplies. However, specific materials like paint, tiles, or wood may need to be purchased separately or provided by the client, depending on the project.",
        },
        {
          question: "How long does it take for a handyman to complete tasks?",
          answer:
            "Most minor repairs or installations are completed within 1-3 hours. Larger projects, like tiling or multiple repairs in one visit, may take longer or require additional visits.",
        },
        {
          question: "Can a handyman help with assembling furniture?",
          answer:
            "Yes, handymen are experienced in assembling flat-pack or custom furniture for homes and offices, often completing the work quickly and efficiently to ensure everything is properly set up.",
        },
        {
          question: "Are handymen insured?",
          answer:
            "Most licensed handymen carry liability insurance to cover any accidental damage or injuries that may occur while working on your property. It’s a good idea to confirm insurance coverage before starting a project.",
        },
      ],
    },
    {
      slug: "inspection-and-surveyors",
      heading: "Inspection & surveying FAQs",
      faqs: [
        {
          question: "How long does a building inspection take?",
          answer:
            "A building inspection usually takes between 1-3 hours, depending on the property’s size and complexity. Larger or more intricate properties require more time to thoroughly examine all structural and safety aspects.",
        },
        {
          question: "Are land surveys necessary for building projects?",
          answer:
            "Yes, land surveys are crucial as they provide essential data for planning, development, and establishing accurate legal boundaries. They help prevent disputes, ensure compliance with zoning regulations, and are often a requirement before beginning construction.",
        },
        {
          question: "Do I need an asbestos inspection for an older home?",
          answer:
            "Yes, if your home was built before 1990, it is highly recommended to have an asbestos inspection. This ensures safety and compliance with regulations, as asbestos-containing materials were commonly used in older buildings and can pose serious health risks if disturbed.",
        },
      ],
    },
    {
      slug: "security-and-automation",
      heading: "Home security & automation FAQs",
      faqs: [
        {
          question: "Are smart locks secure?",
          answer:
            "Yes, smart locks are designed with advanced security features like encryption, tamper alerts, and customizable access controls. Many models allow you to monitor lock activity and receive alerts, providing greater control and security compared to traditional locks. However, it’s important to choose reputable brands and update software regularly to minimize vulnerabilities.",
        },
        {
          question: "Can I control my security system remotely?",
          answer:
            "Yes, most modern security systems offer remote access through mobile apps. This allows you to arm or disarm alarms, view live camera feeds, lock or unlock doors, and adjust smart home settings from anywhere. Remote control provides flexibility and real-time updates, making it easier to monitor your property while you’re away.",
        },
        {
          question: "Is professional monitoring necessary?",
          answer:
            "While professional monitoring isn’t mandatory, it offers added peace of mind. With monitoring, a security company will immediately respond to alarms and can contact emergency services on your behalf if needed. This faster response time can be crucial in emergencies, making it a valuable service for homeowners who prioritize safety and convenience.",
        },
      ],
    },
    {
      slug: "insulation-services",
      heading: "Insulation FAQs",
      faqs: [
        {
          question: "How often should insulation be replaced?",
          answer:
            "Insulation generally lasts 15-20 years, but it should be inspected periodically and replaced if it becomes damaged, wet, or loses effectiveness. Regular checks help ensure your insulation continues to perform well over time.",
        },
        {
          question: "Is insulation flammable?",
          answer:
            "Most modern insulation materials are designed to be fire-resistant and comply with AS 1530.3 for fire resistance standards. However, it's essential to verify that the insulation used in your home meets fire safety requirements for added peace of mind.",
        },
        {
          question:
            "What’s the difference between bulk and reflective insulation?",
          answer:
            "Bulk insulation works by trapping air within the material, slowing down heat transfer, which is ideal for temperature control. Reflective insulation, on the other hand, reflects radiant heat away, making it effective in hot climates to keep spaces cooler.",
        },
      ],
    },
    {
      slug: "interior-design",
      heading: "Interior design FAQs",
      faqs: [
        {
          question: "How long does an interior design project take?",
          answer:
            "The timeline varies based on the project scope and complexity. Small projects may take only a few weeks, while full home renovations or complex commercial spaces can take several months to complete, including design, sourcing, and installation.",
        },
        {
          question: "Is hiring an interior designer expensive?",
          answer:
            "Costs depend on factors like project size, material choices, and customization. While hiring a designer adds to the budget, they often save clients money by sourcing materials at discounted rates, managing budgets efficiently, and avoiding costly mistakes.",
        },
        {
          question: "What is 3D rendering in interior design?",
          answer:
            "3D rendering is a technology that creates lifelike models of design concepts, allowing clients to see a virtual representation of the space, including layout, colors, and textures, before any physical work begins. This helps clients make informed decisions and visualize the final outcome.",
        },
      ],
    },
    {
      slug: "locksmiths",
      heading: "Locksmith FAQs",
      faqs: [
        {
          question: "How quickly can a locksmith arrive in an emergency?",
          answer:
            "In most cases, locksmiths aim to respond within 30-60 minutes for emergency situations, like lockouts or security breaches. Availability and location may impact response time, but many locksmiths prioritize urgent cases to help clients regain access and restore security as quickly as possible.",
        },
        {
          question: "Can all locks be rekeyed?",
          answer:
            "Yes, most standard locks can be rekeyed, which involves changing the internal mechanism so only new keys work. This is a cost-effective way to maintain security without replacing the lock itself. However, certain high-security or specialized locks, like restricted key systems, may need additional steps or tools for rekeying, and some digital locks may not be rekeyable in the traditional sense.",
        },
        {
          question: "Are digital locks more secure than traditional locks?",
          answer:
            "Digital locks offer advanced security features like keyless entry, remote access, and access logs, which can provide an extra layer of protection and convenience. However, their effectiveness relies on correct installation and consistent maintenance. While digital locks can be highly secure, it’s essential to update their software, change access codes regularly, and monitor for any issues to ensure maximum security.",
        },
      ],
    },
    {
      slug: "painting-and-decorating",
      heading: "Painting & decoration FAQs",
      faqs: [
        {
          question: "How long does it take to paint a room?",
          answer:
            "Typically, painting a room takes between 1-3 days. This timeframe depends on the room’s size, the condition of the surfaces, and the number of coats required. Additional tasks, such as priming, filling holes, or sanding, may extend the process. Painters may also allow drying time between coats to ensure a smooth, even finish.",
        },
        {
          question: "Is priming surfaces before painting necessary?",
          answer:
            "Yes, priming is highly recommended as it helps the paint adhere to the surface, provides a uniform base, and enhances the paint’s color and finish. Priming is especially essential on new, porous, or previously patched surfaces, as it can prevent peeling and extend the paint’s lifespan, ensuring a longer-lasting and more professional result.",
        },
        {
          question: "Can exterior painting be done in winter?",
          answer:
            "Yes, exterior painting can be done in winter, but it’s important to avoid painting in wet or extremely cold weather. Lower temperatures and humidity levels can affect paint drying and adhesion, potentially leading to an uneven finish or reduced durability. Many painters will wait for dry, mild days in winter to achieve the best results, using paints specifically formulated for cooler temperatures if needed.",
        },
      ],
    },
    {
      slug: "moving-and-storage",
      heading: "Moving FAQs",
      faqs: [
        {
          question: "How far in advance should I book a moving service?",
          answer:
            "It’s best to book moving services at least 2-4 weeks in advance, particularly during busy periods like summer or end-of-month, when demand is high. Booking early ensures you secure your preferred moving date and allows time for any necessary planning, such as packing and organizing utilities.",
        },
        {
          question: "Can movers pack everything in my home?",
          answer:
            "Yes, full-service movers offer comprehensive packing and unpacking services for all household items, from furniture and appliances to delicate glassware and artwork. Professional packing helps prevent damage and ensures that items are appropriately wrapped, boxed, and labelled for safe and efficient transportation.",
        },
        {
          question: "Is insurance necessary for moving?",
          answer:
            "While not legally required, it’s highly recommended to have transit insurance for your belongings. Moving insurance protects against accidental damage, loss, or unforeseen events during transport, offering peace of mind and financial protection. Some moving companies provide basic coverage, but you may opt for additional insurance for high-value or irreplaceable items.",
        },
      ],
    },
    {
      slug: "mold-and-asbestos",
      heading: "Mould & asbestos FAQs",
      faqs: [
        {
          question: "How long does mould remediation take?",
          answer:
            "The duration depends on the severity of the mould infestation and area affected. Minor cases may be resolved in a day, while extensive remediation can take up to a week to ensure complete removal and air quality restoration.",
        },
        {
          question: "Is asbestos always dangerous?",
          answer:
            "Asbestos poses health risks when its fibres become airborne due to damage or disturbance. If undisturbed and in good condition, asbestos is generally safe but should still be professionally assessed, especially in older buildings.",
        },
        {
          question: "Can I remove mould myself?",
          answer:
            "Small mould patches can often be cleaned with household products, but larger infestations, toxic mould types, or recurring mould should be handled by professionals to ensure thorough remediation and prevent health risks.",
        },
        {
          question:
            "How often should asbestos be inspected in older buildings?",
          answer:
            "For safety, asbestos-containing materials in older buildings should be inspected annually or whenever renovations are planned, as disturbance can release harmful fibres.",
        },
      ],
    },
    {
      slug: "pest-control",
      heading: "Pest control FAQs",
      faqs: [
        {
          question: "How often should pest control be done?",
          answer:
            "Pest control treatments are typically recommended every 6-12 months to effectively manage pest risks, although the frequency can vary depending on factors like pest type, property location, and environmental conditions. Properties in high-risk areas may benefit from more frequent inspections and treatments to ensure lasting protection.",
        },
        {
          question: "Is pest control safe for pets and children?",
          answer:
            "Yes, modern pest control treatments are generally safe for pets and children when handled by licensed professionals who follow safety guidelines. Professionals may advise certain precautions, such as keeping pets and children away from treated areas until the application is fully dried and ventilated, to ensure extra safety.",
        },
        {
          question: "How long does a termite treatment last?",
          answer:
            "The longevity of termite treatments depends on the type of treatment applied. Chemical barriers, for example, can last between 5-10 years, while baiting systems may require regular monitoring and refilling. Regular inspections, typically every 1-2 years, are recommended to ensure ongoing effectiveness and early detection of any new termite activity.",
        },
      ],
    },
    {
      slug: "plastering",
      heading: "Plastering FAQs",
      faqs: [
        {
          question: "How long does plaster take to dry?",
          answer:
            "Plaster generally takes 24-48 hours to dry, but drying time can vary based on the thickness of the plaster and the room's humidity level. Thicker applications and high humidity environments may require a longer drying period to ensure the plaster is fully set before any finishing treatments.",
        },
        {
          question: "Is plastering safe for fire-rated walls?",
          answer:
            "Yes, plastering is safe for fire-rated walls when fire-rated plasterboard is used, adhering to AS 3959 standards. This ensures the plaster provides additional fire resistance, making it suitable for both residential and commercial spaces where safety is a priority.",
        },
        {
          question: "Can you paint directly over fresh plaster?",
          answer:
            "No, fresh plaster should not be painted directly. It needs to be primed with a sealer or undercoat first to ensure that paint adheres properly and provides a smooth, even finish. Skipping this step can lead to uneven or peeling paint over time.",
        },
        {
          question: "How often should plaster surfaces be maintained?",
          answer:
            "Plaster surfaces should be inspected every few years for cracks, chips, or water damage. Prompt repairs help maintain the surface’s integrity and prevent minor issues from becoming costly repairs, ensuring the walls and ceilings stay in good condition over time.",
        },
      ],
    },
    {
      slug: "plumbing",
      heading: "Plumbing FAQs",
      faqs: [
        {
          question: "What are the signs of a blocked drain?",
          answer:
            "Common signs include slow drainage, foul odours, gurgling sounds, or water backups. Regular cleaning can help prevent blockages.",
        },
        {
          question: "Can I install a gas appliance myself?",
          answer:
            "No, only licensed professionals should handle gas installations to ensure safety, compliance with regulations, and prevent hazards.",
        },
        {
          question: "How often should I service my hot water system?",
          answer:
            "It’s recommended to service your hot water system every 1-2 years. This helps maintain efficiency, prevent costly repairs, and ensure reliable performance.",
        },
      ],
    },
    {
      slug: "pools-and-spas",
      heading: "Pool & spa FAQs",
      faqs: [
        {
          question: "How often should pool water be tested?",
          answer:
            "Pool water should be tested at least once a week to maintain a safe and balanced chemical environment. Regular testing helps prevent issues like algae growth, cloudy water, or skin irritation and ensures that chlorine, pH, and other chemical levels remain within safe ranges. During peak swimming season, more frequent testing is recommended.",
        },
        {
          question: "Is it necessary to winterize a pool?",
          answer:
            "Yes, winterizing a pool is essential to protect it from damage caused by freezing temperatures. Proper winterization helps prevent pipes and equipment from cracking, reduces the risk of algae buildup, and keeps the water clean over the colder months. This process typically involves lowering the water level, adding winter chemicals, and covering the pool.",
        },
        {
          question: "Can solar heating be installed on existing pools?",
          answer:
            "Yes, solar heating systems can be retrofitted to most existing pools, offering an energy-efficient way to heat the water. Solar panels are installed on a roof or sunny area near the pool, and water circulates through the panels to absorb solar heat before returning to the pool. This eco-friendly option can extend the swimming season without significantly increasing energy costs.",
        },
      ],
    },
    {
      slug: "rendering",
      heading: "Rendering FAQs",
      faqs: [
        {
          question: "How long does rendering take to dry?",
          answer:
            "Rendering typically takes 24-48 hours to set, allowing it to become firm enough for light handling, but full curing can take up to 28 days. The final curing time depends on factors like the type of render (e.g., cement, acrylic) and environmental conditions such as temperature and humidity. For best results, it’s advisable to allow the render to fully cure before painting or applying any additional finishes.",
        },
        {
          question: "Can rendering be applied over painted surfaces?",
          answer:
            "Yes, rendering can be applied over painted surfaces, but preparation is essential. The painted surface must be thoroughly cleaned, sanded, and primed to create a rough texture that allows the new render to adhere properly. Without these steps, the render may not bond well, which can lead to peeling or cracking over time.",
        },
        {
          question: "Is rendering waterproof?",
          answer:
            "Certain types of renders, such as acrylic and insulated render, offer better water resistance compared to traditional cement render. While these renders provide a level of protection against moisture, additional waterproofing measures—such as specialized coatings or membranes—are often recommended for areas exposed to significant moisture, such as bathrooms or exterior walls in high-rainfall regions.",
        },
      ],
    },
    {
      slug: "renovation-and-restoration",
      heading: "Renovation & restoration FAQs",
      faqs: [
        {
          question: "How long does a home renovation take?",
          answer:
            "The timeline for a home renovation can vary widely depending on the project's scope and complexity. Small renovations, such as updating a bathroom or kitchen, may take only a few weeks. However, larger projects, such as full home remodels or multi-room renovations, can take several months to complete. Factors like obtaining permits, sourcing materials, and unexpected structural issues can also impact the duration.",
        },
        {
          question: "Can I live in my house during a renovation?",
          answer:
            "In many cases, homeowners can continue living in their house during smaller renovations, especially if only a single room or specific area is under construction. However, major renovations, such as those involving the kitchen, bathrooms, or entire floors, may require temporary relocation due to noise, dust, and lack of essential services. Consulting with the contractor in advance can help determine if staying on-site is practical.",
        },
        {
          question:
            "What is the difference between renovation and restoration?",
          answer:
            "Renovation and restoration serve different purposes. Renovation focuses on updating and improving spaces, often adding modern features, enhancing functionality, and aligning with current design trends. Restoration, on the other hand, aims to return a building or space to its original condition, preserving historical or architectural elements using traditional materials and methods. While renovations offer customization, restorations emphasize preserving original aesthetics and structural integrity.",
        },
      ],
    },
    {
      slug: "roofing",
      heading: "Roofing FAQs",
      faqs: [
        {
          question: "How often should a roof be inspected?",
          answer:
            "Roofs should be inspected at least once a year, ideally in spring or autumn, to catch any wear, leaks, or structural issues early. Inspections are also important after severe weather events, such as hailstorms or high winds, which can cause hidden damage that, if untreated, may lead to costly repairs.",
        },
        {
          question: "Can a roof be replaced during winter?",
          answer:
            "Yes, roof replacement can occur in winter, but wet or snowy conditions can delay the project. Cold weather may also affect the installation process, as some materials perform better in warmer temperatures. Many professionals recommend scheduling replacements in summer or autumn to avoid these issues, though a skilled roofer can manage winter replacements if necessary.",
        },
        {
          question: "How long does a typical roof last?",
          answer:
            "Roof lifespan depends on the materials used and maintenance practices. Asphalt shingle roofs typically last 20-25 years, while metal roofs can last 40-50 years or longer with proper care. Regular inspections and minor repairs can help maximize a roof’s lifespan regardless of material.",
        },
        {
          question: "What is the best material for a roof?",
          answer:
            "The best roofing material depends on your location, budget, and aesthetic preferences. Metal roofing is highly durable and weather-resistant, making it ideal for areas with extreme weather. Tile and slate provide excellent insulation and can withstand heavy winds, while asphalt shingles are affordable and widely available. Consulting with a professional roofer can help determine the best choice for your specific needs.",
        },
        {
          question: "Is roof insulation necessary?",
          answer:
            "Yes, roof insulation is essential for maintaining energy efficiency in a home. Proper insulation reduces heat loss in winter and keeps interiors cooler in summer, leading to lower energy bills. Standards like AS/NZS 4200.2 specify insulation requirements to ensure optimal thermal performance, which not only improves comfort but also helps protect the roof from condensation-related damage.",
        },
      ],
    },
    {
      slug: "scaffolding",
      heading: "Scaffolding FAQs",
      faqs: [
        {
          question: "How often should scaffolding be inspected?",
          answer:
            "Scaffolding should be inspected before initial use to ensure safety and stability. During active use, inspections should occur weekly to check for wear, damage, or any changes in stability. Additional inspections are recommended after any alterations to the structure or following adverse weather conditions, such as high winds or heavy rain, to confirm that the scaffolding remains secure.",
        },
        {
          question: "Is scaffolding necessary for single-story renovations?",
          answer:
            "Scaffolding may be required for single-story projects depending on the scope of work and height involved. For tasks like roof repairs, gutter cleaning, or painting higher walls, mobile or lightweight scaffolding can provide safe access and stability. Smaller projects may not require full scaffolding setups, but ladders or mobile scaffolding can be used to ensure safety for elevated tasks.",
        },
        {
          question: "Can scaffolding be used in bad weather?",
          answer:
            "Scaffolding should not be used during severe weather conditions, such as strong winds, heavy rain, or storms. High winds can destabilize scaffolding, and rain can make surfaces slippery, increasing the risk of falls or accidents. For safety, work on scaffolding should resume only when weather conditions improve and an inspection confirms that the structure remains secure.",
        },
      ],
    },
    {
      slug: "shopfitting",
      heading: "Shopfitting FAQs",
      faqs: [
        {
          question: "How long does a typical shopfitting project take?",
          answer:
            "The duration of a shopfitting project depends on factors such as the size of the space, complexity of the design, and the scope of work involved. Smaller projects, like a simple retail fit-out, may take as little as 4 to 6 weeks. More extensive projects, such as a complete renovation of a large commercial space, can take several months to complete. Delays can also arise due to sourcing materials, custom designs, or changes made during the process.",
        },
        {
          question:
            "Can shopfitters handle compliance with fire safety regulations?",
          answer:
            "Yes, professional shopfitters are well-versed in meeting safety regulations, including fire safety. They ensure that all fit-out work complies with local fire safety standards, which may include installing fire-rated doors, proper signage, fire escapes, and sprinklers where necessary. Shopfitters will also ensure that accessibility guidelines are met, allowing for safe evacuation routes and other necessary compliance features to meet Australian standards.",
        },
        {
          question: "Do shopfitters offer design services?",
          answer:
            "Many shopfitting companies provide comprehensive design services as part of their fit-out offerings. This typically includes designing layouts, creating 3D visualizations, selecting materials, and integrating branding elements into the interior space. From initial concept to final installation, shopfitters work closely with clients to ensure the design aligns with business goals and customer needs, providing a seamless experience that covers all aspects of the fit-out.",
        },
      ],
    },
    {
      slug: "skip-bins",
      heading: "Skip bin FAQs",
      faqs: [
        {
          question: "Can skip bins accept all types of waste?",
          answer:
            "No, skip bins cannot accept all types of waste. Restrictions typically apply to hazardous materials, asbestos, chemicals, and certain electronic waste. It's important to check with the skip bin provider to understand what waste can and cannot be disposed of in the bin. For example, materials like paints, oils, or medical waste may require special disposal methods.",
        },
        {
          question: "How long can I keep a skip bin?",
          answer:
            "Standard hire periods for skip bins usually range from 3 to 7 days. However, if your project requires more time, you can arrange for a longer hire period. Extended hire periods may incur additional fees depending on the provider, so it's best to discuss the duration and costs upfront when booking the bin.",
        },
        {
          question: "Do I need a permit for a skip bin?",
          answer:
            "Yes, if you place a skip bin on public property, such as a street or footpath, a permit from the local council is typically required. The permit ensures that the bin doesn't obstruct traffic or pedestrian pathways and complies with local regulations. Skip bins placed on private property usually don't require a permit, but it's always wise to confirm with the provider and council.",
        },
        {
          question: "Can skip bins be delivered to hard-to-reach areas?",
          answer:
            "Yes, skip bins can be delivered to a variety of locations, but accessibility plays a key role in determining where the bin can be placed. If your property or project site has narrow driveways or limited access, it's important to inform the provider in advance so they can arrange for the appropriate size and delivery method. Delivery to difficult areas may incur additional charges.",
        },
        {
          question: "How do I know what size skip bin I need?",
          answer:
            "The size of the skip bin you need depends on the amount of waste generated by your project. Common sizes range from small 2 cubic meter bins for small clean-ups to larger 10 cubic meter or 12 cubic meter bins for construction or renovation projects. Most skip bin providers offer guidance based on the type of waste and the size of your project, so don't hesitate to ask for recommendations when booking.",
        },
      ],
    },
    {
      slug: "solar-energy",
      heading: "Solar Energy FAQs",
      faqs: [
        {
          question: "How long does it take to install a solar system?",
          answer:
            "The installation time for a solar system depends on the size and complexity of the system. For most residential systems, the installation process typically takes between 1 to 3 days. Larger or more complex setups, such as commercial or off-grid systems, may take longer. This includes the time needed for roof inspections, equipment setup, and grid connection.",
        },
        {
          question: "Is solar energy worth the investment?",
          answer:
            "Yes, solar energy is generally a sound investment. It helps reduce electricity bills, increases the value of the property, and offers long-term financial savings, especially with the potential for government incentives or rebates. Solar power systems typically pay for themselves over time through reduced energy costs and provide a reliable, renewable source of energy that can offer returns for 25 years or more.",
        },
        {
          question: "Can solar panels work during cloudy days?",
          answer:
            "Yes, solar panels can still generate electricity on cloudy days, but their efficiency is reduced compared to sunny days. Solar panels rely on sunlight to generate power, and while direct sunlight is ideal, diffuse light from cloudy skies still allows for energy production, albeit at a lower rate. The overall performance on cloudy days can depend on the cloud cover and the quality of the solar system.",
        },
      ],
    },
    {
      slug: "staircases",
      heading: "Staircase FAQs",
      faqs: [
        {
          question: "How long does it take to install a solar system?",
          answer:
            "The installation time for a solar system depends on the size and complexity of the system. For most residential systems, the installation process typically takes between 1 to 3 days. Larger or more complex setups, such as commercial or off-grid systems, may take longer. This includes the time needed for roof inspections, equipment setup, and grid connection.",
        },
        {
          question: "Is solar energy worth the investment?",
          answer:
            "Yes, solar energy is generally a sound investment. It helps reduce electricity bills, increases the value of the property, and offers long-term financial savings, especially with the potential for government incentives or rebates. Solar power systems typically pay for themselves over time through reduced energy costs and provide a reliable, renewable source of energy that can offer returns for 25 years or more.",
        },
        {
          question: "Can solar panels work during cloudy days?",
          answer:
            "Yes, solar panels can still generate electricity on cloudy days, but their efficiency is reduced compared to sunny days. Solar panels rely on sunlight to generate power, and while direct sunlight is ideal, diffuse light from cloudy skies still allows for energy production, albeit at a lower rate. The overall performance on cloudy days can depend on the cloud cover and the quality of the solar system.",
        },
      ],
    },
    {
      slug: "stonemasonry",
      heading: "Stonemasonry  FAQs",
      faqs: [
        {
          question: "How long does stonework last?",
          answer:
            "Properly installed and maintained stonework can last for decades, even centuries, making it a durable investment for any property.",
        },
        {
          question: "Is stonemasonry eco-friendly?",
          answer:
            "Yes, natural stone is an eco-friendly material. Many stonemasons source stone locally to minimize transportation emissions and reduce environmental impact.",
        },
        {
          question: "Is engineered stone safe to use?",
          answer:
            "Engineered stone contains high levels of crystalline silica, which can pose serious health risks, including silicosis, a fatal lung disease. In response, Australia has implemented a ban on the use, supply, and manufacture of engineered stone benchtops, panels, and slabs, effective from 1 July 2024. You can find more details on the ban on Safe Work Australia. Additionally, from 1 September 2024, enhanced regulations for crystalline silica processes have come into effect, covering industries like tunnelling and quarrying. For more information, visit Standards Australia.",
        },
        {
          question: "What are the benefits of stone over other materials?",
          answer:
            "Stone offers superior durability, aesthetic appeal, and low maintenance compared to other materials. It is resistant to wear, heat, and moisture, making it ideal for high-traffic areas and kitchen environments.",
        },
        {
          question: "How much does stone work cost?",
          answer:
            "The cost of stone work depends on the material, project size, and complexity. Natural stones like granite and marble are typically more expensive than engineered stones.",
        },
      ],
    },
    {
      slug: "tiling",
      heading: "Tiling FAQs",
      faqs: [
        {
          question: "How long does it take to tile a room?",
          answer:
            "The time required for tiling depends on the size and complexity of the area. Typically, it can take anywhere from 2 to 5 days to complete floor or wall tiling, with larger or more intricate projects taking longer.",
        },
        {
          question: "Is tile waterproof?",
          answer:
            "While tiles themselves are water-resistant, they are not entirely waterproof. Proper waterproofing, such as applying a membrane beneath the tiles, is necessary in wet areas like bathrooms or kitchens to prevent water leaks and damage.",
        },
        {
          question: "Can tiles be installed over existing tiles?",
          answer:
            "Yes, tiles can be installed over existing tiles, provided the current tiles are in good condition, securely attached, and the surface is level. The area must also be properly prepared (cleaned and primed) before new tiles are applied to ensure a secure bond.",
        },
      ],
    },
    {
      slug: "waterproofing",
      heading: "Waterproofing FAQs",
      faqs: [
        {
          question: "How long does waterproofing last?",
          answer:
            "Waterproofing can last 10-20 years or more, depending on materials and maintenance. Regular inspections and upkeep are essential to ensure long-lasting protection against water damage.",
        },
        {
          question: "Can waterproofing be done in wet weather?",
          answer:
            "While some waterproofing products are designed to be applied in wet weather, most require dry conditions for optimal adhesion and effectiveness. It's important to choose the right product for specific conditions. Always follow manufacturer guidelines for application in varying weather.",
        },
        {
          question: "What areas need to be waterproofed internally?",
          answer:
            "Internal areas like bathrooms, showers, kitchens, laundries, and basements require waterproofing to prevent leaks and water damage. High-moisture areas need proper membrane and sealing applications to ensure long-term protection.",
        },
        {
          question: "What areas need to be waterproofed externally?",
          answer:
            "External areas such as balconies, terraces, rooftops, and foundations should be waterproofed to avoid water penetration, which can cause structural issues. Waterproofing protects these areas from weather and moisture damage, preserving the integrity of the building.",
        },
      ],
    },
  ];
  const categoryData = data.filter((item) => item.slug === slug);
  const [Show, setShow] = useState(-1);
  const toggleHandler = (index) => {
    if (Show === index) {
      return setShow(null);
    } else {
      setShow(index);
    }
  };
  return (
    <>
      {categoryData.map((item, index) => {
        return (
          <>
            <div className="flex text-offblack mt-10 xl:mt-40 mx-[5%] xl:mx-auto xl:w-[1000px] 2xl:w-[1075px] ">
              <div className="w-full">
                <div>
                  <Heading
                    variant={"h2"}
                    className={"mb-7 sm:mb-10 lg:mb-20 text-offblack"}
                  >
                    {item.heading}
                  </Heading>
                  {item.faqs.map((e, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          className={
                            Show === index
                              ? "bg-brightblue text-white h-[70px] xl:w-[1000px] 2xl:w-[1075px] flex items-center rounded-t-xl px-5"
                              : "bg-brightblue text-white h-[70px] sm:h-[100px] xl:w-[1000px] 2xl:w-[1075px] flex items-center rounded-xl px-5 mb-4 sm:mb-8"
                          }
                        >
                          <div>
                            <InteractiveText variant={"FAQHeader"}>
                              {e.question}
                            </InteractiveText>
                          </div>
                          <div className="w-[16px] h-[70px] ml-auto flex items-center justify-center rounded-t-xl">
                            <i
                              className={
                                Show === index
                                  ? "fi fi-rs-angle-up text-xs lg:text-sm cursor-pointer"
                                  : "fi fi-rs-angle-down text-xs lg:text-sm cursor-pointer"
                              }
                              onClick={() => {
                                toggleHandler(index);
                              }}
                            ></i>
                          </div>
                        </div>
                        {Show === index && (
                          <div className="bg-brightblue text-white h-auto xl:w-[1000px] 2xl:w-[1075px] flex items-center rounded-b-xl px-5 pb-5 mb-4 sm:mb-8 border-t-primaryblue">
                            <InteractiveText variant={"FAQAnswer"}>
                              {e.answer}
                            </InteractiveText>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default CommonFAQs;
