import React, { useEffect, useState } from "react";
import WhyTradesPoster from "../sections/BusinessSupport/WhyTradesPoster";
import Banner from "../components/common/Banners/Banner";
import MainButton from "../components/common/MainButton/MainButton";
import Login from "../sections/Login/Login";
import { SignUp as SignUpRegister } from "../sections/SignUp/SignUp";
import HelpSupport from "../sections/CustomerSupport/HelpSupport";
import MessagingSystemLayout from "../sections/BusinessSupport/MessagingSystemLayout";
import UsageGuide from "../sections/BusinessSupport/UsageGuide";
import BusinessPortfolio from "../sections/BusinessSupport/BusinessPortfolio";
import Stories from "../sections/BusinessSupport/Stories";
import FullLengthSlider from "../sections/AllServicesSlider/FullLengthSlider";

const BusinessSupport = () => {
  const [SignUp, setSignUp] = useState(false);
  const [SignIn, setSignIn] = useState(false);
  useEffect(() => {
    if (SignUp) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [SignUp]);
  return (
    <>
      <Banner
        headingClasses={
          "text-center sm:text-start text-white xl:w-[900px] 2xl:w-[1000px] "
        }
        heading={"Unlock Endless Business Opportunities"}
        className={"bg-manWithDog"}
      >
        <MainButton
          variant={"extralarge"}
          className={"mt-10"}
          onClick={() => setSignUp(true)}
        >
          Business Sign Up
        </MainButton>
        {SignIn && (
          <Login
            SignIn={SignIn}
            setSignIn={setSignIn}
            SignUpState={SignUp}
            setSignUpState={setSignUp}
          />
        )}
        {SignUp && (
          <SignUpRegister
            SignUpState={SignUp}
            setSignUpState={setSignUp}
            SignIn={SignIn}
            setSignIn={setSignIn}
          />
        )}
      </Banner>
      <WhyTradesPoster />
      <UsageGuide />
      <MessagingSystemLayout />
      <BusinessPortfolio />
      <Stories />
      <HelpSupport />
      <FullLengthSlider />
    </>
  );
};

export default BusinessSupport;
