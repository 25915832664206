import React, { useEffect, useState } from "react";

const BeforeAfterTransition = () => {
  const [scrolledVersion, setScrolledVersion] = useState(false);

  useEffect(() => {
    const event = () => {
      setScrolledVersion(window.scrollY >= 4800);
    };
    document.addEventListener("scroll", event);

    // Cleanup function
    return () => {
      document.removeEventListener("scroll", event);
    };
  }, []);

  return (
    <div className="mx-[5%] xl:mx-auto xl:w-[1150px] 2xl:w-[1440px] h-[500px] sm:h-auto 2xl:h-[900px] sm:aspect-[1440/900] mt-7 sm:mt-10 lg:mt-20 relative">
      <img
        src={require("../../assets/img/TradesPosterSpeciality/no-bidding.jpg")}
        className={` w-full h-full rounded-3xl filter grayscale absolute ${
          scrolledVersion && "fadeOut"
        }`}
        alt=""
      />
      <img
        src={require("../../assets/img/TradesPosterSpeciality/no-bidding.jpg")}
        className={`w-full h-full rounded-3xl ${
          scrolledVersion && "fadeIn"
        }`}
        alt=""
      />
    </div>
  );
};

export default BeforeAfterTransition;
