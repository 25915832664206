/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Filters from "../../sections/Service/Filters";

const SelectedCity = ({ formik }) => {
  return (
    <>
     <div className="mx-[5%] xl:mx-[2%] 2xxl:mx-[5%] 3xl:mx-auto 3xl:w-[1748px] pt-16 xl:pt-24">
        <Filters />
      </div>
    </>
  );
};

export default SelectedCity;
