import React from "react";
// import { useNavigate } from "react-router-dom";
import {
  initial_values,
  validationSchema,
} from "../../../FormValidations/AccountManagementSchema";
import { useFormik } from "formik";
import NotificationsLayout from "../../../sections/BusinessDashboard/Settings/NotificationsLayout";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";

const NotificationSettings = () => {
  const formik = useFormik({
    initialValues: initial_values,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {},
  });
  return (
    <>
      <DashboardMainContainer className={"lg:px-5 lg:pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center text-offblack"}>
          Business notification preferences
        </Heading>
        <div className="mt-[60px] min-h-screen">
          <form onSubmit={formik.handleSubmit}>
            <NotificationsLayout />
          </form>
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default NotificationSettings;
