import React from "react";
import Heading from "../../components/common/Typography/Heading";
import Paragraph from "../../components/common/Typography/Paragraph";

const LeftText = ({ heading, text }) => {
  return (
    <>
      <div className="flex flex-col lg:flex-row items-center gap-x-16 mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] mt-10 sm:mt-40">
        <div className="mt-7 sm:mt-10 lg:my-auto order-2 lg:order-1">
          <div>
            <Heading variant={"h2"} className={"text-offblack"}>
              {heading}
            </Heading>
            <Paragraph variant={"MainParagraph"} className={"mt-3"}>
              {text}
            </Paragraph>
          </div>
        </div>
        <div className="order-1 lg:order-2 h-auto w-full lg:w-1/2 aspect-square shrink-0">
          <img
            className="object-cover w-full h-full rounded-3xl"
            src={require("../../assets/img/TradesPosterSpeciality/businesses.jpg")}
            alt="trade-poster"
          />
        </div>
      </div>
    </>
  );
};

export default LeftText;
