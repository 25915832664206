import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";

const CoFounderMessage = () => {
  return (
    <>
      {/* Co- founder Message */}
      <div className="flex flex-col lg:flex-row items-center gap-x-16 mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] mt-10 sm:mt-40">
        <div className="mt-7 sm:mt-10 lg:my-auto order-2 lg:order-1">
          <div>
          <SecondaryHeading
            variant={"MainParagraphHeading"}
            className={" text-offblack mt-5 lg:mt-0"}
          >
            TradesPoster stands for more than just business; it's about setting
            a higher standard. We pledge to foster a community where every trade
            excels, building Australia’s most trusted service network.
          </SecondaryHeading>
          <div className="mt-2">
            <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
              Hamza, Co-Founder
            </Paragraph>
          </div>
          </div>
        </div>
        <div className="order-1 lg:order-2 h-auto w-full lg:w-1/2 lg:h-[500px] aspect-square lg:aspect-auto shrink-0">
          <img
            className="object-cover w-full h-full rounded-3xl"
            src={require("../../assets/img/TradesPosterSpeciality/customers.jpg")}
            alt="trade-poster"
          />
        </div>
      </div>
    </>
  );
};

export default CoFounderMessage;
