import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";

const ServicesTags = () => {
  const services = [
    {
      businessCategoryName: "Air con & Heating",
    },
    {
      businessCategoryName: "Architects & Design",
    },
    {
      businessCategoryName: "Blinds & Awning",
    },
    {
      businessCategoryName: "Bricklaying",
    },
    {
      businessCategoryName: "Cabinetmakers",
    },
    {
      businessCategoryName: "Carpentry",
    },
    {
      businessCategoryName: "test",
    },
    {
      businessCategoryName: "Carpet & Flooring",
    },
    {
      businessCategoryName: "Air con & Heating",
    },
    {
      businessCategoryName: "Bricklaying",
    },
    {
      businessCategoryName: "Cabinetmakers",
    },
    {
      businessCategoryName: "Carpet & Flooring",
    },
    {
      businessCategoryName: "Bricklaying",
    },
    {
      businessCategoryName: "Cabinetmakers",
    },
    {
      businessCategoryName: "Carpentry",
    },
    {
      businessCategoryName: "Carpet & Flooring",
    },
    {
      businessCategoryName: "Air con & Heating",
    },
    {
      businessCategoryName: "Bricklaying",
    },
    {
      businessCategoryName: "Architects & Design",
    },
    {
      businessCategoryName: "Blinds & Awning",
    },
    {
      businessCategoryName: "Bricklaying",
    },
    {
      businessCategoryName: "Cabinetmakers",
    },
    {
      businessCategoryName: "Carpentry",
    },
    {
      businessCategoryName: "Carpet & Flooring",
    },
  ];
  return (
    <>
      <div className={"text-start"}>
        <SecondaryHeading variant={"MainParagraphHeading"} className={"text-offblack"}>
          keywords
        </SecondaryHeading>
      </div>
      <div className="flex items-center">
            <div className="flex items-center flex-wrap gap-2 md:gap-3 mt-5">
              {services.map((item) => {
                return (
                  <>
                    <div className="px-3 py-2 bg-white text-offblack flex justify-center rounded-3xl">
                      <AuxiliaryText variant={"Placeholder"}>
                        {item.businessCategoryName}
                      </AuxiliaryText>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
    </>
  );
};

export default ServicesTags;
