import React from "react";
import Card from "../IconBox/Card";

const ImageWithLongCardsColumn = ({ data, Img, heading, cardClasses }) => {
  return (
    <>
      <div className="mx-[5%] xl:mx-auto h-[1000px] sm:h-[800px] md:h-[750px] lg:h-[700px] xl:w-[1150px] xl:h-[800px] 2xl:w-[1440px] 2xl:h-[900px] mt-7 sm:mt-10 relative flex justify-center items-center lg:flex-none">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-x-4 justify-center lg:justify-end lg:left-[60%] 2xl:left-[65%] 3xl:left-[70%] lg:bottom-[100px] xl:bottom-[250px] 2xl:bottom-[340px] absolute">
          {data.map((e, index) => {
            return (
              <>
                <div className="mb-4" key={index}>
                  <Card
                    imgclassName={
                      "cursor-pointer w-[40px] h-[40px] 2xl:w-[50px] 2xl:h-[50px]"
                    }
                    cardClassNames={
                      "flex items-center w-[340px] h-[100px] xl:h-auto xl:w-[300px] 2xl:w-[350px]"
                    }
                    image={e.icon}
                    Alt={e.heading + "icon"}
                    text={e.heading}
                    paragraphNeed={true}
                    paragraphText={e.description}
                  />
                </div>
              </>
            );
          })}
        </div>
        <img
          src={require("../../../assets/img/about-us.jpg")}
          className="w-full h-full object-cover rounded-3xl bg-center bg-no-repeat"
          alt=""
        />
      </div>
    </>
  );
};

export default ImageWithLongCardsColumn;
