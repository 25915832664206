import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";
import Heading from "../../components/common/Typography/Heading";
import { useParams } from "react-router-dom";

const WhyProfessional = () => {
  const { slug } = useParams();
  const data = [
    {
      slug: "architectural",
      listHeading: "Why hire a licensed architect?",
      list: [
        {
          inlineHeading: "Design expertise",
          inlineParagraph:
            "Architects provide tailored designs that meet client needs and adhere to building codes, ensuring spaces are functional and visually appealing. Their training allows them to creatively solve design challenges.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "They ensure all designs comply with legal and safety standards, minimizing the risk of issues during construction. This proactive approach helps avoid costly fines and project delays.",
        },
        {
          inlineHeading: "Cost efficiency",
          inlineParagraph:
            "Effective planning reduces changes during construction, keeping projects within budget and on schedule. This foresight ultimately saves clients’ money in the long run.",
        },
        {
          inlineHeading: "Creative solutions",
          inlineParagraph:
            "Architects offer innovative designs that optimize space utilization, enhancing both functionality and visual appeal. Their fresh perspectives can lead to unique features that set a project apart.",
        },
        {
          inlineHeading: "Long-term value",
          inlineParagraph:
            "High-quality designs improve immediate aesthetics and increase property value, making buildings more marketable. This focus on value ensures clients see a return on their investment.",
        },
        {
          inlineHeading: "Sustainability",
          inlineParagraph:
            "Incorporating energy-efficient and sustainable solutions, architects help reduce environmental impact while also lowering operating costs for clients. This commitment to sustainability is increasingly important in today's market.",
        },
      ],
    },
    {
      slug: "air-conditioning-and-heating",
      listHeading: "Why hire a licensed HVAC expert?",
      list: [
        {
          inlineHeading: "Expertise",
          inlineParagraph:
            "Licensed HVAC experts are skilled in managing all aspects of heating, ventilation, and air conditioning systems. Their training ensures accurate diagnostics and tailored solutions, whether it's installation, repair, or maintenance.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Hiring a licensed HVAC professional ensures adherence to Australian safety and efficiency standards, including AS/NZS 1668.2 for ventilation and AS/NZS 3000 for electrical installations. Compliance is crucial for safe operation, preventing risks associated with improper installations or electrical faults.",
        },
        {
          inlineHeading: "Efficiency",
          inlineParagraph:
            "Licensed HVAC professionals deliver fast and effective service, minimizing disruptions. They use specialized tools and techniques to resolve issues quickly, preventing further system damage or costly repairs.",
        },
        {
          inlineHeading: "Energy savings",
          inlineParagraph:
            "Proper installation and maintenance by licensed HVAC experts help reduce energy consumption, enhancing system efficiency and lowering utility costs.",
        },
        {
          inlineHeading: "Reliability",
          inlineParagraph:
            "Licensed professionals ensure consistent system performance and quality service, providing dependable climate control in homes.",
        },
        {
          inlineHeading: "Safety first",
          inlineParagraph:
            "Proper handling of electrical and gas components is essential for safety. Licensed experts ensure safe installations and repairs, protecting against potential hazards.",
        },
      ],
    },
    {
      slug: "bricklaying",
      listHeading: "Why hire a licensed bricklayer?",
      list: [
        {
          inlineHeading: "Expertise",
          inlineParagraph:
            "Licensed bricklayers have the training and skills to handle diverse residential projects, ensuring precise construction, durability, and adherence to Australian standards.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Professional bricklayers ensure all work meets standards like AS 3700, guaranteeing safety, quality, and legal compliance.",
        },
        {
          inlineHeading: "Efficiency",
          inlineParagraph:
            "Certified professionals complete projects efficiently, minimizing disruptions and ensuring high-quality results.",
        },
        {
          inlineHeading: "Peace of mind",
          inlineParagraph:
            "Hiring a licensed bricklayer often comes with warranties and insurance, protecting homeowners from potential liabilities.",
        },
      ],
    },
    {
      slug: "cabinet-making",
      listHeading: "Why hire a licensed cabinet maker?",
      list: [
        {
          inlineHeading: "Expertise",
          inlineParagraph:
            "Licensed cabinet makers offer specialized skills to create precise, high-quality cabinetry tailored to individual needs.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Ensuring compliance with standards like AS 4386 and AS/NZS 2047, licensed professionals guarantee safety and quality.",
        },
        {
          inlineHeading: "Efficiency",
          inlineParagraph:
            "Skilled cabinet makers complete projects efficiently, delivering accurate installations with minimal disruptions.",
        },
        {
          inlineHeading: "Peace of mind",
          inlineParagraph:
            "Licensed services often include warranties and insurance, providing protection against potential issues.",
        },
      ],
    },
    {
      slug: "carpentry",
      listHeading: "Why hire a licensed carpenter?",
      list: [
        {
          inlineHeading: "Structural integrity",
          inlineParagraph:
            "Ensures the safety and durability of frameworks and finishes. A licensed carpenter follows rigorous methods to maintain stability. This guarantees the safety of occupants and prevents structural failures.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Adheres to AS 1684 standards for timber construction, ensuring safety. Licensed carpenters stay updated on regulations and building codes. This ensures all work meets legal requirements, avoiding compliance issues.",
        },
        {
          inlineHeading: "Versatility",
          inlineParagraph:
            "Expertise in various carpentry tasks, from framing to finishing. Skilled carpenters can handle a wide range of projects, from minor repairs to major renovations. They adapt to different materials and techniques, ensuring quality results.",
        },
        {
          inlineHeading: "Custom solutions",
          inlineParagraph:
            "Tailored designs to meet specific needs and styles. Carpenters create bespoke features that align with your vision. This approach offers flexibility in design, ensuring personalized outcomes.",
        },
        {
          inlineHeading: "Enhanced aesthetics",
          inlineParagraph:
            "High-quality craftsmanship for a polished, professional look. Attention to detail improves both the functionality and beauty of interiors and exteriors. Proper finishing adds a refined touch to any space.",
        },
        {
          inlineHeading: "Durability",
          inlineParagraph:
            "Professional carpentry enhances the lifespan of structures and features. Quality materials and techniques ensure lasting results. Regular maintenance and repairs by experts also extend the service life of timber elements.",
        },
      ],
    },
    {
      slug: "carpet-and-flooring",
      listHeading: "Why hire a licensed flooring specialist?",
      list: [
        {
          inlineHeading: "Precision installation",
          inlineParagraph:
            "Achieves a seamless fit and finish, reducing gaps and wear, which enhances both durability and appearance.",
        },
        {
          inlineHeading: "Quality materials",
          inlineParagraph:
            "Professionals provide access to premium materials, offering tailored options like stain-resistant carpet, durable tiles, and engineered timber suited to specific needs.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Adheres to key standards such as AS 1884 for resilient floor coverings, AS/NZS 4586 for slip resistance, and AS 3958 for tiling, ensuring safety and performance.",
        },
        {
          inlineHeading: "Enhanced comfort",
          inlineParagraph:
            "Installation with underlay improves insulation, cushioning, and overall comfort, making interiors warmer and quieter.",
        },
        {
          inlineHeading: "Durability",
          inlineParagraph:
            "Expert installation extends the lifespan of flooring by ensuring proper subfloor preparation and secure fitting, minimizing future repairs.",
        },
        {
          inlineHeading: "Visual appeal",
          inlineParagraph:
            "High-quality craftsmanship elevates interior aesthetics, creating a polished and attractive finish that enhances the look of any space.",
        },
      ],
    },
    {
      slug: "caulking",
      listHeading: "Why hire a licensed caulking specialist?",
      list: [
        {
          inlineHeading: "Proper sealing",
          inlineParagraph:
            "Licensed specialists ensure a thorough, clean, and effective seal for gaps and joints, using the right materials and techniques for different surfaces. This prevents issues like drafts, moisture intrusion, and structural damage.",
        },
        {
          inlineHeading: "Durability",
          inlineParagraph:
            "Professional caulking lasts significantly longer due to the use of high-quality materials and expert application methods. This reduces the frequency of repairs and minimizes maintenance costs, ensuring long-term protection.",
        },
        {
          inlineHeading: "Improved energy efficiency",
          inlineParagraph:
            "Properly sealed gaps prevent unwanted air leaks, improving insulation and regulating indoor temperatures. This enhances overall energy efficiency, leading to reduced heating and cooling costs.",
        },
        {
          inlineHeading: "Aesthetic finish",
          inlineParagraph:
            "A neat, precise caulking job adds a polished, professional appearance to both interiors and exteriors. Whether it’s along windows, skirting boards, or bathroom tiles, expert caulking enhances visual appeal.",
        },
        {
          inlineHeading: "Pest prevention",
          inlineParagraph:
            "Sealing gaps and cracks with professional-grade caulk helps block entry points for pests like insects and rodents, reducing potential infestations and related damage.",
        },
        {
          inlineHeading: "Water resistance",
          inlineParagraph:
            "Effective caulking in wet areas, such as bathrooms, kitchens, and around pools, prevents moisture penetration, reducing the risk of water damage, mold, and mildew growth. This ensures better hygiene and structural integrity in moisture-prone spaces.",
        },
      ],
    },
    {
      slug: "cleaning-services",
      listHeading: "Why hire a professional cleaner?",
      list: [
        {
          inlineHeading: "Thorough cleaning",
          inlineParagraph:
            "Professional cleaners use advanced tools and techniques that provide deep and effective cleaning, reaching areas that are often missed in regular cleaning routines. This ensures a more comprehensive approach, leaving spaces spotless and well-maintained.",
        },
        {
          inlineHeading: "Health benefits",
          inlineParagraph:
            "Professional cleaning removes allergens, bacteria, and mold that can compromise indoor air quality. By using specialized equipment and cleaning agents, it minimizes health risks such as respiratory issues, allergies, and illnesses, creating a safer environment for occupants.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Cleaning services adhere to industry standards, including sanitation and safety guidelines. Professionals follow protocols like AS/NZS 3733 and AS 4801, ensuring that cleaning processes meet legal requirements and maintain hygienic conditions, especially in commercial settings.",
        },
        {
          inlineHeading: "Time-saving",
          inlineParagraph:
            "Hiring professionals allows for efficient cleaning, freeing up your time to focus on other tasks. Whether it’s a busy household or a bustling office, professional services reduce the effort needed for regular maintenance while delivering superior results.",
        },
        {
          inlineHeading: "Reliability",
          inlineParagraph:
            "Trained professionals deliver consistent, high-quality results. They follow a set process to ensure all areas are thoroughly cleaned, providing peace of mind that the job is done right every time, whether it’s a routine or specialized cleaning task.",
        },
        {
          inlineHeading: "Proper disposal",
          inlineParagraph:
            "Professional cleaners handle the safe disposal of cleaning agents and waste in accordance with regulations. This reduces environmental impact and ensures compliance with local waste management laws, contributing to sustainable cleaning practices.",
        },
      ],
    },
    {
      slug: "concrete-and-paving",
      listHeading: "Why hire a licensed concrete & paving specialist?",
      list: [
        {
          inlineHeading: "Structural integrity",
          inlineParagraph:
            "Licensed specialists ensure concrete and paved surfaces are installed safely, with proper reinforcement and leveling. This results in durable, stable structures that can withstand heavy use and resist cracking, settling, or shifting over time.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Professional installers adhere to Australian standards like AS 3600 for concrete structures and AS 3727 for pavement design. These standards guarantee that all work meets legal and safety requirements, reducing liability and ensuring long-lasting performance.",
        },
        {
          inlineHeading: "Durability",
          inlineParagraph:
            "Proper techniques and high-quality materials enhance surface longevity. Licensed specialists ensure correct subgrade preparation, adequate curing, and finishing, which prevent early wear, water damage, and structural failures, keeping surfaces in good condition for years.",
        },
        {
          inlineHeading: "Safety",
          inlineParagraph:
            "Specialists provide non-slip finishes, especially in high-traffic areas like driveways, pathways, and patios. This minimizes the risk of slips and falls, making both residential and commercial areas safer for occupants and visitors.",
        },
        {
          inlineHeading: "Aesthetic appeal",
          inlineParagraph:
            "Quality craftsmanship ensures visually appealing surfaces, whether it’s a sleek polished floor, decorative stamped concrete, or neatly laid pavers. Licensed professionals offer various finishes that enhance the overall look of properties.",
        },
        {
          inlineHeading: "Weather resistance",
          inlineParagraph:
            "Proper sealing and installation protect surfaces from weather-related damage like moisture intrusion, cracking, or fading. Sealed concrete resists stains, while paved surfaces retain their strength and color, even under harsh conditions.",
        },
      ],
    },
    {
      slug: "conveyancing",
      listHeading: "Why hire a licensed conveyancer?",
      list: [
        {
          inlineHeading: "Legal compliance",
          inlineParagraph:
            "Licensed conveyancers ensure that all legal requirements are met during property transactions, adhering to relevant laws and standards. This compliance reduces the risk of disputes or complications that could arise from overlooked regulations.",
        },
        {
          inlineHeading: "Risk mitigation",
          inlineParagraph:
            "Conveyancers identify potential legal or financial risks before a transaction is completed. This includes uncovering issues like unresolved debts attached to the property, planning restrictions, or zoning concerns, protecting you from unexpected liabilities.",
        },
        {
          inlineHeading: "Smooth settlements",
          inlineParagraph:
            "They facilitate timely, accurate, and hassle-free settlements, coordinating with financial institutions, real estate agents, and other parties involved. Their efficient management ensures a seamless process, even in complex transactions.",
        },
        {
          inlineHeading: "Cost-effective",
          inlineParagraph:
            "Professional handling of legal and administrative tasks helps minimize unexpected costs and delays. Conveyancers can foresee and prevent costly errors, making the investment worthwhile in the long run.",
        },
        {
          inlineHeading: "Documentation accuracy",
          inlineParagraph:
            "Ensures that all documents, such as contracts, vendor statements, and title transfers, are prepared correctly and in full compliance with property laws. Proper documentation reduces the likelihood of mistakes or legal challenges.",
        },
        {
          inlineHeading: "Expert guidance",
          inlineParagraph:
            "Licensed professionals provide valuable advice on legal obligations, property laws, and available grants or concessions, such as first home buyer schemes. Their expertise helps you make informed decisions and navigate the complexities of property transactions confidently.",
        },
      ],
    },
    {
      slug: "blinds-and-curtains",
      listHeading: "Why hire a licensed window covering specialist?",
      list: [
        {
          inlineHeading: "Custom fit",
          inlineParagraph:
            "Licensed specialists take precise measurements to ensure your curtains, blinds, or awnings are perfectly fitted. This results in optimal performance, better light control, and a polished, professional appearance that enhances your space.",
        },
        {
          inlineHeading: "Wide range of options",
          inlineParagraph:
            "Professionals offer access to a diverse selection of materials, styles, and designs, allowing you to choose the perfect window coverings to match your decor and meet your functional needs. From motorized blinds to UV-resistant awnings, they can recommend the best solutions for your space.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Licensed specialists ensure installations meet Australian safety standards, such as AS 2663 for internal window coverings and AS 4055 for external structures. Compliance with these codes guarantees the safety, reliability, and weather resistance of your window treatments.",
        },
        {
          inlineHeading: "Energy efficiency",
          inlineParagraph:
            "Expert installation can greatly improve a property's energy efficiency. Properly fitted window coverings provide better insulation, helping to regulate indoor temperatures and reduce energy costs by minimizing heat loss in winter and heat gain in summer.",
        },
        {
          inlineHeading: "Safety compliance",
          inlineParagraph:
            "Specialists are trained to install child-safe solutions, such as cord-free blinds or safety clips, to prevent accidents in homes with young children. They ensure that all products comply with safety regulations, providing peace of mind for families.",
        },
        {
          inlineHeading: "Durability",
          inlineParagraph:
            "Professional installation minimizes wear and tear, ensuring that window coverings last longer and perform reliably. High-quality materials and expert techniques contribute to the longevity and durability of your investment, saving you money on repairs and replacements over time.",
        },
      ],
    },
    {
      slug: "decking-and-gazebos",
      listHeading: "Why hire a licensed outdoor structures specialist?",
      list: [
        {
          inlineHeading: "Structural safety",
          inlineParagraph:
            "Licensed specialists ensure that outdoor structures like decks, gazebos, and carports are installed with stability and safety in mind. They use appropriate anchoring methods and reinforcements to create durable structures that can withstand heavy loads and harsh conditions, reducing the risk of accidents or damage.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Professionals adhere to Australian building standards, such as AS 1684 for timber framing and AS 5601 for structures near gas appliances. This compliance is critical for ensuring the safety, legality, and quality of your outdoor installations, and it protects you from potential fines or liabilities associated with non-compliant work.",
        },
        {
          inlineHeading: "Weather resistance",
          inlineParagraph:
            "Proper sealing, quality finishes, and the use of weather-resistant materials help structures endure extreme weather conditions. Specialists select the right treatments and construction methods to prevent warping, rotting, or rust, ensuring your investment remains in excellent condition over time.",
        },
        {
          inlineHeading: "Custom design",
          inlineParagraph:
            "Licensed professionals offer tailored designs that maximize your outdoor space and complement your property's aesthetics. Whether you want a modern pergola, a cozy veranda, or a functional carport, specialists create customized solutions that enhance the look and usability of your outdoor areas.",
        },
        {
          inlineHeading: "Durability",
          inlineParagraph:
            "High-quality construction methods and materials contribute to the longevity of your structures. Professionals understand how to build for durability, using techniques that minimize maintenance needs and keep your outdoor spaces looking great for years, even with regular use.",
        },
        {
          inlineHeading: "Increased functionality",
          inlineParagraph:
            "Well-planned outdoor structures enhance the usability and comfort of your property. From adding extra entertaining areas to providing sheltered parking or creating peaceful retreats, licensed specialists design spaces that improve your lifestyle and increase your property's overall value.",
        },
      ],
    },
    {
      slug: "demolition-and-excavation",
      listHeading: "Why hire a licensed  demolition & excavation  specialist?",
      list: [
        {
          inlineHeading: "Safety compliance",
          inlineParagraph:
            "Licensed specialists adhere to strict safety guidelines to prevent accidents and hazards on-site. They use the appropriate techniques and equipment to ensure the safety of workers and nearby properties, following industry best practices to minimize risks during demolition and excavation.",
        },
        {
          inlineHeading: "Environmental protection",
          inlineParagraph:
            "Professionals handle the proper disposal of materials, including hazardous substances like asbestos, in compliance with environmental regulations. They implement dust suppression, noise control, and erosion prevention measures to minimize the environmental impact and protect surrounding ecosystems.",
        },
        {
          inlineHeading: "Precision",
          inlineParagraph:
            "Accurate demolition and excavation are critical for successful site preparation. Specialists ensure that structures are safely removed, and land is graded and levelled correctly, creating a stable foundation for new construction. Their expertise reduces the likelihood of future structural issues, such as soil erosion or uneven settling.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Licensed contractors meet all relevant Australian standards, such as AS 2601 for demolition and AS 3798 for site preparation. Compliance with these standards ensures that all work is performed legally and safely, giving property owners peace of mind and avoiding potential fines or legal complications.",
        },
        {
          inlineHeading: "Efficiency",
          inlineParagraph:
            "Professional demolition and excavation teams are equipped with advanced machinery and trained staff, allowing them to complete projects quickly and efficiently. This helps minimize project delays and keeps construction timelines on track, saving time and money in the long run.",
        },
        {
          inlineHeading: "Risk mitigation",
          inlineParagraph:
            "Experienced contractors manage risks related to structural integrity and underground utilities, such as gas lines or electrical cables. They conduct thorough site assessments and use specialized techniques to prevent damage to nearby infrastructure, reducing the potential for costly accidents or repairs.",
        },
      ],
    },
    {
      slug: "electrical",
      listHeading: "Why hire a licensed electrician?",
      list: [
        {
          inlineHeading: "Safety assurance",
          inlineParagraph:
            "Licensed electricians prioritize safe handling and installation of electrical systems, using proper techniques and high-quality materials to prevent hazards such as electrical fires, shocks, or system failures. Their expertise ensures that all work is done securely, protecting both people and property.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Professional electricians adhere to Australian standards, such as AS/NZS 3000, ensuring that wiring and installations are up to code. Compliance with these regulations reduces the risk of future legal or safety issues and guarantees that the electrical system meets all requirements for functionality and reliability.",
        },
        {
          inlineHeading: "Energy efficiency",
          inlineParagraph:
            "Licensed electricians can recommend and install energy-saving solutions, like LED lighting, energy-efficient appliances, and solar panels. These installations not only reduce energy consumption but also help lower electricity bills, making homes and businesses more environmentally friendly and cost-effective.",
        },
        {
          inlineHeading: "Reliability",
          inlineParagraph:
            "With professional work, you can be confident that your electrical systems will operate consistently and efficiently. Licensed electricians use the right tools and methods to ensure long-term performance, minimizing the risk of recurring issues or system breakdowns.",
        },
        {
          inlineHeading: "Emergency response",
          inlineParagraph:
            "In the event of an electrical emergency, such as a power outage or a dangerous fault, licensed electricians are trained to respond promptly and efficiently. Their expertise allows them to quickly diagnose and resolve issues, restoring safety and functionality as soon as possible.",
        },
        {
          inlineHeading: "Insurance coverage",
          inlineParagraph:
            "Electrical work completed by a licensed professional is often covered by insurance, which helps to reduce liability risks for property owners. If something goes wrong, the coverage provides financial protection, offering peace of mind that unlicensed work cannot guarantee.",
        },
      ],
    },
    {
      slug: "engineering-services",
      listHeading: "Why hire a licensed engineer?",
      list: [
        {
          inlineHeading: "Technical expertise",
          inlineParagraph:
            "Licensed engineers bring advanced technical skills and knowledge, ensuring accurate analysis, design, and problem-solving across various engineering disciplines. Their expertise covers everything from structural integrity to efficient electrical and mechanical systems, tailored to meet the specific needs of each project.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Engineers are well-versed in Australian safety and building standards, such as AS 3600 for concrete structures and AS/NZS 3000 for electrical installations. They ensure that all designs and construction practices comply with these regulations, minimizing legal risks and guaranteeing the safety and durability of the final structure.",
        },
        {
          inlineHeading: "Cost-effectiveness",
          inlineParagraph:
            "Professional engineers focus on efficient planning and resource management, which can significantly reduce project costs. By addressing potential issues in the design phase, they help prevent costly rework, construction delays, and material waste, ensuring projects are completed on time and within budget.",
        },
        {
          inlineHeading: "Safety assurance",
          inlineParagraph:
            "Engineers prioritize safety in every aspect of their work, from load-bearing calculations to fire protection systems. Their designs incorporate protective measures to minimize hazards, ensuring that structures and systems are secure for occupants and the surrounding environment.",
        },
        {
          inlineHeading: "Long-term solutions",
          inlineParagraph:
            "Licensed engineers develop sustainable and future-proof designs that withstand environmental stresses and changing needs. Their focus on durability and efficient resource use results in infrastructure that remains functional and safe over time, reducing the need for frequent maintenance or upgrades.",
        },
        {
          inlineHeading: "Reliability",
          inlineParagraph:
            "Working with qualified engineers ensures consistent and dependable outcomes. Their experience and commitment to best practices give clients confidence that projects will be executed to a high standard, using innovative and effective engineering solutions.",
        },
      ],
    },
    {
      slug: "fence-and-gates",
      listHeading: "Why hire a licensed fencing & gate specialist?",
      list: [
        {
          inlineHeading: "Safety compliance",
          inlineParagraph:
            "Licensed specialists ensure that all fences, especially pool barriers, comply with strict safety and legal requirements, such as AS 1926.1 for pool fencing. This compliance is crucial for preventing accidents and meeting local council regulations.",
        },
        {
          inlineHeading: "Quality workmanship",
          inlineParagraph:
            "Professional installation guarantees strength, stability, and an attractive appearance. Experts use precise techniques to ensure that fences and gates are securely anchored and level, reducing the likelihood of sagging or damage over time.",
        },
        {
          inlineHeading: "Durability",
          inlineParagraph:
            "High-quality materials combined with proper installation methods significantly extend the lifespan of fences and gates. Licensed specialists select materials suited to the environment, such as weather-resistant timber or corrosion-resistant metal, to ensure long-term durability.",
        },
        {
          inlineHeading: "Security",
          inlineParagraph:
            "Properly installed fences and gates enhance property security by creating reliable barriers against intruders. Specialists understand how to design and install structures that offer maximum protection while complementing the property's layout.",
        },
        {
          inlineHeading: "Custom design",
          inlineParagraph:
            "Licensed professionals provide tailored designs that meet specific needs for privacy, aesthetics, and functionality. Whether it’s a modern automatic gate or a rustic timber fence, specialists work with clients to achieve the desired look and practical benefits.",
        },
      ],
    },
    {
      slug: "glazing-and-screens",
      listHeading: "Why hire a licensed glazier & screen specialist?",
      list: [
        {
          inlineHeading: "Safety assurance",
          inlineParagraph:
            "Licensed glaziers are trained to handle and install glass safely, using the correct techniques and equipment to minimize risks. They ensure that all glass installations comply with relevant safety standards, protecting occupants from hazards like breakage or improper fitting.",
        },
        {
          inlineHeading: "Durability",
          inlineParagraph:
            "Professional installation enhances the durability and performance of glazing and screen solutions. Experts select the right materials and methods to withstand environmental factors, reducing the likelihood of cracks, leaks, or other damage. This longevity helps you avoid costly repairs and replacements over time.",
        },
        {
          inlineHeading: "Aesthetic appeal",
          inlineParagraph:
            "High-quality, custom-cut glass enhances the visual appeal of any space, whether it’s a sleek shower screen, a large commercial window, or elegant glass balustrades. Licensed specialists ensure a precise fit and flawless finish, elevating the overall look and functionality of your property.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Glaziers follow Australian standards, such as AS 1288 for safe glass installation and AS 3740 for waterproofing bathroom screens. Compliance with these standards not only ensures safety but also provides peace of mind that the work meets all legal and regulatory requirements.",
        },
        {
          inlineHeading: "Energy efficiency",
          inlineParagraph:
            "Licensed professionals can install energy-efficient solutions like double glazing, which improves insulation and helps maintain indoor temperatures. These upgrades reduce energy consumption and lower heating and cooling costs, making your building more sustainable and cost-effective in the long run.",
        },
      ],
    },
    {
      slug: "garden-and-landscaping",
      listHeading: "Why hire a licensed landscaper?",
      list: [
        {
          inlineHeading: "Creative design",
          inlineParagraph:
            "Licensed landscapers bring expertise and creative vision to transform outdoor spaces. They develop customized solutions that blend aesthetics with functionality, considering site conditions, client preferences, and the property's overall style. From selecting the right plants to designing beautiful hardscapes, professionals create landscapes that are unique and cohesive.",
        },
        {
          inlineHeading: "Increased property value",
          inlineParagraph:
            "A well-designed and maintained landscape can significantly increase property value and curb appeal. Beautiful gardens, strategic plantings, and attractive outdoor features make a property more desirable and marketable. Landscapers know how to use design elements to maximize both visual impact and financial returns, making the investment worthwhile.",
        },
        {
          inlineHeading: "Efficient water use",
          inlineParagraph:
            "Professional landscapers understand how to optimize water usage through the installation of efficient irrigation systems and the selection of drought-tolerant plants. They use techniques that conserve water, reduce utility costs, and promote the health of the garden, even in dry conditions. This eco-friendly approach is essential in regions where water conservation is a priority.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Licensed landscapers ensure that all work complies with Australian standards, such as AS 4419 for soil quality and AS 4970 for tree protection during development. Adherence to these standards guarantees that landscapes are safe, environmentally responsible, and built to last, avoiding issues that could lead to fines or legal problems.",
        },
        {
          inlineHeading: "Safety & durability",
          inlineParagraph:
            "Proper installation of hardscapes, retaining walls, and other structural features is critical for safety and longevity. Landscapers use industry best practices to construct durable and stable outdoor structures, reducing the risk of accidents or failures over time. They also ensure that drainage and soil conditions are managed to prevent erosion or damage.",
        },
        {
          inlineHeading: "Long-term maintenance",
          inlineParagraph:
            "Licensed professionals design landscapes with sustainability and ease of maintenance in mind. They consider plant growth patterns, seasonal changes, and soil conditions to create gardens that thrive with minimal upkeep. This results in a beautiful, long-lasting landscape that remains healthy and appealing with less effort from the property owner.",
        },
      ],
    },
    {
      slug: "handyman",
      listHeading: "Why hire a licensed handyman?",
      list: [
        {
          inlineHeading: "Versatility",
          inlineParagraph:
            "Licensed handymen are skilled in a wide range of tasks, from fixing plumbing leaks to assembling furniture and patching walls. This versatility saves you the hassle of hiring multiple specialized contractors, making them ideal for handling multiple small jobs around your property efficiently.",
        },
        {
          inlineHeading: "Cost-effective",
          inlineParagraph:
            "Hiring a handyman is often more affordable than bringing in separate specialists for each minor repair or maintenance task. By consolidating different jobs into a single visit, you save on labour costs and get better value for your money, especially for routine upkeep and simple fixes.",
        },
        {
          inlineHeading: "Efficiency",
          inlineParagraph:
            "Licensed handymen can quickly and efficiently complete small jobs, preventing further damage or inconvenience. Their experience and access to the right tools enable them to deliver quality work in a timely manner, helping maintain your property’s condition and functionality.",
        },
        {
          inlineHeading: "Safety assurance",
          inlineParagraph:
            "Safety is paramount when dealing with repairs, especially those involving electrical work or structural fixes. Licensed handymen follow safety regulations and standards, such as AS/NZS 3000 for electrical installations, ensuring that tasks are performed correctly and securely, reducing the risk of accidents or future problems.",
        },
        {
          inlineHeading: "Convenience",
          inlineParagraph:
            "A licensed handyman can tackle multiple jobs in one visit, providing you with a one-stop solution for property maintenance. This convenience saves you time and simplifies the process of keeping your home or office in good shape, with minimal disruption to your daily routine.",
        },
      ],
    },
    {
      slug: "inspection-and-surveyors",
      listHeading: "Why hire a licensed inspector or surveyor?",
      list: [
        {
          inlineHeading: "Safety compliance",
          inlineParagraph:
            "Licensed inspectors ensure that properties adhere to all relevant legal and safety standards. This is crucial for protecting occupants and reducing liability risks for property owners or developers.",
        },
        {
          inlineHeading: "Accurate reporting",
          inlineParagraph:
            "Professionals provide detailed, comprehensive assessments that are both precise and easy to understand. This includes structural evaluations, boundary verifications, and compliance checks, which are essential for making informed decisions.",
        },
        {
          inlineHeading: "Risk mitigation",
          inlineParagraph:
            "By identifying issues such as structural defects, pest infestations, or geotechnical concerns early, inspectors and surveyors help prevent costly repairs or legal complications in the future. Their expertise minimizes the risk of unexpected problems arising after a purchase or during construction.",
        },
        {
          inlineHeading: "Legal assurance",
          inlineParagraph:
            "Surveyors verify land boundaries and legal titles, which is especially important for resolving or preventing property disputes. Accurate surveying ensures that property divisions and easements are correctly recorded, protecting owners’ rights and investments.",
        },
        {
          inlineHeading: "Investment protection",
          inlineParagraph:
            "For potential buyers, pre-purchase inspections are invaluable. They provide a clear understanding of a property’s condition, highlighting any hidden defects or areas that require immediate attention. This helps buyers make informed offers and avoid unforeseen expenses after the purchase.",
        },
        {
          inlineHeading: "Development support",
          inlineParagraph:
            "Surveying services are critical for property development, as they provide essential data for planning and construction. From topographic surveys to subsurface utility mapping, surveyors ensure that projects are built on solid foundations with accurate measurements, reducing the risk of costly setbacks.",
        },
      ],
    },
    {
      slug: "security-and-automation",
      listHeading: "Why hire a licensed security & automation expert?",
      list: [
        {
          inlineHeading: "Enhanced safety",
          inlineParagraph:
            "Licensed experts ensure that security systems, such as intruder alarms and CCTV cameras, are installed correctly and strategically to provide optimal protection against break-ins and unauthorized access. Professionally designed systems minimize vulnerabilities and maximize your home’s security.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Security and automation systems must adhere to Australian standards, such as AS 2201 for intruder alarms and AS/NZS 3000 for electrical installations. Licensed professionals guarantee that installations meet these requirements, ensuring both safety and legal compliance while reducing the risk of system malfunctions.",
        },
        {
          inlineHeading: "Remote access",
          inlineParagraph:
            "Modern security and home automation systems allow you to control and monitor your property from anywhere using mobile apps. A licensed expert ensures seamless integration and setup, giving you the ability to lock doors, check camera footage, or adjust lighting and climate control remotely.",
        },
        {
          inlineHeading: "Increased convenience",
          inlineParagraph:
            "Smart home technology streamlines daily routines by automating tasks such as lighting schedules, climate control, and appliance operation. Licensed installers customize these systems to fit your lifestyle, making home management effortless and efficient.",
        },
        {
          inlineHeading: "Energy efficiency",
          inlineParagraph:
            "Automation experts can set up systems like smart thermostats, energy-saving lights, and efficient appliance management to reduce energy consumption. By optimizing energy use, you save money on utility bills while contributing to a more sustainable environment.",
        },
        {
          inlineHeading: "Reliability",
          inlineParagraph:
            "Professional installation ensures that all components of your security and automation systems work efficiently and consistently. Experts test and configure devices to avoid glitches, provide guidance on using your system, and offer support for future updates or troubleshooting, ensuring long-term peace of mind.",
        },
      ],
    },
    {
      slug: "insulation-services",
      listHeading: "Why hire a licensed insulation specialist?",
      list: [
        {
          inlineHeading: "Energy efficiency",
          inlineParagraph:
            "Licensed specialists ensure insulation is installed correctly to maximize thermal performance, helping reduce heating and cooling costs. Proper installation minimizes energy loss, resulting in lower utility bills and improved energy efficiency.",
        },
        {
          inlineHeading: "Safety compliance",
          inlineParagraph:
            "Professional installers adhere to Australian standards, such as AS 4859.1 for insulation materials, ensuring compliance with fire safety, moisture control, and other critical regulations. This compliance helps maintain the safety and integrity of the building.",
        },
        {
          inlineHeading: "Improved comfort",
          inlineParagraph:
            "Professionally installed insulation provides consistent indoor temperatures year-round, enhancing comfort in all seasons. By retaining heat in winter and cooling in summer, effective insulation keeps living and working spaces comfortable.",
        },
        {
          inlineHeading: "Soundproofing",
          inlineParagraph:
            "Insulation specialists can reduce unwanted noise by installing acoustic insulation, making homes and offices quieter. Proper soundproofing helps create a more peaceful environment, improving quality of life and productivity.",
        },
        {
          inlineHeading: "Moisture control",
          inlineParagraph:
            "Proper insulation installation prevents moisture buildup, which helps avoid mold growth, wood rot, and other moisture-related issues. This not only protects the structure but also promotes healthier indoor air quality.",
        },
        {
          inlineHeading: "Long-term performance",
          inlineParagraph:
            "Licensed professionals use high-quality materials and proven techniques, ensuring that insulation performs well over time. Durable insulation means fewer replacements and reduced maintenance, saving money and effort in the long run.",
        },
      ],
    },
    {
      slug: "interior-design",
      listHeading: "Why hire a licensed interior designer?",
      list: [
        {
          inlineHeading: "Expert planning",
          inlineParagraph: "Designers optimize space and enhance aesthetics.",
        },
        {
          inlineHeading: "Custom solutions",
          inlineParagraph:
            "Tailored designs to fit style, function, and budget.",
        },
        {
          inlineHeading: "Property value",
          inlineParagraph:
            "Professional design boosts property appeal and value.",
        },
        {
          inlineHeading: "Sustainability",
          inlineParagraph: "Recommends eco-friendly materials and practices.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Adheres to standards like AS 1428 for accessibility.",
        },
        {
          inlineHeading: "Stress-free",
          inlineParagraph: "Manages the project from concept to completion.",
        },
      ],
    },
    {
      slug: "locksmiths",
      listHeading: "Why hire a licensed locksmith?",
      list: [
        {
          inlineHeading: "Safety & security",
          inlineParagraph: "Ensures secure lock installations and repairs.",
        },
        {
          inlineHeading: "Quick response",
          inlineParagraph: "Fast help in emergencies, like lockouts.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph: "Adheres to AS 4145 standards for safety.",
        },
        {
          inlineHeading: "Expert advice",
          inlineParagraph: "Recommends optimal security solutions.",
        },
        {
          inlineHeading: "Quality work",
          inlineParagraph: "Ensures reliable installation and repairs.",
        },
        {
          inlineHeading: "Custom solutions",
          inlineParagraph:
            "Offers tailored security plans, including digital locks.",
        },
      ],
    },
    {
      slug: "painting-and-decorating",
      listHeading: "Why hire a professional painter & decorator?",
      list: [
        {
          inlineHeading: "High-quality finish",
          inlineParagraph: "Even, consistent, and durable results.",
        },
        {
          inlineHeading: "Expert color advice",
          inlineParagraph:
            "Professionals choose colors that enhance mood and space.",
        },
        {
          inlineHeading: "Efficient",
          inlineParagraph: "Timely work with minimal disruption.",
        },
        {
          inlineHeading: "Standards compliance",
          inlineParagraph: "Meets AS 2311 and AS 4361.1 for safe applications.",
        },
        {
          inlineHeading: "Long-lasting results",
          inlineParagraph:
            "Quality prep and materials for extended durability.",
        },
        {
          inlineHeading: "Safety",
          inlineParagraph:
            "Proper tools, gear, and techniques ensure safe application.",
        },
      ],
    },
    {
      slug: "moving-and-storage",
      listHeading: "Why hire professional movers?",
      list: [
        {
          inlineHeading: "Safe handling",
          inlineParagraph:
            "Reduces damage risk with proper equipment and techniques.",
        },
        {
          inlineHeading: "Efficiency",
          inlineParagraph:
            "Speeds up packing and moving with professional organization.",
        },
        {
          inlineHeading: "Insurance",
          inlineParagraph: "Covers items against damage during transport.",
        },
        {
          inlineHeading: "Stress-free",
          inlineParagraph: "Full-service support from packing to delivery.",
        },
        {
          inlineHeading: "Specialized equipment",
          inlineParagraph: "Safely manages heavy or bulky items.",
        },
        {
          inlineHeading: "Reliable transport",
          inlineParagraph: "Maintains vehicles and efficient delivery routes.",
        },
      ],
    },
    {
      slug: "mold-and-asbestos",
      listHeading: "Why hire professional mould & asbestos experts?",
      list: [
        {
          inlineHeading: "Safety compliance",
          inlineParagraph: "Adheres to strict safety guidelines with PPE.",
        },
        {
          inlineHeading: "Thorough inspection",
          inlineParagraph:
            "Ensures accurate identification and complete removal.",
        },
        {
          inlineHeading: "Legal compliance",
          inlineParagraph:
            "Meets standards like AS 4964 for asbestos and AS 3666.2 for air quality.",
        },
        {
          inlineHeading: "Proper disposal",
          inlineParagraph: "Certified disposal minimizes environmental risks.",
        },
        {
          inlineHeading: "Health protection",
          inlineParagraph:
            "Reduces risks of respiratory issues and long-term illness.",
        },
        {
          inlineHeading: "Preventive measures",
          inlineParagraph:
            "Prevents mould regrowth and contains asbestos fibres.",
        },
      ],
    },
    {
      slug: "pest-control",
      listHeading: "Why hire a licensed pest controller?",
      list: [
        {
          inlineHeading: "Effective solutions",
          inlineParagraph:
            "Proven methods to eliminate pests and prevent return.",
        },
        {
          inlineHeading: "Health & safety",
          inlineParagraph:
            "Safe, approved treatments protect residents, pets, and the environment.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Adheres to Australian standards, including AS 3660.1 and AS/NZS 4341.",
        },
        {
          inlineHeading: "Long-term protection",
          inlineParagraph: "Preventive measures keep properties pest-free.",
        },
        {
          inlineHeading: "Quick response",
          inlineParagraph: "Rapid support for urgent pest issues.",
        },
        {
          inlineHeading: "Custom plans",
          inlineParagraph:
            "Tailored solutions based on pest type and property needs.",
        },
      ],
    },
    {
      slug: "plastering",
      listHeading: "Why hire a professional plasterer?",
      paragraphStyle: [
        "Professional plasterers deliver smooth, flawless finishes that are perfectly prepared for painting or further treatments, ensuring a high-quality look. Their expertise guarantees durability, with proper application techniques that minimize the need for future repairs. Skilled plasterers can also expertly repair any damage to walls and ceilings, restoring them to their original condition.",
        <>
          Compliance with Australian standards, such as{" "}
          <span className="text-primaryblue">AS 2589 for wall lining</span> and{" "}
          <span className="text-primaryblue">
            AS 3959 for fire-resistant plastering
          </span>
          , ensures that all work meets safety and quality requirements.
          Additionally, professional plasterers complete projects efficiently,
          minimizing disruptions and saving time for property owners. With a
          variety of techniques, from essential repairs to custom decorative
          finishes, they offer tailored solutions to fit any project’s needs.
        </>,
      ],
    },
    {
      slug: "plumbing",
      listHeading: "Why hire a licensed plumber?",
      list: [
        {
          inlineHeading: "Expertise",
          inlineParagraph: "Skilled handling of all plumbing jobs.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph: "Work meets legal and safety standards.",
        },
        {
          inlineHeading: "Efficiency",
          inlineParagraph: "Fast solutions to minimize downtime.",
        },
        {
          inlineHeading: "Peace of mind",
          inlineParagraph: "Coverage by warranties and insurance.",
        },
        {
          inlineHeading: "Reliability",
          inlineParagraph: "Consistent service quality every time.",
        },
        {
          inlineHeading: "Safety first",
          inlineParagraph: "Proper precautions taken for safety.",
        },
      ],
    },
    {
      slug: "pools-and-spas",
      listHeading: "Why hire pool & spa professional?",
      list: [
        {
          inlineHeading: "Safe installation",
          inlineParagraph: "Compliance with safety and building codes.",
        },
        {
          inlineHeading: "Water quality",
          inlineParagraph:
            "Maintains proper chemistry, preventing algae and bacteria.",
        },
        {
          inlineHeading: "Efficient repairs",
          inlineParagraph: "Quick issue diagnosis and fixes.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph: "Adheres to AS 1926.1 and AS 2610 standards.",
        },
        {
          inlineHeading: "Durability",
          inlineParagraph:
            "Increases lifespan with quality installation and upkeep.",
        },
        {
          inlineHeading: "Custom solutions",
          inlineParagraph: "Personalized designs and features.",
        },
      ],
    },
    {
      slug: "rendering",
      listHeading: "Why hire a professional renderer?",
      list: [
        {
          inlineHeading: "Durable finish",
          inlineParagraph:
            "Provides a weather-resistant, long-lasting surface.",
        },
        {
          inlineHeading: "Enhanced insulation",
          inlineParagraph: "Boosts energy efficiency for year-round comfort.",
        },
        {
          inlineHeading: "Crack resistance",
          inlineParagraph: "Flexible renders prevent cracking over time.",
        },
        {
          inlineHeading: "Standards compliance",
          inlineParagraph: "Adheres to AS 3700 and AS 2311 for quality.",
        },
        {
          inlineHeading: "Aesthetic options",
          inlineParagraph:
            "Smooth, textured, or colored finishes to suit any style.",
        },
        {
          inlineHeading: "Expert application",
          inlineParagraph:
            "Ensures even coverage and proper curing for durability.",
        },
      ],
    },
    {
      slug: "renovation-and-restoration",
      listHeading: "Benefits of hiring a renovation & restoration expert",
      list: [
        {
          inlineHeading: "Expert craftsmanship",
          inlineParagraph: "Delivers high-quality, lasting finishes.",
        },
        {
          inlineHeading: "Structural integrity",
          inlineParagraph: "Safeguards building stability.",
        },
        {
          inlineHeading: "Code compliance",
          inlineParagraph: "Meets standards like AS 1684 and AS 1428.",
        },
        {
          inlineHeading: "Historic preservation",
          inlineParagraph: "Maintains original details in heritage projects.",
        },
        {
          inlineHeading: "Energy savings",
          inlineParagraph: "Adds efficiency with modern materials.",
        },
        {
          inlineHeading: "Personalized design",
          inlineParagraph: "Custom solutions to fit style and budget.",
        },
      ],
    },
    {
      slug: "roofing",

      listHeading: "Why hire a professional roofer?",
      list: [
        {
          inlineHeading: "Safety compliance",
          inlineParagraph: "Adheres to building and safety codes.",
        },
        {
          inlineHeading: "Durability",
          inlineParagraph: "Quality materials for long-lasting protection.",
        },
        {
          inlineHeading: "Leak prevention",
          inlineParagraph: "Prevents water damage with skilled repairs.",
        },
        {
          inlineHeading: "Standards compliance",
          inlineParagraph: "Follows AS 1562.1 and AS/NZS 4200.2.",
        },
        {
          inlineHeading: "Cost-effective",
          inlineParagraph: "Reduces frequent replacement needs.",
        },
        {
          inlineHeading: "Proper drainage",
          inlineParagraph:
            "Ensures effective gutter and downpipe installation.",
        },
      ],
    },
    {
      slug: "scaffolding",
      listHeading: "Why hire a professional scaffolder?",
      list: [
        {
          inlineHeading: "Safety compliance",
          inlineParagraph:
            "Reduces accident risks by meeting safety standards.",
        },
        {
          inlineHeading: "Proper assembly",
          inlineParagraph: "Ensures stable, secure access to work areas.",
        },
        {
          inlineHeading: "Efficiency",
          inlineParagraph: "Boosts productivity and speeds up projects.",
        },
        {
          inlineHeading: "Standards compliance",
          inlineParagraph: "Adheres to AS/NZS 1576 and AS/NZS 4576.",
        },
        {
          inlineHeading: "Custom solutions",
          inlineParagraph: "Provides scaffolds suited to project needs.",
        },
        {
          inlineHeading: "Regular inspections",
          inlineParagraph: "Maintains safety through routine checks.",
        },
      ],
    },
    {
      slug: "shopfitting",
      listHeading: "Why hire a professional shopfitter?",
      list: [
        {
          inlineHeading: "Custom layouts",
          inlineParagraph: "Designs that maximize space and flow.",
        },
        {
          inlineHeading: "Brand consistency",
          inlineParagraph: "Interiors aligned with brand identity.",
        },
        {
          inlineHeading: "Code compliance",
          inlineParagraph: "Meets AS 1428 and AS 1684 standards.",
        },
        {
          inlineHeading: "Efficiency",
          inlineParagraph: "Full project management for minimal downtime.",
        },
        {
          inlineHeading: "Durable materials",
          inlineParagraph: "High-quality, commercial-grade.",
        },
        {
          inlineHeading: "System integration",
          inlineParagraph: "Lighting, electrical, and security.",
        },
      ],
    },
    {
      slug: "skip-bins",
      listHeading: "Why hire a professional skip bin service?",
      list: [
        {
          inlineHeading: "Efficient waste removal",
          inlineParagraph: "Saves time and simplifies waste management.",
        },
        {
          inlineHeading: "Safety compliance",
          inlineParagraph: "Meets local regulations for waste disposal.",
        },
        {
          inlineHeading: "Variety of sizes",
          inlineParagraph: "Offers bins to suit different project needs.",
        },
        {
          inlineHeading: "Eco-friendly",
          inlineParagraph: "Promotes recycling and reduces landfill use.",
        },
        {
          inlineHeading: "Cost-effective",
          inlineParagraph:
            "Transparent pricing for budget-friendly waste management.",
        },
        {
          inlineHeading: "Convenient pickup",
          inlineParagraph: "Timely collection to keep sites clean and safe.",
        },
      ],
    },
    {
      slug: "solar-energy",
      listHeading: "Why hire a professional solar installer?",
      list: [
        {
          inlineHeading: "Expert design",
          inlineParagraph: "Maximizes energy production.",
        },
        {
          inlineHeading: "Safety compliance",
          inlineParagraph: "Meets safety standards and regulations.",
        },
        {
          inlineHeading: "Optimal performance",
          inlineParagraph: "Ensures efficient energy savings.",
        },
        {
          inlineHeading: "Standards compliance",
          inlineParagraph: "Adheres to AS/NZS 5033 and AS 4777.",
        },
        {
          inlineHeading: "Financial benefits",
          inlineParagraph: "Guides rebates and incentives.",
        },
        {
          inlineHeading: "Reliable backup",
          inlineParagraph: "Provides battery and hybrid solutions.",
        },
      ],
    },
    {
      slug: "staircases",
      listHeading: "Why hire a professional staircase builder?",
      list: [
        {
          inlineHeading: "Custom fit",
          inlineParagraph:
            "Tailored designs to maximize space and match style.",
        },
        {
          inlineHeading: "Safety compliance",
          inlineParagraph:
            "Built to safety standards, reducing accident risks.",
        },
        {
          inlineHeading: "Durability",
          inlineParagraph: "High-quality materials for long-lasting results.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph: "Meets AS 1657 and AS 1428 standards.",
        },
        {
          inlineHeading: "Efficient installation",
          inlineParagraph: "Quick, minimal disruption during installation.",
        },
        {
          inlineHeading: "Versatile materials",
          inlineParagraph:
            "Options like wood, metal, and glass for various preferences and budgets.",
        },
      ],
    },
    {
      slug: "stonemasonry",
      listHeading: "Why hire a professional stone installer?",
      list: [
        {
          inlineHeading: "Expert craftsmanship",
          inlineParagraph: "Ensures durable, high-quality stonework.",
        },
        {
          inlineHeading: "Heritage preservation",
          inlineParagraph: "Preserves historical integrity.",
        },
        {
          inlineHeading: "Safety compliance",
          inlineParagraph: "Meets AS 3700 for stability and safety.",
        },
        {
          inlineHeading: "Custom design",
          inlineParagraph: "Offers diverse stone types and finishes.",
        },
        {
          inlineHeading: "Weather resistance",
          inlineParagraph: "Perfect for outdoor and high-traffic areas.",
        },
        {
          inlineHeading: "Versatile applications",
          inlineParagraph: "Ideal for walls, pathways, facades, and more.",
        },
      ],
    },
    {
      slug: "tiling",
      listHeading: "Why hire a professional tiler?",
      list: [
        {
          inlineHeading: "Precise installation",
          inlineParagraph: "Ensures even tiles and seamless grouting.",
        },
        {
          inlineHeading: "Durability",
          inlineParagraph: "Prevents cracking and loosening.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph: "Meets AS 3958.1 and AS 3740 standards.",
        },
        {
          inlineHeading: "Custom design",
          inlineParagraph: "Offers diverse materials and patterns.",
        },
        {
          inlineHeading: "Waterproofing",
          inlineParagraph: "Protects against leaks in wet areas.",
        },
        {
          inlineHeading: "Efficiency",
          inlineParagraph: "Quick work with minimal disruption.",
        },
      ],
    },
    {
      slug: "waterproofing",
      listHeading: "Why hire a professional waterproofers?",
      list: [
        {
          inlineHeading: "Durability",
          inlineParagraph: "Ensures long-term protection from water damage.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph: "Adheres to AS 3740 and AS 4654.2 standards.",
        },
        {
          inlineHeading: "Prevention",
          inlineParagraph: "Prevents costly issues like leaks and mold.",
        },
        {
          inlineHeading: "Custom solutions",
          inlineParagraph: "Waterproofing tailored to each area.",
        },
        {
          inlineHeading: "Expert application",
          inlineParagraph: "Uses the best techniques and products.",
        },
        {
          inlineHeading: "Value",
          inlineParagraph:
            "Protects property and boosts value by preventing damage.",
        },
      ],
    },
  ];
  const categoryData = data.filter((item) => item.slug === slug);
  return (
    <>
      {categoryData.map((item, index) => {
        return (
          <>
            <div
              key={index}
              className="flex flex-col lg:flex-row gap-x-28 mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1440px] mt-10 sm:mt-40"
            >
              <div className="mt-7 sm:mt-10 order-2 lg:order-1">
                <div>
                  <Heading variant={"h2"} className={"text-offblack"}>
                    {item.listHeading}
                  </Heading>
                  {item.paragraphStyle &&
                    item.paragraphStyle.map((item, index) => {
                      return (
                        <Paragraph
                          key={index}
                          variant={"MainParagraph"}
                          className={
                            index === 0
                              ? "mt-5 text-offblack"
                              : "mt-3 text-offblack"
                          }
                        >
                          {item}
                        </Paragraph>
                      );
                    })}
                  {item.list && (
                    <ul className="flex flex-col space-y-2 mt-5 list-decimal">
                      {item.list.map((item, index) => {
                        return (
                          <li className={"inline"} key={index}>
                            <SecondaryHeading
                              variant={"MainParagraphHeading"}
                              className="font-semibold text-offblack mr-2 inline"
                            >
                              {index + 1}.
                            </SecondaryHeading>
                            <SecondaryHeading
                              variant={"MainParagraphHeading"}
                              className={"inline font-semibold"}
                            >
                              {item.inlineHeading}
                            </SecondaryHeading>
                            <Paragraph
                              variant={"MainParagraph"}
                              className={"inline text-offblack"}
                            >
                              &nbsp;&nbsp;{item.inlineParagraph}
                            </Paragraph>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </div>
              <div className="order-1 lg:order-2 h-auto w-full lg:w-1/2 lg:h-[700px] xl:w-[650px] xl:h-[800px] 2xl:w-[700px] 2xl:h-[900px] aspect-square lg:aspect-auto shrink-0">
                <img
                  className="object-cover w-full h-full rounded-3xl"
                  src={require("../../assets/img/TradesPosterSpeciality/customers.jpg")}
                  alt="trade-poster"
                />
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default WhyProfessional;
