import React from "react";
import Blinds from "../../assets/img/advance-algorithm.png";
import Heading from "../../components/common/Typography/Heading";
import ImageWithLongCardsColumn from "../../components/common/Generics/ImageWithLongCardsColumn";

const WhyTradesPoster = () => {
  const cardsdata = [
    {
      heading: "Free listings",
      icon: Blinds,
      description: "Start free, grow as needed.",
    },
    {
      heading: "No lommission",
      icon: Blinds,
      description: "Keep 100% of earnings.",
    },
 
    {
      heading: "Multi-category",
      icon: Blinds,
      description: "List across categories.",
    },
    {
      heading: "Custom quotes",
      icon: Blinds,
      description: "Send personalized quotes.",
    },
    {
      heading: "Custom profiles",
      icon: Blinds,
      description: "Create contactable profiles.",
    },
    {
      heading: "Performance tracking",
      icon: Blinds,
      description: "Access business analytics.",
    },
    
  ];
  return (
    <>
     
        <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] mt-10 sm:mt-40">
          <Heading
            variant={"h2"}
            className={
              "flex justify-start text-start text-offblack max-w-[650px] 2xl:max-w-[850px]"
            }
          >
           What sets trades poster apart from the rest 
          </Heading>
        </div>
        <ImageWithLongCardsColumn data={cardsdata} />
     
    </>
  );
};

export default WhyTradesPoster;
