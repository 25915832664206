import React from "react";
import Blinds from "../../assets/img/advance-algorithm.png";
import Heading from "../../components/common/Typography/Heading";
import ImageWithLongCardsColumn from "../../components/common/Generics/ImageWithLongCardsColumn";

const WhyTradesposter = () => {
  const cardsdata = [
    {
      heading: "Free account",
      icon: Blinds,
      description: "Always free to use.",
    },
    {
      heading: "Verified pros",
      icon: Blinds,
      description: "Trusted experts.",
    },
    {
      heading: "Licensed & insured",
      icon: Blinds,
      description: "Certified pros.",
    },
    {
      heading: "Custom quotes",
      icon: Blinds,
      description: "Tailored offers..",
    },
    {
      heading: "Direct contact",
      icon: Blinds,
      description: "Message businesses.",
    },
    {
      heading: "Pay directly",
      icon: Blinds,
      description: "Negotiate and pay on your terms.",
    },
  ];
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] mt-10 sm:mt-40">
        <Heading
          variant={"h2"}
          className={
            "text-start text-offblack max-w-[700px] 2xl:max-w-[850px]"
          }
        >
          Why register as a customer on Trades Poster?
        </Heading>
      </div>
      <ImageWithLongCardsColumn data={cardsdata} />
    </>
  );
};

export default WhyTradesposter;
