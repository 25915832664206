import React from "react";
import PaymentHistory from "../../../sections/BusinessDashboard/Subscriptions/Payment/PaymentHistory";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";
import AddNew from "../../../sections/BusinessDashboard/Subscriptions/Payment/AddNew";
import SpecialText from "../../../components/common/Typography/SpecialText";
const PaymentDetails = () => {
  return (
    <>
      <DashboardMainContainer className={"lg:px-5 lg:pb-10 pt-[33px]"}>
        <div>
          <Heading variant={"h6"} className={"text-center text-offblack"}>
            Billing and payments
          </Heading>
        </div>
        <div className="mt-[60px] min-h-screen">
          <AddNew />
          <PaymentHistory />
          <SpecialText
            variant={"FootNoteDisclaimer"}
            className={"mt-20 text-center text-lightgray"}
          >
            You've reached the end of the list.
          </SpecialText>
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default PaymentDetails;
