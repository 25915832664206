import React, { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// import required modules
import { Grid } from "swiper/modules";
import { Link } from "react-router-dom";
import DashboardAdscard from "../../../components/common/AdsCard/DashboardAdsCard";
import CircularButton from "../../../components/common/MainButton/CircularButton";
// ./swiper.esm.js
const AllPostSlider = () => {
  const Images = [
    {
      original: require("./../../../assets/img/P1.jpeg"),
      thumbnail: require("./../../../assets/img/P1.jpeg"),
    },
    {
      original: require("./../../../assets/img/P2.jpeg"),
      thumbnail: require("./../../../assets/img/P2.jpeg"),
    },
    {
      original: require("./../../../assets/img/P3.jpeg"),
      thumbnail: require("./../../../assets/img/P3.jpeg"),
    },
    {
      original: require("./../../../assets/img/P4.jpeg"),
      thumbnail: require("./../../../assets/img/P4.jpeg"),
    },
    {
      original: require("./../../../assets/img/P5.jpeg"),
      thumbnail: require("./../../../assets/img/P5.jpeg"),
    },
    {
      original: require("./../../../assets/img/P77.jpg"),
      thumbnail: require("./../../../assets/img/P77.jpg"),
    },
  ];
  const swiperRef = useRef();
  return (
    <>
      <div className="mx-[5%] md:mx-auto md:w-[400px] lg:w-[413px] xl:w-[832px] 2xl:w-[992px]">
        <Swiper
          className="w-auto lg:w-full"
          // slidesPerView={2}
          breakpoints={{
            400: {
              slidesPerView: 1,
            },
            640: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 1,
            },
            1024: {
              slidesPerView: 2,
            },
            1280: {
              slidesPerView: 2,
            },
          }}
          grid={{
            rows: 2,
            fill: "row",
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Grid]}
          spaceBetween={32}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {Images?.map((item, index) => {
            return (
              <>
                <SwiperSlide className="w-fit">
                  <DashboardAdscard
                    additionalActions={true}
                    viewButton={true}
                    deleteButton={true}
                    serviceName={"Blinds"}
                    serviceDescription={
                      "A quick brown fox jumped to a wall and catches the birds to eat."
                    }
                    category={"Blinds"}
                    type={"Featured"}
                    Images={Images}
                    rating={5}
                    companyName={"Trades Poster"}
                    companyLogo={require("./../../../assets/img/User.png")}
                    // key={index}
                    // serviceName={e?.listingTitle}
                    // serviceDescription={e?.description}
                    // category={
                    //   e?.category ?? e?.listingCategory?.businessCategoryName
                    // }
                    // type={e?.listType}
                    // Images={ListImage?.length > 0 ? ListImage : [DefaultImage]}
                    // rating={e?.listRating}
                    // companyName={
                    //   e?.companyName ?? e?.userProfile?.company?.companyName
                    // }
                    // companyLogo={
                    //   e?.companyLogo ?? e?.userProfile?.company?.companyLogo
                    // }
                    // serviceId={e?.listingId ?? e.id}
                    // companyId={e?.companyId ?? e?.userProfile?.company?.id}
                    // reaction={e?.listReaction}
                  />
                </SwiperSlide>
              </>
            );
          })}
          <div className="hidden lg:flex items-center justify-end gap-x-2 mt-5">
            <CircularButton
              onClick={() => swiperRef.current?.slideNext()}
              variant={"prev30"}
            ></CircularButton>
            <CircularButton
              variant={"next30"}
              onClick={() => swiperRef.current?.slidePrev()}
            ></CircularButton>
          </div>
        </Swiper>
      </div>
    </>
  );
};

export default AllPostSlider;
