import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";

const ServiceHighlights = () => {
  const services = [
    {
      businessCategoryName: "Air con & Heating",
    },
    {
      businessCategoryName: "Architects & Design",
    },
    {
      businessCategoryName: "Blinds & Awning",
    },
    {
      businessCategoryName: "Bricklaying",
    },
    {
      businessCategoryName: "Cabinetmakers",
    },
    {
      businessCategoryName: "Carpentry",
    },
    {
      businessCategoryName: "Carpet & Flooring",
    },
    {
      businessCategoryName: "Air con & Heating",
    },
    {
      businessCategoryName: "Bricklaying",
    },
    {
      businessCategoryName: "Cabinetmakers",
    },
    {
      businessCategoryName: "Carpet & Flooring",
    },
  ];
  return (
    <>
      <div>
        <SecondaryHeading
          variant={"MainParagraphHeading"}
          className={"flex justify-start"}
        >
          Trade skills 
        </SecondaryHeading>
        <div className="flex items-center">
              <div className="flex items-center flex-wrap gap-3 mt-5">
                {services.map((item) => {
                  return (
                    <>
                      <div className="px-3 py-2 bg-white text-offblack flex justify-center rounded-3xl">
                        <AuxiliaryText variant={"Placeholder"}>
                          {item.businessCategoryName}
                        </AuxiliaryText>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
      </div>
    </>
  );
};

export default ServiceHighlights;
