import React from "react";
import Heading from "../../components/common/Typography/Heading";
import Paragraph from "../../components/common/Typography/Paragraph";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import SpecialText from "../../components/common/Typography/SpecialText";
import { useParams } from "react-router-dom";

const PricingEstimates = () => {
  const { slug } = useParams();
  const headings = ["Sydney", "Melbourne", "Brisbane", "Perth"];
  const data = [
    {
      slug: "architectural",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Residential design",
          cost: [
            "$5,000-15,000",
            "$4,800-14,000",
            "$4,500-13,000",
            "$5,000-15,000",
          ],
        },
        {
          heading: "Commercial architecture",
          cost: [
            "$15,000-50,000",
            "$14,000-48,000",
            "$13,500-45,000",
            "$15,000-50,000",
          ],
        },
        {
          heading: "Interior design",
          cost: [
            "$3,000-10,000",
            "$2,800-9,500",
            "$2,500-9,000",
            "$3,000-10,000",
          ],
        },
        {
          heading: "Drafting & Planning",
          cost: [
            "$1,500-5,000",
            "$1,400-4,800",
            "$1,300-4,500",
            "$1,500-5,000",
          ],
        },
        {
          heading: "Landscape architecture",
          cost: [
            "$4,000-12,000",
            "$3,800-11,500",
            "$3,500-11,000",
            "$4,000-12,000",
          ],
        },
        {
          heading: "Building permits",
          cost: [
            "$2,000-6,000",
            "$1,800-5,500",
            "$1,700-5,000",
            "$2,000-6,000",
          ],
        },
        {
          heading: "Heritage conservation",
          cost: [
            "$7,000-20,000",
            "$6,500-18,500",
            "$6,000-17,000",
            "$7,000-20,000",
          ],
        },
        {
          heading: "Project management",
          cost: [
            "5-10% of total project cost",
            "5-10% of total project cost",
            "5-10% of total project cost",
            "5-10% of total project cost",
          ],
        },
      ],
    },
    {
      slug: "air-conditioning-and-heating",
      Mainheading: "Cost guide by popular categories",
      table: [
        {
          heading: "Installation",
          cost: [
            "$3,000-7,000",
            "$2,800-6,500",
            "$2,700-6,000",
            "$3,000-7,000",
          ],
        },
        {
          heading: "Ducted system repair",
          cost: ["$500-1,200", "$450-1,100", "$400-1,000", "$500-1,200"],
        },
        {
          heading: "Split system service",
          cost: ["$150-300", "$140-280", "$130-260", "$150-300"],
        },
        {
          heading: "Evaporative cooling",
          cost: ["$200-500", "$180-450", "$160-400", "$200-500"],
        },
        {
          heading: "Gas heating service",
          cost: ["$300-700", "$280-650", "$260-600", "$300-700"],
        },
        {
          heading: "Thermostat install",
          cost: ["$200-400", "$180-360", "$160-320", "$200-400"],
        },
        {
          heading: "Maintenance (Annual)",
          cost: ["$150-300", "$140-280", "$130-260", "$150-300"],
        },
        {
          heading: "Emergency service",
          cost: ["$150-250/hr", "$140-230/hr", "$130-220/hr", "$150-250/hr"],
        },
      ],
    },
    {
      slug: "bricklaying",
      Mainheading: "Cost guide by popular categories",
      table: [
        {
          heading: "Residential wall",
          cost: [
            "$1,200-3,200",
            "$1,100-3,000",
            "$1,000-2,800",
            "$1,200-3,200",
          ],
        },
        {
          heading: "Retaining wall",
          cost: [
            "$2,500-5,200",
            "$2,300-5,000",
            "$2,200-4,800",
            "$2,500-5,200",
          ],
        },
        {
          heading: "Paving (per sqm)",
          cost: ["$100-250", "$90-230", "$80-210", "$100-250"],
        },
        {
          heading: "Chimney repair",
          cost: [
            "$1,500-4,200",
            "$1,400-4,000",
            "$1,300-3,800",
            "$1,500-4,200",
          ],
        },
        {
          heading: "Repointing (per sqm)",
          cost: ["$200-450", "$180-420", "$160-390", "$200-450"],
        },
        {
          heading: "Brick restoration",
          cost: [
            "$2,000-5,200",
            "$1,800-5,000",
            "$1,700-4,800",
            "$2,000-5,200",
          ],
        },
        {
          heading: "Brick fence",
          cost: ["$1,000-4,000", "$900-3,800", "$800-3,500", "$1,000-4,000"],
        },
        {
          heading: "Brick patio",
          cost: [
            "$1,500-4,500",
            "$1,400-4,200",
            "$1,200-4,000",
            "$1,500-4,500",
          ],
        },
        {
          heading: "Brick veneer cladding",
          cost: [
            "$2,000-6,000",
            "$1,800-5,800",
            "$1,700-5,500",
            "$2,000-6,000",
          ],
        },
        {
          heading: "Fireplace construction",
          cost: [
            "$2,500-6,000",
            "$2,300-5,800",
            "$2,200-5,500",
            "$2,500-6,000",
          ],
        },
      ],
    },
    {
      slug: "cabinet-making",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Kitchen cabinets",
          cost: [
            "$5,000-20,000",
            "$4,800-18,000",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Bathroom vanities",
          cost: [
            "$1,500-6,000",
            "$1,400-5,500",
            "$1,300-5,000",
            "$1,500-6,000",
          ],
        },
        {
          heading: "Wardrobes & closets",
          cost: [
            "$3,000-12,000",
            "$2,800-11,000",
            "$2,500-10,000",
            "$3,000-12,000",
          ],
        },
        {
          heading: "Laundry cabinets",
          cost: [
            "$1,500-5,000",
            "$1,400-4,800",
            "$1,300-4,500",
            "$1,500-5,000",
          ],
        },
        {
          heading: "Entertainment units",
          cost: [
            "$2,000-8,000",
            "$1,800-7,500",
            "$1,700-7,000",
            "$2,000-8,000",
          ],
        },
        {
          heading: "Home office fit-outs",
          cost: [
            "$3,000-10,000",
            "$2,800-9,500",
            "$2,500-9,000",
            "$3,000-10,000",
          ],
        },
        {
          heading: "Commercial cabinets",
          cost: [
            "$5,000-25,000",
            "$4,800-23,000",
            "$4,500-20,000",
            "$5,000-25,000",
          ],
        },
        {
          heading: "Repairs & refacing",
          cost: ["$1,000-4,000", "$900-3,800", "$800-3,500", "$1,000-4,000"],
        },
      ],
    },
    {
      slug: "carpentry",
      Mainheading: "Cost guide by popular categories",
      table: [
        {
          heading: "Framing",
          cost: [
            "$5,000-25,000",
            "$4,800-23,000",
            "$4,500-20,000",
            "$5,000-25,000",
          ],
        },
        {
          heading: "Decking/pergolas",
          cost: [
            "$3,000-15,000",
            "$2,800-14,000",
            "$2,500-13,000",
            "$3,000-15,000",
          ],
        },
        {
          heading: "Doors/windows",
          cost: ["$1,000-6,000", "$900-5,500", "$800-5,000", "$1,000-6,000"],
        },
        {
          heading: "Flooring",
          cost: [
            "$2,000-10,000",
            "$1,800-9,500",
            "$1,500-8,500",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Skirting",
          cost: ["$1,000-5,000", "$900-4,800", "$800-4,500", "$1,000-5,000"],
        },
        {
          heading: "Cabinetry",
          cost: [
            "$3,000-12,000",
            "$2,800-11,000",
            "$2,500-10,000",
            "$3,000-12,000",
          ],
        },
        {
          heading: "Roofing",
          cost: [
            "$5,000-20,000",
            "$4,800-18,500",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Renovations",
          cost: [
            "$10,000-50,000",
            "$9,500-48,000",
            "$9,000-45,000",
            "$10,000-50,000",
          ],
        },
        {
          heading: "Fencing",
          cost: [
            "$2,000-10,000",
            "$1,800-9,500",
            "$1,500-8,500",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Formwork",
          cost: [
            "$3,000-15,000",
            "$2,800-14,000",
            "$2,500-13,000",
            "$3,000-15,000",
          ],
        },
        {
          heading: "Stairs/railings",
          cost: [
            "$4,000-18,000",
            "$3,800-16,500",
            "$3,500-15,000",
            "$4,000-18,000",
          ],
        },
        {
          heading: "Partitions",
          cost: [
            "$3,000-12,000",
            "$2,800-11,000",
            "$2,500-10,000",
            "$3,000-12,000",
          ],
        },
        {
          heading: "Outdoor structures",
          cost: [
            "$5,000-25,000",
            "$4,800-23,000",
            "$4,500-20,000",
            "$5,000-25,000",
          ],
        },
      ],
    },
    {
      slug: "carpet-and-flooring",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Carpet installation",
          cost: [
            "$3,000-8,000",
            "$2,800-7,500",
            "$2,500-7,000",
            "$3,000-8,000",
          ],
        },
        {
          heading: "Timber flooring",
          cost: [
            "$5,000-20,000",
            "$4,800-18,500",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Laminate flooring",
          cost: [
            "$2,000-8,000",
            "$1,800-7,500",
            "$1,500-7,000",
            "$2,000-8,000",
          ],
        },
        {
          heading: "Vinyl flooring",
          cost: [
            "$2,000-10,000",
            "$1,800-9,500",
            "$1,500-8,500",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Hybrid flooring",
          cost: [
            "$3,000-12,000",
            "$2,800-11,000",
            "$2,500-10,000",
            "$3,000-12,000",
          ],
        },
        {
          heading: "Tile flooring",
          cost: [
            "$4,000-15,000",
            "$3,800-14,000",
            "$3,500-13,000",
            "$4,000-15,000",
          ],
        },
        {
          heading: "Epoxy flooring",
          cost: [
            "$3,000-10,000",
            "$2,800-9,500",
            "$2,500-9,000",
            "$3,000-10,000",
          ],
        },
        {
          heading: "Polished concrete",
          cost: [
            "$5,000-20,000",
            "$4,800-18,500",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Commercial flooring",
          cost: [
            "$5,000-25,000",
            "$4,800-23,000",
            "$4,500-20,000",
            "$5,000-25,000",
          ],
        },
        {
          heading: "Repairs & re-stretching",
          cost: ["$1,000-4,000", "$900-3,800", "$800-3,500", "$1,000-4,000"],
        },
      ],
    },
    {
      slug: "caulking",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Interior caulking",
          cost: ["$300-1,000", "$280-950", "$250-900", "$300-1,000"],
        },
        {
          heading: "Exterior caulking",
          cost: ["$500-2,000", "$450-1,800", "$400-1,700", "$500-2,000"],
        },
        {
          heading: "Wet area caulking",
          cost: ["$400-1,500", "$380-1,400", "$350-1,300", "$400-1,500"],
        },
        {
          heading: "Expansion joint caulking",
          cost: ["$600-2,500", "$550-2,200", "$500-2,000", "$600-2,500"],
        },
        {
          heading: "Window & door caulking",
          cost: ["$300-1,000", "$280-950", "$250-900", "$300-1,000"],
        },
        {
          heading: "Pool & spa caulking",
          cost: ["$800-3,000", "$750-2,800", "$700-2,500", "$800-3,000"],
        },
        {
          heading: "Commercial caulking",
          cost: ["$1,000-5,000", "$950-4,500", "$900-4,000", "$1,000-5,000"],
        },
        {
          heading: "Silicone replacement",
          cost: ["$300-1,200", "$280-1,100", "$250-1,000", "$300-1,200"],
        },
      ],
    },
    {
      slug: "cleaning-services",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Residential cleaning",
          cost: ["$100-300", "$90-280", "$80-260", "$100-300"],
        },
        {
          heading: "Commercial cleaning",
          cost: ["$200-1,000", "$180-950", "$150-900", "$200-1,000"],
        },
        {
          heading: "End of lease cleaning",
          cost: ["$300-1,500", "$280-1,400", "$250-1,200", "$300-1,500"],
        },
        {
          heading: "Carpet cleaning",
          cost: ["$100-500", "$90-450", "$80-400", "$100-500"],
        },
        {
          heading: "Window cleaning",
          cost: ["$150-600", "$140-550", "$130-500", "$150-600"],
        },
        {
          heading: "Pressure washing",
          cost: ["$200-1,000", "$180-950", "$150-900", "$200-1,000"],
        },
        {
          heading: "Deep cleaning",
          cost: ["$200-800", "$180-750", "$150-700", "$200-800"],
        },
        {
          heading: "Post-construction",
          cost: ["$500-2,000", "$450-1,800", "$400-1,600", "$500-2,000"],
        },
        {
          heading: "Tile & grout cleaning",
          cost: ["$150-600", "$140-550", "$130-500", "$150-600"],
        },
        {
          heading: "Move-in cleaning",
          cost: ["$300-1,200", "$280-1,100", "$250-1,000", "$300-1,200"],
        },
      ],
    },
    {
      slug: "concrete-and-paving",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Concrete driveways",
          cost: [
            "$5,000-15,000",
            "$4,800-14,000",
            "$4,500-13,000",
            "$5,000-15,000",
          ],
        },
        {
          heading: "Patios & pathways",
          cost: [
            "$3,000-10,000",
            "$2,800-9,500",
            "$2,500-9,000",
            "$3,000-10,000",
          ],
        },
        {
          heading: "Concrete slabs",
          cost: [
            "$4,000-12,000",
            "$3,800-11,000",
            "$3,500-10,000",
            "$4,000-12,000",
          ],
        },
        {
          heading: "Stamped concrete",
          cost: [
            "$6,000-20,000",
            "$5,800-18,500",
            "$5,500-17,000",
            "$6,000-20,000",
          ],
        },
        {
          heading: "Exposed aggregate",
          cost: [
            "$5,000-15,000",
            "$4,800-14,000",
            "$4,500-13,000",
            "$5,000-15,000",
          ],
        },
        {
          heading: "Paving services",
          cost: [
            "$3,000-10,000",
            "$2,800-9,500",
            "$2,500-9,000",
            "$3,000-10,000",
          ],
        },
        {
          heading: "Retaining walls",
          cost: [
            "$3,000-12,000",
            "$2,800-11,000",
            "$2,500-10,000",
            "$3,000-12,000",
          ],
        },
        {
          heading: "Polished concrete",
          cost: [
            "$5,000-20,000",
            "$4,800-18,500",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Concrete sealing",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Commercial paving",
          cost: [
            "$10,000-50,000",
            "$9,500-48,000",
            "$9,000-45,000",
            "$10,000-50,000",
          ],
        },
      ],
    },
    {
      slug: "conveyancing",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Residential conveyancing",
          cost: ["$1,000-2,500", "$950-2,400", "$900-2,200", "$1,000-2,500"],
        },
        {
          heading: "Commercial conveyancing",
          cost: [
            "$2,000-5,000",
            "$1,900-4,800",
            "$1,800-4,500",
            "$2,000-5,000",
          ],
        },
        {
          heading: "Off-the-plan conveyancing",
          cost: [
            "$1,500-3,500",
            "$1,400-3,300",
            "$1,300-3,000",
            "$1,500-3,500",
          ],
        },
        {
          heading: "Property title transfer",
          cost: ["$800-2,000", "$750-1,900", "$700-1,800", "$800-2,000"],
        },
        {
          heading: "Settlement services",
          cost: ["$500-1,500", "$450-1,400", "$400-1,300", "$500-1,500"],
        },
        {
          heading: "First home buyer services",
          cost: ["$1,000-2,500", "$950-2,400", "$900-2,200", "$1,000-2,500"],
        },
        {
          heading: "Subdivision conveyancing",
          cost: [
            "$2,000-6,000",
            "$1,900-5,800",
            "$1,800-5,500",
            "$2,000-6,000",
          ],
        },
        {
          heading: "Mortgage refinancing",
          cost: ["$500-1,500", "$450-1,400", "$400-1,300", "$500-1,500"],
        },
        {
          heading: "Vendor statement (sec 32)",
          cost: ["$300-800", "$280-750", "$250-700", "$300-800"],
        },
        {
          heading: "Contract review",
          cost: ["$300-1,000", "$280-950", "$250-900", "$300-1,000"],
        },
      ],
    },
    {
      slug: "blinds-and-curtains",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Custom curtains",
          cost: ["$500-2,000", "$480-1,800", "$450-1,700", "$500-2,000"],
        },
        {
          heading: "Roller blinds",
          cost: ["$200-1,000", "$180-950", "$150-900", "$200-1,000"],
        },
        {
          heading: "Roman blinds",
          cost: ["$300-1,200", "$280-1,100", "$250-1,000", "$300-1,200"],
        },
        {
          heading: "Venetian blinds",
          cost: ["$250-1,000", "$230-950", "$200-900", "$250-1,000"],
        },
        {
          heading: "Motorized blinds",
          cost: ["$800-3,000", "$750-2,800", "$700-2,500", "$800-3,000"],
        },
        {
          heading: "Awnings (retractable)",
          cost: [
            "$1,500-6,000",
            "$1,400-5,500",
            "$1,200-5,000",
            "$1,500-6,000",
          ],
        },
        {
          heading: "Plantation shutters",
          cost: ["$500-3,000", "$450-2,800", "$400-2,500", "$500-3,000"],
        },
        {
          heading: "Outdoor blinds",
          cost: ["$1,000-5,000", "$900-4,800", "$850-4,500", "$1,000-5,000"],
        },
        {
          heading: "Repairs & maintenance",
          cost: ["$100-500", "$90-450", "$80-400", "$100-500"],
        },
      ],
    },
    {
      slug: "decking-and-gazebos",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Timber decking",
          cost: [
            "$5,000-20,000",
            "$4,800-18,500",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Composite decking",
          cost: [
            "$7,000-25,000",
            "$6,500-23,000",
            "$6,000-22,000",
            "$7,000-25,000",
          ],
        },
        {
          heading: "Pool decking",
          cost: [
            "$10,000-30,000",
            "$9,500-28,000",
            "$9,000-27,000",
            "$10,000-30,000",
          ],
        },
        {
          heading: "Pergolas & gazebos",
          cost: [
            "$8,000-25,000",
            "$7,500-23,000",
            "$7,000-22,000",
            "$8,000-25,000",
          ],
        },
        {
          heading: "Verandas",
          cost: [
            "$6,000-20,000",
            "$5,800-18,500",
            "$5,500-17,000",
            "$6,000-20,000",
          ],
        },
        {
          heading: "Carports",
          cost: [
            "$5,000-15,000",
            "$4,800-14,000",
            "$4,500-13,000",
            "$5,000-15,000",
          ],
        },
        {
          heading: "Patios",
          cost: [
            "$4,000-15,000",
            "$3,800-14,000",
            "$3,500-13,000",
            "$4,000-15,000",
          ],
        },
        {
          heading: "Roofed decks & gazebos",
          cost: [
            "$10,000-30,000",
            "$9,500-28,000",
            "$9,000-27,000",
            "$10,000-30,000",
          ],
        },
        {
          heading: "Deck extensions",
          cost: [
            "$5,000-20,000",
            "$4,800-18,500",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Deck repairs",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
      ],
    },
    {
      slug: "demolition-and-excavation",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Residential demolition",
          cost: [
            "$10,000-30,000",
            "$9,500-28,000",
            "$9,000-27,000",
            "$10,000-30,000",
          ],
        },
        {
          heading: "Commercial demolition",
          cost: [
            "$20,000-100,000",
            "$18,000-95,000",
            "$17,000-90,000",
            "$20,000-100,000",
          ],
        },
        {
          heading: "Industrial demolition",
          cost: [
            "$50,000-200,000",
            "$48,000-190,000",
            "$45,000-180,000",
            "$50,000-200,000",
          ],
        },
        {
          heading: "Concrete removal",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Land clearing",
          cost: [
            "$5,000-20,000",
            "$4,800-18,500",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Foundation excavation",
          cost: [
            "$5,000-15,000",
            "$4,800-14,000",
            "$4,500-13,000",
            "$5,000-15,000",
          ],
        },
        {
          heading: "Bulk earthworks",
          cost: [
            "$20,000-100,000",
            "$18,500-95,000",
            "$17,000-90,000",
            "$20,000-100,000",
          ],
        },
        {
          heading: "Trenching",
          cost: [
            "$2,000-10,000",
            "$1,900-9,500",
            "$1,800-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Asbestos removal",
          cost: [
            "$3,000-15,000",
            "$2,800-14,000",
            "$2,500-13,000",
            "$3,000-15,000",
          ],
        },
        {
          heading: "Rubbish & waste removal",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
      ],
    },
    {
      slug: "electrical",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "General electrical repair",
          cost: ["$100-500", "$90-450", "$80-400", "$100-500"],
        },
        {
          heading: "Wiring & rewiring",
          cost: [
            "$5,000-15,000",
            "$4,800-14,000",
            "$4,500-13,000",
            "$5,000-15,000",
          ],
        },
        {
          heading: "Lighting installation",
          cost: ["$300-2,000", "$280-1,800", "$250-1,700", "$300-2,000"],
        },
        {
          heading: "Switchboard upgrades",
          cost: [
            "$1,500-5,000",
            "$1,400-4,800",
            "$1,300-4,500",
            "$1,500-5,000",
          ],
        },
        {
          heading: "Safety inspections",
          cost: ["$200-800", "$180-750", "$150-700", "$200-800"],
        },
        {
          heading: "Ceiling fan installation",
          cost: ["$200-600", "$180-550", "$150-500", "$200-600"],
        },
        {
          heading: "Smoke alarm installation",
          cost: ["$150-500", "$140-450", "$130-400", "$150-500"],
        },
        {
          heading: "Appliance installation",
          cost: ["$200-1,000", "$180-950", "$150-900", "$200-1,000"],
        },
        {
          heading: "Solar panel installation",
          cost: [
            "$3,000-10,000",
            "$2,800-9,500",
            "$2,500-9,000",
            "$3,000-10,000",
          ],
        },
        {
          heading: "Commercial electrical",
          cost: [
            "$10,000-50,000",
            "$9,500-48,000",
            "$9,000-45,000",
            "$10,000-50,000",
          ],
        },
      ],
    },
    {
      slug: "engineering-services",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Structural engineering",
          cost: [
            "$5,000-20,000",
            "$4,800-18,000",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Civil engineering",
          cost: [
            "$7,000-30,000",
            "$6,500-28,000",
            "$6,000-25,000",
            "$7,000-30,000",
          ],
        },
        {
          heading: "Electrical engineering",
          cost: [
            "$2,000-10,000",
            "$1,800-9,500",
            "$1,500-8,500",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Mechanical engineering",
          cost: [
            "$4,000-15,000",
            "$3,800-14,000",
            "$3,500-13,000",
            "$4,000-15,000",
          ],
        },
        {
          heading: "Geotechnical services",
          cost: [
            "$3,000-10,000",
            "$2,800-9,500",
            "$2,500-9,000",
            "$3,000-10,000",
          ],
        },
        {
          heading: "Hydraulic engineering",
          cost: [
            "$3,000-12,000",
            "$2,800-11,000",
            "$2,500-10,000",
            "$3,000-12,000",
          ],
        },
        {
          heading: "Compliance certification",
          cost: [
            "$1,500-5,000",
            "$1,400-4,800",
            "$1,300-4,500",
            "$1,500-5,000",
          ],
        },
        {
          heading: "Project management",
          cost: [
            "5-10% of total project cost",
            "5-10% of total project cost",
            "5-10% of total project cost",
            "5-10% of total project cost",
          ],
        },
      ],
    },
    {
      slug: "fence-and-gates",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Residential fencing",
          cost: [
            "$2,000-10,000",
            "$1,800-9,500",
            "$1,500-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Pool fencing",
          cost: [
            "$3,000-12,000",
            "$2,800-11,500",
            "$2,500-10,500",
            "$3,000-12,000",
          ],
        },
        {
          heading: "Commercial fencing",
          cost: [
            "$5,000-25,000",
            "$4,800-23,000",
            "$4,500-22,000",
            "$5,000-25,000",
          ],
        },
        {
          heading: "Automatic gates",
          cost: [
            "$4,000-15,000",
            "$3,800-14,000",
            "$3,500-13,000",
            "$4,000-15,000",
          ],
        },
        {
          heading: "Sliding & swing gates",
          cost: [
            "$3,000-10,000",
            "$2,800-9,500",
            "$2,500-9,000",
            "$3,000-10,000",
          ],
        },
        {
          heading: "Temporary fencing",
          cost: ["$500-3,000", "$450-2,800", "$400-2,500", "$500-3,000"],
        },
        {
          heading: "Gate repairs",
          cost: ["$200-1,000", "$180-950", "$150-900", "$200-1,000"],
        },
        {
          heading: "Rural & farm fencing",
          cost: [
            "$4,000-20,000",
            "$3,800-18,500",
            "$3,500-17,000",
            "$4,000-20,000",
          ],
        },
        {
          heading: "Privacy screens",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Custom fencing",
          cost: [
            "$5,000-20,000",
            "$4,800-18,500",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
      ],
    },
    {
      slug: "glazing-and-screens",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Window glazing",
          cost: ["$300-2,000", "$280-1,800", "$250-1,700", "$300-2,000"],
        },
        {
          heading: "Shower screens",
          cost: ["$600-2,500", "$550-2,300", "$500-2,000", "$600-2,500"],
        },
        {
          heading: "Bathroom screens",
          cost: ["$700-3,000", "$650-2,800", "$600-2,500", "$700-3,000"],
        },
        {
          heading: "Commercial glazing",
          cost: [
            "$5,000-50,000",
            "$4,800-48,000",
            "$4,500-45,000",
            "$5,000-50,000",
          ],
        },
        {
          heading: "Safety & security glass",
          cost: ["$800-5,000", "$750-4,800", "$700-4,500", "$800-5,000"],
        },
        {
          heading: "Splashbacks",
          cost: ["$500-2,500", "$450-2,300", "$400-2,000", "$500-2,500"],
        },
        {
          heading: "Glass balustrades",
          cost: [
            "$2,000-10,000",
            "$1,900-9,500",
            "$1,800-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Mirror installation",
          cost: ["$200-1,000", "$180-950", "$150-900", "$200-1,000"],
        },
        {
          heading: "Glass repairs",
          cost: ["$200-1,500", "$180-1,400", "$150-1,200", "$200-1,500"],
        },
        {
          heading: "Pool fencing",
          cost: [
            "$3,000-12,000",
            "$2,800-11,500",
            "$2,500-10,500",
            "$3,000-12,000",
          ],
        },
      ],
    },
    {
      slug: "garden-and-landscaping",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Landscape design",
          cost: [
            "$2,000-10,000",
            "$1,800-9,500",
            "$1,500-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Garden maintenance",
          cost: ["$100-500", "$90-450", "$80-400", "$100-500"],
        },
        {
          heading: "Hardscaping",
          cost: [
            "$5,000-30,000",
            "$4,800-28,000",
            "$4,500-27,000",
            "$5,000-30,000",
          ],
        },
        {
          heading: "Soft scaping",
          cost: ["$1,000-8,000", "$950-7,500", "$900-7,000", "$1,000-8,000"],
        },
        {
          heading: "Irrigation systems",
          cost: [
            "$2,000-7,000",
            "$1,900-6,500",
            "$1,800-6,000",
            "$2,000-7,000",
          ],
        },
        {
          heading: "Turf installation",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Retaining walls",
          cost: [
            "$3,000-15,000",
            "$2,800-14,000",
            "$2,500-13,000",
            "$3,000-15,000",
          ],
        },
        {
          heading: "Pool landscaping",
          cost: [
            "$3,000-20,000",
            "$2,800-18,500",
            "$2,500-17,000",
            "$3,000-20,000",
          ],
        },
        {
          heading: "Mulching & soil prep",
          cost: ["$500-2,000", "$450-1,800", "$400-1,700", "$500-2,000"],
        },
        {
          heading: "Outdoor lighting",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
      ],
    },
    {
      slug: "handyman",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "General repairs",
          cost: ["$100-500", "$90-450", "$80-400", "$100-500"],
        },
        {
          heading: "Plumbing fixes",
          cost: ["$100-300", "$90-280", "$80-260", "$100-300"],
        },
        {
          heading: "Electrical repairs",
          cost: ["$150-500", "$140-450", "$130-400", "$150-500"],
        },
        {
          heading: "Carpentry",
          cost: ["$200-800", "$180-750", "$150-700", "$200-800"],
        },
        {
          heading: "Painting & patching",
          cost: ["$300-1,500", "$280-1,400", "$250-1,200", "$300-1,500"],
        },
        {
          heading: "Tiling & grouting",
          cost: ["$200-1,000", "$180-950", "$150-900", "$200-1,000"],
        },
        {
          heading: "Furniture assembly",
          cost: ["$100-400", "$90-380", "$80-350", "$100-400"],
        },
        {
          heading: "Gutter cleaning",
          cost: ["$150-500", "$140-450", "$130-400", "$150-500"],
        },
        {
          heading: "Picture & TV mounting",
          cost: ["$100-400", "$90-380", "$80-350", "$100-400"],
        },
        {
          heading: "Fence repairs",
          cost: ["$200-1,000", "$180-950", "$150-900", "$200-1,000"],
        },
      ],
    },
    {
      slug: "inspection-and-surveyors",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Building inspections",
          cost: ["$400-800", "$380-750", "$350-700", "$400-800"],
        },
        {
          heading: "Land surveys",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Pest inspections",
          cost: ["$200-600", "$180-550", "$150-500", "$200-600"],
        },
        {
          heading: "Dilapidation reports",
          cost: ["$1,000-3,000", "$950-2,800", "$900-2,500", "$1,000-3,000"],
        },
        {
          heading: "Boundary surveys",
          cost: ["$1,000-4,000", "$950-3,800", "$900-3,500", "$1,000-4,000"],
        },
        {
          heading: "Compliance inspections",
          cost: ["$500-2,000", "$450-1,800", "$400-1,700", "$500-2,000"],
        },
        {
          heading: "Subdivision surveys",
          cost: [
            "$2,000-10,000",
            "$1,900-9,500",
            "$1,800-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Asbestos inspections",
          cost: ["$400-1,500", "$380-1,400", "$350-1,200", "$400-1,500"],
        },
        {
          heading: "Strata surveys",
          cost: [
            "$1,500-6,000",
            "$1,400-5,500",
            "$1,300-5,000",
            "$1,500-6,000",
          ],
        },
        {
          heading: "Structural inspections",
          cost: ["$500-2,500", "$480-2,300", "$450-2,000", "$500-2,500"],
        },
      ],
    },
    {
      slug: "security-and-automation",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Intruder alarms",
          cost: ["$1,000-4,000", "$950-3,800", "$900-3,500", "$1,000-4,000"],
        },
        {
          heading: "CCTV cameras",
          cost: [
            "$1,500-5,000",
            "$1,400-4,800",
            "$1,300-4,500",
            "$1,500-5,000",
          ],
        },
        {
          heading: "Smart locks",
          cost: ["$500-2,000", "$450-1,800", "$400-1,700", "$500-2,000"],
        },
        {
          heading: "Home automation systems",
          cost: [
            "$2,000-8,000",
            "$1,900-7,500",
            "$1,800-7,000",
            "$2,000-8,000",
          ],
        },
        {
          heading: "Intercom systems",
          cost: ["$1,000-3,000", "$950-2,800", "$900-2,500", "$1,000-3,000"],
        },
        {
          heading: "Access control",
          cost: [
            "$2,000-10,000",
            "$1,900-9,500",
            "$1,800-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Smart lighting",
          cost: ["$500-3,000", "$450-2,800", "$400-2,500", "$500-3,000"],
        },
        {
          heading: "Security lighting",
          cost: ["$1,000-4,000", "$950-3,800", "$900-3,500", "$1,000-4,000"],
        },
        {
          heading: "Smoke & fire alarms",
          cost: ["$300-1,500", "$280-1,400", "$250-1,200", "$300-1,500"],
        },
        {
          heading: "Energy management",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
      ],
    },
    {
      slug: "insulation-services",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Ceiling insulation",
          cost: ["$1,000-4,000", "$950-3,800", "$900-3,500", "$1,000-4,000"],
        },
        {
          heading: "Wall insulation",
          cost: [
            "$1,500-5,000",
            "$1,400-4,800",
            "$1,300-4,500",
            "$1,500-5,000",
          ],
        },
        {
          heading: "Floor insulation",
          cost: ["$1,000-3,500", "$950-3,300", "$900-3,000", "$1,000-3,500"],
        },
        {
          heading: "Roof insulation",
          cost: [
            "$2,000-6,000",
            "$1,900-5,500",
            "$1,800-5,000",
            "$2,000-6,000",
          ],
        },
        {
          heading: "Soundproofing",
          cost: [
            "$1,500-6,000",
            "$1,400-5,500",
            "$1,300-5,000",
            "$1,500-6,000",
          ],
        },
        {
          heading: "Pipe & duct insulation",
          cost: ["$500-2,000", "$450-1,800", "$400-1,700", "$500-2,000"],
        },
        {
          heading: "Spray foam insulation",
          cost: [
            "$2,000-7,000",
            "$1,900-6,500",
            "$1,800-6,000",
            "$2,000-7,000",
          ],
        },
        {
          heading: "Foil insulation",
          cost: ["$1,000-4,000", "$950-3,800", "$900-3,500", "$1,000-4,000"],
        },
        {
          heading: "Insulation replacement",
          cost: [
            "$1,500-5,000",
            "$1,400-4,800",
            "$1,300-4,500",
            "$1,500-5,000",
          ],
        },
        {
          heading: "Commercial insulation",
          cost: [
            "$10,000-50,000",
            "$9,500-48,000",
            "$9,000-45,000",
            "$10,000-50,000",
          ],
        },
      ],
    },
    {
      slug: "interior-design",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Residential design",
          cost: [
            "$2,000-10,000",
            "$1,800-9,500",
            "$1,500-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Commercial design",
          cost: [
            "$5,000-50,000",
            "$4,800-48,000",
            "$4,500-45,000",
            "$5,000-50,000",
          ],
        },
        {
          heading: "Space planning",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Color consultation",
          cost: ["$200-800", "$180-750", "$150-700", "$200-800"],
        },
        {
          heading: "Furniture sourcing",
          cost: ["$500-5,000", "$450-4,800", "$400-4,500", "$500-5,000"],
        },
        {
          heading: "Lighting design",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Bathroom design",
          cost: [
            "$2,000-10,000",
            "$1,800-9,500",
            "$1,500-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Kitchen design",
          cost: [
            "$3,000-15,000",
            "$2,800-14,000",
            "$2,500-13,000",
            "$3,000-15,000",
          ],
        },
        {
          heading: "Sustainable design",
          cost: [
            "$2,000-20,000",
            "$1,900-18,500",
            "$1,800-17,000",
            "$2,000-20,000",
          ],
        },
        {
          heading: "3D rendering",
          cost: ["$500-3,000", "$450-2,800", "$400-2,500", "$500-3,000"],
        },
      ],
    },
    {
      slug: "locksmiths",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Lock installation",
          cost: ["$100-400", "$90-380", "$80-350", "$100-400"],
        },
        {
          heading: "Lock repair",
          cost: ["$80-300", "$70-280", "$60-250", "$80-300"],
        },
        {
          heading: "Rekeying services",
          cost: ["$50-150", "$45-140", "$40-130", "$50-150"],
        },
        {
          heading: "Master key systems",
          cost: ["$500-2,000", "$450-1,900", "$400-1,800", "$500-2,000"],
        },
        {
          heading: "Key cutting",
          cost: ["$10-50", "$9-45", "$8-40", "$10-50"],
        },
        {
          heading: "Safe installation",
          cost: ["$500-5,000", "$480-4,800", "$450-4,500", "$500-5,000"],
        },
        {
          heading: "Digital lock systems",
          cost: ["$300-1,500", "$280-1,400", "$250-1,300", "$300-1,500"],
        },
        {
          heading: "Security upgrades",
          cost: ["$200-1,000", "$180-950", "$150-900", "$200-1,000"],
        },
        {
          heading: "Emergency lockout",
          cost: ["$100-400", "$90-380", "$80-350", "$100-400"],
        },
        {
          heading: "Window/gate locking",
          cost: ["$50-300", "$45-280", "$40-250", "$50-300"],
        },
      ],
    },
    {
      slug: "painting-and-decorating",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Interior painting",
          cost: [
            "$2,000-10,000",
            "$1,800-9,500",
            "$1,500-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Exterior painting",
          cost: [
            "$3,000-15,000",
            "$2,800-14,000",
            "$2,500-13,000",
            "$3,000-15,000",
          ],
        },
        {
          heading: "Wallpaper installation",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Decorative finishes",
          cost: [
            "$1,500-6,000",
            "$1,400-5,500",
            "$1,300-5,000",
            "$1,500-6,000",
          ],
        },
        {
          heading: "Spray painting",
          cost: [
            "$2,000-10,000",
            "$1,800-9,500",
            "$1,500-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Color consultation",
          cost: ["$200-800", "$180-750", "$150-700", "$200-800"],
        },
        {
          heading: "Feature walls",
          cost: ["$500-2,000", "$450-1,800", "$400-1,700", "$500-2,000"],
        },
        {
          heading: "Ceiling & trim painting",
          cost: ["$1,000-4,000", "$950-3,800", "$900-3,500", "$1,000-4,000"],
        },
        {
          heading: "Fence & deck painting",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Surface preparation",
          cost: ["$500-2,500", "$450-2,300", "$400-2,000", "$500-2,500"],
        },
      ],
    },
    {
      slug: "moving-and-storage",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Local residential moving",
          cost: ["$500-2,000", "$450-1,800", "$400-1,600", "$500-2,000"],
        },
        {
          heading: "Interstate moving",
          cost: [
            "$3,000-8,000",
            "$2,800-7,500",
            "$2,500-7,000",
            "$3,000-8,000",
          ],
        },
        {
          heading: "Commercial moving",
          cost: [
            "$1,500-10,000",
            "$1,400-9,500",
            "$1,300-9,000",
            "$1,500-10,000",
          ],
        },
        {
          heading: "Furniture removals",
          cost: ["$300-2,000", "$280-1,800", "$250-1,600", "$300-2,000"],
        },
        {
          heading: "Packing & unpacking",
          cost: ["$500-2,500", "$450-2,300", "$400-2,000", "$500-2,500"],
        },
        {
          heading: "Specialized item moving",
          cost: ["$800-5,000", "$750-4,800", "$700-4,500", "$800-5,000"],
        },
        {
          heading: "Short-term storage",
          cost: [
            "$100-400/month",
            "$90-380/month",
            "$80-350/month",
            "$100-400/month",
          ],
        },
        {
          heading: "Long-term storage",
          cost: [
            "$80-300/month",
            "$75-280/month",
            "$70-260/month",
            "$80-300/month",
          ],
        },
        {
          heading: "Moving supplies & boxes",
          cost: ["$50-300", "$45-280", "$40-250", "$50-300"],
        },
        {
          heading: "Last-minute moving",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
      ],
    },
    {
      slug: "mold-and-asbestos",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Mould inspection & testing",
          cost: ["$200-800", "$180-750", "$150-700", "$200-800"],
        },
        {
          heading: "Mould remediation",
          cost: ["$500-5,000", "$450-4,800", "$400-4,500", "$500-5,000"],
        },
        {
          heading: "Asbestos inspection & testing",
          cost: ["$300-1,200", "$280-1,100", "$250-1,000", "$300-1,200"],
        },
        {
          heading: "Asbestos removal",
          cost: [
            "$1,500-20,000",
            "$1,400-18,000",
            "$1,300-17,000",
            "$1,500-20,000",
          ],
        },
        {
          heading: "Asbestos disposal",
          cost: ["$500-5,000", "$450-4,800", "$400-4,500", "$500-5,000"],
        },
        {
          heading: "Air quality monitoring",
          cost: ["$200-1,000", "$180-950", "$150-900", "$200-1,000"],
        },
        {
          heading: "Preventive mould treatments",
          cost: ["$300-2,000", "$280-1,900", "$250-1,800", "$300-2,000"],
        },
        {
          heading: "Encapsulation of asbestos",
          cost: [
            "$2,000-10,000",
            "$1,800-9,500",
            "$1,700-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Dehumidification & ventilation",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Clearance certificates",
          cost: ["$200-800", "$180-750", "$150-700", "$200-800"],
        },
      ],
    },
    {
      slug: "pest-control",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Termite treatment",
          cost: ["$1,000-4,000", "$950-3,800", "$900-3,500", "$1,000-4,000"],
        },
        {
          heading: "Rodent control",
          cost: ["$200-800", "$180-750", "$150-700", "$200-800"],
        },
        {
          heading: "Insect control",
          cost: ["$150-600", "$140-550", "$120-500", "$150-600"],
        },
        {
          heading: "Bed bug treatment",
          cost: ["$500-2,000", "$450-1,800", "$400-1,700", "$500-2,000"],
        },
        {
          heading: "Flea & tick control",
          cost: ["$200-800", "$180-750", "$150-700", "$200-800"],
        },
        {
          heading: "Wasp & bee removal",
          cost: ["$250-1,000", "$230-950", "$200-900", "$250-1,000"],
        },
        {
          heading: "Bird control",
          cost: ["$500-2,000", "$450-1,800", "$400-1,700", "$500-2,000"],
        },
        {
          heading: "Fumigation services",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "End-of-lease treatment",
          cost: ["$150-500", "$140-480", "$120-450", "$150-500"],
        },
        {
          heading: "Pre-construction barrier",
          cost: [
            "$2,000-10,000",
            "$1,900-9,500",
            "$1,800-9,000",
            "$2,000-10,000",
          ],
        },
      ],
    },
    {
      slug: "plastering",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Wall plastering",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Ceiling plastering",
          cost: [
            "$1,500-6,000",
            "$1,400-5,500",
            "$1,300-5,000",
            "$1,500-6,000",
          ],
        },
        {
          heading: "Drywall installation",
          cost: [
            "$2,000-8,000",
            "$1,800-7,500",
            "$1,500-7,000",
            "$2,000-8,000",
          ],
        },
        {
          heading: "Repairs & patching",
          cost: ["$200-1,500", "$180-1,400", "$150-1,200", "$200-1,500"],
        },
        {
          heading: "Skim coating",
          cost: ["$1,000-4,000", "$950-3,800", "$900-3,500", "$1,000-4,000"],
        },
        {
          heading: "Rendering",
          cost: [
            "$3,000-12,000",
            "$2,800-11,500",
            "$2,500-10,500",
            "$3,000-12,000",
          ],
        },
        {
          heading: "Cornice installation",
          cost: ["$500-2,000", "$450-1,800", "$400-1,700", "$500-2,000"],
        },
        {
          heading: "Partition walls",
          cost: [
            "$2,000-10,000",
            "$1,900-9,500",
            "$1,800-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Plaster moulding",
          cost: ["$1,000-6,000", "$950-5,500", "$900-5,000", "$1,000-6,000"],
        },
        {
          heading: "Fire-resistant plaster",
          cost: [
            "$2,000-8,000",
            "$1,900-7,500",
            "$1,800-7,000",
            "$2,000-8,000",
          ],
        },
      ],
    },
    {
      slug: "plumbing",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Maintenance",
          cost: ["$150-300", "$140-280", "$130-260", "$150-300"],
        },
        {
          heading: "Pipe repair",
          cost: ["$100-200", "$90-180", "$80-160", "$100-200"],
        },
        {
          heading: "Drain cleaning",
          cost: ["$300-500", "$280-480", "$260-450", "$300-500"],
        },
        {
          heading: "Water heater",
          cost: ["$1,000-2,500", "$900-2,400", "$850-2,200", "$1,000-2,500"],
        },
        {
          heading: "Leak detection",
          cost: ["$200-400", "$180-360", "$160-320", "$200-400"],
        },
        {
          heading: "Gas fitting",
          cost: ["$300-700", "$280-650", "$260-600", "$300-700"],
        },
        {
          heading: "Bathroom plumbing",
          cost: [
            "$2,500-7,000",
            "$2,300-6,500",
            "$2,200-6,200",
            "$2,500-7,000",
          ],
        },
        {
          heading: "Emergency (per hr)",
          cost: ["$150-250", "$140-230", "$130-220", "$150-250"],
        },
        {
          heading: "Sewer repair",
          cost: [
            "$3,000-6,000",
            "$2,800-5,500",
            "$2,700-5,200",
            "$3,000-6,000",
          ],
        },
      ],
    },
    {
      slug: "pools-and-spas",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Pool installation",
          cost: [
            "$20,000-80,000",
            "$18,000-75,000",
            "$17,000-70,000",
            "$20,000-80,000",
          ],
        },
        {
          heading: "Spa installation",
          cost: [
            "$5,000-25,000",
            "$4,800-23,000",
            "$4,500-22,000",
            "$5,000-25,000",
          ],
        },
        {
          heading: "Pool maintenance",
          cost: [
            "$100-500/month",
            "$90-450/month",
            "$80-400/month",
            "$100-500/month",
          ],
        },
        {
          heading: "Spa maintenance",
          cost: [
            "$100-400/month",
            "$90-380/month",
            "$80-350/month",
            "$100-400/month",
          ],
        },
        {
          heading: "Pool repairs",
          cost: ["$200-5,000", "$180-4,800", "$150-4,500", "$200-5,000"],
        },
        {
          heading: "Spa repairs",
          cost: ["$200-4,000", "$180-3,800", "$150-3,500", "$200-4,000"],
        },
        {
          heading: "Pool renovations",
          cost: [
            "$10,000-50,000",
            "$9,500-48,000",
            "$9,000-45,000",
            "$10,000-50,000",
          ],
        },
        {
          heading: "Spa upgrades",
          cost: ["$1,000-10,000", "$950-9,500", "$900-9,000", "$1,000-10,000"],
        },
        {
          heading: "Pool safety inspection",
          cost: ["$150-500", "$140-480", "$120-450", "$150-500"],
        },
        {
          heading: "Heating & solar systems",
          cost: [
            "$3,000-15,000",
            "$2,800-14,000",
            "$2,500-13,000",
            "$3,000-15,000",
          ],
        },
      ],
    },
    {
      slug: "rendering",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Cement rendering",
          cost: [
            "$3,000-12,000",
            "$2,800-11,500",
            "$2,500-10,500",
            "$3,000-12,000",
          ],
        },
        {
          heading: "Acrylic rendering",
          cost: [
            "$4,000-15,000",
            "$3,800-14,500",
            "$3,500-13,500",
            "$4,000-15,000",
          ],
        },
        {
          heading: "Textured finishes",
          cost: [
            "$5,000-18,000",
            "$4,800-17,000",
            "$4,500-16,000",
            "$5,000-18,000",
          ],
        },
        {
          heading: "Bagging",
          cost: [
            "$2,000-8,000",
            "$1,900-7,500",
            "$1,800-7,000",
            "$2,000-8,000",
          ],
        },
        {
          heading: "Lime rendering",
          cost: [
            "$4,000-15,000",
            "$3,800-14,500",
            "$3,500-13,500",
            "$4,000-15,000",
          ],
        },
        {
          heading: "Colored rendering",
          cost: [
            "$4,000-16,000",
            "$3,800-15,500",
            "$3,500-14,500",
            "$4,000-16,000",
          ],
        },
        {
          heading: "Polished rendering",
          cost: [
            "$5,000-20,000",
            "$4,800-19,000",
            "$4,500-18,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Foam cladding rendering",
          cost: [
            "$5,000-20,000",
            "$4,800-19,000",
            "$4,500-18,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Repair & restoration",
          cost: ["$500-5,000", "$450-4,800", "$400-4,500", "$500-5,000"],
        },
        {
          heading: "Insulated rendering",
          cost: [
            "$8,000-25,000",
            "$7,500-24,000",
            "$7,000-23,000",
            "$8,000-25,000",
          ],
        },
      ],
    },
    {
      slug: "renovation-and-restoration",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Home renovations",
          cost: [
            "$20,000-100,000",
            "$18,000-95,000",
            "$17,000-90,000",
            "$20,000-100,000",
          ],
        },
        {
          heading: "Commercial renovations",
          cost: [
            "$30,000-200,000",
            "$28,000-190,000",
            "$25,000-180,000",
            "$30,000-200,000",
          ],
        },
        {
          heading: "Heritage restoration",
          cost: [
            "$50,000-300,000",
            "$45,000-280,000",
            "$40,000-260,000",
            "$50,000-300,000",
          ],
        },
        {
          heading: "Structural repairs",
          cost: [
            "$5,000-50,000",
            "$4,800-48,000",
            "$4,500-45,000",
            "$5,000-50,000",
          ],
        },
        {
          heading: "Bathroom renovations",
          cost: [
            "$10,000-30,000",
            "$9,500-28,000",
            "$9,000-27,000",
            "$10,000-30,000",
          ],
        },
        {
          heading: "Kitchen renovations",
          cost: [
            "$15,000-50,000",
            "$14,000-48,000",
            "$13,000-45,000",
            "$15,000-50,000",
          ],
        },
        {
          heading: "Roof restoration",
          cost: [
            "$5,000-20,000",
            "$4,800-18,000",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Facade restoration",
          cost: [
            "$10,000-60,000",
            "$9,500-58,000",
            "$9,000-55,000",
            "$10,000-60,000",
          ],
        },
        {
          heading: "Water damage restoration",
          cost: [
            "$5,000-25,000",
            "$4,800-24,000",
            "$4,500-23,000",
            "$5,000-25,000",
          ],
        },
        {
          heading: "Accessibility upgrades",
          cost: [
            "$5,000-50,000",
            "$4,800-48,000",
            "$4,500-45,000",
            "$5,000-50,000",
          ],
        },
      ],
    },
    {
      slug: "roofing",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Roof installation",
          cost: [
            "$10,000-50,000",
            "$9,500-48,000",
            "$9,000-45,000",
            "$10,000-50,000",
          ],
        },
        {
          heading: "Roof replacement",
          cost: [
            "$12,000-60,000",
            "$11,000-58,000",
            "$10,500-55,000",
            "$12,000-60,000",
          ],
        },
        {
          heading: "Roof repairs",
          cost: ["$500-10,000", "$450-9,500", "$400-9,000", "$500-10,000"],
        },
        {
          heading: "Roof restoration",
          cost: [
            "$5,000-20,000",
            "$4,800-18,000",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Guttering & downpipes",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Roof insulation",
          cost: [
            "$2,000-10,000",
            "$1,900-9,500",
            "$1,800-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Roof cleaning",
          cost: ["$500-2,000", "$450-1,800", "$400-1,700", "$500-2,000"],
        },
        {
          heading: "Skylight installation",
          cost: [
            "$1,500-7,000",
            "$1,400-6,500",
            "$1,300-6,000",
            "$1,500-7,000",
          ],
        },
        {
          heading: "Roof ventilation",
          cost: ["$800-3,000", "$750-2,800", "$700-2,500", "$800-3,000"],
        },
        {
          heading: "Metal roofing",
          cost: [
            "$15,000-70,000",
            "$14,000-68,000",
            "$13,000-65,000",
            "$15,000-70,000",
          ],
        },
      ],
    },
    {
      slug: "scaffolding",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Scaffold hire",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Scaffold installation",
          cost: [
            "$2,000-10,000",
            "$1,900-9,500",
            "$1,800-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Mobile scaffolding",
          cost: ["$1,000-4,000", "$950-3,800", "$900-3,500", "$1,000-4,000"],
        },
        {
          heading: "Suspended scaffolding",
          cost: [
            "$5,000-20,000",
            "$4,800-18,500",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Tube & fitting scaffolding",
          cost: [
            "$3,000-12,000",
            "$2,800-11,500",
            "$2,500-11,000",
            "$3,000-12,000",
          ],
        },
        {
          heading: "Modular scaffolding",
          cost: [
            "$2,500-10,000",
            "$2,300-9,500",
            "$2,200-9,000",
            "$2,500-10,000",
          ],
        },
        {
          heading: "Scaffold inspection",
          cost: ["$200-1,000", "$180-950", "$150-900", "$200-1,000"],
        },
        {
          heading: "Heavy-duty scaffolding",
          cost: [
            "$5,000-25,000",
            "$4,800-23,500",
            "$4,500-22,000",
            "$5,000-25,000",
          ],
        },
        {
          heading: "Staircase scaffolding",
          cost: [
            "$3,000-12,000",
            "$2,800-11,500",
            "$2,500-11,000",
            "$3,000-12,000",
          ],
        },
        {
          heading: "Event scaffolding",
          cost: [
            "$2,000-15,000",
            "$1,900-14,000",
            "$1,800-13,000",
            "$2,000-15,000",
          ],
        },
      ],
    },
    {
      slug: "shopfitting",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Retail fit-outs",
          cost: [
            "$20,000-150,000",
            "$18,000-140,000",
            "$17,000-130,000",
            "$20,000-150,000",
          ],
        },
        {
          heading: "Cafe & restaurant fit-outs",
          cost: [
            "$30,000-200,000",
            "$28,000-190,000",
            "$25,000-180,000",
            "$30,000-200,000",
          ],
        },
        {
          heading: "Commercial fit-outs",
          cost: [
            "$50,000-300,000",
            "$45,000-280,000",
            "$40,000-260,000",
            "$50,000-300,000",
          ],
        },
        {
          heading: "Custom joinery",
          cost: [
            "$5,000-50,000",
            "$4,800-48,000",
            "$4,500-45,000",
            "$5,000-50,000",
          ],
        },
        {
          heading: "POS installations",
          cost: [
            "$3,000-20,000",
            "$2,800-19,000",
            "$2,500-18,000",
            "$3,000-20,000",
          ],
        },
        {
          heading: "Lighting solutions",
          cost: [
            "$2,000-15,000",
            "$1,900-14,000",
            "$1,800-13,000",
            "$2,000-15,000",
          ],
        },
        {
          heading: "Partitioning",
          cost: [
            "$5,000-40,000",
            "$4,800-38,000",
            "$4,500-35,000",
            "$5,000-40,000",
          ],
        },
        {
          heading: "Ceiling & flooring",
          cost: [
            "$10,000-60,000",
            "$9,500-58,000",
            "$9,000-55,000",
            "$10,000-60,000",
          ],
        },
        {
          heading: "Signage & branding",
          cost: [
            "$2,000-20,000",
            "$1,900-19,000",
            "$1,800-18,000",
            "$2,000-20,000",
          ],
        },
        {
          heading: "Security & surveillance",
          cost: [
            "$5,000-30,000",
            "$4,800-28,000",
            "$4,500-27,000",
            "$5,000-30,000",
          ],
        },
      ],
    },
    {
      slug: "skip-bins",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Residential skip bins",
          cost: ["$200-800", "$180-750", "$150-700", "$200-800"],
        },
        {
          heading: "Commercial skip bins",
          cost: ["$300-1,200", "$280-1,100", "$250-1,000", "$300-1,200"],
        },
        {
          heading: "Construction skip bins",
          cost: ["$500-2,500", "$450-2,300", "$400-2,000", "$500-2,500"],
        },
        {
          heading: "Green waste skip bins",
          cost: ["$200-800", "$180-750", "$150-700", "$200-800"],
        },
        {
          heading: "Mixed waste skip bins",
          cost: ["$300-1,500", "$280-1,400", "$250-1,300", "$300-1,500"],
        },
        {
          heading: "Heavy waste skip bins",
          cost: ["$500-3,000", "$450-2,800", "$400-2,500", "$500-3,000"],
        },
        {
          heading: "Roll-on roll-off bins",
          cost: ["$1,000-6,000", "$950-5,800", "$900-5,500", "$1,000-6,000"],
        },
        {
          heading: "Recycling bins",
          cost: ["$200-800", "$180-750", "$150-700", "$200-800"],
        },
        {
          heading: "Hazardous waste bins",
          cost: ["$800-4,000", "$750-3,800", "$700-3,500", "$800-4,000"],
        },
        {
          heading: "Bin delivery & pickup",
          cost: ["$100-400", "$90-380", "$80-350", "$100-400"],
        },
      ],
    },
    {
      slug: "solar-energy",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Residential solar system",
          cost: [
            "$5,000-15,000",
            "$4,800-14,000",
            "$4,500-13,000",
            "$5,000-15,000",
          ],
        },
        {
          heading: "Commercial solar system",
          cost: [
            "$20,000-100,000",
            "$18,000-95,000",
            "$17,000-90,000",
            "$20,000-100,000",
          ],
        },
        {
          heading: "Battery storage",
          cost: [
            "$5,000-20,000",
            "$4,800-18,000",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Solar inverter",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Grid-connected solar",
          cost: [
            "$6,000-20,000",
            "$5,800-19,000",
            "$5,500-18,000",
            "$6,000-20,000",
          ],
        },
        {
          heading: "Off-grid solar system",
          cost: [
            "$15,000-60,000",
            "$14,000-58,000",
            "$13,000-55,000",
            "$15,000-60,000",
          ],
        },
        {
          heading: "Hybrid solar system",
          cost: [
            "$10,000-40,000",
            "$9,500-38,000",
            "$9,000-35,000",
            "$10,000-40,000",
          ],
        },
        {
          heading: "Solar maintenance",
          cost: ["$200-1,000", "$180-950", "$150-900", "$200-1,000"],
        },
        {
          heading: "Solar repairs",
          cost: ["$500-5,000", "$450-4,800", "$400-4,500", "$500-5,000"],
        },
        {
          heading: "Commercial solar design",
          cost: [
            "$2,000-10,000",
            "$1,900-9,500",
            "$1,800-9,000",
            "$2,000-10,000",
          ],
        },
      ],
    },
    {
      slug: "staircases",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Custom staircase design",
          cost: [
            "$5,000-30,000",
            "$4,800-28,000",
            "$4,500-25,000",
            "$5,000-30,000",
          ],
        },
        {
          heading: "Timber staircase",
          cost: [
            "$4,000-20,000",
            "$3,800-19,000",
            "$3,500-18,000",
            "$4,000-20,000",
          ],
        },
        {
          heading: "Metal staircase",
          cost: [
            "$6,000-35,000",
            "$5,500-33,000",
            "$5,000-30,000",
            "$6,000-35,000",
          ],
        },
        {
          heading: "Glass balustrades",
          cost: [
            "$2,000-10,000",
            "$1,900-9,500",
            "$1,800-9,000",
            "$2,000-10,000",
          ],
        },
        {
          heading: "Floating staircase",
          cost: [
            "$10,000-50,000",
            "$9,500-48,000",
            "$9,000-45,000",
            "$10,000-50,000",
          ],
        },
        {
          heading: "Staircase renovation",
          cost: [
            "$2,000-15,000",
            "$1,900-14,000",
            "$1,800-13,000",
            "$2,000-15,000",
          ],
        },
        {
          heading: "Outdoor staircase",
          cost: [
            "$3,000-20,000",
            "$2,800-18,000",
            "$2,500-17,000",
            "$3,000-20,000",
          ],
        },
        {
          heading: "Spiral staircase",
          cost: [
            "$5,000-25,000",
            "$4,800-24,000",
            "$4,500-22,000",
            "$5,000-25,000",
          ],
        },
        {
          heading: "Handrails & balustrades",
          cost: [
            "$1,500-10,000",
            "$1,400-9,500",
            "$1,300-9,000",
            "$1,500-10,000",
          ],
        },
        {
          heading: "Accessibility ramps",
          cost: [
            "$3,000-15,000",
            "$2,800-14,000",
            "$2,500-13,000",
            "$3,000-15,000",
          ],
        },
      ],
    },
    {
      slug: "stonemasonry",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Stone wall construction",
          cost: [
            "$5,000-30,000",
            "$4,800-28,000",
            "$4,500-25,000",
            "$5,000-30,000",
          ],
        },
        {
          heading: "Stone cladding",
          cost: [
            "$3,000-20,000",
            "$2,800-18,000",
            "$2,500-17,000",
            "$3,000-20,000",
          ],
        },
        {
          heading: "Heritage restoration",
          cost: [
            "$10,000-100,000",
            "$9,500-95,000",
            "$9,000-90,000",
            "$10,000-100,000",
          ],
        },
        {
          heading: "Stone paving",
          cost: [
            "$3,000-25,000",
            "$2,800-23,000",
            "$2,500-22,000",
            "$3,000-25,000",
          ],
        },
        {
          heading: "Stone fireplaces",
          cost: [
            "$5,000-20,000",
            "$4,800-18,000",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Granite & marble benchtops",
          cost: [
            "$2,000-15,000",
            "$1,900-14,000",
            "$1,800-13,000",
            "$2,000-15,000",
          ],
        },
        {
          heading: "Stone sculpture",
          cost: ["$1,000-10,000", "$950-9,500", "$900-9,000", "$1,000-10,000"],
        },
        {
          heading: "Stone repair & repointing",
          cost: ["$500-5,000", "$450-4,800", "$400-4,500", "$500-5,000"],
        },
        {
          heading: "Stone steps & staircases",
          cost: [
            "$4,000-25,000",
            "$3,800-23,000",
            "$3,500-22,000",
            "$4,000-25,000",
          ],
        },
        {
          heading: "Memorials & monuments",
          cost: [
            "$3,000-50,000",
            "$2,800-48,000",
            "$2,500-45,000",
            "$3,000-50,000",
          ],
        },
      ],
    },
    {
      slug: "tiling",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Floor tiling",
          cost: ["$50-150/sqm", "$45-140/sqm", "$40-130/sqm", "$50-150/sqm"],
        },
        {
          heading: "Wall tiling",
          cost: ["$40-120/sqm", "$35-110/sqm", "$30-100/sqm", "$40-120/sqm"],
        },
        {
          heading: "Bathroom tiling",
          cost: [
            "$3,000-10,000",
            "$2,800-9,500",
            "$2,500-9,000",
            "$3,000-10,000",
          ],
        },
        {
          heading: "Kitchen splashbacks",
          cost: ["$500-3,000", "$450-2,800", "$400-2,500", "$500-3,000"],
        },
        {
          heading: "Outdoor tiling",
          cost: ["$60-200/sqm", "$55-180/sqm", "$50-160/sqm", "$60-200/sqm"],
        },
        {
          heading: "Tile repair & replacement",
          cost: ["$200-1,500", "$180-1,400", "$150-1,300", "$200-1,500"],
        },
        {
          heading: "Mosaic tiling",
          cost: ["$80-300/sqm", "$75-280/sqm", "$70-260/sqm", "$80-300/sqm"],
        },
        {
          heading: "Grout cleaning & sealing",
          cost: ["$5-20/sqm", "$4.50-18/sqm", "$4-16/sqm", "$5-20/sqm"],
        },
        {
          heading: "Waterproofing for tiling",
          cost: ["$30-80/sqm", "$28-75/sqm", "$25-70/sqm", "$30-80/sqm"],
        },
        {
          heading: "Commercial tiling",
          cost: ["$50-200/sqm", "$45-180/sqm", "$40-160/sqm", "$50-200/sqm"],
        },
      ],
    },
    {
      slug: "waterproofing",
      Mainheading: "Cost guide by category",
      table: [
        {
          heading: "Bathroom waterproofing",
          cost: ["$1,000-4,000", "$950-3,800", "$900-3,500", "$1,000-4,000"],
        },
        {
          heading: "Balcony waterproofing",
          cost: [
            "$2,000-7,000",
            "$1,900-6,500",
            "$1,800-6,000",
            "$2,000-7,000",
          ],
        },
        {
          heading: "Roof waterproofing",
          cost: [
            "$3,000-10,000",
            "$2,800-9,500",
            "$2,500-9,000",
            "$3,000-10,000",
          ],
        },
        {
          heading: "Basement waterproofing",
          cost: [
            "$5,000-20,000",
            "$4,800-18,000",
            "$4,500-17,000",
            "$5,000-20,000",
          ],
        },
        {
          heading: "Retaining wall waterproofing",
          cost: [
            "$2,000-8,000",
            "$1,900-7,500",
            "$1,800-7,000",
            "$2,000-8,000",
          ],
        },
        {
          heading: "Shower waterproofing",
          cost: ["$500-2,500", "$450-2,300", "$400-2,000", "$500-2,500"],
        },
        {
          heading: "Deck & patio waterproofing",
          cost: [
            "$1,500-6,000",
            "$1,400-5,500",
            "$1,300-5,000",
            "$1,500-6,000",
          ],
        },
        {
          heading: "Concrete waterproofing",
          cost: ["$1,000-5,000", "$950-4,800", "$900-4,500", "$1,000-5,000"],
        },
        {
          heading: "Pool waterproofing",
          cost: [
            "$3,000-15,000",
            "$2,800-14,000",
            "$2,500-13,000",
            "$3,000-15,000",
          ],
        },
        {
          heading: "Leaking balcony/roof repairs",
          cost: [
            "$1,500-8,000",
            "$1,400-7,500",
            "$1,300-7,000",
            "$1,500-8,000",
          ],
        },
      ],
    },
  ];
  const categoryData = data.filter((item) => item.slug === slug);
  return (
    <>
      {categoryData.map((item) => {
        return (
          <>
            <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1075px] mt-10 sm:mt-40">
              <div className="text-offblack">
                <Heading variant={"h2"}>{item.Mainheading}</Heading>
              </div>
              <div className="mt-10 max-w-[800px] mx-auto">
                <div className="grid grid-cols-6 border border-r-0 border-mediumgray xl:w-full">
                  <div className="col-span-2 flex items-center justify-center border-r border-r-mediumgray">
                    <SecondaryHeading
                      variant={"MainParagraphHeading"}
                      className={"text-offblack font-semibold"}
                    >
                      Service
                    </SecondaryHeading>
                  </div>
                  {headings.map((e, index) => {
                    return (
                      <>
                        <div className="col-span-1 flex items-center justify-center border-r border-r-mediumgray py-1">
                          <SecondaryHeading
                            variant={"MainParagraphHeading"}
                            key={index}
                            className={"text-offblack font-semibold"}
                          >
                            {e}
                          </SecondaryHeading>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="grid grid-cols-6 xl:w-full border border-t-0 border-b-0 border-mediumgray">
                  {item.table.map((e, index) => {
                    return (
                      <>
                        <div
                          key={index}
                          className="col-span-2 border-b border-b-mediumgray flex items-center justify-center"
                        >
                          <Paragraph
                            variant={"MainParagraph"}
                            className={"text-offblack py-2 text-center"}
                          >
                            &nbsp;{e.heading}
                          </Paragraph>
                        </div>
                        {e.cost.map((item, index) => {
                          return (
                            <>
                              <div
                                key={index}
                                className="col-span-1 border-b border-l border-b-mediumgray border-l-mediumgray py-2 flex items-center justify-center"
                              >
                                <Paragraph
                                  variant={"MainParagraph"}
                                  className={"text-offblack text-center"}
                                >
                                  {item}
                                </Paragraph>
                              </div>
                            </>
                          );
                        })}
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="mx-auto max-w-fit">
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className={"text-offblack mt-3 "}
                >
                  {/* <span className="font-semibold">Disclaimer</span>&nbsp;&nbsp; */}
                  Cost are estimates only; obtain a detailed quote for accuracy.
                </SpecialText>
              </div>
            </div>
          </>
        );
      })}
    </>
  );
};

export default PricingEstimates;
