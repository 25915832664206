import React from "react";
import Master from "../../../assets/img/payment/master.svg";
import Visa from "../../../assets/img/payment/visa.svg";
import Cash from "../../../assets/img/payment/cash.svg";
import Amex from "../../../assets/img/payment/amex.svg";
import Bank from "../../../assets/img/payment/banktransfer.svg";
import Afterpay from "../../../assets/img/payment/afterpay.svg";
import Paypal from "../../../assets/img/payment/paypal.svg";
import Googlepay from "../../../assets/img/payment/googlepay.svg";
import Applepay from "../../../assets/img/payment/applepay.svg";
import ZipPay from "../../../assets/img/payment/zippay.svg";
import humm from "../../../assets/img/payment/humm.svg";
import klarna from "../../../assets/img/payment/klarna.svg";
import PaymentComp from "../../../sections/BusinessDashboard/Settings/PaymentComp";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";

const Payment = () => {
  const paymentMethods = [
    {
      method: "Master",
      image: Master,
      status: "Remove",
    },
    {
      method: "Visa",
      image: Visa,
      status: "Add",
    },
    {
      method: "Deposit",
      image: Bank,
      status: "Add",
    },
    {
      method: "Amex",
      image: Amex,
      status: "Add",
    },
    {
      method: "Cash",
      image: Cash,
      status: "Add",
    },
    {
      method: "Apple Pay",
      image: Applepay,
      status: "Add",
    },
    {
      method: "Google Pay",
      image: Googlepay,
      status: "Add",
    },
    {
      method: "PayPal",
      image: Paypal,
      status: "Add",
    },
    {
      method: "Afterpay",
      image: Afterpay,
      status: "Add",
    },
    {
      method: "ZipPay",
      image: ZipPay,
      status: "Add",
    },
    {
      method: "humm",
      image: humm,
      status: "Add",
    },
    {
      method: "Klarna",
      image: klarna,
      status: "Add",
    },
  ];
  return (
    <>
      <DashboardMainContainer className={"lg:px-5 lg:pb-10 lg:pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center text-offblack"}>
          Payment options
        </Heading>
        <div className="mt-[60px] min-h-screen">
          <div className="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-4 2k:grid-cols-6 4k:grid-cols-7 justify-between mx-[5%] lg:mx-auto lg:w-[600px] xl:w-[680px] 2xl:w-[680px] 2k:w-[1000px] 4k:w-[1200px] gap-y-16">
            {paymentMethods?.map((item, index) => {
              return (
                <>
                  <PaymentComp
                    key={index}
                    method={item.method}
                    image={item.image}
                    status={item.status}
                  />
                </>
              );
            })}
          </div>
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default Payment;
