import React from "react";
import "swiper/css";
import MapView from "../../components/common/Map/MapView";
import Heading from "../../components/common/Typography/Heading";
import { useParams } from "react-router-dom";

const ServicesMap = () => {
  const { slug } = useParams();
  const data = [
    {
      slug: "architectural",
      heading: "Architectural services near you",
    },
    {
      slug: "air-conditioning-and-heating",
      heading: "Air conditioning & heating services near you",
    },
    {
      slug: "bricklaying",
      heading: "Bricklaying services near you",
    },
    {
      slug: "cabinet-making",
      heading: "Cabinet making services near you",
    },
    {
      slug: "carpentry",
      heading: "Carpentry services near you",
    },
    {
      slug: "carpet-and-flooring",
      heading: "Carpet & flooring services near you",
    },
    {
      slug: "caulking",
      heading: "Caulking services near you",
    },
    {
      slug: "cleaning-services",
      heading: "Cleaning services near you",
    },
    {
      slug: "concrete-and-paving",
      heading: "Concrete & paving services near you",
    },
    {
      slug: "conveyancing",
      heading: "Conveyancing services near you",
    },
    {
      slug: "blinds-and-curtains",
      heading: "Curtains, blinds & awnings services near you",
    },
    {
      slug: "decking-and-gazebos",
      heading: "Decking, gazebo & outdoor structures services near you",
    },
    {
      slug: "demolition-and-excavation",
      heading: "Demolition & excavation services near you",
    },
    {
      slug: "electrical",
      heading: "Electrical services near you",
    },
    {
      slug: "engineering-services",
      heading: "Engineering services near you",
    },
    {
      slug: "fence-and-gates",
      heading: "Fencing & gate services near you",
    },
    {
      slug: "glazing-and-screens",
      heading: "Glazing & screen services near you",
    },
    {
      slug: "garden-and-landscaping",
      heading: "Garden & landscaping services near you",
    },
    {
      slug: "handyman",
      heading: "Handyman services near you",
    },
    {
      slug: "inspection-and-surveyors",
      heading: "Inspection & surveyor services near you",
    },
    {
      slug: "security-and-automation",
      heading: "Home security & automation services near you",
    },
    {
      slug: "insulation-services",
      heading: "Insulation services near you",
    },
    {
      slug: "interior-design",
      heading: "Interior design services near you",
    },
    {
      slug: "locksmiths",
      heading: "Locksmith services near you",
    },
    {
      slug: "painting-and-decorating",
      heading: "Painting & decoration services near you",
    },
    {
      slug: "moving-and-storage",
      heading: "Moving services near you",
    },
    {
      slug: "mold-and-asbestos",
      heading: "Mould & asbestos services near you",
    },

    {
      slug: "pest-control",
      heading: "Pest control services near you",
    },
    {
      slug: "plastering",
      heading: "Plastering services near you",
    },
    {
      slug: "plumbing",
      heading: "Plumbing services near you",
    },
    {
      slug: "pools-and-spas",
      heading: "Find services nearby",
    },
    {
      slug: "rendering",
      heading: "Rendering services near you",
    },
    {
      slug: "renovation-and-restoration",
      heading: "Renovation & restoration services near you",
    },
    {
      slug: "roofing",
      heading: "Roofing services near you",
    },
    {
      slug: "scaffolding",
      heading: "Scaffolding services near you",
    },
    {
      slug: "shopfitting",
      heading: "Shopfitting services near you",
    },
    {
      slug: "skip-bins",
      heading: "Skip bin services near you",
    },
    {
      slug: "solar-energy",
      heading: "Solar energy services near you",
    },
    {
      slug: "staircases",
      heading: "Staircase services near you",
    },
    {
      slug: "stonemasonry",
      heading: "Stone installer services near you",
    },
    {
      slug: "tiling",
      heading: "Tiling services near you",
    },
    {
      slug: "waterproofing",
      heading: "Waterproofing services near you",
    },
  ];
  const categoryData = data.filter((item) => item.slug === slug);
  return (
    <>
      {categoryData.map((item, index) => {
        return (
          <>
            <div
              className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] mt-10 sm:mt-40"
              key={index}
            >
              <div className="flex items-center justify-between">
                <Heading
                  variant={"h2"}
                  className={"flex justify-start text-start text-offblack"}
                >
                  {item.heading}
                </Heading>
              </div>
            </div>
            <div className="mx-[5%] xl:mx-[2%] 2xxl:mx-[5%] 3xl:mx-auto 3xl:w-[1748px] h-[500px] md:h-[700px] lg:h-[700px] xl:h-[800px] 2xl:h-[1000px] 3xl:h-[1100px] mt-7 sm:mt-10">
              <MapView />
            </div>
          </>
        );
      })}
    </>
  );
};

export default ServicesMap;
