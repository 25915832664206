import React from "react";
import BlogCard from "../../components/common/BlogCard/BlogCard";
import Heading from "../../components/common/Typography/Heading";

const Blogs = [
  {
    link: "",
    blogName: "Fixing a Leaky Tap: A Step-by-Step Guide",
    text: "Discover the simple steps to tighten up that pesky leak yourself.",
  },
  {
    link: "",
    blogName: "When to Call a Plumber: Mixer Repair Edition",
    text: "Learn the signs that indicate it’s time to call in the professionals.",
  },
  {
    link: "",
    blogName: "Choosing the Right Mixer for Your Kitchen",
    text: "A comprehensive guide to selecting a mixer that combines functionality with style.",
  },
];
const BlogsMobile = [
  {
    link: "",
    blogName: "Fixing a Leaky Tap: A Step-by-Step Guide",
    text: "Discover the simple steps to tighten up that pesky leak yourself.",
  },
  {
    link: "",
    blogName: "When to Call a Plumber: Mixer Repair Edition",
    text: "Learn the signs that indicate it’s time to call in the professionals.",
  },
  {
    link: "",
    blogName: "Choosing the Right Mixer for Your Kitchen",
    text: "A comprehensive guide to selecting a mixer that combines functionality with style.",
  },
  {
    link: "",
    blogName: "Choosing the Right Mixer for Your Kitchen",
    text: "A comprehensive guide to selecting a mixer that combines functionality with style.",
  },
];

const Tips = () => {
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] mt-10 sm:mt-40">
        <div>
          <Heading variant={"h2"} className={"text-offblack"}>
          DIY tips & How to guides
          </Heading>
        </div>
      </div>

      <div className="flex justify-center mx-[5%] lg:hidden mt-7 sm:mt-10 lg:mt-20">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 justify-items-center w-full mx-auto gap-8">
          {BlogsMobile.map((e, index) => {
            return (
              <BlogCard
                link={e.link}
                key={index}
                blogName={e.blogName.split(/\s+/).slice(0, 9).join(" ")}
                text={e.text}
                blogTitle={e.blogTitle}
              />
            );
          })}
        </div>
      </div>
      {/* For Large screens */}
      <div className="hidden lg:flex justify-center mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] mt-20">
        <div className="grid lg:grid-cols-3 w-fit mx-auto gap-8">
          {Blogs.map((e, index) => {
            return (
              <BlogCard
                link={e.link}
                key={index}
                blogName={e.blogName.split(/\s+/).slice(0, 9).join(" ")}
                text={e.text}
                blogTitle={e.blogTitle}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Tips;
