/* eslint-disable default-case */
import React, { useState } from "react";
// import RUG from "react-upload-gallery";
import "react-upload-gallery/dist/style.css";
// import { ImgUrl } from "../../Apiconfig";
import { useEffect } from "react";
import MainButton from "../../components/common/MainButton/MainButton";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
const DashboardPortfolio = ({ setImageList, ImageList }) => {
  const [images, setImages] = useState([]);

  const handleSelect = ({ target: { files } }) => {
    let data = new FormData();
    // console.log("data", files);
    // console.log("ImageList", ImageList);
    setImageList([...files]);

    // data.append("file", files[0]);
  };

  useEffect(() => {
    setImages(
      ImageList.map((file) => {
        console.log("file", file);
        if (file.source) {
          return { url: file.source };
        }
        return { file, url: URL.createObjectURL(file) };
      })
    );
  }, [ImageList]);

  const deletImage = (index) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };
  const completion = 79.32;
  return (
    <>
      {/* <RUG
              rules={{
                limit: 10,
              }}
              accept={["jpg", "jpeg", "png", "jfif"]}
              action={`${ImgUrl}api/account/UploadFile`}
              initialState={ImageList}
              source={(response) => ImgUrl + response?.key}
              onChange={(images) => {
                const imageObject = images?.map((img) => img.source);
                setImageList([...imageObject]); // save current component
              }}
              onWarning={(type, rules) => {
                switch (type) {
                  case "limit":
                    console.log("limit <= ", rules.limit);
                }
              }}
            /> */}

      <label className={`block mb-2`}>
        <AuxiliaryText variant={"FieldLabel"} className={"text-offblack"}>
          Upload
        </AuxiliaryText>
      </label>
      <div className="flex items-center justify-center w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px]">
        <input
          type="file"
          id="imageslist"
          onChange={handleSelect}
          accept="image/*"
          className="cursor-pointer hidden"
          multiple
        />
        <label
          role="button"
          htmlFor="imageslist"
          className="h-[140px] w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] rounded-lg flex justify-center bg-white lg:bg-pastalblue"
        >
          <div className="my-auto">
            <div className="flex justify-center">
              <div className="flex justify-center items-center">
                <i className="fi fi-rs-inbox-out text-base text-offblack"></i>
              </div>
            </div>
            <div className="flex justify-center">
              <AuxiliaryText
                variant={"Placeholder"}
                className={"mt-1 text-primaryblue"}
              >
                Click to upload
              </AuxiliaryText>
            </div>
          </div>
        </label>
      </div>
      <div className="mt-10 w-full">
        <div className="flex gap-3 w-full">
          <i className="fi fi-br-picture text-[50px] text-primaryblue"></i>
          <div className="w-full flex flex-col gap-2 mt-5">
            <div className="flex items-center justify-between w-full">
              <AuxiliaryText variant={"FieldLabel"}>
                myprofile.png
              </AuxiliaryText>

              <AuxiliaryText variant={"FieldLabel"}>
                {completion}%
              </AuxiliaryText>
            </div>
            <div className="w-full h-1.5 bg-white lg:bg-pastalblue rounded-full">
              <div
                className={`h-full bg-primaryblue rounded-full`}
                style={{ width: `${completion}%` }}
              ></div>
            </div>
          </div>
          <i className="fi fi-rs-cross text-sm cursor-pointer mt-10"></i>
        </div>
        <div className="flex gap-3 w-full mt-5">
          <i className="fi fi-br-picture text-[50px] text-primaryblue"></i>
          <div className="w-full flex flex-col gap-2 mt-5">
            <div className="flex items-center justify-between w-full">
              <AuxiliaryText variant={"FieldLabel"}>
                myprofile.png
              </AuxiliaryText>

              <AuxiliaryText variant={"FieldLabel"}>
                {completion}%
              </AuxiliaryText>
            </div>
            <div className="w-full h-1.5 bg-white lg:bg-pastalblue rounded-full">
              <div
                className={`h-full bg-primaryblue rounded-full`}
                style={{ width: `${completion}%` }}
              ></div>
            </div>
          </div>
          <i className="fi fi-rs-cross text-sm cursor-pointer mt-10"></i>
        </div>
      </div>

      <div className="flex justify-end w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mt-10">
        <MainButton variant={"small"}>Submit</MainButton>
      </div>

      {/* <div className="flex flex-col gap-8 w-full sm:w-[350px] md:w-[380px] lg:w-[380px] xl:w-[420px] 2xl:w-[500px]">
        {images.map((image, index) => {
          return (
            <>
              <div className="relative" key={index}>
                <i
                  class="fi fi-sr-cross-circle text-[16px] text-primaryblue flex justify-end items-center right-0 absolute m-1 cursor-pointer"
                  onClick={() => deletImage(index)}
                  type="button"
                ></i>
                <img
                  className="w-[81px] h-[81px] rounded-lg"
                  src={image.url}
                  alt={""}
                />
              </div>
            </>
          );
        })}
      </div> */}
    </>
  );
};
export default DashboardPortfolio;
