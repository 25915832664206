import React, { useState } from "react";
import GeneralComponent from "../../../sections/BusinessDashboard/Settings/Associations/GeneraComponet";
import Heading from "../../../components/common/Typography/Heading";
import Paragraph from "../../../components/common/Typography/Paragraph";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";
import AuxiliaryText from "../../../components/common/Typography/AuxiliaryText";
import { TypeAll } from "../../../sections/BusinessDashboard/Settings/Associations/LatestData";

const Associations = () => {
  const [type, setType] = useState("A");
  const types = [
    { letter: "A" },
    { letter: "B" },
    { letter: "C" },
    { letter: "D" },
    { letter: "E" },
    { letter: "F" },
    { letter: "G" },
    { letter: "H" },
    { letter: "I" },
    { letter: "J" },
    { letter: "K" },
    { letter: "L" },
    { letter: "M" },
    { letter: "N" },
    { letter: "O" },
    { letter: "P" },
    { letter: "Q" },
    { letter: "R" },
    { letter: "S" },
    { letter: "T" },
    { letter: "U" },
    { letter: "V" },
    { letter: "W" },
    { letter: "X" },
    { letter: "Y" },
    { letter: "Z" },
    { letter: "ALL" },
  ];

  // Filter TypeAll to find the selected category's associationsList
  const selectedAssociationsList =
    TypeAll.find((category) => category.heading === type)?.associationsList ||
    [];

  return (
    <DashboardMainContainer className="lg:px-5 lg:pb-10 pt-[33px]">
      <Heading variant="h6" className="text-center text-offblack">
        Associations & memberships
      </Heading>

      <div className="mt-[60px] min-h-screen">
        <div className="grid grid-cols-12 gap-1 mb-10 lg:mx-auto lg:w-[600px] xl:w-[700px] 2xl:w-[800px] ">
          {types.map((e, index) => (
            <div
              key={index}
              className={`col-span-1 ${
                type === e.letter
                  ? "bg-primaryblue text-white"
                  : "bg-white lg:bg-pastalblue text-offblack"
              } flex items-center justify-center cursor-pointer hover:bg-offblack hover:text-white py-1`}
              onClick={() => setType(e.letter)}
            >
              <AuxiliaryText variant="FieldLabel">{e.letter}</AuxiliaryText>
            </div>
          ))}
        </div>

        {selectedAssociationsList.length > 0 ? (
          <div
            className={
              selectedAssociationsList.length === 1
                ? "grid grid-cols-1 w-fit mx-auto"
                : selectedAssociationsList.length === 2
                ? "grid grid-cols-2 w-fit gap-16 mx-auto"
                : "grid grid-cols-2 w-fit gap-16 mx-auto"
            }
          >
            {selectedAssociationsList.map((item, index) => (
              <GeneralComponent
                key={index}
                heading={item.heading}
                image={item.image}
                status={item.status}
              />
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-center justify-center mt-20">
            <Heading variant="h5" className="text-offblack">
              Sorry
            </Heading>
            <Paragraph
              variant="MainParagraph"
              className="text-offblack mt-3 text-center"
            >
              The requested Association type isn't available.
            </Paragraph>
          </div>
        )}
      </div>
    </DashboardMainContainer>
  );
};

export default Associations;
