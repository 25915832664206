import React from "react";
import { Link } from "react-router-dom";
import useAuth from "../../../Hooks/useAuth";
import MainButton from "../MainButton/MainButton";
import Paragraph from "../Typography/Paragraph";
import AuxiliaryText from "../Typography/AuxiliaryText";
import SpecialText from "../Typography/SpecialText";

const PriceCard = ({
  MainDiveClasses,
  tagging,
  tag,
  label,
  price,
  description,
  buttonText,
  listItems,
  listItemsValue,
  upgradePackageHandler,
  pricingColor,
  bottomLine,
  listClasses
}) => {
  const { auth } = useAuth();
  return (
    <>
      <div
        className={`rounded-3xl pt-5 pr-5 pl-5 xl:pl-[20px] 2xl:pl-[30px] sm:pt-[30px] xl:pt-[30px] xl:pr-[20px] 2xl:pt-[50px] 2xl:pr-[20px] ${MainDiveClasses}`}
      >
        <div className="flex items-center justify-between h-[20px]">
          <div>
            <AuxiliaryText variant={"FieldLabel"}>{label}</AuxiliaryText>
          </div>
          {!tagging && (
            <div className="bg-primaryblue text-white px-[2px] py-[1px]">
              <AuxiliaryText variant={"Infographic"}>Best Value</AuxiliaryText>
            </div>
          )}
        </div>
        <div className="flex h-fit">
          <SpecialText
            variant={"ExtraLargeNumber"}
            className={`${pricingColor}`}
          >
            {/* $00 */}
            ${price}
          </SpecialText>
          <sub className="ml-1 mt-auto mb-5">
            <AuxiliaryText variant={"Placeholder"}>/&nbsp; month</AuxiliaryText>
          </sub>
        </div>
        <div className={`mt-3 sm:mt-5 ${bottomLine}`}>
          <Paragraph
            variant={"MainParagraph"}
            className={"w-full h-[40px] xs:h-[59px] sm:h-[65px] lg:h-[41px] xl:h-[42px] 2xl:h-[46px] overflow-hidden text-ellipsis text-offblack"}
          >
            {description}
          </Paragraph>
        </div>
        <div className={`pt-3 sm:pt-5 xl:pt-5 2xl:pt-10 ${listClasses}`}>
          <ul>
            {listItems.map((item, index) => {
              return (
                <li
                  key={index}
                  className="flex items-center h-4capitalize mt-1 sm:mt-3"
                >
                  <i className="fi fi-rs-check text-sm text-primaryblue mr-5 my-auto"></i>
                  <Paragraph variant={"MainParagraph"}>
                    {item} &nbsp; {listItemsValue?.[index] ?? ""}
                  </Paragraph>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex justify-center sticky top-[100vh]">
          <Link
            to={"/business-dashboard/subscriptions/billing-details"}
            // to={
            //   auth?.roles?.find((f) => f.roleName === "User")
            //     ? ""
            //     : "/business-dashboard/subscriptions/billing-details"
            // }
          >
            <MainButton
              onClick={() => upgradePackageHandler(listItems?.id)}
              variant={"extralarge"}
            >
              {buttonText}
            </MainButton>
          </Link>
        </div>
      </div>
    </>
  );
};

export default PriceCard;
