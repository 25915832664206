import React from "react";
import StoriesLayout from "../sections/BusinessStories/StoriesLayout";
import Banner from "../components/common/Banners/Banner";
import MainButton from "../components/common/MainButton/MainButton";

const BusinessStories = () => {
  return (
    <>
      <Banner
        headingClasses={
          "text-center sm:text-center sm:text-start text-white xl:w-[900px] 2xl:w-[1000px]"
        }
        heading={"Inspiring Journeys of Trade Businesses"}
        className={"bg-about"}
      >
        <MainButton variant={"extralarge"} className={"mt-10"}>
          Create Your Business Story
        </MainButton>
      </Banner>
      <StoriesLayout />
    </>
  );
};

export default BusinessStories;
