/* eslint-disable no-unused-vars */
import React from "react";
import { useLocation } from "react-router-dom";
import Spinner from "../components/common/Spinner";
import useAuth from "../Hooks/useAuth";
import {
  usePopularListingOfRelatedListing,
  useRelatedListingOfSelectedListing,
} from "../Hooks/useListing";
import {
  useAddRecentViwed,
  useServicesById,
  useServicesDetail,
} from "../Hooks/useServices";
// import Section1 from "../sections/ServiceDetails/Section1";
// import Section2 from "../sections/ServiceDetails/Section2";
// import Section3 from "../sections/ServiceDetails/Section3";
import ServiceDetailsContainer from "../sections/ServiceDetails/ServiceDetailsContainer";

const ServiceDetail = () => {
  function useParams() {
    return new URLSearchParams(useLocation().search);
  }
  const params = useParams();
  const ListingId = params.get("id");
  const { data, status } = useServicesDetail(ListingId);
  const { auth } = useAuth();
  let UserProfile = data?.data?.key?.userProfile;
  const { status: AuthnicateStatus, data: AuthnicateData } = useServicesById(
    auth?.user?.id
  );
  useAddRecentViwed(auth?.user?.id, ListingId);

  const { status: isLoadingPopularServices, data: PopularServices } =
    usePopularListingOfRelatedListing(ListingId, auth?.user?.id ?? null);
  const { status: isLoadingRelatedServices, data: RelatedServices } =
    useRelatedListingOfSelectedListing(ListingId, auth?.user?.id ?? null);
  return (
    <>
      <div className="pt-16 sm:pt-24">
        {status === "loading" && <Spinner />}
        {status === "success" && (
          <>
            <ServiceDetailsContainer
              data={data?.data?.key}
              ListingId={ListingId}
              AuthnicateData={AuthnicateData}
              listRating={parseInt(data?.data?.value)}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ServiceDetail;
