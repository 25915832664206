import React, { useEffect, useState } from "react";
import DefineCategory from "../../../sections/BusinessDashboard/Post/CreatePost/DefineCategory";
import SelectPostType from "../../../sections/BusinessDashboard/Post/CreatePost/SelectPostType";
import MainButton from "../../../components/common/MainButton/MainButton";
import LocationandPortfolio from "../../../sections/BusinessDashboard/Post/CreatePost/LocationandPortfolio";
import PortfolioUploading from "../../../sections/BusinessDashboard/Post/CreatePost/PortfolioUploading";
import Spinner from "../../../components/common/Spinner";
import { Flip, ToastContainer, toast } from "react-toastify";
import {
  AddProductListing,
  EditProductListing,
  GetRelatedListing,
} from "../../../Api/Listing";
import { useFormik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import {
  initial_values,
  validationSchema,
} from "../../../FormValidations/ListingValidationSchema";
import { useSelector } from "react-redux";
import { useSellerProfileInfo } from "../../../Hooks/useSellerProfileInfo";
import useAuth from "../../../Hooks/useAuth";
import { useUserBusinessCategories } from "../../../Hooks/useUserBusinessCategory";
import { useListingType } from "../../../Hooks/useBusinessCategories";
import PostReview from "../../../sections/BusinessDashboard/Post/CreatePost/PostReview";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";
import PostDetails from "../../../sections/BusinessDashboard/Post/CreatePost/PostDetails";
import InteractiveText from "../../../components/common/Typography/InteractiveText";
import Step1 from "./Stepper/Step1";
import Step2 from "./Stepper/Step2";
import Step3 from "./Stepper/Step3";
import Step4 from "./Stepper/Step4";
import Step5 from "./Stepper/Step5";
const LISTING_TYPE = {
  FEATURE: "featured",
  REGULAR: "regular",
};

const CreatePost = () => {
  const { auth } = useAuth();
  const [step, setStep] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [ImageList, setImageList] = useState([]);
  const navigate = useNavigate();
  const [FindListingType, setFindListingType] = useState();
  const [RequestStart, setRequetStart] = useState({
    isProcessing: false,
    isRequestSuccess: false,
    isRequestError: false,
  });

  const {
    // isLoading: IsLoadingUserBusinessCategories,
    data: UserBusinessCategories,
  } = useUserBusinessCategories(auth?.user?.id);
  const { data: listingType } = useListingType();
  const ListCategory = useSelector((cat) => cat.BusinessCategoryReducer);
  const params = new URLSearchParams(useLocation().search);
  const Type = params?.get("type");
  const listId = params?.get("id") ?? 0;

  const { isError, isLoading, data } = useSellerProfileInfo(auth?.user?.id);
  let { key } = data?.data ?? [];
  const [isFeature, setIsFeature] = useState(
    Type !== null
      ? Type?.toLocaleLowerCase() === LISTING_TYPE.FEATURE
        ? true
        : false
      : false
  );
  const [isRegular, setIsRegular] = useState(
    Type !== null
      ? Type?.toLocaleLowerCase() === LISTING_TYPE.REGULAR
        ? true
        : false
      : true
  );

  useEffect(() => {
    if (isFeature) {
      setFindListingType(
        listingType?.data?.key?.$values?.find(
          (item) => item?.type?.toLowerCase() === LISTING_TYPE.FEATURE
        )
      );
    } else {
      setFindListingType(
        listingType?.data?.key?.$values?.find(
          (item) => item?.type?.toLowerCase() === LISTING_TYPE.REGULAR
        )
      );
    }
  }, [isFeature, isRegular, listingType]);

  useEffect(() => {
    if (listId !== 0) {
      setIsFetching(true);
      GetRelatedListing(listId)
        .then((response) => {
          if (response.status === 200) {
            const image = JSON.parse(response?.data?.key?.pictures);
            for (let i = 0; i < image.length; i++) {
              setImageList((pre) => [
                ...pre,
                {
                  source: image?.[i],
                },
              ]);
            }
            formik.setFieldValue("id", listId);
            formik.setFieldValue(
              "listTitle",
              response?.data?.key?.listingTitle ?? ""
            );
            formik.setFieldValue(
              "listCategory",
              response?.data?.key?.listingCategory?.id ?? ""
            );

            formik.setFieldValue(
              "description",
              response?.data?.key?.description ?? ""
            );
            formik.setFieldValue(
              "createdOn",
              response?.data?.key?.createdOn ?? ""
            );
            formik.setFieldValue("prevNoOfImages", image?.length ?? 0);
            formik.setFieldValue(
              "listingTags",
              JSON?.parse(response?.data?.key?.tags) ?? []
            );
          }
          setIsFetching(false);
        })
        .catch((error) => {});
    }
  }, []);

  const formik = useFormik({
    initialValues: initial_values,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      setRequetStart({ ...RequestStart, isProcessing: true });
      values.listingImages = ImageList;
      const FindListCategory = ListCategory?.find(
        (item) => item?.id?.toString() === values?.listCategory?.toString()
      );
      const listObject = {
        Id: listId,
        listingTitle: values?.listTitle,
        description: values?.description,
        tags: JSON.stringify(values?.listingTags),
        // pictures: JSON.stringify(ImageList),
        // noOfImages: ImageList?.length,
        pictures: JSON.stringify([]),
        noOfImages: 0,
        prevNoOfImages: values?.prevNoOfImages,
        state: values?.state,
        createdOn: values?.createdOn ?? new Date(),
        modifiedOn: new Date(),
        createdBy: "",
        listingType: {
          id: FindListingType?.id,
          type: FindListingType?.type,
        },
        userProfile: data?.data?.key,
        listingCategory: {
          id: FindListCategory?.id,
          businessCategoryName: FindListCategory?.businessCategoryName,
        },
      };
      if (listId === 0) {
        AddProductListing(listObject)
          .then((res) => {
            if (res.status === 200) {
              setRequetStart({
                ...RequestStart,
                isProcessing: false,
              });
              toast.success("Post published", {
                position: toast.POSITION.TOP_RIGHT,
                transition: Flip,
              });
              navigate(-1);
            }
          })
          .catch((error) => {
            setRequetStart({
              ...RequestStart,
              isProcessing: false,
            });
            toast.error("Something Went Wrong", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          });
      } else {
        EditProductListing(listObject, listId)
          .then((res) => {
            setRequetStart({
              ...RequestStart,
              isProcessing: false,
            });
            toast.success("Posts Updated", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
            navigate(-1);
          })
          .catch((error) => {
            setRequetStart({
              ...RequestStart,
              isProcessing: false,
            });
            toast.error("Something Went Wrong", {
              position: toast.POSITION.TOP_RIGHT,
              transition: Flip,
            });
          });
      }
    },
  });

  return (
    <>
      <ToastContainer autoClose={3000} />
      <DashboardMainContainer
        className={"lg:px-5 lg:pb-10 pt-[33px] min-h-screen"}
      >
        <div>
          {isFetching && <Spinner />}
          {RequestStart.isProcessing && <Spinner />}
          {step === 1 && (
            <>
              <Heading variant={"h6"} className={"text-center text-offblack"}>
                Select type
              </Heading>
            </>
          )}
          {step === 2 && (
            <>
              <Heading variant={"h6"} className={"text-center text-offblack"}>
                Define category
              </Heading>
            </>
          )}
          {step === 3 && (
            <>
              <Heading variant={"h6"} className={"text-center text-offblack"}>
                Add details
              </Heading>
            </>
          )}
          {step === 4 && (
            <>
              <Heading variant={"h6"} className={"text-center text-offblack"}>
                Set location
              </Heading>
            </>
          )}
          {step === 5 && (
            <>
              <Heading variant={"h6"} className={"text-center text-offblack"}>
                Review and publish
              </Heading>
            </>
          )}
        </div>
        {step === 1 && (
          <>
            <SelectPostType
              nextStep={() => setStep(2)}
              isFeature={isFeature}
              setIsFeature={setIsFeature}
              isRegular={isRegular}
              setIsRegular={setIsRegular}
            />
          </>
        )}
        {step === 2 && (
          <>
            <DefineCategory
              formik={formik}
              UserBusinessCategories={UserBusinessCategories}
            />
          </>
        )}

        {step === 3 && (
          <>
            <PostDetails />
          </>
        )}
        {step === 4 && (
          <>
            <div>
              <LocationandPortfolio />
              <PortfolioUploading
                setImageList={setImageList}
                ImageList={ImageList}
              />
            </div>
          </>
        )}

        {step === 5 && (
          <>
            <PostReview
              formik={formik}
              type={isFeature ? "Featured" : "Regular"}
              data={key?.company}
            />
          </>
        )}
        <div
          className={
            step === 5
              ? "flex items-center justify-between mt-10 lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto"
              : "flex items-center mt-10 w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto justify-between"
          }
        >
          <div
            className={
              step === 1
                ? "flex items-center justify-between w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto"
                : step === 5
                ? "flex items-center justify-between w-full lg:w-[420px] xl:w-[420px] 2xl:w-[500px]"
                : "flex items-center justify-between w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px]"
            }
          >
            {step !== 1 && (
              <div onClick={() => setStep((state) => state - 1)}>
                {/* className="flex items-center gap-2 group h-4" */}
                {/* <i class="fi fi-rr-arrow-left text-base text-mediumgray group-hover:text-primaryblue pt-1"></i> */}
                <InteractiveText
                  variant={"ActionLink"}
                  className={
                    "text-mediumgray hover:text-primaryblue cursor-pointer"
                  }
                >
                  Back
                </InteractiveText>

                {/* <MainButton
                  disabled={step === 1}
                  onClick={() => setStep((state) => state - 1)}
                  variant={"outlinesmall"}
                >
                  Back
                </MainButton> */}
              </div>
            )}
            <div>
              {step < 5 && (
                <MainButton
                  disabled={step === 1}
                  onClick={() => setStep((state) => state + 1)}
                  variant={"small"}
                >
                  Next
                </MainButton>
              )}
              {step === 5 && (
                <MainButton onClick={formik.handleSubmit} variant={"small"}>
                  Publish
                </MainButton>
              )}
            </div>
          </div>
        </div>
        {step === 1 ? (
          <Step1 />
        ) : step === 2 ? (
          <Step2 />
        ) : step === 3 ? (
          <Step3 />
        ) : step === 4 ? (
          <Step4 />
        ) : (
          <Step5 />
        )}
      </DashboardMainContainer>
    </>
  );
};

export default CreatePost;
