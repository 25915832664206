import React from "react";
import Banner from "../components/common/Banners/Banner";
import Paragraphs from "../sections/BusinessSuccessStories/Paragraphs";
import PortfoliosSection from "../sections/BusinessSuccessStories/PortfoliosSection";

const BusinessSuccessStories = () => {
  return (
    <>
      <Banner
        headingClasses={
          "text-center sm:text-start text-white xl:w-[900px] 2xl:w-[1000px] "
        }
        heading={"Bringing Trades, Empowering Projects"}
        className={"bg-about"}
      />
      <Paragraphs />
      <PortfoliosSection />
    </>
  );
};

export default BusinessSuccessStories;
