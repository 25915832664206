import React, { useEffect, useState } from "react";
import ServicesMapView from "../sections/CustomerSupport/ServicesMapView";
import WhyTradesposter from "../sections/CustomerSupport/WhyTradesposter";
import SatisfiedClients from "../sections/CustomerSupport/SatisfiedClients";
import Banner from "../components/common/Banners/Banner";
import MainButton from "../components/common/MainButton/MainButton";
import Login from "../sections/Login/Login";
import { SignUp as SignUpRegister } from "../sections/SignUp/SignUp";
import HireWithConf from "../sections/CustomerSupport/HireWithConf";
import HelpSupport from "../sections/CustomerSupport/HelpSupport";
import ImageWithTopText from "../components/common/Generics/ImageWithTopText";
import FullLengthSlider from "../sections/AllServicesSlider/FullLengthSlider";

const CustomerSupport = () => {
  const [SignUp, setSignUp] = useState(false);
  const [SignIn, setSignIn] = useState(false);
  useEffect(() => {
    if (SignUp) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [SignUp]);
  return (
    <>
      <Banner
        headingClasses={
          "text-center sm:text-start text-white xl:w-[900px] 2xl:w-[1000px] "
        }
        heading={
          "Get Your Job Done Right"
        }
        className={"bg-about"}
      >
        <MainButton
          variant={"extralarge"}
          className={"mt-10"}
          onClick={() => setSignUp(true)}
        >
          Customer Sign Up
        </MainButton>
        {SignIn && (
          <Login
            SignIn={SignIn}
            setSignIn={setSignIn}
            SignUpState={SignUp}
            setSignUpState={setSignUp}
          />
        )}
        {SignUp && (
          <SignUpRegister
            SignUpState={SignUp}
            setSignUpState={setSignUp}
            SignIn={SignIn}
            setSignIn={setSignIn}
          />
        )}
      </Banner>
      <WhyTradesposter />
      <ImageWithTopText
        classNames={
          "mx-[5%] xl:mx-auto h-[500px] sm:h-auto xl:w-[1100px] 2xl:w-[1264px] 2xl:h-[700px] sm:aspect-[1264/700]"
        }
        TopText={
          "Get started by setting up your profile"
        }
      />
      <ImageWithTopText
        classNames={
          "mx-[5%] xl:mx-auto h-[500px] sm:h-auto xl:w-[1100px] 2xl:w-[1264px] 2xl:h-[700px] sm:aspect-[1264/700]"
        }
        TopText={
          "Browse, Connect, and Review"
        }
      />
      <ServicesMapView />
      <ImageWithTopText
        classNames={
          "mx-[5%] xl:mx-auto h-[500px] sm:h-auto xl:w-[1150px] 2xl:w-[1440px] 2xl:h-[900px] sm:aspect-[1440/900]"
        }
        TopText={
          "Easily contact businesses with our messaging system"
        }
      />
      <ImageWithTopText
        classNames={
          "mx-[5%] xl:mx-auto h-[500px] sm:h-auto xl:w-[1150px] 2xl:w-[1440px] 2xl:h-[900px] sm:aspect-[1440/900]"
        }
        TopText={
          "Manage everything in one place"
        }
      />
      <SatisfiedClients />
      <HireWithConf />
      <HelpSupport />
      <FullLengthSlider />
    </>
  );
};

export default CustomerSupport;
