import React from "react";
import SecondaryHeading from "../Typography/SecondaryHeading";
import Paragraph from "../Typography/Paragraph";

const Card = ({
  image,
  text,
  imgclassName,
  textclassName,
  paragraphNeed,
  paragraphText,
  cardClassNames
}) => {
  return (
    <>
      <div className={`bg-white rounded-xl p-3 sm:p-5 ${cardClassNames}`}>
        <div className="flex items-center gap-5">
          <img src={image} className={`shrink-0 ${imgclassName}`} alt="" />
          <div>
            <SecondaryHeading
              variant={"MainParagraphHeading"}
              className={`${textclassName}`}
            >
              {text}
            </SecondaryHeading>
            {paragraphNeed && (
              <Paragraph
                variant={"MainParagraph"}
                className="text-offblack mt-1 sm:mt-3"
              >
                {paragraphText}
              </Paragraph>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Card;
