import React from "react";
import Heading from "../../components/common/Typography/Heading";
import BigSlidesSlider from "../SuccessJourneySlider/BigSlidesSlider";

const SuccessSlider = () => {
  const data = [
    {
      subHeading: "Signing up is free and easy—join today and start growing your trade business with direct access to local customers.",
      // para: "connect with top-rated trades experts dedicated to quality and transparency. No fees, no hassle—just reliable service tailored to your needs.",
      buttonText: "Discover our services",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[140px] sm:w-[150px] lg:w-[165px] xl:w-[165px] 2xl:w-[175px] text-white",
      subClass: "group-hover:w-[145px] sm:group-hover:w-[155px] lg:group-hover:w-[170px] xl:group-hover:w-[170px] 2xl:group-hover:w-[180px]",
      link: "",
      navigationText : true
    },
    {
      subHeading: "Simple, flexible pricing—no commissions, just plans that work for your trade business. Start free, upgrade as you grow.",
      // para: "showcase your services on our platform and let customers reach out to you directly. We provide a bridge, not a barrier, to new business opportunities.",
      buttonText: "Start listing today",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[125px] sm:w-[135px] lg:w-[145px] xl:w-[145px] 2xl:w-[155px] text-white",
      subClass: "group-hover:w-[130px] sm:group-hover:w-[140px] lg:group-hover:w-[150px] xl:group-hover:w-[150px] 2xl:group-hover:w-[160px]",
      link: "",
      navigationText : true
    },
    {
      subHeading: "Call, message, or text—businesses with paid profiles can display their contact info, certifications, and social media for direct customer connections.",
      // para: "every expert on our platform is thoroughly vetted, ensuring you receive services from professionals you can trust.",
      buttonText: "View verified professionals",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[175px] sm:w-[185px] lg:w-[195px] xl:w-[195px] 2xl:w-[210px] text-white",
      subClass: "group-hover:w-[180px] sm:group-hover:w-[190px] lg:group-hover:w-[205px] xl:group-hover:w-[205px] 2xl:group-hover:w-[220px]",
      link: "",
      navigationText : true
    },
    {
      subHeading: "Stand out with verified certifications—show customers you're the expert they can trust for their projects.",
      // para: "gain insights into how your services are performing with detailed analytics on profile and post views, enhancing your business strategy.",
      buttonText: "View your analytics",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[130px] sm:w-[140px] lg:w-[150px] xl:w-[150px] 2xl:w-[160px] text-white",
      subClass: "group-hover:w-[135px] sm:group-hover:w-[145px] lg:group-hover:w-[155px] xl:group-hover:w-[160px] 2xl:group-hover:w-[165px]",
      link: "",
      navigationText : true
    },
    {
      subHeading: "Track your performance with real-time analytics. Use data-driven insights to improve and grow your trade business.",
      // para: "choose how to connect with tradespeople directly through their profiles—whether by phone, email, or onsite inquiries.",
      buttonText: "Connect now",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[60px] sm:w-[70px] lg:w-[75px] xl:w-[75px] 2xl:w-[100px] text-white",
      subClass: "group-hover:w-[65px] sm:group-hover:w-[65px] lg:group-hover:w-[80px] xl:group-hover:w-[80px] 2xl:group-hover:w-[105px]",
      link: "",
      navigationText : true
    },
    {
      subHeading: "Highlight your best work—use portfolios and certifications to showcase your skills and attract more customers.",
      // para: "engage directly with tradespeople to discuss and understand pricing—no hidden charges, just clear, straightforward quotes.",
      buttonText: "Explore transparent pricing",
      image: require("../../../src/assets/img/demo.jpg"),
      class: "w-[180px] sm:w-[190px] lg:w-[200px] xl:w-[200px] 2xl:w-[215px] text-white",
      subClass: "group-hover:w-[185px] sm:group-hover:w-[195px] lg:group-hover:w-[205px] xl:group-hover:w-[205px] 2xl:group-hover:w-[220px]",
      link: "",
      navigationText : true
    },
  ];
  
  
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] pt-10 sm:pt-20 2xl:pt-40">
        <Heading variant={"h2"} className={"text-offblack "}>
          Transforming your trade experience 
        </Heading>
      </div>
      <BigSlidesSlider data={data} lastButton={true}/>
    </>
  );
};

export default SuccessSlider;
