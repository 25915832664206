import React from "react";
import Heading from "../../components/common/Typography/Heading";
import BigSlidesSlider from "../SuccessJourneySlider/BigSlidesSlider";

const SuccessSlider = () => {
  const data = [
    {
      rareHeading: "Maximize Your Reach with Priority Placement",
      para : "Get seen by more customers with priority search placement, ensuring your business is always in the spotlight.",
      original: require("../../../src/assets/img/P1.jpeg"),
    },
    {
      rareHeading: "Showcase Your Best Work with Portfolio Features",
      para : "Display your top projects and customer reviews to inspire confidence and attract new clients.",
      original: require("../../../src/assets/img/P2.jpeg"),
    },
    {
      rareHeading: "Advanced Profile Features That Set You Apart",
      para : "Create a standout profile with rich media, detailed descriptions, and enhanced business presentation.",
      original: require("../../../src/assets/img/P3.jpeg"),
    },
    {
      rareHeading: "Stay Ahead with Detailed Metrics & Analytics",
      para : "Track your business's growth with in-depth insights, allowing you to fine-tune your strategy and optimize performance.",
      original: require("../../../src/assets/img/P3.jpeg"),
    },
    {
      rareHeading: "Build Trust with Verified Credentials",
      para : "Establish credibility with your customers by highlighting certifications, associations, and a Pro badge that signifies excellence.",
      original: require("../../../src/assets/img/P3.jpeg"),
    },
    {
      rareHeading: "Unlimited Posts, Endless Opportunities",
      para : "Post as many services as you need with no limits. More exposure, more opportunities to connect with new clients.",
      original: require("../../../src/assets/img/P3.jpeg"),
    },
  ];
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] mt-10 sm:mt-40">
        <Heading variant={"h2"} className={"text-start text-offblack"}>
        Visibility that drives results
        </Heading>
      </div>
      <BigSlidesSlider data={data} rightAligning={true}/>
    </>
  );
};

export default SuccessSlider;
