import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Spinner from "../components/common/Spinner";
import useAuth from "../Hooks/useAuth";
import { useServices, useServicesById } from "../Hooks/useServices";
import ServiceCards from "../sections/Service/ServiceCards";
import { useSelector } from "react-redux";
import Filters from "../sections/Service/Filters";
import MainButton from "../components/common/MainButton/MainButton";
import Login from "../sections/Login/Login";
import { SignUp as SignUpRegister } from "../sections/SignUp/SignUp";
const Services = ({ formik }) => {
  const LISTING_TYPE = "Feature";
  const { auth } = useAuth();
  const location = useLocation();
  const { status, data } = useServices();
  const { status: AuthnicateStatus, data: AuthnicateData } = useServicesById(
    auth?.user?.id
  );
  function useParams() {
    return new URLSearchParams(useLocation().search);
  }
  const params = useParams();
  const CategoryId = params.get("id");
  const [FeaturedServices, setFeaturedServices] = useState([]);
  const [PopulorServices, setPopulorServices] = useState([]);
  const [RecentServices, setRecentServices] = useState([]);
  const [SignUp, setSignUp] = React.useState(false);
  const [SignIn, setSignIn] = React.useState(false);
  const services = !auth?.user
    ? data?.data.key.$values ?? []
    : AuthnicateData?.data?.$values ?? [];
  useEffect(() => {
    const filterFeatured = services?.filter(
      (list) => list?.listType === LISTING_TYPE
    );
    !auth?.user
      ? setFeaturedServices(sortOrderByDescbyRating(filterFeatured.slice(0, 5)))
      : setFeaturedServices(sortOrderByDescbyRating(filterFeatured));
    const filterPopulorServices = services;
    !auth?.user
      ? setPopulorServices(
          sortOrderByDescbyRating(filterPopulorServices.slice(0, 5))
        )
      : setPopulorServices(sortOrderByDescbyRating(filterPopulorServices));
    !auth?.user
      ? setRecentServices(
          sortOrderByDescbyRating(filterPopulorServices.slice(0, 5))
        )
      : setRecentServices(sortOrderByDescbyRating(filterPopulorServices));
  }, [CategoryId, AuthnicateData, data, location]);
  const sortOrderByDescbyRating = (List) => {
    let sortedDsc = List.sort((a, b) => {
      return parseInt(b?.listRating) - parseInt(a?.listRating);
    });
    return sortedDsc;
  };
  const businessCategories = useSelector(
    (category) => category.BusinessCategoryReducer
  );

  return (
    <>
      <div className="mx-[5%] pt-16 sm:pt-24">
        {(status === "loading" || AuthnicateStatus === "loading") && (
          <Spinner />
        )}
        <Filters />
      </div>
      {status === "success" && (
        <>
          <div className="mt-10 sm:mt-20">
            <div className="flex justify-center">
              <div
                className={
                  !auth?.user
                    ? "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 4k:grid-cols-5 mx-[5%] gap-8 mb-20"
                    : "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 4k:grid-cols-5 mx-[5%] gap-8 mb-20"
                }
              >
                <ServiceCards
                  addType={"Featured"}
                  Services={FeaturedServices}
                />
                <ServiceCards addType={"Popular"} Services={PopulorServices} />
                <ServiceCards addType={"Recent"} Services={RecentServices} />
              </div>
            </div>
            {SignIn ? (
              <Login
                SignIn={SignIn}
                setSignIn={setSignIn}
                SignUpState={SignUp}
                setSignUpState={setSignUp}
              />
            ) : (
              ""
            )}
            {SignUp ? (
              <SignUpRegister
                SignUpState={SignUp}
                setSignUpState={setSignUp}
                SignIn={SignIn}
                setSignIn={setSignIn}
              />
            ) : (
              ""
            )}

            {auth?.user ? (
              ""
            ) : (
              <>
                <div className="flex items-center justify-center ">
                  <MainButton
                    variant={"extralarge"}
                    onClick={() => setSignUp(true)}
                  >
                    Sign up to continue
                  </MainButton>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Services;
