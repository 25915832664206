import React from "react";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Snippet from "../../components/common/SnippetIcon/Snippet";
import Paragraph from "../../components/common/Typography/Paragraph";

const PackageDetailsTable = () => {
  const data = [
    {
      content: "Basic Business Profile",
      free: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <Paragraph variant={"MainParagraph"}>Advanced business profile</Paragraph>,
    },
    {
      content: "Messaging System",
      free: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Cover Image",
      free: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "ABN Verification",
      free: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Social Media Integration",
      free: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Keywords",
      free: <Paragraph variant={"MainParagraph"}>1</Paragraph>,
      standard: <Paragraph variant={"MainParagraph"}>3</Paragraph>,
      premium: <Paragraph variant={"MainParagraph"}>10</Paragraph>,
    },
    {
      content: "Regular Posts",
      free: <Paragraph variant={"MainParagraph"}>1</Paragraph>,
      standard: <Paragraph variant={"MainParagraph"}>Unlimited</Paragraph>,
      premium: <Paragraph variant={"MainParagraph"}>Unlimited</Paragraph>,
    },
    {
      content: "Featured posts",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <Paragraph variant={"MainParagraph"}>5</Paragraph>,
      premium: <Paragraph variant={"MainParagraph"}>20</Paragraph>,
    },
    {
      content: "Portfolio Gallery",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Portfolio Images",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <Paragraph variant={"MainParagraph"}>10</Paragraph>,
      premium: <Paragraph variant={"MainParagraph"}>20</Paragraph>,
    },
    {
      content: "Post Images",
      free: <Paragraph variant={"MainParagraph"}>1</Paragraph>,
      standard: <Paragraph variant={"MainParagraph"}>3</Paragraph>,
      premium: <Paragraph variant={"MainParagraph"}>8</Paragraph>,
    },
    {
      content: "Business Certifications & Associations",
      free: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Multiple category selection",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      snip: true,
      snippText:
        "Combine services in one profile to create more posts and visibility.",
    },
    {
      content: "Business Website Link",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Business trading hours",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Business contact Information",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Trade skills",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      snip: true,
      snippText:
        "Showcase additional skills to attract clients.",
    },
    {
      content: "Business Payment Methods",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      snip: true,
      snippText:
        "Show payment options you accept on your profile for easy transactions.",
    },
    {
      content: "Custom Business FAQs",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      snip: true,
      snippText:
        "Create custom FAQs on your profile tailored to your business.",
    },
    {
      content: "Priority Search Placement",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Enhanced Profile Visibility",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <Paragraph variant={"MainParagraph"}>10x more visibility in post</Paragraph>,
    },
    {
      content: "Advanced Profile Features",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Showcase Your Project Work",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Metrics & Analytics",
      free: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      standard: <i className="fi fi-rs-cross text-[10px] text-alertred"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    {
      content: "Business Success Stories",
      free: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      standard: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
      premium: <i className="fi fi-rs-check text-sm text-primaryblue"></i>,
    },
    
  ];

  return (
    <>
      <>
        <div className="grid grid-cols-12 pt-10 sm:pt-20 lg:pt-40 mx-[5%] xl:mx-auto xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] text-offblack">
          <div className="col-span-4 md:col-span-3 flex items-center justify-start">
            <SecondaryHeading variant={"MainParagraphHeading"}>
              Features
            </SecondaryHeading>
          </div>
          <div className="col-span-2 md:col-span-3 flex items-center justify-center">
            <SecondaryHeading variant={"MainParagraphHeading"}>
              Free
            </SecondaryHeading>
          </div>
          <div className="col-span-3 md:col-span-3 flex items-center justify-center">
            <SecondaryHeading variant={"MainParagraphHeading"}>
              Basic
            </SecondaryHeading>
          </div>
          <div className="col-span-3 md:col-span-3 flex items-center justify-center">
            <SecondaryHeading variant={"MainParagraphHeading"}>
              Pro
            </SecondaryHeading>
          </div>
        </div>
        <div className="bg-pastalblue mx-[5%] xl:mx-auto xl:w-[1000px] 2xl:w-[1075px] 3xl:w-[1264px] mt-10 text-offblack">
          {data.map((e) => {
            return (
              <>
                <div className="grid grid-cols-12 pb-5">
                  <div className="col-span-4 md:col-span-3 flex items-center justify-start">
                    <Paragraph variant={"MainParagraph"}>{e.content}</Paragraph>
                    {e.snip && (
                      <div className="ml-2">
                        <Snippet variant={"icon"} text={e.snippText} />
                      </div>
                    )}
                  </div>
                  <div className="col-span-2 md:col-span-3 flex items-center justify-center">
                    {e.free}
                  </div>
                  <div className="col-span-3 md:col-span-3 flex items-center justify-center">
                    {e.standard}
                  </div>
                  <div className="col-span-3 md:col-span-3 flex items-center justify-center">
                    {e.premium}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    </>
  );
};

export default PackageDetailsTable;
