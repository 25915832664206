/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import Facebook from "../../../assets/img/social/facebook.svg";
import Instagram from "../../../assets/img/social/instagram.svg";
import Linkedin from "../../../assets/img/social/linkedin.svg";
import Tiktok from "../../../assets/img/social/tiktok.svg";
import Youtube from "../../../assets/img/social/youtube.svg";
import Pinterest from "../../../assets/img/social/pinterest.svg";
import Threads from "../../../assets/img/social/threads.svg";
import Popups from "../../../components/popup/Popups";
import VerificationPopUp from "../../VerificationPop/VerificationPopUp";
import { useState } from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import Paragraph from "../../../components/common/Typography/Paragraph";
function SocialMedia() {
  const badgeData = [
    {
      heading: "Facebook ",
      description:
        "Badge related information Badge related information badge related information",
      image: Facebook,
      status: "Remove",
    },
    {
      heading: "Instagram ",
      description:
        "Badge related information Badge related information badge related information",
      image: Instagram,
      status: "Add",
    },
    {
      heading: "Linkedin ",
      description:
        "Badge related information Badge related information badge related information",
      image: Linkedin,
      status: "Add",
    },
    {
      heading: "TikTok ",
      description:
        "Badge related information Badge related information badge related information",
      image: Tiktok,
      status: "Add",
    },
    {
      heading: "Youtube ",
      description:
        "Badge related information Badge related information badge related information",
      image: Youtube,
      status: "Add",
    },
    {
      heading: "Twitter ",
      description:
        "Badge related information Badge related information badge related information",
      image: Threads,
      status: "Add",
    },
    {
      heading: "Pinterest ",
      description:
        "Badge related information Badge related information badge related information",
      image: Pinterest,
      status: "Add",
    },
  ];

  return (
    <>
      <div className="grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-3 md:grid-cols-4 2k:grid-cols-6 4k:grid-cols-7 justify-between mx-[5%] lg:mx-auto lg:w-[600px] xl:w-[680px] 2xl:w-[680px] 2k:w-[1000px] 4k:w-[1200px] gap-y-16">
        {badgeData?.map((item, index) => {
          return (
            <>
              <Social
                key={index}
                heading={item.heading}
                description={item.description}
                image={item.image}
                status={item.status}
              />
            </>
          );
        })}
      </div>
    </>
  );
}

export default SocialMedia;

const Social = ({ heading, description, image, status }) => {
  const [link, setLink] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  useEffect(() => {
    if (link) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [link]);
  return (
    <>
      <div>
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <img className="w-[50px] h-[50px]" src={image} alt="badge" />
          </div>
          <div className="mt-1">
            <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
              {heading}
            </Paragraph>
          </div>
          <div className="mt-3">
            <MainButton
              variant={status === "Remove" ? "outlineextrasmall" : "extrasmall"}
              onClick={() => {
                setLink(true);
              }}
            >
              {status}
            </MainButton>
          </div>
        </div>
      </div>
      {link && (
        <Popups
          setTrigger={() => setLink(false)}
          ShowCrossButton={ShowCrossButton}
          popupWidth={"w-[350px] h-[500px] xs:w-[400px] sm:w-[600px] sm:h-[500px] 2xl:w-[550px] 2xl:h-[600px] pb-10"}
              crossPosition={
                "flex justify-end w-[16px] absolute left-[320px] xs:left-[370px] sm:left-[565px] 2xl:left-[515px] top-3"
              }
        >
          <VerificationPopUp
            closePopup={() => setLink(false)}
            firstMainHeading={"Connect your social media profile"}
            firstP={
              "Link your social media accounts to boost your business's visibility and connect with customers directly on Trades Poster."
            }
            label={"Social Media Profile URL"}
            placeholder={"Paste the URL of your social media profile here."}
            secondMainHeading={"Social media profile linked"}
            secondP={
              "Your social media account has been successfully linked! This connection enhances your profile and encourages more engagement through your linked accounts."
            }
            smallPara={
              "By linking your social media profile, you allow Trades Poster to display your profile on our platform, which may increase customer engagement. We assure you that your information is securely handled in compliance with privacy regulations."
            }
            buttonText={"Save"}
          />
        </Popups>
      )}
    </>
  );
};
