import React from "react";
import MainButton from "../../../../components/common/MainButton/MainButton";
import TextFiled from "../../../../components/common/InputField/TextFiled";
import { useState } from "react";
import Paragraph from "../../../../components/common/Typography/Paragraph";
import Heading from "../../../../components/common/Typography/Heading";
import AuxiliaryText from "../../../../components/common/Typography/AuxiliaryText";

const Form = ({ secondMainHeading, secondP, closePopup }) => {
  const [step, setStep] = useState(1);
  const completion = 79.32;
  return (
    <>
      {step === 1 && (
        <>
          <Heading
            variant={"h6"}
            className={"text-center mb-10 mt-[70px] text-offblack"}
          >
            Certification credential verification
          </Heading>
          <div className="mx-auto rounded-lg mb-[16px] w-[300px] h-[350px] sm:w-[350px] sm:h-[300px] md:w-[400px] md:h-[400px] lg:w-[400px] lg:h-[400px] xl:w-[400px] xl:h-[400px] 2xl:w-[400px] 2xl:h-[400px] overflow-y-auto hidden-scrollbar pb-5">
            <div>
              <TextFiled
                variant={"general"}
                label={"Full name"}
                placeholder={"Enter your full name"}
              />
            </div>
            <div className="mt-5">
              <TextFiled
                variant={"general"}
                label={"Document Number"}
                placeholder={"Enter your document number"}
              />
            </div>
            <div className="mt-5">
              <TextFiled
                variant={"general"}
                label={"State"}
                placeholder={"Enter your state"}
              />
            </div>
            <div className="mt-5">
              <label className="block mb-2">
                <AuxiliaryText variant={"FieldLabel"}>Issue date</AuxiliaryText>
              </label>
              <div className="flex justify-start -ml-1">
                <TextFiled variant={"extrasmall01"} placeholder={"DD"} />
                <TextFiled variant={"extrasmall01"} placeholder={"MM"} />
                <TextFiled variant={"extrasmall02"} placeholder={"YYYY"} />
              </div>
            </div>
            <div className="mt-5">
              <label className="block mb-2">
                <AuxiliaryText variant={"FieldLabel"}>
                  Expiration date
                </AuxiliaryText>
              </label>
              <div className="flex justify-start -ml-1">
                <TextFiled variant={"extrasmall01"} placeholder={"DD"} />
                <TextFiled variant={"extrasmall01"} placeholder={"MM"} />
                <TextFiled variant={"extrasmall02"} placeholder={"YYYY"} />
              </div>
            </div>
            <div className="mt-5">
              <label className={`block mb-2`}>
                <AuxiliaryText
                  variant={"FieldLabel"}
                  className={"text-offblack"}
                >
                  Upload
                </AuxiliaryText>
              </label>
              <div className="flex items-center justify-center w-full">
                <input
                  type="file"
                  id="imageslist"
                  // onChange={handleSelect}
                  accept="image/*"
                  className="cursor-pointer hidden"
                  multiple
                />
                <label
                  role="button"
                  htmlFor="imageslist"
                  className="h-[112px] w-full rounded-lg flex justify-center bg-white"
                >
                  <div className="my-auto">
                    <div className="flex justify-center">
                      <div className="flex justify-center items-center">
                        <i className="fi fi-rs-inbox-out text-base text-offblack"></i>
                      </div>
                    </div>
                    <div className="flex justify-center">
                      <AuxiliaryText
                        variant={"Placeholder"}
                        className={"mt-1 text-primaryblue"}
                      >
                        Click to upload
                      </AuxiliaryText>
                    </div>
                  </div>
                </label>
              </div>
              <div className="mt-10 w-full">
                <div className="flex gap-3 w-full">
                  <i className="fi fi-br-picture text-[50px] text-primaryblue"></i>
                  <div className="w-full flex flex-col gap-2 mt-5">
                    <div className="flex items-center justify-between w-full">
                      <AuxiliaryText variant={"FieldLabel"}>
                        myprofile.png
                      </AuxiliaryText>

                      <AuxiliaryText variant={"FieldLabel"}>
                        {completion}%
                      </AuxiliaryText>
                    </div>
                    <div className="w-full h-1.5 bg-pastalblue rounded-full">
                      <div
                        className={`h-full bg-primaryblue rounded-full`}
                        style={{ width: `${completion}%` }}
                      ></div>
                    </div>
                  </div>
                  <i className="fi fi-rs-cross text-sm cursor-pointer mt-10"></i>
                </div>
              </div>
            </div>
            <div className="flex items-center justify-end mt-10">
              <MainButton
                variant={"extrasmall"}
                onClick={() => {
                  setStep(2);
                  setTimeout(closePopup, 3000);
                }}
              >
                Submit
              </MainButton>
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[500px] xl:w-[500px] 2xl:w-[500px] mx-auto">
            <Heading
              variant={"h6"}
              className={"text-center mb-10 mt-20 text-offblack"}
            >
              {secondMainHeading}
            </Heading>
            <div className="flex">
              <div className="w-[100%]">
                <div className="max-w-[400px] mx-auto">
                  <Paragraph variant={"MainParagraph"} className={"text-start"}>
                    {secondP}
                  </Paragraph>
                </div>
                <div className="flex justify-center my-10">
                  <i className="fi fi-ss-badge-check text-[50px] text-primaryblue"></i>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Form;
