import React from "react";
import Banner from "../components/common/Banners/Banner";
import AtoZFilters from "../sections/Councils/AtoZFilters";

const CouncilRagulators = () => {
  return (
    <>
      <Banner
        headingClasses={
          "text-center sm:text-start text-white xl:w-[900px] 2xl:w-[1000px] "
        }
        heading={"Navigating Your Local Trade Regulations and Guidelines"}
        className={"bg-about"}
      />
      <AtoZFilters />
    </>
  );
};

export default CouncilRagulators;
