import React from "react";
import Heading from "../../components/common/Typography/Heading";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";
import { useParams } from "react-router-dom";

const CodesCompliance = () => {
  const { slug } = useParams();
  const data = [
    {
      slug: "architectural",
      listHeading: "Architectural codes & compliance",
      list: [
        {
          inlineHeading: "AS 1428",
          inlineParagraph: "Design for access and mobility.",
        },
        {
          inlineHeading: "AS/NZS 1170",
          inlineParagraph: "Structural design actions.",
        },
        {
          inlineHeading: "AS 1684",
          inlineParagraph: "Timber-framed construction standards.",
        },
        {
          inlineHeading: "AS 3600",
          inlineParagraph: "Concrete structures and design.",
        },
        {
          inlineHeading: "AS/NZS 2107",
          inlineParagraph: "Acoustics in buildings.",
        },
        {
          inlineHeading: "AS 3959",
          inlineParagraph: "Building in bushfire-prone areas.",
        },
        {
          inlineHeading: "NCC",
          inlineParagraph:
            "National Construction Code for all building compliance.",
        },
      ],
    },
    {
      slug: "air-conditioning-and-heating",
      listHeading: "Codes & Compliance",
      list: [
        {
          inlineHeading: "AS/NZS 1668.2",
          inlineParagraph: "Ventilation in buildings.",
        },
        {
          inlineHeading: "AS/NZS 3000",
          inlineParagraph: "Electrical installations in HVAC systems.",
        },
        {
          inlineHeading: "AS/NZS 3823",
          inlineParagraph: "Testing performance of air conditioning units.",
        },
        {
          inlineHeading: "AS/NZS 5601.1",
          inlineParagraph: "Gas installations in heating systems.",
        },
        {
          inlineHeading: "AS/NZS 4012",
          inlineParagraph: "Energy performance standards for heaters.",
        },
      ],
    },
    {
      slug: "bricklaying",
      listHeading: "Codes & Compliance",
      list: [
        {
          inlineHeading: "AS 3700",
          inlineParagraph: "Masonry structure standards.",
        },
        {
          inlineHeading: "AS/NZS 4456",
          inlineParagraph: "Masonry units and pavers standards.",
        },
        {
          inlineHeading: "AS 4773",
          inlineParagraph: "Masonry in small buildings standards.",
        },
        {
          inlineHeading: "AS 1379",
          inlineParagraph: "Concrete standards for reinforced brickwork.",
        },
        {
          inlineHeading: "AS 2187",
          inlineParagraph: "Concrete anchors in brickwork.",
        },
      ],
    },
    {
      slug: "cabinet-making",
      listHeading: "Codes & Compliance",
      list: [
        {
          inlineHeading: "AS 4386",
          inlineParagraph: "Kitchen cabinetry standards.",
        },
        {
          inlineHeading: "AS/NZS 1680",
          inlineParagraph:
            "Interior lighting standards for optimal workspace lighting.",
        },
        {
          inlineHeading: "AS 2047",
          inlineParagraph:
            "Windows and glazing, applicable for cabinets near windows.",
        },
        {
          inlineHeading: "AS/NZS 2712",
          inlineParagraph:
            "Standards for cabinetry in wet areas (e.g., bathrooms, laundries).",
        },
        {
          inlineHeading: "AS 5601",
          inlineParagraph:
            "Gas installation requirements for cabinetry near gas appliances.",
        },
      ],
    },
    {
      slug: "carpentry",
      listHeading: "Codes & Compliance",
      list: [
        {
          inlineHeading: "AS 1684",
          inlineParagraph: "Timber-framed construction standards.",
        },
        {
          inlineHeading: "AS 1720",
          inlineParagraph: "Timber structures design and construction.",
        },
        {
          inlineHeading: "AS 4786",
          inlineParagraph: "Timber flooring installation guidelines.",
        },
        {
          inlineHeading: "AS/NZS 1562",
          inlineParagraph: "Roof installation using timber trusses.",
        },
        {
          inlineHeading: "AS/NZS 2179",
          inlineParagraph: "Timber door and window frames.",
        },
      ],
    },
    {
      slug: "carpet-and-flooring",
      listHeading: "Codes & Compliance",
      list: [
        {
          inlineHeading: "AS 1884",
          inlineParagraph: "Installation of resilient floor coverings.",
        },
        {
          inlineHeading: "AS/NZS 4586",
          inlineParagraph: "Slip resistance classification for flooring.",
        },
        {
          inlineHeading: "AS 3958",
          inlineParagraph: "Ceramic tiling standards.",
        },
        {
          inlineHeading: "AS 2455",
          inlineParagraph: "Installation of textile floor coverings (carpets).",
        },
        {
          inlineHeading: "AS 4663",
          inlineParagraph: "Slip resistance measurement of existing flooring.",
        },
      ],
    },
    {
      slug: "caulking",
      listHeading: "Caulking regulators",
    },
    {
      slug: "cleaning-services",
      listHeading: "Codes & Compliance",
      list: [
        {
          inlineHeading: "AS/NZS 3733",
          inlineParagraph: "Standards for carpet cleaning maintenance.",
        },
        {
          inlineHeading: "AS 4801",
          inlineParagraph:
            "Occupational health and safety management for cleaners.",
        },
        {
          inlineHeading: "AS/NZS 4146",
          inlineParagraph: "Laundry and linen cleaning standards for hygiene.",
        },
        {
          inlineHeading: "AS/NZS 4815",
          inlineParagraph:
            "Office cleaning standards to prevent cross-contamination.",
        },
      ],
    },
    {
      slug: "concrete-and-paving",
      listHeading: "Codes & Compliance",
      list: [
        {
          inlineHeading: "AS 3600",
          inlineParagraph: "Standards for concrete structures.",
        },
        {
          inlineHeading: "AS 3727",
          inlineParagraph:
            "Standards for residential pavements and pedestrian areas.",
        },
        {
          inlineHeading: "AS 2870",
          inlineParagraph: "Standards for slab and footing construction.",
        },
        {
          inlineHeading: "AS 4586",
          inlineParagraph:
            "Slip resistance classification for flooring surfaces.",
        },
        {
          inlineHeading: "AS 3958",
          inlineParagraph:
            "Standards for tile installation on concrete surfaces.",
        },
      ],
    },
    {
      slug: "conveyancing",
      listHeading: "Conveyancing codes & compliance",
      list: [
        {
          inlineHeading: "ACL",
          inlineParagraph: "Ensures fair conduct in transactions.",
        },
        {
          inlineHeading: "State Property Acts",
          inlineParagraph: "Legal requirements for real estate.",
        },
        {
          inlineHeading: "Land Transfer Acts",
          inlineParagraph: "State-specific rules for land transfers.",
        },
        {
          inlineHeading: "Licensing Act",
          inlineParagraph: "Standards for licensed conveyancers.",
        },
        {
          inlineHeading: "FIRB Requirements",
          inlineParagraph: "Compliance for foreign property buyers.",
        },
      ],
    },
    {
      slug: "blinds-and-curtains",
      listHeading: "Curtains, blinds & awnings codes & compliance",
      list: [
        {
          inlineHeading: "AS 2663",
          inlineParagraph: "Safety standards for internal window coverings.",
        },
        {
          inlineHeading: "AS 4055",
          inlineParagraph:
            "Wind loads for external window coverings and awnings.",
        },
        {
          inlineHeading: "AS 4420",
          inlineParagraph: "Testing methods for external blinds and awnings.",
        },
        {
          inlineHeading: "AS/NZS 5039",
          inlineParagraph:
            "Safety requirements for barrier screens and shutters.",
        },
        {
          inlineHeading: "AS 2047",
          inlineParagraph: "Standards for windows and glazing in buildings.",
        },
      ],
    },
    {
      slug: "decking-and-gazebos",
      listHeading: "Decking, gazebos & outdoor structures codes & compliance",
      list: [
        {
          inlineHeading: "AS 1684",
          inlineParagraph: "Standards for timber-framed construction.",
        },
        {
          inlineHeading: "AS 5601",
          inlineParagraph:
            "Safety standards for gas installations near outdoor structures.",
        },
        {
          inlineHeading: "AS 3959",
          inlineParagraph: "Construction standards in bushfire-prone areas.",
        },
        {
          inlineHeading: "AS 2155",
          inlineParagraph: "Standards for exterior timber work.",
        },
        {
          inlineHeading: "AS/NZS 3661",
          inlineParagraph: "Slip resistance requirements for outdoor surfaces.",
        },
      ],
    },
    {
      slug: "demolition-and-excavation",
      listHeading: "Demolition & excavation codes & compliance",
      list: [
        {
          inlineHeading: "AS 2601",
          inlineParagraph: "Standards for demolition of structures.",
        },
        {
          inlineHeading: "AS 3798",
          inlineParagraph:
            "Guidelines for earthworks for commercial and residential developments.",
        },
        {
          inlineHeading: "AS 4361",
          inlineParagraph:
            "Standards for hazardous materials management during demolition.",
        },
        {
          inlineHeading: "AS/NZS 4801",
          inlineParagraph:
            "Safety management systems for occupational health and safety.",
        },
        {
          inlineHeading: "AS 4970",
          inlineParagraph:
            "Protection of trees on development sites during excavation.",
        },
      ],
    },
    {
      slug: "electrical",
      listHeading: "Electrical codes & compliance",
      list: [
        {
          inlineHeading: "AS/NZS 3000",
          inlineParagraph:
            "Standards for electrical installations and wiring rules.",
        },
        {
          inlineHeading: "AS/NZS 3760",
          inlineParagraph:
            "Standards for electrical equipment testing and safety.",
        },
        {
          inlineHeading: "AS/NZS 3018",
          inlineParagraph: "Guidelines for domestic electrical installations.",
        },
        {
          inlineHeading: "AS/NZS 4777",
          inlineParagraph:
            "Standards for grid connection of energy systems like solar panels.",
        },
        {
          inlineHeading: "AS/NZS 2293",
          inlineParagraph:
            "Emergency lighting and exit sign standards for buildings.",
        },
      ],
    },
    {
      slug: "engineering-services",
      listHeading: "Engineering codes & compliance",
      list: [
        {
          inlineHeading: "AS 3600",
          inlineParagraph: "Concrete structures and design.",
        },
        {
          inlineHeading: "AS/NZS 3000",
          inlineParagraph: "Electrical installations for safety compliance.",
        },
        {
          inlineHeading: "AS 4100",
          inlineParagraph: "Steel structures design.",
        },
        {
          inlineHeading: "AS/NZS 1170",
          inlineParagraph: "Structural design actions for buildings.",
        },
        {
          inlineHeading: "AS 3500",
          inlineParagraph: "Plumbing and drainage standards.",
        },
        {
          inlineHeading: "AS/NZS 1668.2",
          inlineParagraph: "Mechanical ventilation for indoor air quality.",
        },
        {
          inlineHeading: "NCC",
          inlineParagraph:
            "National Construction Code for building safety and compliance.",
        },
      ],
    },
    {
      slug: "fence-and-gates",
      listHeading: "Codes & Compliance",
      list: [
        {
          inlineHeading: "AS 1926.1",
          inlineParagraph:
            "Standards for safety barriers around swimming pools.",
        },
        {
          inlineHeading: "AS 1725",
          inlineParagraph: "Standards for chain-link and wire fences.",
        },
        {
          inlineHeading: "AS 3000",
          inlineParagraph: "Electrical standards for gate automation systems.",
        },
        {
          inlineHeading: "AS 4687",
          inlineParagraph: "Standards for temporary fencing and hoardings.",
        },
        {
          inlineHeading: "AS 2155",
          inlineParagraph: "Standards for timber construction in fencing.",
        },
      ],
    },
    {
      slug: "glazing-and-screens",
      listHeading: "Glazing codes & compliance",
      list: [
        {
          inlineHeading: "AS 1288",
          inlineParagraph: "Standards for glass installation in buildings.",
        },
        {
          inlineHeading: "AS 2047",
          inlineParagraph: "Standards for window installation.",
        },
        {
          inlineHeading: "AS 2208",
          inlineParagraph: "Safety glazing materials in buildings.",
        },
        {
          inlineHeading: "AS/NZS 4666",
          inlineParagraph:
            "Standards for insulating glass units (double glazing).",
        },
      ],
    },
    {
      slug: "garden-and-landscaping",
      listHeading: "Landscaping codes & compliance",
      list: [
        {
          inlineHeading: "AS 4419",
          inlineParagraph: "Standards for soil quality and use in landscaping.",
        },
        {
          inlineHeading: "AS 4970",
          inlineParagraph:
            "Tree protection standards during landscape development.",
        },
        {
          inlineHeading: "AS 3500",
          inlineParagraph:
            "Standards for irrigation systems, ensuring efficient water use and compliance.",
        },
        {
          inlineHeading: "AS 2155",
          inlineParagraph:
            "Standards for the construction of retaining walls and timber landscaping structures.",
        },
        {
          inlineHeading: "AS/NZS 1158",
          inlineParagraph:
            "Standards for outdoor lighting and pathway illumination.",
        },
      ],
    },
    {
      slug: "handyman",

      listHeading: "Handyman codes & compliance",
      list: [
        {
          inlineHeading: "AS/NZS 3000",
          inlineParagraph:
            "Standards for electrical installations for minor electrical repairs.",
        },
        {
          inlineHeading: "AS 3500",
          inlineParagraph:
            "Standards for minor plumbing work, including repairs and replacements.",
        },
        {
          inlineHeading: "AS 2311",
          inlineParagraph: "Standards for general painting and maintenance.",
        },
        {
          inlineHeading: "AS 3661",
          inlineParagraph:
            "Standards for tiling and slip resistance in wet areas.",
        },
        {
          inlineHeading: "AS 1288",
          inlineParagraph:
            "Standards for glass handling and installation in window or door repairs.",
        },
      ],
    },
    {
      slug: "inspection-and-surveyors",
      listHeading: "Inspection & surveying codes & compliance",
      list: [
        {
          inlineHeading: "AS 4349",
          inlineParagraph: "Standards for building inspections.",
        },
        {
          inlineHeading: "AS 5488",
          inlineParagraph:
            "Guidelines for subsurface utility information in land surveys.",
        },
        {
          inlineHeading: "AS 3660.1",
          inlineParagraph:
            "Standards for termite management in building inspections.",
        },
        {
          inlineHeading: "AS 1726",
          inlineParagraph:
            "Geotechnical site investigations for land and construction surveys.",
        },
        {
          inlineHeading: "AS 4964",
          inlineParagraph: "Guidelines for asbestos surveys and assessments.",
        },
      ],
    },
    {
      slug: "security-and-automation",
      listHeading: "Security & automation codes & compliance",
      list: [
        {
          inlineHeading: "AS 2201",
          inlineParagraph: "Standards for intruder alarm systems.",
        },
        {
          inlineHeading: "AS/NZS 3000",
          inlineParagraph: "Electrical wiring rules for safe installations.",
        },
        {
          inlineHeading: "AS 3786",
          inlineParagraph: "Standards for smoke alarm installation and safety.",
        },
        {
          inlineHeading: "AS 1288",
          inlineParagraph:
            "Glazing standards, relevant for smart window systems.",
        },
        {
          inlineHeading: "AS 2688",
          inlineParagraph:
            "Security systems for restricted access installations.",
        },
      ],
    },
    {
      slug: "insulation-services",
      listHeading: "Insulation codes & compliance",
      list: [
        {
          inlineHeading: "AS 4859.1",
          inlineParagraph:
            "Standards for thermal insulation materials in buildings.",
        },
        {
          inlineHeading: "AS 1530.3",
          inlineParagraph: "Fire resistance testing for insulation materials.",
        },
        {
          inlineHeading: "AS 3999",
          inlineParagraph:
            "Installation standards for bulk thermal insulation in residential buildings.",
        },
        {
          inlineHeading: "AS/NZS 4200",
          inlineParagraph: "Standards for reflective foil insulation.",
        },
        {
          inlineHeading: "AS 4072.1",
          inlineParagraph:
            "Fire protection standards for penetrations in walls, floors, and ceilings.",
        },
      ],
    },
    {
      slug: "interior-design",
      listHeading: "Interior design codes & compliance",
      list: [
        {
          inlineHeading: "AS 1428",
          inlineParagraph:
            "Accessibility standards for inclusive interior spaces.",
        },
        {
          inlineHeading: "AS/NZS 2107",
          inlineParagraph: "Acoustic standards for homes and offices.",
        },
        {
          inlineHeading: "AS 1680",
          inlineParagraph: "Standards for lighting and ergonomics.",
        },
        {
          inlineHeading: "AS 2311",
          inlineParagraph:
            "Guidelines for interior painting and color application.",
        },
        {
          inlineHeading: "AS 3715",
          inlineParagraph: "Standards for sustainable building materials.",
        },
      ],
    },
    {
      slug: "locksmiths",
      listHeading: "Locksmith codes & compliance",
      list: [
        {
          inlineHeading: "AS 4145",
          inlineParagraph: "Standards for lockset performance and durability.",
        },
        {
          inlineHeading: "AS 5039",
          inlineParagraph: "Standards for security doors and window grilles.",
        },
        {
          inlineHeading: "AS 2209",
          inlineParagraph: "Standards for safe and vault security.",
        },
        {
          inlineHeading: "AS/NZS 2201",
          inlineParagraph: "Standards for electronic security systems.",
        },
        {
          inlineHeading: "AS 2803",
          inlineParagraph: "Standards for key control in master key systems.",
        },
      ],
    },
    {
      slug: "painting-and-decorating",
      listHeading: "Codes & Compliance",
      list: [
        {
          inlineHeading: "AS 2311",
          inlineParagraph:
            "Standards for general painting practices, covering surface preparation, paint application, and finishes.",
        },
        {
          inlineHeading: "AS 4361.1",
          inlineParagraph:
            "Guidelines for managing lead-based and hazardous paints.",
        },
        {
          inlineHeading: "AS 4286",
          inlineParagraph:
            "Quality control standards for painting and surface finishes.",
        },
        {
          inlineHeading: "AS 2455",
          inlineParagraph:
            "Standards for carpet and textile coverings in interior decoration.",
        },
      ],
    },
    {
      slug: "moving-and-storage",
      listHeading: "Codes & Compliance",
      list: [
        {
          inlineHeading: "CoR rules",
          inlineParagraph: "Safe loading and transport practices.",
        },
        {
          inlineHeading: "AS/NZS 4801",
          inlineParagraph: "Occupational safety standards for handling.",
        },
        {
          inlineHeading: "WHS regulations",
          inlineParagraph: "Ensures safe procedures and equipment use.",
        },
        {
          inlineHeading: "Consumer guarantees act",
          inlineParagraph: "Protects consumer rights.",
        },
        {
          inlineHeading: "Insurance",
          inlineParagraph: "Transit insurance covers item damage.",
        },
      ],
    },
    {
      slug: "mold-and-asbestos",
      listHeading: "Codes & Compliance",
      list: [
        {
          inlineHeading: "AS 4964",
          inlineParagraph: "Asbestos testing standards.",
        },
        {
          inlineHeading: "AS 3666.2",
          inlineParagraph: "Air quality control for mould.",
        },
        {
          inlineHeading: "AS 2601",
          inlineParagraph: "Safe asbestos handling during demolition.",
        },
        {
          inlineHeading: "AS 3740",
          inlineParagraph: "Waterproofing standards for mould prevention.",
        },
        {
          inlineHeading: "Safe work Australia",
          inlineParagraph: "Guidelines for asbestos removal and disposal.",
        },
      ],
    },
    {
      slug: "pest-control",
      listHeading: "Pest control codes & compliance",
      list: [
        {
          inlineHeading: "AS 3660.1",
          inlineParagraph: "Termite management standards for new buildings.",
        },
        {
          inlineHeading: "AS/NZS 4341",
          inlineParagraph: "Standards for fumigation of enclosed spaces.",
        },
        {
          inlineHeading: "AS 2507",
          inlineParagraph:
            "Storage and handling of pesticides and chemicals used in pest control.",
        },
        {
          inlineHeading: "AS 1604",
          inlineParagraph:
            "Standards for preservative treatment of timber to prevent pest damage.",
        },
        {
          inlineHeading: "AS 2187",
          inlineParagraph:
            "Safe handling and use of pesticides for pest management in residential and commercial settings.",
        },
      ],
    },
    {
      slug: "plastering",
      listHeading: "Plastering codes & compliance",
      list: [
        {
          inlineHeading: "AS 2589",
          inlineParagraph: "Plasterboard installation standards.",
        },
        {
          inlineHeading: "AS/NZS 2311",
          inlineParagraph: "Surface preparation and painting.",
        },
        {
          inlineHeading: "AS 3959",
          inlineParagraph: "Fire-resistant construction.",
        },
        {
          inlineHeading: "AS 4745",
          inlineParagraph: "Decorative plastering guidelines.",
        },
      ],
    },
    {
      slug: "plumbing",
      listHeading: "Plumbing codes & compliance",
      list: [
        {
          inlineHeading: "AS/NZS 3500",
          inlineParagraph: "Plumbing and drainage standards.",
        },
        {
          inlineHeading: "AS/NZS 5601",
          inlineParagraph: "Gas installation standards.",
        },
        {
          inlineHeading: "AS/NZS 2845.1",
          inlineParagraph: "Backflow prevention standards.",
        },
        {
          inlineHeading: "AS/NZS 6400",
          inlineParagraph: "Rating and labelling standards.",
        },
        {
          inlineHeading: "AS/NZS 3500.4",
          inlineParagraph: "Hot water system installation standards.",
        },
        {
          inlineHeading: "AS/NZS 1260",
          inlineParagraph:
            "PVC pipes and fittings for drain, waste, and vent systems.",
        },
        {
          inlineHeading: "AS/NZS 2032",
          inlineParagraph:
            "Installation of PVC pipe systems—Correct installation guidelines.",
        },
        {
          inlineHeading: "AS 3688",
          inlineParagraph:
            "Water supply and gas systems—Standards for metallic fittings.",
        },
      ],
    },
    {
      slug: "pools-and-spas",
      listHeading: "Pool & spa compliance",
      list: [
        {
          inlineHeading: "AS 1926.1",
          inlineParagraph: "Pool safety barriers, gates, and fencing.",
        },
        {
          inlineHeading: "AS 2610",
          inlineParagraph: "Spa construction, safety, and operation.",
        },
        {
          inlineHeading: "AS/NZS 1838",
          inlineParagraph: "Vinyl-liner swimming pool standards.",
        },
        {
          inlineHeading: "AS 3979",
          inlineParagraph: "Hydrotherapy pool maintenance guidelines.",
        },
        {
          inlineHeading: "AS/NZS 4801",
          inlineParagraph: "Contractor safety management systems.",
        },
      ],
    },
    {
      slug: "rendering",
      listHeading: "Rendering codes & compliance",
      list: [
        {
          inlineHeading: "AS 3700",
          inlineParagraph: "Masonry construction and wall rendering.",
        },
        {
          inlineHeading: "AS 2311",
          inlineParagraph: "Surface preparation and coating.",
        },
        {
          inlineHeading: "AS 4858",
          inlineParagraph: "Waterproofing for wet areas.",
        },
        {
          inlineHeading: "AS 3959",
          inlineParagraph: "Fire-resistant rendering in bushfire zones.",
        },
        {
          inlineHeading: "AS/NZS 4284",
          inlineParagraph: "Standards for building facades and finishes.",
        },
      ],
    },
    {
      slug: "renovation-and-restoration",
      listHeading: "Renovation & restoration codes & compliance",
      list: [
        {
          inlineHeading: "AS 1684",
          inlineParagraph: "Residential building standards.",
        },
        {
          inlineHeading: "AS 1428",
          inlineParagraph: "Accessible design guidelines.",
        },
        {
          inlineHeading: "AS 3600",
          inlineParagraph: "Concrete structure standards.",
        },
        {
          inlineHeading: "AS 3740",
          inlineParagraph: "Waterproofing for wet areas.",
        },
        {
          inlineHeading: "AS 4349",
          inlineParagraph: "Inspection standards for renovations.",
        },
      ],
    },
    {
      slug: "roofing",
      listHeading: "Roofing codes & compliance",
      list: [
        {
          inlineHeading: "AS 1562.1",
          inlineParagraph: "Installation for metal, tile, shingles.",
        },
        {
          inlineHeading: "AS/NZS 4200.2",
          inlineParagraph: "Insulation and sarking.",
        },
        {
          inlineHeading: "AS 2050",
          inlineParagraph: "Tile fixing standards.",
        },
        {
          inlineHeading: "AS 4046.8",
          inlineParagraph: "Skylight installation.",
        },
        {
          inlineHeading: "AS/NZS 3500",
          inlineParagraph: "Guttering and drainage.",
        },
      ],
    },
    {
      slug: "scaffolding",
      listHeading: "Scaffolding codes & compliance",
      list: [
        {
          inlineHeading: "AS/NZS 1576",
          inlineParagraph: "Scaffolding design and construction.",
        },
        {
          inlineHeading: "AS/NZS 4576",
          inlineParagraph: "Safe use and maintenance.",
        },
        {
          inlineHeading: "AS 1657",
          inlineParagraph: "Fixed platforms and ladders.",
        },
        {
          inlineHeading: "AS 2550.16",
          inlineParagraph: "Cranes and hoists in setups.",
        },
        {
          inlineHeading: "AS/NZS 4801",
          inlineParagraph: "Health and safety management.",
        },
      ],
    },
    {
      slug: "shopfitting",
      listHeading: "Shopfitting codes & compliance",
      list: [
        {
          inlineHeading: "AS 1428",
          inlineParagraph:
            "Standards for designing accessible commercial spaces, ensuring equal access for all customers.",
        },
        {
          inlineHeading: "AS 1684",
          inlineParagraph:
            "Guidelines for timber framing, relevant in constructing retail and commercial interiors.",
        },
        {
          inlineHeading: "AS 3740",
          inlineParagraph:
            "Waterproofing standards, applicable to wet areas in cafes and restaurants.",
        },
        {
          inlineHeading: "AS/NZS 3000",
          inlineParagraph:
            "Electrical installations and compliance for commercial fit-outs.",
        },
        {
          inlineHeading: "AS 4586",
          inlineParagraph:
            "Slip resistance standards for flooring in commercial and retail spaces.",
        },
      ],
    },
    {
      slug: "skip-bins",
      listHeading: "Skip bin codes & compliance",
      list: [
        {
          inlineHeading: "AS 3816",
          inlineParagraph: "Waste handling and disposal guidelines.",
        },
        {
          inlineHeading: "AS/NZS 5021",
          inlineParagraph: "Waste container and transport standards.",
        },
        {
          inlineHeading: "AS 5377",
          inlineParagraph: "E-waste recycling.",
        },
        {
          inlineHeading: "AS/NZS 3831",
          inlineParagraph: "Safe hazardous waste disposal.",
        },
        {
          inlineHeading: "AS 4123.7",
          inlineParagraph: "Waste bin design and segregation.",
        },
      ],
    },
    {
      slug: "solar-energy",
      listHeading: "Solar power codes & compliance",
      list: [
        {
          inlineHeading: "AS/NZS 5033",
          inlineParagraph: "PV installation and safety standards.",
        },
        {
          inlineHeading: "AS 4777",
          inlineParagraph: "Grid connection standards via inverters.",
        },
        {
          inlineHeading: "AS/NZS 3000",
          inlineParagraph: "Wiring rules for solar systems.",
        },
        {
          inlineHeading: "AS/NZS 1170.2",
          inlineParagraph: "Wind load standards for mounting systems.",
        },
        {
          inlineHeading: "Clean Energy Council Guidelines",
          inlineParagraph: "Certification for installers.",
        },
      ],
    },
    {
      slug: "staircases",
      listHeading: "Staircase codes & compliance",
      list: [
        {
          inlineHeading: "AS 1657",
          inlineParagraph: "Safe design for platforms, walkways, and stairs.",
        },
        {
          inlineHeading: "AS 1428",
          inlineParagraph:
            "Accessibility guidelines for ramps, stairs, and handrails.",
        },
        {
          inlineHeading: "AS/NZS 1170.1",
          inlineParagraph: "Structural safety for load-bearing capacity.",
        },
        {
          inlineHeading: "AS 2047",
          inlineParagraph: "Glass safety standards for balustrades.",
        },
        {
          inlineHeading: "AS 4586",
          inlineParagraph: "Slip resistance for stair surfaces.",
        },
      ],
    },
    {
      slug: "stonemasonry",
      listHeading: "Stonemasonry codes & compliance",
      list: [
        {
          "inlineHeading": "AS 3700",
          "inlineParagraph": "Masonry structure safety."
        },
        {
          "inlineHeading": "AS 4456",
          "inlineParagraph": "Stone durability testing."
        },
        {
          "inlineHeading": "AS/NZS 1170.1",
          "inlineParagraph": "Structural safety for stone."
        },
        {
          "inlineHeading": "AS 3958.1",
          "inlineParagraph": "Stone tiling and paving standards."
        },
        {
          "inlineHeading": "AS 1428",
          "inlineParagraph": "Accessibility for ramps."
        },
        {
          "inlineHeading": "Silica safety",
          "inlineParagraph": "New regulations on engineered stone due to dust risks."
        }
      ]
      ,
    },
    {
      
slug: "tiling",
      listHeading: "Tiling codes & compliance",
      list: [
        {
          "inlineHeading": "AS 3958.1",
          "inlineParagraph": "Ceramic tile installation standards."
        },
        {
          "inlineHeading": "AS 3740",
          "inlineParagraph": "Waterproofing for wet areas."
        },
        {
          "inlineHeading": "AS 4586",
          "inlineParagraph": "Slip resistance for tiles."
        },
        {
          "inlineHeading": "AS 4459",
          "inlineParagraph": "Tile durability and moisture testing."
        },
        {
          "inlineHeading": "AS 1428",
          "inlineParagraph": "Accessibility standards for slip-resistant tiles."
        }
      ]
      ,
    },
    {
     
slug: "waterproofing",
      listHeading: "Waterproofing codes & compliance",
      list:[
        {
          "inlineHeading": "AS 3740",
          "inlineParagraph": "Waterproofing for wet areas."
        },
        {
          "inlineHeading": "AS 4654.2",
          "inlineParagraph": "External area waterproofing."
        },
        {
          "inlineHeading": "AS 4858",
          "inlineParagraph": "Membrane durability for wet areas."
        },
        {
          "inlineHeading": "AS 1558",
          "inlineParagraph": "Water stops and joint sealing."
        },
        {
          "inlineHeading": "AS 3600",
          "inlineParagraph": "Concrete waterproofing standards."
        }
      ]
      ,
    },
  ];
  const categoryData = data.filter((item) => item.slug === slug);
  return (
    <>
      {categoryData &&
        categoryData.map((item, index) => {
          return (
            <>
              <div
                key={index}
                className="mx-[5%] xl:mx-auto h-[1200px] sm:h-[800px] xl:w-[1075px] xl:h-[500px] 2xl:h-[700px] 2xl:w-[1440px] bg-about bg-no-repeat bg-center bg-cover bg-opacity-10 rounded-3xl mt-10 lg:mt-40 relative flex items-center"
              >
                <div className="absolute inset-0 p-10 flex justify-center lg:justify-start items-center lg:ml-20">
                  <div className="w-fit">
                    <div className="text-white">
                      <Heading variant={"h2"}>{item.listHeading}</Heading>
                      {item.list && (
                        <ul className="flex flex-col space-y-2 mt-5">
                          {item.list.map((item, index) => {
                            return (
                              <li className={"inline"} key={index}>
                                <SecondaryHeading
                                  variant={"MainParagraphHeading"}
                                  className={"inline font-semibold"}
                                >
                                  {item.inlineHeading}
                                </SecondaryHeading>
                                <Paragraph
                                  variant={"MainParagraph"}
                                  className={"inline"}
                                >
                                  &nbsp;&nbsp;{item.inlineParagraph}
                                </Paragraph>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </>
  );
};

export default CodesCompliance;
