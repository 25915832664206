import React from "react";
import Email from "../../../sections/CustomerDashboard/Settings/Email";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";

const CustomerEmail = () => {
  return (
    <>
    <DashboardMainContainer className={"lg:px-5 lg:pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center"}>Update email address</Heading>
        <div className="flex justify-center mt-[60px] min-h-screen">
          <Email />
        </div>
    </DashboardMainContainer>
    </>
  );
};

export default CustomerEmail;
