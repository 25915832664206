import React, { useEffect, useState } from "react";
import RatingStars from "../../components/common/RatingStars/RatingStars";
import { useServiceReviews } from "../../Hooks/useServices";
import ProgressBar from "../../components/common/ProgressBar/ProgressBar";
import ReviewSubmissionForm from "../../components/common/ReviewForm/ReviewSubmissionForm";
import { useLocation } from "react-router-dom";
import SpecialText from "../../components/common/Typography/SpecialText";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";

const PosterRanking = ({
  data,
  ListingId,
  AuthnicateData,
  listRating,
  showMe,
  show,
}) => {
  const getReactionInfo = AuthnicateData?.data?.$values.find(
    (e) => e.id?.toString() === ListingId.toString()
  );
  const location = useLocation();
  const { data: reviewsData, status } = useServiceReviews(ListingId);
  const [ReviewsList, setReviewsList] = useState();
  useEffect(() => {
    const sortOrderByDescbyRating = (List) => {
      let sortedDsc = List?.sort((a, b) => {
        return parseInt(b.starRating) - parseInt(a.starRating);
      });
      return sortedDsc;
    };
    setReviewsList(sortOrderByDescbyRating(reviewsData?.data?.key?.$values));
  }, [ListingId, reviewsData]);
  const dataList = [
    {
      heading: "Communication",
      number: 5,
    },
    {
      heading: "Reliability",
      number: 4,
    },
    {
      heading: "Satisfaction",
      number: 3,
    },
    {
      heading: "Recommend",
      number: 2,
    },
    {
      heading: "Punctuality",
      number: 1,
    },
  ];
  return (
    <>
      <div
        className={
          location?.pathname?.includes("customer-dashboard") ||
          location?.pathname?.includes("business-dashboard") ||
          location?.pathname?.includes("poster-profile")
            ? "max-w-full md:max-w-full xl:max-w-full 2xl:max-w-full h-[300px] xl:h-[400px] 2xl:h-[400px] bg-white lg:bg-pastalblue text-offblack rounded-3xl pb-5 px-10 pt-10 xl:pt-[60px] xl:pb-[50px] xl:px-10"
            : "max-w-full md:max-w-full xl:max-w-full 2xl:max-w-full h-[400px] xl:h-[400px] 2xl:h-[400px] bg-white text-offblack rounded-3xl pb-5 px-10 pt-10 xl:pt-10 xl:pb-20 xl:px-10"
        }
      >
        {!show && (
          <div>
              <ReviewSubmissionForm />
          </div>
        )}
        <div className="mt-5 xl:mt-10">
          <div className="grid grid-cols-12 items-center xl:mx-5">
            <div className="col-span-5 md:col-span-6 mr-[15px] xl:mr-[20px]">
              <div className="flex items-center justify-between">
                <AuxiliaryText variant={"FieldLabel"}>Reviews</AuxiliaryText>
                <SpecialText variant={"LargeNumber"}>3k</SpecialText>
              </div>
            </div>
            <div className="col-span-3 md:col-span-3">
              <div
                className={
                  location?.pathname?.includes("customer-dashboard") ||
                  location?.pathname?.includes("business-dashboard")
                    ? "ml-2 md:ml-10"
                    : "ml-2 md:ml-10"
                }
              >
                <AuxiliaryText variant={"FieldLabel"}>
                  Overall rating
                </AuxiliaryText>
              </div>
            </div>
            <div className="col-span-4 md:col-span-3 flex justify-end">
              <span className="flex items-center gap-2">
                {/* <Rating
                  value={getReactionInfo?.listRating ?? listRating}
                  stars={1}
                  style={{ gap: 5 }}
                  cancel={false}
                  defaultValue={getReactionInfo?.listRating}
                /> */}
                <div className="flex justify-center">
                  <SpecialText variant={"LargeNumber"}>4.3</SpecialText>
                  {/* <SpecialText variant={"Counter"} className={"mt-5 ml-1"}>
                    /5
                  </SpecialText> */}
                </div>
              </span>
            </div>
          </div>
          <div className="mt-5">
            {dataList.map((e, index) => {
              return (
                <>
                  <div key={index} className="grid grid-cols-12 items-center h-4 mb-3 xl:mx-5">
                    <div className="col-span-5 md:col-span-6 mr-[15px] xl:mr-[20px]">
                      <ProgressBar
                        number={e.number}
                        percent={
                          ReviewsList?.filter((e) => e.starRating === e.number)
                            .length
                        }
                        className={"h-3"}
                      />
                    </div>
                    <div className="col-span-3 md:col-span-2">
                      <div className={"ml-2 md:ml-10"}>
                        <AuxiliaryText variant={"Placeholder"}>
                          {e.heading}
                        </AuxiliaryText>
                      </div>
                    </div>
                    <div className="col-span-4 md:col-span-4 flex justify-end">
                      <span className="flex items-center">
                        <RatingStars
                          rating={getReactionInfo?.listRating ?? listRating}
                        />
                        <SpecialText variant={"Counter"}>4.1</SpecialText>
                      </span>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default PosterRanking;
