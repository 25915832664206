import { useFormik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  initial_values,
  validationSchema,
} from "../../../FormValidations/AccountManagementSchema";
import ChangePassword from "../../../sections/BusinessDashboard/Settings/ChangePassword";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";

const ResetPassword = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: initial_values,
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {},
  });
  return (
    <>
      <DashboardMainContainer className={"lg:px-5 lg:pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center"}>
          Reset password
        </Heading>
        <div className="flex justify-center min-h-screen mt-[60px]">
          <form onSubmit={formik.handleSubmit}>
            <ChangePassword />
          </form>
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default ResetPassword;
