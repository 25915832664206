import React from "react";
import Heading from "../../components/common/Typography/Heading";
import Paragraph from "../../components/common/Typography/Paragraph";
import MainButton from "../../components/common/MainButton/MainButton";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";

const LastSection = () => {
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1075px] mt-10 sm:mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          Begin your journey
        </Heading>
        <div className="flex flex-col md:flex-row items-center gap-8 mt-7 sm:mt-10">
          <div className="w-full md:w-1/2 rounded-3xl rounded-br-none bg-white flex flex-col pt-10">
            <div className="flex-grow">
              <SecondaryHeading variant={"MainParagraphHeading"} className={"pl-10"}>
                Join as a business
              </SecondaryHeading>
              <Paragraph
                variant={"MainParagraph"}
                className={"text-offblack mt-3 lg:mt-5 pl-10"}
              >
                Showcase your trade services and reach more customers. Create
                your profile, list services, and connect directly with clients.
              </Paragraph>
              <div className={"flex justify-center mt-10"}>
                <MainButton variant={"extralarge"}>
                  Sign up your business
                </MainButton>
              </div>
            </div>
            <div className="flex items-center justify-end h-[400px] mt-5">
              <img
                src={require("../../assets/img/left-image.jpg")}
                className="w-[90%] h-full rounded-l-3xl rounded-bl-none"
                alt=""
              />
            </div>
          </div>

          <div className="w-full md:w-1/2 rounded-3xl rounded-bl-none bg-white flex flex-col pt-10">
            <div className="flex-grow pl-10">
              <SecondaryHeading variant={"MainParagraphHeading"}>
                Join as customer
              </SecondaryHeading>
              <Paragraph
                variant={"MainParagraph"}
                className={"text-offblack mt-3 lg:mt-5"}
              >
                Easily find and hire verified professionals. Browse services,
                request quotes, and get the job done.
              </Paragraph>
              <div className={"flex justify-center mt-10"}>
                <MainButton variant={"extralarge"}>
                  Sign up as a customer
                </MainButton>
              </div>
            </div>
            <div className="flex items-center justify-start h-[400px] mt-5">
              <img
                src={require("../../assets/img/right-image.jpg")}
                className="w-[90%] h-full rounded-r-3xl rounded-br-none"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LastSection;
