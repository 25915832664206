import React from "react";
import TextFiled from "../../../../components/common/InputField/TextFiled";
import Snippet from "../../../../components/common/SnippetIcon/Snippet";
import { Chips } from "primereact/chips";
import TextArea from "../../../../components/common/InputField/TextArea";

const PostDetails = () => {
  return (
    <>
      <div className="w-fit mx-auto mt-[60px]">
        <div>
          <TextFiled
            variant={"large"}
            label={"Post Title"}
            className={"lg:bg-pastalblue"}
            //   onChange={formik?.handleChange}
            //   value={formik?.values?.listTitle}
            id="listTitle"
            name="listTitle"
            placeholder={"Write service post title"}
            star={true}
          />
        </div>
        <div className="mt-5">
          <Snippet
            variant={"label"}
            label={"Keywords"}
            text={"Add keywords to boost search visibility."}
            snippet={true}
            star={true}
          />
          <Chips
            id="addyourtags"
            placeholder="Tag your post with relevant keywords"
            className="custom-chips-bg placeholder-lightgray text-offblack px-2 w-full min-h-[46px] sm:min-h-[50px] md:min-h-[54px] lg:min-h-[56px] rounded-lg "
            //   value={formik?.values?.listingTags}
            //   onChange={(e) => {
            //     formik.setFieldValue("listingTags", e.target.value);
            //     // setListingTags(e.target.value);
            //   }}
            separator=","
          />
        </div>
        <div className="mt-5">
          <TextArea
            variant={"large"}
            star={true}
            className={"lg:bg-pastalblue"}
            //   onChange={formik?.handleChange}
            //   value={formik?.values?.description}
            name="description"
            label={"Post Description"}
            placeholder={"Provide a detailed description of your services post"}
          />
        </div>
      </div>
    </>
  );
};

export default PostDetails;
