import React from "react";
import { Link, useParams } from "react-router-dom";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";

const Regulators = () => {
  const { slug } = useParams();
  const data = [
    {
      slug: "architectural",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "National building codes.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Design standards..",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "AIA",
              inlineParagraph: "Represents architects.",
              link: "https://www.architects.org.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Architects Board",
              inlineParagraph: "Licensing.",
              link: "https://www.architects.nsw.gov.au/",
            },
            {
              inlineHeading: "ARBV",
              inlineParagraph: "Compliance in Victoria.",
              link: "https://www.arbv.vic.gov.au/",
            },
            {
              inlineHeading: "BOAQ",
              inlineParagraph: "QLD standards.",
              link: "https://www.boaq.qld.gov.au/",
            },
            {
              inlineHeading: "WA Architects Board",
              inlineParagraph: "WA compliance.",
              link: "https://www.architectsboardwa.com.au/",
            },
            {
              inlineHeading: "SA Architects Board",
              inlineParagraph: "SA compliance.",
              link: "https://www.archboardsa.org.au/",
            },
            {
              inlineHeading: "CBOS Tasmania",
              inlineParagraph: "TAS regulation.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT compliance.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AIA",
              inlineParagraph: "Advocacy and resources.",
              link: "https://www.architects.org.au/",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Support in construction.",
              link: "https://www.masterbuilders.com.au/",
            },
          ],
        },
        {
          listHeading: "Local councils",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local architectural rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "air-conditioning-and-heating",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "HVAC codes.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "HVAC standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Safety standards.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
            {
              inlineHeading: "ARC",
              inlineParagraph: "Refrigerant compliance.",
              link: "https://www.arctick.org/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW licensing.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria compliance.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD standards.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "Building and Energy WA",
              inlineParagraph: "WA regulation.",
              link: "https://www.commerce.wa.gov.au/building-and-energy",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS standards.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT compliance.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT regulation.",
              link: "https://nt.gov.au/industry/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AIRAH",
              inlineParagraph: "Training & resources.",
              link: "https://www.airah.org.au/",
            },
            {
              inlineHeading: "AMCA",
              inlineParagraph: "Contractor network.",
              link: "https://www.amca.com.au/",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Construction support.",
              link: "https://www.masterbuilders.com.au/",
            },
          ],
        },
        {
          listHeading: "Local councils",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local air conditioning & heating rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "bricklaying",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "Masonry codes.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Masonry standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Masonry safety.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Industry body.",
              link: "https://www.masterbuilders.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD regulation.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA compliance.",
              link: "https://www.commerce.wa.gov.au/building-and-energy",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS regulation.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT compliance.",
              link: "https://nt.gov.au/industry/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Professional support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Housing & masonry compliance.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "Local councils",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local bricklaying  rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "cabinet-making",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "Sets codes for safe cabinetry.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Cabinet making standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "ACFA",
              inlineParagraph: "National representation.",
              link: "https://www.acfa.org.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW licensing.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria compliance.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD standards.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA standards.",
              link: "https://www.commerce.wa.gov.au/building-and-energy",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA compliance.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS Tasmania",
              inlineParagraph: "TAS regulation.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT compliance.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "ACFA",
              inlineParagraph: "Advocacy and resources.",
              link: "https://www.acfa.org.au/",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Support for cabinetry.",
              link: "https://www.masterbuilders.com.au/",
            },
          ],
        },
        {
          listHeading: "Local councils",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local cabinetry rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "carpentry",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "Timber construction standards.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Carpentry guidelines.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Nationwide representation.",
              link: "https://www.masterbuilders.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW",
              inlineParagraph: "Fair Trading.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VIC",
              inlineParagraph: "VBA compliance.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QLD",
              inlineParagraph: "QBCC standards.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "WA",
              inlineParagraph: "DMIRS regulation.",
              link: "https://www.commerce.wa.gov.au/building-and-energy",
            },
            {
              inlineHeading: "SA",
              inlineParagraph: "OTR safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "TAS",
              inlineParagraph: "CBOS regulation.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT",
              inlineParagraph: "Planning compliance.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT",
              inlineParagraph: "Building standards.",
              link: "https://nt.gov.au/industry/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Support.",
              link: "https://www.masterbuilders.com.au/",
            },
          ],
        },
        {
          listHeading: "Local councils",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local carpentry rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "carpet-and-flooring",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "Flooring safety standards.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Installation guidelines.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "FCAA",
              inlineParagraph: "National representation.",
              link: "https://www.fcaa.org.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "Licensing.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria compliance.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "Queensland standards.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA regulation.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS Tasmania",
              inlineParagraph: "TAS regulation.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "Compliance.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "FCAA",
              inlineParagraph: "Advocacy and resources.",
              link: "https://www.fcaa.org.au/",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Support for flooring.",
              link: "https://www.masterbuilders.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council guide",
          list: [
            {
              inlineHeading: "Local councils",
              inlineParagraph: "Find local regulations.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "caulking",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Sets guidelines for sealing materials.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Represents caulking in construction.",
              link: "https://www.masterbuilders.com.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "Manages licensing in NSW.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Oversees compliance in Victoria.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "Regulates standards in Queensland.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "Ensures compliance in WA.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "Oversees safety in SA.",
              link: "https://www.otr.sa.gov.au",
            },
            {
              inlineHeading: "CBOS Tasmania",
              inlineParagraph: "Manages regulations in TAS.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "Ensures compliance in ACT.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "Sets standards in NT.",
              link: "https://www.nt.gov.au",
            },
          ],
        },
        {
          listHeading: "Local councils guide",
          list: [
            {
              inlineHeading: "Local councils",
              inlineParagraph: "Access specific caulking regulations.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "cleaning-services",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Safety standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "Cleaning Council",
              inlineParagraph: "Representation.",
              link: "https://www.cleaningcouncil.org.au",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Builders' cleaning.",
              link: "https://www.masterbuilders.com.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW",
              inlineParagraph: "Fair Trading.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VIC",
              inlineParagraph: "VBA standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QLD",
              inlineParagraph: "QBCC regulations.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "WA",
              inlineParagraph: "DMIRS safety.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "SA",
              inlineParagraph: "OTR cleaning.",
              link: "https://www.otr.sa.gov.au",
            },
            {
              inlineHeading: "TAS",
              inlineParagraph: "CBOS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT",
              inlineParagraph: "Planning rules.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT",
              inlineParagraph: "Building standards.",
              link: "https://nt.gov.au/industry/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Cleaning Council",
              inlineParagraph: "Advocacy.",
              link: "https://www.cleaningcouncil.org.au",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Support.",
              link: "https://www.masterbuilders.com.au",
            },
          ],
        },
        {
          listHeading: "Local council guide",
          list: [
            {
              inlineHeading: "Local councils",
              inlineParagraph: "Local cleaning rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "concrete-and-paving",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Concrete/paving standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Contractor representation.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "ACRA",
              inlineParagraph: "Maintenance guidelines.",
              link: "https://www.acra.org.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW",
              inlineParagraph: "Fair Trading compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VIC",
              inlineParagraph: "VBA standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QLD",
              inlineParagraph: "QBCC compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "WA",
              inlineParagraph: "DMIRS standards.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "SA",
              inlineParagraph: "OTR compliance.",
              link: "https://www.sa.gov.au/otr",
            },
            {
              inlineHeading: "TAS",
              inlineParagraph: "CBOS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT",
              inlineParagraph: "Planning regulations.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT",
              inlineParagraph: "Building standards.",
              link: "https://nt.gov.au/industry/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "ACRA",
              inlineParagraph: "Repair advocacy.",
              link: "https://www.acra.org.au",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Paving support.",
              link: "https://www.masterbuilders.com.au",
            },
          ],
        },
        {
          listHeading: "Local council guide",
          list: [
            {
              inlineHeading: "Local councils",
              inlineParagraph: "Local concrete rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "conveyancing",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "AIC",
              inlineParagraph: "National conveyancer support.",
              link: "https://www.aic.gov.au",
            },
            {
              inlineHeading: "ACCC",
              inlineParagraph: "Fair trading in property.",
              link: "https://www.accc.gov.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD oversight.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA regulation.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA compliance.",
              link: "https://www.sa.gov.au/topics/business-and-trade/otr",
            },
            {
              inlineHeading: "CBOS Tasmania",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-construction",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AIC State Branches",
              inlineParagraph: "State support.",
              link: "https://www.aic.gov.au/state-branches",
            },
            {
              inlineHeading: "Property Council",
              inlineParagraph: "Property advocacy.",
              link: "https://www.propertycouncil.com.au",
            },
            {
              inlineHeading: "REIA",
              inlineParagraph: "Transaction insights.",
              link: "https://www.reia.com.au",
            },
          ],
        },
        {
          listHeading: "Local council conveyancing guide",
          list: [
            {
              inlineHeading: "Find local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "blinds-and-curtains",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "Codes for coverings.",
              link: "https://www.abcb.gov.au",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Standards compliance.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "BIAA",
              inlineParagraph: "Industry support.",
              link: "https://www.biaa.com.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA regulation.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA compliance.",
              link: "https://www.sa.gov.au/topics/business-and-trade/otr",
            },
            {
              inlineHeading: "CBOS Tasmania",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-construction",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "BIAA State Branches",
              inlineParagraph: "State support.",
              link: "https://www.biaa.com.au",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Construction support.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Housing industry advocacy.",
              link: "https://hia.com.au",
            },
          ],
        },
        {
          listHeading: "Local council window covering guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "decking-and-gazebos",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Decking & gazebo standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "MBA",
              inlineParagraph: "Construction support.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Residential compliance.",
              link: "https://hia.com.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA regulation.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/otr",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Builders' support.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Residential sector.",
              link: "https://hia.com.au",
            },
            {
              inlineHeading: "DIA",
              inlineParagraph: "Decking sector.",
              link: "https://www.deckingindustry.com.au",
            },
          ],
        },
        {
          listHeading: "Local council guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "demolition-and-excavation",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Demolition standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "MBA",
              inlineParagraph: "Industry support.",
              link: "https://www.mbansw.asn.au",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Safety rules.",
              link: "https://www.safeworkaustralia.gov.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/planning-and-property/otr",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-construction",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Support.",
              link: "https://www.mbansw.asn.au",
            },
            {
              inlineHeading: "ADIA",
              inlineParagraph: "Industry body.",
              link: "https://www.adia.org.au",
            },
          ],
        },
        {
          listHeading: "Local council guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "electrical",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Electrical standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "MEA",
              inlineParagraph: "Best practices.",
              link: "https://www.mea.asn.au",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Safety rules.",
              link: "https://www.safeworkaustralia.gov.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/planning-and-property/otr",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-construction",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MEA",
              inlineParagraph: "Electricians’ support.",
              link: "https://www.mea.asn.au",
            },
            {
              inlineHeading: "NECA",
              inlineParagraph: "Industry body.",
              link: "https://www.neca.asn.au",
            },
            {
              inlineHeading: "ETU",
              inlineParagraph: "Electricians’ rights.",
              link: "https://www.etu.org.au",
            },
          ],
        },
        {
          listHeading: "Local council electrical guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "engineering-services",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "Building codes.",
              link: "https://www.abcb.gov.au",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Engineering standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "Engineers Australia",
              inlineParagraph: "Professional body.",
              link: "https://www.engineersaustralia.org.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW licensing.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria compliance.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "BPEQ",
              inlineParagraph: "QLD registration.",
              link: "https://www.bpeq.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA regulation.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Engineers Australia",
              inlineParagraph: "Professional support.",
              link: "https://www.engineersaustralia.org.au",
            },
            {
              inlineHeading: "Consult Australia",
              inlineParagraph: "Industry advocacy.",
              link: "https://www.consultaustralia.com.au",
            },
          ],
        },
        {
          listHeading: "Local council engineering guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "fence-and-gates",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Fencing standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Australia (MBA)",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "AFIA",
              inlineParagraph: "Fencing industry body.",
              link: "https://www.afia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/planning-and-property",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-energyefficiency",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AFIA",
              inlineParagraph: "Fencing support.",
              link: "https://www.afia.com.au/",
            },
            {
              inlineHeading: "MBA",
              inlineParagraph: "Builders' network.",
              link: "https://www.masterbuilders.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council fencing & gate guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "glazing-and-screens",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Glazing standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Glaziers Australia (MGA)",
              inlineParagraph: "Best practices.",
              link: "https://www.mga.org.au/",
            },
            {
              inlineHeading: "AGGA",
              inlineParagraph: "Glass industry guidelines.",
              link: "https://www.agga.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/planning-and-property",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-energyefficiency",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MGA",
              inlineParagraph: "Glazier support.",
              link: "https://www.mga.org.au/",
            },
            {
              inlineHeading: "AGGA",
              inlineParagraph: "Glass industry advocacy.",
              link: "https://www.agga.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council glazing & screen guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "garden-and-landscaping",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Landscaping standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "LIA",
              inlineParagraph: "Industry best practices.",
              link: "https://www.landscape.org.au/",
            },
            {
              inlineHeading: "AILA",
              inlineParagraph: "Environmental compliance.",
              link: "https://www.aila.org.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/planning-and-property",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-energyefficiency",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "LIA",
              inlineParagraph: "Landscaping support.",
              link: "https://www.landscape.org.au/",
            },
            {
              inlineHeading: "AILA",
              inlineParagraph: "Landscape design advocacy.",
              link: "https://www.aila.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council landscaping guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "handyman",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "General maintenance standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Australia (MBA)",
              inlineParagraph: "Building industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association (HIA)",
              inlineParagraph: "Handyman services advocacy.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.commerce.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-employment/occupational-health-and-safety",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://www.nt.gov.au/",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Handyman support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Handyman services.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council handyman guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "inspection-and-surveyors",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Inspection & surveying standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "AIBS",
              inlineParagraph: "Building surveyor support.",
              link: "https://www.aibs.com.au/",
            },
            {
              inlineHeading: "SSSI",
              inlineParagraph: "Surveying professionals.",
              link: "https://www.sssi.org.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.commerce.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-employment/occupational-health-and-safety",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://www.nt.gov.au/",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AIBS",
              inlineParagraph: "Surveyor support.",
              link: "https://www.aibs.com.au/",
            },
            {
              inlineHeading: "SSSI",
              inlineParagraph: "Surveying representation.",
              link: "https://www.sssi.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council inspection & surveying guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "security-and-automation",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Security & automation standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "ASIAL",
              inlineParagraph: "Security industry body.",
              link: "https://www.asial.com.au/",
            },
            {
              inlineHeading: "Master Electricians Australia (MEA)",
              inlineParagraph: "Electrical & automation support.",
              link: "https://www.masterelectricians.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.commerce.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-employment/occupational-health-and-safety",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://www.nt.gov.au/",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "ASIAL",
              inlineParagraph: "Security professionals.",
              link: "https://www.asial.com.au/",
            },
            {
              inlineHeading: "MEA",
              inlineParagraph: "Electrical & automation specialists.",
              link: "https://www.masterelectricians.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council security & automation guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "insulation-services",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Insulation standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "AIMG",
              inlineParagraph: "Manufacturer support.",
              link: "https://www.aimg.org.au",
            },
            {
              inlineHeading: "Clean Energy Council",
              inlineParagraph: "Sustainable practices.",
              link: "https://www.cleanenergycouncil.org.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/consumer-protection/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AIMG",
              inlineParagraph: "Insulation advocacy.",
              link: "https://www.aimg.org.au",
            },
            {
              inlineHeading: "CEC",
              inlineParagraph: "Energy & insulation.",
              link: "https://www.cleanenergycouncil.org.au",
            },
          ],
        },
        {
          listHeading: "Local council insulation guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "interior-design",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Accessibility, Acoustics.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "DIA",
              inlineParagraph: "Interior design representation.",
              link: "https://www.dia.org.au",
            },
            {
              inlineHeading: "GBCA",
              inlineParagraph: "Sustainable design.",
              link: "https://www.gbca.org.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW design compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria safety.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD standards.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA compliance.",
              link: "https://www.sa.gov.au/topics/business-and-trade/consumer-protection/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS guidelines.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "DIA",
              inlineParagraph: "Professional support.",
              link: "https://www.dia.org.au",
            },
            {
              inlineHeading: "GBCA",
              inlineParagraph: "Green practices.",
              link: "https://www.gbca.org.au",
            },
            {
              inlineHeading: "AIDA",
              inlineParagraph: "Interior design excellence.",
              link: "https://www.aida.org.au",
            },
          ],
        },
        {
          listHeading: "Local council interior design  guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "locksmiths",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Lock & security standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "MLAA",
              inlineParagraph: "Locksmith body.",
              link: "https://www.mlaa.org.au",
            },
            {
              inlineHeading: "SPAAL",
              inlineParagraph: "Security compliance.",
              link: "https://www.spaal.org.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD regulation.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/consumer-protection/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MLAA",
              inlineParagraph: "Locksmith support.",
              link: "https://www.mlaa.org.au",
            },
            {
              inlineHeading: "SPAAL",
              inlineParagraph: "Security services.",
              link: "https://www.spaal.org.au",
            },
          ],
        },
        {
          listHeading: "Local council locksmith guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "painting-and-decorating",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Painting standards & safety.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master painters Australia",
              inlineParagraph: "Industry representation.",
              link: "https://www.masterpainters.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Compliance support.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.cbs.sa.gov.au/otr",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-technical/building-advisory-service",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Master Painters Australia",
              inlineParagraph: "Professional support.",
              link: "https://www.masterpainters.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Industry advocacy.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council painting & decoration guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "moving-and-storage",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "AFRA",
              inlineParagraph: "Moving standards & accreditation.",
              link: "https://www.afra.com.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Safety compliance.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
            {
              inlineHeading: "ACCC",
              inlineParagraph: "Consumer protection.",
              link: "https://www.accc.gov.au/",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AFRA",
              inlineParagraph: "Professional movers.",
              link: "https://www.afra.com.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Workplace safety.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
          ],
        },
        {
          listHeading: "Local council moving guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules & permits.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "mold-and-asbestos",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Asbestos safety.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
            {
              inlineHeading: "NATA",
              inlineParagraph: "Testing accreditation.",
              link: "https://www.nata.com.au/",
            },
            {
              inlineHeading: "EnHealth",
              inlineParagraph: "Mould management guidelines.",
              link: "https://www.health.gov.au/health-topics/environmental-health/enhealth",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.cbs.sa.gov.au/otr",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-technical/building-advisory-service",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "NATA",
              inlineParagraph: "Testing support.",
              link: "https://www.nata.com.au/",
            },
            {
              inlineHeading: "EnHealth",
              inlineParagraph: "Indoor air quality.",
              link: "https://www.health.gov.au/health-topics/environmental-health/enhealth",
            },
          ],
        },
        {
          listHeading: "Local council mould & asbestos guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "pest-control",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Pest control standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "AEPMA",
              inlineParagraph: "Industry representation.",
              link: "https://www.aepma.com.au/",
            },
            {
              inlineHeading: "NPMA",
              inlineParagraph: "Compliance support.",
              link: "https://www.pestworld.org/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-energysaving/building-advisory-services",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AEPMA",
              inlineParagraph: "Pest management support.",
              link: "https://www.aepma.com.au/",
            },
            {
              inlineHeading: "NPMA",
              inlineParagraph: "Safety & compliance.",
              link: "https://www.pestworld.org/",
            },
          ],
        },
        {
          listHeading: "Local council pest control guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "plastering",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Plastering standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Plasterers Australia",
              inlineParagraph: "Industry representation.",
              link: "https://www.masterplasterers.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-energysaving/building-advisory-services",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Master Plasterers Australia",
              inlineParagraph: "Professional support.",
              link: "https://www.masterplasterers.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Plastering advocacy.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council plastering guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "plumbing",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "National plumbing codes.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Plumbing standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Workplace safety.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
            {
              inlineHeading: "Master Plumbers Australia",
              inlineParagraph: "Industry body.",
              link: "https://www.masterplumbers.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD regulation.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA compliance.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS regulation.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT compliance.",
              link: "https://nt.gov.au/industry/building-and-energysaving/building-advisory-services",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Master Plumbers",
              inlineParagraph: "Professional support.",
              link: "https://www.masterplumbers.com.au/",
            },
            {
              inlineHeading: "Plumbing Industry Association",
              inlineParagraph: "Advocacy & training.",
              link: "https://www.plumber.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Housing & plumbing compliance.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "NFIA",
              inlineParagraph: "Fire safety systems.",
              link: "https://www.nfia.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council plumbing guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },

    {
      slug: "pools-and-spas",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Pool & spa standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "SPASA",
              inlineParagraph: "Industry compliance.",
              link: "https://www.spasa.com.au/",
            },
            {
              inlineHeading: "RLSSA",
              inlineParagraph: "Drowning prevention.",
              link: "https://www.royallifesaving.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-energysaving/building-advisory-services",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "SPASA",
              inlineParagraph: "Pool & spa support.",
              link: "https://www.spasa.com.au/",
            },
            {
              inlineHeading: "RLSSA",
              inlineParagraph: "Water safety.",
              link: "https://www.royallifesaving.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council pool & spa guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "rendering",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Rendering standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association (MBA)",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association (HIA)",
              inlineParagraph: "Compliance advocacy.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-energysaving/building-advisory-services",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Rendering support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Building compliance.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council rendering guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "renovation-and-restoration",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Building & restoration standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association (MBA)",
              inlineParagraph: "Renovation support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association (HIA)",
              inlineParagraph: "Compliance in renovations.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/industry/building-and-energysaving/building-advisory-services",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Building support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Renovation advocacy.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council renovation & restoration guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },

    {
      slug: "roofing",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Roofing standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association (MBA)",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association (HIA)",
              inlineParagraph: "Compliance & safety.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://www.nt.gov.au/",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Roofing professionals.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Roofing advocacy.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council roofing guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "scaffolding",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Scaffolding standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association (MBA)",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Safety compliance.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://www.nt.gov.au/",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Scaffolding professionals.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Workplace safety.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
          ],
        },
        {
          listHeading: "Local council scaffolding guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "shopfitting",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Shopfitting standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association (MBA)",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association (HIA)",
              inlineParagraph: "Compliance & safety.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://www.nt.gov.au/",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Shopfitting professionals.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Industry advocacy.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council shopfitting guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "skip-bins",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Waste & bin standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "EPA",
              inlineParagraph: "Environmental safety.",
              link: "https://www.epa.gov.au",
            },
            {
              inlineHeading: "WCRA",
              inlineParagraph: "Waste management advocacy.",
              link: "https://www.wcra.com.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW EPA",
              inlineParagraph: "NSW compliance.",
              link: "https://www.epa.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DWER WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dwer.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.cbs.sa.gov.au",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://www.nt.gov.au/industry/building-and-technical-regulation",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "WCRA",
              inlineParagraph: "Waste professionals.",
              link: "https://www.wcra.com.au",
            },
            {
              inlineHeading: "EPA",
              inlineParagraph: "Environmental protection.",
              link: "https://www.epa.gov.au",
            },
          ],
        },
        {
          listHeading: "Local council skip bin guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "solar-energy",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Clean Energy Council (CEC)",
              inlineParagraph: "Accreditation & best practices.",
              link: "https://www.cleanenergycouncil.org.au",
            },
            {
              inlineHeading: "Australian Energy Regulator (AER)",
              inlineParagraph: "Energy regulation.",
              link: "https://www.aer.gov.au",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Solar safety standards.",
              link: "https://www.standards.org.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.cbs.sa.gov.au",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://www.nt.gov.au/industry/building-and-technical-regulation",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "CEC",
              inlineParagraph: "Solar industry support.",
              link: "https://www.cleanenergycouncil.org.au",
            },
            {
              inlineHeading: "AER",
              inlineParagraph: "Energy compliance.",
              link: "https://www.aer.gov.au",
            },
          ],
        },
        {
          listHeading: "Local council solar energy guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "staircases",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Staircase standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "Master Builders Association (MBA)",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "Housing Industry Association (HIA)",
              inlineParagraph: "Compliance in construction.",
              link: "https://hia.com.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.cbs.sa.gov.au",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://www.nt.gov.au/industry/building-and-technical-regulation",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Builders' network.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Industry advocacy.",
              link: "https://hia.com.au",
            },
          ],
        },
        {
          listHeading: "Local council staircase guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },

    {
      slug: "stonemasonry",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Stonemasonry standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association (MBA)",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association (HIA)",
              inlineParagraph: "Compliance in stonemasonry.",
              link: "https://www.hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://www.nt.gov.au/industry/building-and-construction",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Stonemason network.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Industry advocacy.",
              link: "https://www.hia.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council stonemasonry guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },

    {
      slug: "tiling",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Tiling standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association (MBA)",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association (HIA)",
              inlineParagraph: "Compliance & safety.",
              link: "https://www.hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://www.nt.gov.au/industry/building-and-construction",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Tiler network.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Industry advocacy.",
              link: "https://www.hia.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council tiling guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "waterproofing",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Waterproofing standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "National Construction Code (NCC)",
              inlineParagraph: "National compliance.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Master Builders Association (MBA)",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association (HIA)",
              inlineParagraph: "Compliance advocacy.",
              link: "https://www.hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA regulations.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://www.nt.gov.au/industry/building-and-construction",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Waterproofing professionals.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Industry advocacy.",
              link: "https://www.hia.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council waterproofing guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
  ];
  const categoryData = data.filter((item) => item.slug === slug);
  return (
    <>
      {categoryData.map((item, index) => {
        return (
          <>
            <div
              key={index}
              className="text-offblack mt-10 mx-[5%] xl:mx-auto xl:w-[1075px] 2xl:w-[1440px] px-5"
            >
              {item.regulators && (
                <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-10">
                  {item.regulators.map((item, index) => {
                    return (
                      <div key={index}>
                        <SecondaryHeading
                          variant={"MainParagraphHeading"}
                          className={"inline font-semibold"}
                        >
                          {item.listHeading}
                        </SecondaryHeading>

                        {item.list && (
                          <ul className="flex flex-col mt-3 space-y-2">
                            {item.list.map((item, index) => {
                              return (
                                <li className={"inline"} key={index}>
                                  <Link
                                    className={
                                      item.link
                                        ? "hover:text-primaryblue"
                                        : "cursor-default"
                                    }
                                    to={item.link}
                                    target="_blank"
                                  >
                                    <SecondaryHeading
                                      variant={"MainParagraphHeading"}
                                      className={"inline font-semibold"}
                                    >
                                      {item.inlineHeading}
                                    </SecondaryHeading>
                                  </Link>
                                  <Paragraph
                                    variant={"MainParagraph"}
                                    className={"inline text-offblack"}
                                  >
                                    &nbsp;&nbsp;{item.inlineParagraph}{" "}
                                    &nbsp;&nbsp;{" "}
                                  </Paragraph>
                                </li>
                              );
                            })}
                          </ul>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </>
        );
      })}
    </>
  );
};

export default Regulators;
