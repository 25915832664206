import React from "react";
import SecondaryHeading from "../Typography/SecondaryHeading";
import Paragraph from "../Typography/Paragraph";
import SpecialText from "../Typography/SpecialText";
import { Link } from "react-router-dom";

const BlogDesign = ({ heading, para, timing }) => {
  return (
    <>
      <div className="pt-10 mt-10 border-t border-t-mediumgray">
        <div className="flex gap-3 h-[150px] sm:h-[225px]">
          <div className="w-[140px] h-[150px] sm:w-[200px] sm:h-[225px] flex-shrink-0">
            <img
              src={require("../../../assets/img/default-image.jpg")}
              alt="blog title"
              className="w-full h-full rounded-xl shrink-0"
            />
          </div>
          <div>
            <Link to={"/blogs/article-id-will-be-here"}>
              <SecondaryHeading
                variant={"MainParagraphHeading"}
                className={"hover:text-primaryblue"}
              >
                {heading}
              </SecondaryHeading>
            </Link>
            <Paragraph
              variant={"MainParagraph"}
              className={
                "mt-5 overflow-hidden text-ellipsis h-[58px] sm:h-[77px] lg:h-auto"
              }
            >
              {para}
            </Paragraph>
            <SpecialText
              variant={"DateStamp"}
              className={"sticky top-[100vh] text-offblack"}
            >
              {timing}
            </SpecialText>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDesign;
