import React from "react";
import PostSlider from "./PostSlider";
import { Link } from "react-router-dom";
import SpecialText from "../../../../components/common/Typography/SpecialText";
import InteractiveText from "../../../../components/common/Typography/InteractiveText";

const PostContainer = () => {
  return (
    <>
      <div className="hidden sm:flex items-center mt-5">
        {/* Tabs */}
        <div class="border-b-2 border-lightgray">
          <ul class="flex flex-wrap justify-between -mb-px text-center text-lightgray">
            <li>
              <Link className="flex items-center justify-center gap-2 pb-2 h-5 pl-2.5 pr-20 border-b-2 border-transparent rounded-t-lg text-primaryblue hover:text-primaryblue border-b-primaryblue hover:border-primaryblue group">
                {/* <i className="fi fi-rs-heart text-xs cursor-pointer text-primaryblue"></i> */}
                <InteractiveText variant={"FAQHeader"}>Active</InteractiveText>
                <SpecialText
                  variant={"TabsCounter"}
                  className={"flex items-center justify-center"}
                >
                  23
                </SpecialText>
              </Link>
            </li>
            <li>
              <Link className="flex items-center justify-center gap-2 pb-2 h-5 pr-20 border-b-2 border-transparent rounded-t-lg hover:text-primaryblue hover:border-primaryblue group">
                {/* <i className="fi fi-rr-share-alt-square text-xs cursor-pointer text-lightgray group-hover:text-primaryblue"></i> */}
                <InteractiveText variant={"FAQHeader"}>
                Drafts
                </InteractiveText>
                <SpecialText
                  variant={"TabsCounter"}
                  className={"flex items-center justify-center"}
                >
                  5
                </SpecialText>
              </Link>
            </li>
            <li>
              <Link className="flex items-center justify-center gap-2 pb-2  h-5 pr-20 border-b-2 border-transparent rounded-t-lg hover:text-primaryblue hover:border-primaryblue group">
                {/* <i className="fi fi-rr-share-alt-square text-xs cursor-pointer text-lightgray group-hover:text-primaryblue"></i> */}
                <InteractiveText variant={"FAQHeader"}>Bin</InteractiveText>
                <SpecialText
                  variant={"TabsCounter"}
                  className={"flex items-center justify-center"}
                >
                  8
                </SpecialText>
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <PostSlider />
    </>
  );
};

export default PostContainer;
