import React from "react";
import NotificationLayout from "../../../sections/CustomerDashboard/Inbox/Notifications/NotificationLayout";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";
import SpecialText from "../../../components/common/Typography/SpecialText";

const CustNotifications = () => {
  return (
    <DashboardMainContainer className={"lg:px-5 lg:pb-10 pt-[33px]"}>
      <Heading variant={"h6"} className={"text-center text-offblack"}>
        Alerts and notifications
      </Heading>

      <div className="mx-auto mt-[60px] min-h-screen">
        <NotificationLayout />
        <SpecialText
        variant={"FootNoteDisclaimer"}
        className={"mt-20 text-center text-lightgray"}
      >
        You've reached the end of the list.
      </SpecialText>
      </div>
    </DashboardMainContainer>
  );
};

export default CustNotifications;
