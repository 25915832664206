import React  from "react";
import { useState } from "react";

const MovingRight = ({children}) => {
  const [pauseAnimation, setPauseAnimation] = useState(false);
  
  const handleMouseEnter = () => {
    setPauseAnimation(true);
  };

  const handleMouseLeave = () => {
    setPauseAnimation(false);
  };

  return (
    <>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="sliderContainer"
      >
        <div className="sliderWrapper w-[100%] overflow-hidden">
          <section
           className={`sliderSectionReverse ${pauseAnimation ? "stopAnimation" : ""}`}
           style={{ "--speed": `1000s` }}
          >
           {children}
          </section>
        </div>
      </div>
    </>
  );
};

export default MovingRight;
