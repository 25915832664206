import React from "react";
import Heading from "../../components/common/Typography/Heading";
import MovingRight from "../../components/common/FullLengthSwipers/MovingRight";
import MovingLeft from "../../components/common/FullLengthSwipers/MovingLeft";
import img1 from "../../assets/img/default-image.jpg";
import SwiperCard from "../../components/common/AdsCard/SwiperCard";
const LatestUpdates = () => {
  const data = [
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 1,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 1,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 1,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 1,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 1,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 1,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 1,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 5,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 4,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 3,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
    {
      heading:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      rating: 2,
      company: "Building Experts",
      category:
        "Architecture and Design A b C D E f G H i j k l m n o p q r s t u v w x y z",
      original: img1,
    },
  ];

  return (
    <>
      <div className="pt-10 sm:pt-20 xl:pt-40 w-[100%]">
        <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px]">
            <Heading variant={"h2"} className={"text-offblack"}>
            Browse newest posts
            </Heading>
        </div>
        <div className="mt-7 sm:mt-10 xl:mt-20">
          <MovingLeft>
            {data.map((item, index) => (
              <div className="sliderImg " key={index}>
                <SwiperCard heading={item.heading} Images={item.original} />
              </div>
            ))}
          </MovingLeft>
          <MovingRight>
            {data.map((item, index) => (
              <div className="sliderImg " key={index}>
                <SwiperCard heading={item.heading} Images={item.original} />
              </div>
            ))}
          </MovingRight>
        </div>
      </div>
    </>
  );
};

export default LatestUpdates;
