import { motion } from "framer-motion";
import React from "react";
// import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import CircularButton from "../common/MainButton/CircularButton";

const Popups = (props) => {
  const navigate = useNavigate();
  const dropIn = {
    visible: {
      y: "0",
      opacity: 1,
      transition: {
        duration: 0.9,
        type: "ease",
        damping: 25,
        stiffness: 500,
      },
    },
    exit: {
      y: "100vh",
      opacity: 0,
      transition: {
        duration: 0.9,
        type: "ease",
        damping: 25,
        stiffness: 500,
      },
    },
  };
  return (
    <motion.div
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      className={`fixed top-0 left-0 w-full h-full flex flex-col justify-center items-center p-3 z-50 backdrop-blur-sm bg-neutral-900 bg-opacity-40 md:overflow-auto md:pt-20 lg:overflow-hidden lg:pt-0`}
    >
      <div
        className={`bg-pastalblue rounded-3xl w-[350px] ${props.popupWidth} mx-auto relative`}
      >
        <div className={`${props.crossPosition}`}>
          <button
            className={`size-8 cursor-pointer flex justify-center items-center ${
              !props?.ShowCrossButton ? "hidden" : "block"
            }`}
            onClick={() => {
              props.setTrigger(false);
              props.onClose();
            }}
          >
            <i className="fi fi-rs-cross text-xs text-offblack hover:text-primaryblue cursor-pointer"></i>
          </button>
        </div>
        <div>{props.children}</div>
      </div>
    </motion.div>
  );
};
export default Popups;
