import React from "react";
import Heading from "../../components/common/Typography/Heading";
import MainButton from "../../components/common/MainButton/MainButton";
import { useNavigate } from "react-router-dom";

const HelpSupport = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1000px] 2xl:w-[1075px] mt-10 sm:mt-40 relative">
        <div className="flex items-center justify-between">
          <Heading variant={"h2"} className={"text-offblack"}>
            Can we help?
          </Heading>
          <MainButton
            variant={"medium"}
            className={"mt-5"}
            type="button"
            onClick={() => navigate("/HelpCentre")}
          >
            Help Centre
          </MainButton>
        </div>
        <div className="w-full h-[400px] 2xl:h-[450px] sm:aspect-[1075/450] mt-7 sm:mt-10">
        <img
          src={require("../../assets/img/handshaking.jpg")}
          className="w-full h-full rounded-3xl object-cover"
          alt=""
        />

        </div>
      </div>
    </>
  );
};

export default HelpSupport;
