import React, { useEffect, useState, useRef } from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import TextArea from "../../../components/common/InputField/TextArea";
// import Paragraph from "../../../components/common/Typography/Paragraph";
// import useClickOutside from "../../../Hooks/useClickOutside";
import { Dropdown } from "primereact/dropdown";
import AuxiliaryText from "../../../components/common/Typography/AuxiliaryText";
import SpecialText from "../../../components/common/Typography/SpecialText";

const Deactivation = () => {
  const [selectedReason, setSelectedReason] = useState(null);
  const reasons = [
    { name: "I no longer require services at the moment." },
    { name: "I've found the services I needed through a different source." },
    { name: "The services on the platform did not meet my expectations." },
    { name: "I experienced difficulties navigating the platform." },
    { name: "I have concerns about my privacy and data." },
    { name: "Others" },
  ];
  const feedbackPlaceholder =
    "Your insights are invaluable to us. If you have any additional comments about your experience, encountered difficulties, suggestions for improvement, or compliments, we'd love to hear them.";

  return (
    <>
      <div className="grid grid-cols-12 w-fit mx-auto">
        <div className="col-span-12 w-full">
          <label className={`block mb-2`}>
            <AuxiliaryText variant={"FieldLabel"}>
              Let us know why you're leaving
            </AuxiliaryText>
          </label>
          <div className="custom-dropdown">
            <Dropdown
              value={selectedReason}
              onChange={(e) => setSelectedReason(e.value)}
              options={reasons}
              optionLabel="name"
              placeholder="Please select your reason for leaving"
              panelClassName="custom-dropdown-panel bg-white lg:bg-pastalblue"
              className="custom-dropdown-field p-dropdown-trigger-custom text-offblack flex items-center font-ibmplex font-normal normal-case text-[12px] leading-[16px] tracking-[0.70px] sm:text-[12px] sm:leading-[16px] sm:tracking-[0.70px] md:text-[12px] md:leading-[16px] md:tracking-[0.80px] lg:text-[12px] lg:leading-[16px] lg:tracking-[0.90px] xl:text-[13px] xl:leading-[16px] xl:tracking-[1px] 2xl:text-[13px] 2xl:leading-[16px] 2xl:tracking-[1px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[56px]"
            />
            <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack hover:cursor-pointer custom-dropdown-icon"></i>
          </div>

          <div className="mt-5">
            <TextArea
              variant={"large"}
              label={"Your feedback matters "}
              placeholder={feedbackPlaceholder}
              className="w-full lg:bg-pastalblue"
            />
          </div>
          <div className="mt-10">
            <div className="w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px]">
              <div className="flex items-center">
              <label
                  className="relative flex items-center rounded-full cursor-pointer mr-3"
                  htmlFor="link"
                >
                  <input
                    type="checkbox"
                    className="before:content[''] peer relative size-4 cursor-pointer appearance-none rounded-[4px] border border-mediumgray transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primaryblue checked:bg-primaryblue checked:before:bg-primaryblue"
                    id="link"
                  />
                  <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-[40%] left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                    <i className="fi fi-rs-check text-[10px]"></i>
                  </span>
                </label>
                <SpecialText variant={"FootNoteDisclaimer"}>
                  Account deactivation agreement
                </SpecialText>
              </div>
              <SpecialText
                variant={"FootNoteDisclaimer"}
                className={"w-full pl-7 mt-2"}
              >
                By checking this box, you confirm your intention to deactivate
                your customer account. This action will terminate all ongoing
                interactions and remove access to any preferences or saved data
                on our platform.
              </SpecialText>
            </div>
          </div>
          <div className="flex justify-end mt-10">
            <MainButton variant={"specialredbuttonmedium"}>
              Deactivate
            </MainButton>
          </div>
        </div>
      </div>
    </>
  );
};
export default Deactivation;
