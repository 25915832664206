import React, { useState } from "react";
import SecondaryHeading from "../../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../../components/common/Typography/Paragraph";
import AuxiliaryText from "../../../components/common/Typography/AuxiliaryText";
import { Dropdown } from "primereact/dropdown";
import GeneralInquiry from "./GeneralInquiry";

const ContactUSForms = () => {
  const [selectedReason, setSelectedReason] = useState("");
  const forms = [
    { name: "General inquiry" },
    { name: "Billing questions" },
    { name: "Technical support" },
    { name: "Feedback & suggestions" },
    { name: "Account issues" },
    { name: "Other" },
  ];

  return (
    <>
      <div className="mx-[10%] sm:mx-auto sm:w-[400px] md:w-[450px] lg:w-[500px] xl:w-[616px] 3xl:w-[700px] mt-10 sm:mt-20">
        <SecondaryHeading variant={"MainParagraphHeading"}>
          Questions, comments, or concerns?
        </SecondaryHeading>
        <Paragraph variant={"MainParagraph"} className={"mt-3"}>
          Feel free to reach out. Our dedicated team is available from Monday
          through Friday, 9 am to 5 pm (AET). We aim to respond to all inquiries
          within two business day.
        </Paragraph>
        <div className="mt-10 xl:w-[616px] 3xl:w-[700px] mx-auto">
          <label className={`block mb-2`}>
            <AuxiliaryText variant={"FieldLabel"}>
              Please choose your inquiry type
            </AuxiliaryText>
          </label>
          <div className="custom-dropdown">
            <Dropdown
              value={selectedReason}
              onChange={(e) => setSelectedReason(e.value)}
              options={forms}
              optionLabel="name"
              placeholder={"━"}
              // {"━━━━━━━━━━━━"}
              placeholderClassName="custom-placeholder"
              className="custom-dropdown-paceholder bg-white text-offblack flex items-center font-ibmplex font-normal normal-case text-[12px] leading-[16px] tracking-[0.70px] sm:text-[12px] sm:leading-[16px] sm:tracking-[0.70px] md:text-[12px] md:leading-[16px] md:tracking-[0.80px] lg:text-[12px] lg:leading-[16px] lg:tracking-[0.90px] xl:text-[13px] xl:leading-[16px] xl:tracking-[1px] 2xl:text-[13px] 2xl:leading-[16px] 2xl:tracking-[1px] rounded-lg w-full h-[46px] sm:h-[50px] md:h-[54px] lg:h-[54px] xl:h-[56px] 2xl:h-[56px]"
            />
            <i className="fi fi-rs-angle-down text-xs lg:text-sm text-offblack hover:cursor-pointer custom-dropdown-icon"></i>
          </div>
        </div>
        {/* {selectedReason.name === "-" && <></>} */}
        {selectedReason.name === "General inquiry" && (
          <>
            <div className="mx-auto w-full mt-10">
              <GeneralInquiry />
            </div>
          </>
        )}
        {selectedReason.name === "Billing questions" && (
          <>
            <div className="mx-auto w-full mt-10">
              <GeneralInquiry />
            </div>
          </>
        )}
        {selectedReason.name === "Technical support" && (
          <>
            <div className="mx-auto w-full mt-10">
              <GeneralInquiry />
            </div>
          </>
        )}
        {selectedReason.name === "Feedback & suggestions" && (
          <>
            <div className="mx-auto w-full mt-10">
              <GeneralInquiry />
            </div>
          </>
        )}
        {selectedReason.name === "Account issues" && (
          <>
            <div className="w-[616px] mx-auto mt-10">
              <GeneralInquiry />
            </div>
          </>
        )}
        {selectedReason.name === "Other" && (
          <>
            <div className="mx-auto w-full mt-10">
              <GeneralInquiry />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default ContactUSForms;
