import React, { useState } from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import TextFiled from "../../../components/common/InputField/TextFiled";
import Heading from "../../../components/common/Typography/Heading";
import Paragraph from "../../../components/common/Typography/Paragraph";
import useAuth from "../../../Hooks/useAuth";
import SpecialText from "../../../components/common/Typography/SpecialText";
import Popups from "../../../components/popup/Popups";
import InteractiveText from "../../../components/common/Typography/InteractiveText";

const ChangePassword = () => {
  const [step, setStep] = useState(1);
  const { auth, setAuth } = useAuth();
  const [verify, setVerify] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  return (
    <>
      <>
        <div className="md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto">
          <div>
            <div>
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"New password"}
                placeholder={"Your new secure password "}
              />
            </div>
            <div className="mt-5">
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"Confirm password"}
                placeholder={"Re-enter your new password "}
              />
            </div>
            <div className="flex justify-end mt-10">
              <MainButton variant={"small"} onClick={() => setVerify(true)}>
                Reset
              </MainButton>
            </div>
          </div>
        </div>
      </>
      {verify && (
        <Popups
          setTrigger={() => {
            setVerify(false);
            setStep(1);
          }}
          ShowCrossButton={ShowCrossButton}
          popupWidth={
            "w-[350px] h-[500px] xs:w-[400px] sm:w-[600px] sm:h-[500px] 2xl:w-[550px] 2xl:h-[600px] pb-10"
          }
          crossPosition={
            "flex justify-end w-[16px] absolute left-[320px] xs:left-[370px] sm:left-[565px] 2xl:left-[515px] top-3"
          }
        >
          {step === 1 && (
            <>
              <Heading variant={"h6"} className={"text-center mb-10 mt-20 xl:mt-28"}>
                Verify your password reset
              </Heading>
              <div className="grid grid-cols-12 w-[300px] sm:w-[400px] mx-auto">
                <div className="col-span-12">
                  <div>
                    <Paragraph variant={"MainParagraph"}>
                      A verification code has been sent to your registered
                      email. Please enter the code below to continue resetting
                      your password and ensure enhanced account security.
                    </Paragraph>
                  </div>
                  <form>
                    <div className="mt-10">
                      <TextFiled
                        className={"bg-white"}
                        label={"Verification code"}
                        variant={"general"}
                        type="email"
                        name="email"
                        placeholder={"Enter the 5-digit verification code"}
                        // onChange={formik?.handleChange}
                        // value={formik?.values?.email}
                      />
                      <SpecialText
                        variant={"FootNoteDisclaimer"}
                        className={"mt-5"}
                      >
                        Entering the correct verification code ensures that only
                        you can change your password. If you encounter any
                        issues, please reach out to our support team for
                        assistance."
                      </SpecialText>
                    </div>

                    <div className="flex items-center justify-between mt-10">
                      <InteractiveText
                        variant={"ActionLink"}
                        className={"text-primaryblue cursor-pointer"}
                      >
                        Resend
                      </InteractiveText>
                      <MainButton
                        variant={"extrasmall"}
                        onClick={() => {
                          setStep(2);
                          setTimeout(() => {
                            setAuth({
                              user: null,
                              roles: null,
                              accessToken: "",
                            });
                            localStorage.removeItem("LoginSession");
                          }, 4000);
                        }}
                      >
                        Verify
                      </MainButton>
                    </div>
                  </form>
                </div>
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <Heading
                variant={"h6"}
                className={"text-center mb-10 mt-20 xl:mt-36 text-offblack"}
              >
                Password reset successful
              </Heading>
              <div className="w-[300px] sm:w-[400px] mx-auto">
                <Paragraph
                  variant={"MainParagraph"}
                  className={"text-start text-offblack"}
                >
                  Your password has been successfully updated. For your
                  security, you have been logged out of all devices. Please log
                  in again using your new password.
                </Paragraph>
              </div>
            </>
          )}
        </Popups>
      )}
    </>
  );
};
export default ChangePassword;
