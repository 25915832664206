import React from "react";
import ButtonText from "../Typography/ButtonText";

const MainButton = ({
  variant,
  children,
  className,
  onClick,
  type = "",
  disabled,
}) => {
  return (
    <>
      {/* Latest Buttons Started*/}
      {/* extra Large filled button for Desktop, Tabs & Mobiles only pricing cards */}
      {variant === "extralarge" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
    ${className} relative group overflow-hidden rounded-[35px] w-[260px] h-[50px] sm:w-[280px] sm:h-[54px] md:w-[300px] md:h-[54px] lg:w-[250px] xl:w-[300px] bg-primaryblue text-white text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-offblack group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center transition-all duration-300">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {/* Large filled button for Desktop, Tabs & Mobiles */}
      {variant === "large" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
    ${className} relative group overflow-hidden rounded-[35px] w-[170px] h-[48px] sm:w-[180px] sm:h-[50px] md:w-[190px] md:h-[54px] bg-primaryblue text-white text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-offblack group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center transition-all duration-300">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {/* Medium filled button for Desktop, Tabs & Mobiles */}
      {variant === "medium" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden rounded-[35px] w-[120px] h-[46px] sm:w-[130px] sm:h-[48px] md:w-[140px] md:h-[52px] bg-primaryblue text-white text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-offblack group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center transition-all duration-300">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {/* Small filled button for Desktop, Tabs & Mobiles */}
      {variant === "small" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden rounded-[35px] w-[90px] h-[44px] sm:w-[100px] sm:h-[46px] md:w-[110px] md:h-[50px] bg-primaryblue text-white text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-offblack group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center transition-all duration-300">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {/* Extra Small filled button for Desktop, Tabs & Mobiles */}
      {variant === "extrasmall" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden rounded-[35px] w-[70px] h-[40px] sm:w-[80px] sm:h-[42px] md:w-[90px] md:h-[48px] bg-primaryblue text-white text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-offblack group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center transition-all duration-300">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {/* filled buttons with justify between text */}
      {variant === "largebetween" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
    ${className} relative group overflow-hidden rounded-[35px] w-[170px] h-[48px] sm:w-[180px] sm:h-[50px] md:w-[190px] md:h-[54px]  bg-primaryblue text-white text-center disabled:hidden`}
        >
          <ButtonText variant={"PrimaryText"}>
            <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-offblack group-hover:h-[150%]"></span>
            <span className="relative group-hover:text-white flex items-center justify-between px-5 transition-all duration-300">
              {children}
            </span>
          </ButtonText>
        </button>
      )}
      {/* Medium filled button for Desktop, Tabs & Mobiles */}
      {variant === "mediumbetween" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden rounded-[35px] w-[120px] h-[46px] sm:w-[130px] sm:h-[48px] md:w-[140px] md:h-[52px]  bg-primaryblue text-white text-center disabled:hidden`}
        >
          <ButtonText variant={"PrimaryText"}>
            <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-offblack group-hover:h-[150%]"></span>
            <span className="relative group-hover:text-white flex items-center justify-between px-5 transition-all duration-300">
              {children}
            </span>
          </ButtonText>
        </button>
      )}
      {/* Small filled button for Desktop, Tabs & Mobiles */}
      {variant === "smallbetween" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden  rounded-[35px] w-[90px] h-[44px] sm:w-[100px] sm:h-[46px] md:w-[110px] md:h-[50px]  bg-primaryblue text-white text-center disabled:hidden`}
        >
          <ButtonText variant={"PrimaryText"}>
            <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-offblack group-hover:h-[150%]"></span>
            <span className="relative group-hover:text-white flex items-center justify-between px-5 transition-all duration-300">
              {children}
            </span>
          </ButtonText>
        </button>
      )}

      {/* Large outlined button for Desktop, Tabs & Mobiles */}
      {variant === "outlinelarge" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden  rounded-[35px] w-[170px] h-[48px] sm:w-[180px] sm:h-[50px] md:w-[190px] md:h-[54px] border border-offblack hover:border-primaryblue text-offblack text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-primaryblue group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center transition-all duration-300">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {/* Medium outlined button for Desktop, Tabs & Mobiles */}
      {variant === "outlinemedium" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden  rounded-[35px] w-[120px] h-[46px] sm:w-[130px] sm:h-[48px] md:w-[140px] md:h-[52px] border border-offblack hover:border-primaryblue text-offblack text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-primaryblue group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center transition-all duration-300">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {/* Small outlined button for Desktop, Tabs & Mobiles */}
      {variant === "outlinesmall" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden  rounded-[35px] w-[90px] h-[44px] sm:w-[100px] sm:h-[46px] md:w-[110px] md:h-[50px] border border-offblack hover:border-primaryblue text-offblack text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-primaryblue group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center transition-all duration-300">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {/* Extra Small oulined button for Desktop, Tabs & Mobiles */}

      {variant === "outlineextrasmall" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden  rounded-[35px] w-[70px] h-[40px] sm:w-[80px] sm:h-[42px] md:w-[90px] md:h-[48px]  border border-offblack hover:border-primaryblue text-offblack text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-primaryblue group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center transition-all duration-300">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {variant === "headeroutlineextrasmall" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden  rounded-[35px] w-[70px] h-[40px] sm:w-[80px] sm:h-[42px] md:w-[90px] md:h-[48px]  border border-white hover:border-primaryblue text-white text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-primaryblue group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center transition-all duration-300">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {variant === "outlineextralargebetween" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden rounded-[35px] w-[260px] h-[50px] sm:w-[280px] sm:h-[54px] md:w-[300px] md:h-[54px] lg:w-[250px] xl:w-[300px] border border-offblack hover:border-primaryblue text-offblack text-center disabled:hidden`}
        >
          <ButtonText variant={"PrimaryText"}>
            <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-primaryblue group-hover:h-[150%]"></span>
            <span className="relative group-hover:text-white flex items-center justify-between px-5 transition-all duration-300">
              {children}
            </span>
          </ButtonText>
        </button>
      )}
      {variant === "outlinelargebetween" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden  rounded-[35px] w-[170px] h-[48px] sm:w-[180px] sm:h-[50px] md:w-[190px] md:h-[54px]  border border-offblack hover:border-primaryblue text-offblack text-center disabled:hidden`}
        >
          <ButtonText variant={"PrimaryText"}>
            <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-primaryblue group-hover:h-[150%]"></span>
            <span className="relative group-hover:text-white flex items-center justify-between px-5 transition-all duration-300">
              {children}
            </span>
          </ButtonText>
        </button>
      )}
      {variant === "outlinemediumbetween" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden  rounded-[35px] w-[120px] h-[46px] sm:w-[130px] sm:h-[48px] md:w-[140px] md:h-[52px]  border border-offblack hover:border-primaryblue text-offblack text-center disabled:hidden`}
        >
          <ButtonText variant={"PrimaryText"}>
            <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-primaryblue group-hover:h-[150%]"></span>
            <span className="relative group-hover:text-white flex items-center justify-between px-5 transition-all duration-300">
              {children}
            </span>
          </ButtonText>
        </button>
      )}
      {variant === "outlinesmallbetween" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden  rounded-[35px] w-[90px] h-[44px] sm:w-[100px] sm:h-[46px] md:w-[110px] md:h-[50px]  border border-offblack hover:border-primaryblue text-offblack text-center disabled:hidden`}
        >
          <ButtonText variant={"PrimaryText"}>
            <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-primaryblue group-hover:h-[150%]"></span>
            <span className="relative group-hover:text-white flex items-center justify-between px-5 transition-all duration-300">
              {children}
            </span>
          </ButtonText>
        </button>
      )}
      {variant === "facebook" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden rounded-[35px] w-[170px] h-[48px] sm:w-[180px] sm:h-[50px] md:w-[190px] md:h-[54px] text-white bg-[#1877F2] text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-offblack group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {variant === "google" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden rounded-[35px] w-[170px] h-[48px] sm:w-[180px] sm:h-[50px] md:w-[190px] md:h-[54px] text-white bg-[#d4564b] text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-offblack group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {/* Special red button  */}
      {variant === "specialredbutton" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden  rounded-[35px] w-[170px] h-[48px] sm:w-[180px] sm:h-[50px] md:w-[190px] md:h-[54px]  text-white bg-alertred text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-offblack group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {variant === "specialredbuttonmedium" && (
        <button
          disabled={disabled}
          onClick={onClick}
          type={type}
          className={`
          ${className} relative group overflow-hidden rounded-[35px] w-[90px] h-[44px] sm:w-[100px] sm:h-[46px] md:w-[110px] md:h-[50px] text-white bg-alertred text-center disabled:hidden`}
        >
          <span className="rounded-t-full group-hover:rounded-t-none absolute bottom-0 left-1/2 group-hover:left-0 flex w-[1px] group-hover:w-full h-0 mb-0 transition-all duration-300 ease-out transform translate-y-0 bg-offblack group-hover:h-[150%]"></span>
          <span className="relative group-hover:text-white flex items-center justify-center">
            <ButtonText variant={"PrimaryText"}>{children}</ButtonText>
          </span>
        </button>
      )}
      {/* Latest Buttons Ended*/}
    </>
  );
};
export default MainButton;
