import React from "react";
import HeadingsWithParagraphsList from "../sections/ServiceGuide/HeadingsWithParagraphsList";
import CommonFAQs from "../sections/ServiceGuide/CommonFAQs";
import RelatedArticles from "../sections/ServiceGuide/RelatedArticles";
import PricingEstimates from "../sections/ServiceGuide/PricingEstimates";
import Regulators from "../sections/ServiceGuide/Regulators";
import CheckList from "../sections/ServiceGuide/CheckList";
import WhyProfessional from "../sections/ServiceGuide/WhyProfessional";
import Banner from "../components/common/Banners/Banner";
import MainButton from "../components/common/MainButton/MainButton";
import { useNavigate, useParams } from "react-router-dom";
// import PopularTypes from "../sections/ServiceGuide/PopularTypes";
import FullLengthSlider from "../sections/AllServicesSlider/FullLengthSlider";
import ServicesMap from "../sections/ServiceGuide/ServicesMap";
import Factors from "../sections/ServiceGuide/Factors";
import CodesCompliance from "../sections/ServiceGuide/CodesCompliance";

const ServiceGuide = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const Headings = [
    {
      slug: "architectural",
      heading:
        "The Comprehensive Guide to Architectural Services for Every Project ",
      buttonText: "Find Local Architects",
    },
    {
      slug: "air-conditioning-and-heating",
      heading:
        "A Complete Homeowner’s Guide to Air Conditioning & Heating Services ",
      buttonText: "Find Local HVAC Experts",
    },
    {
      slug: "bricklaying",
      heading: "Comprehensive Guide to Bricklaying & Blockwork Solutions ",
      buttonText: "Find Local Bricklayers",
    },
    {
      slug: "cabinet-making",
      heading: "Comprehensive Guide to Cabinet Making & Joinery Solutions",
      buttonText: "Find Local Cabinet Makers",
    },
    {
      slug: "carpentry",
      heading: "Master Carpentry, A Comprehensive Guide to Services ",
      buttonText: "Find Local Carpenters",
    },
    {
      slug: "carpet-and-flooring",
      heading: "From Carpet to Timber, A Unique Guide to Flooring Services ",
      buttonText: "Find Local Flooring Experts",
    },
    {
      slug: "caulking",
      heading: "Seal the Deal, Your Guide to Professional Caulking Services ",
      buttonText: "Find Local Caulking Specialists",
    },
    {
      slug: "cleaning-services",
      heading:
        "From Home to Office, A Comprehensive Guide to Cleaning Services ",
      buttonText: "Find Local Cleaning Experts",
    },
    {
      slug: "concrete-and-paving",
      heading: "Strong Foundations, Your Guide to Concrete & Paving Services ",
      buttonText: "Find Local Concrete & Paving Experts",
    },
    {
      slug: "conveyancing",
      heading:
        "Navigating Property Transactions, Your Guide to Conveyancing Services ",
      buttonText: "Find Local Conveyancers",
    },
    {
      slug: "blinds-and-curtains",
      heading:
        "From Interiors to Exteriors, Your Guide to Curtains, Blinds & Awnings ",
      buttonText: "Find Local Window Covering Specialists",
    },
    {
      slug: "decking-and-gazebos",
      heading:
        "Creating the Perfect Outdoors, Comprehensive Guide to Decking, Gazebos & More ",
      buttonText: "Find Local Decking & Gazebo Experts",
    },
    {
      slug: "demolition-and-excavation",
      heading:
        "Preparing for Construction, Your Guide to Demolition & Excavation Services ",
      buttonText: "Find Local Demolition & Excavation Experts",
    },
    {
      slug: "electrical",
      heading:
        "Stay Connected, Your Guide to Residential & Commercial Electrical Services ",
      buttonText: "Find Local Electricians",
    },
    {
      slug: "engineering-services",
      heading: "Supporting Your Build, Expert Guide to Engineering Services",
      buttonText: "Find Local Engineers",
    },
    {
      slug: "fence-and-gates",
      heading:
        "Setting Boundaries, The Comprehensive Guide to Fences, Gates & Garage Doors ",
      buttonText: "Find Local Fencing & Gate Experts",
    },
    {
      slug: "glazing-and-screens",
      heading:
        "From Windows to Shower Screens, Expert Guide to Glazing Services",
      buttonText: "Find Local Glazing & Screen Experts",
    },
    {
      slug: "garden-and-landscaping",
      heading:
        "From Lawns to Landscapes, Your Complete Guide to Gardening Services ",
      buttonText: "Find Local Garden & Landscaping Experts",
    },
    {
      slug: "handyman",
      heading: "Tackling the To-Do List, The Guide to Handyman Services ",
      buttonText: "Find Local handyman Experts",
    },
    {
      slug: "inspection-and-surveyors",
      heading: "Measure Twice, Your Guide to Property Inspections & Surveying ",
      buttonText: "Find Local Inspectors & Surveyors Experts",
    },
    {
      slug: "security-and-automation",
      heading:
        "From Cameras to Smart Homes, Expert Guide to Security & Automations ",
      buttonText: "Find Local Security & Automation Experts",
    },
    {
      slug: "insulation-services",
      heading: "Energy Efficiency, The Complete Guide to Insulation Solutions ",
      buttonText: "Find Local Insulation Experts",
    },
    {
      slug: "interior-design",
      heading:
        "From Concepts to Reality, Your Guide to Interior Design Solutions ",
      buttonText: "Find Local Interior Designers",
    },
    {
      slug: "locksmiths",
      heading: "Unlocking Solutions, Your Guide to Locksmith Services ",
      buttonText: "Find Local Locksmiths",
    },
    {
      slug: "painting-and-decorating",
      heading: "From Walls to Finishes, Expert Guide to Painting & Decorating",
      buttonText: "Find Local Painters & Decorators",
    },
    {
      slug: "moving-and-storage",
      heading: "Move Stress-Free, Comprehensive Guide to Moving & Storage",
      buttonText: "Find Local Movers",
    },
    {
      slug: "mold-and-asbestos",
      heading:
        "Healthy Living, The Complete Guide to Mould & Asbestos Solutions",
      buttonText: "Find Local Mould & Asbestos Experts",
    },
    {
      slug: "pest-control",
      heading:
        "Safe & Pest-Free, Comprehensive Guide to Pest Control Services ",
      buttonText: "Find Local Pest Control Experts",
    },
    {
      slug: "plastering",
      heading: "Walls That Last, Comprehensive Guide to Plastering Services ",
      buttonText: "Find Local Plasterers Experts",
    },
    {
      slug: "plumbing",
      heading: "The Complete Guide for Plumbing Services",
      buttonText: "Find Local Plumbers",
    },
    {
      slug: "pools-and-spas",
      heading:
        "From Construction to Maintenance, Expert Guide to Pool & Spa Solutions",
      buttonText: "Find Local Pool & Spa Listing",
    },
    {
      slug: "rendering",
      heading:
        "Achieving Perfection, The Complete Guide to Rendering Services ",
      buttonText: "Find Local Renderers",
    },
    {
      slug: "renovation-and-restoration",
      heading:
        "From Upgrades to Restorations, Expert Guide to Renovation Services ",
      buttonText: "Find Local Renovation & Restoration Experts",
    },
    {
      slug: "roofing",
      heading: "Your Complete Guide to Durable Roofing Solutions",
      buttonText: "Find Local Roofers",
    },
    {
      slug: "scaffolding",
      heading: "Reaching New Heights, The Guide to Scaffolding Services ",
      buttonText: "Find Local Scaffolders",
    },
    {
      slug: "shopfitting",
      heading: "From Design to Fit-Outs, Expert Guide to Shopfitting Solution ",
      buttonText: "Find Local Shopfitters",
    },

    {
      slug: "skip-bins",
      heading: "Efficient Cleanup, Comprehensive Guide to Skip Bin Services ",
      buttonText: "Find Local Skip Bin Providers",
    },
    {
      slug: "solar-energy",
      heading:
        "Sustainable Living, The Complete Guide to Solar Energy Services ",
      buttonText: "Find Local Solar Installers",
    },
    {
      slug: "staircases",
      heading:
        "Building Beautiful Stairs, The Complete Guide to Staircase Services ",
      buttonText: "Find Local Staircase Builders",
    },
    {
      slug: "stonemasonry",
      heading:
        "Building Beauty with Stone, The Complete Guide to Stone Services ",
      buttonText: "Find Local Stone installers",
    },
    {
      slug: "tiling",
      heading: "From Floors to Walls, Expert Guide to Tiling Solutions ",
      buttonText: "Find Local Tilers",
    },
    {
      slug: "waterproofing",
      heading:
        "Protecting Against Leaks, Comprehensive Guide to Waterproofing Services ",
      buttonText: "Find Local Waterproofers",
    },
  ];
  const categoryHead = Headings.filter((item) => item.slug === slug);

  return (
    <>
      {categoryHead.map((item, index) => {
        return (
          <>
            <Banner
              headingClasses={
                "text-center sm:text-start text-white xl:w-[900px] 2xl:w-[1000px] "
              }
              heading={item.heading}
              className={"bg-about"}
            >
              <div className="flex items-center justify-center sm:justify-start mt-10">
                <MainButton
                  variant={"extralarge"}
                  onClick={() => navigate("/services-guide")}
                >
                  {item.buttonText}
                </MainButton>
              </div>
            </Banner>
          </>
        );
      })}
      <HeadingsWithParagraphsList />
      <WhyProfessional />
      <CheckList />
      <ServicesMap />
      <PricingEstimates />
      <Factors />
      <CodesCompliance />
      <Regulators />
      <CommonFAQs />
      <RelatedArticles />
      <FullLengthSlider />
    </>
  );
};

export default ServiceGuide;
