import React from "react";
import BlogCard from "../../components/common/BlogCard/BlogCard";
import Heading from "../../components/common/Typography/Heading";
import { useParams } from "react-router-dom";

const RelatedArticles = () => {
  const { slug } = useParams();
  const Blogs = [
    {
      slug: "architectural",
      blogsList: [
        {
          link: "",
          blogName: "Top trends in modern home architecture",
          text: "Explore the latest design trends in residential architecture.",
        },
        {
          link: "",
          blogName: "The importance of sustainable building design",
          text: "Learn how sustainable designs can enhance efficiency and reduce costs.",
        },
        {
          link: "",
          blogName: "How to choose the right architect for your project",
          text: "Key factors to consider when hiring an architect.",
        },
        {
          link: "",
          blogName: "How to choose the right architect for your project",
          text: "Key factors to consider when hiring an architect.",
        },
      ],
    },
    {
      slug: "air-conditioning-and-heating",
      blogsList: [
        {
          link: "",
          blogName: "5 Tips for improving hvac efficiency",
          text: "Maximize system efficiency through regular maintenance and upgrades.",
        },
        {
          link: "",
          blogName: "Choosing the right air conditioning for your home",
          text: "Find the best system based on size, efficiency, and budget.",
        },
        {
          link: "",
          blogName: "Essential winter heating maintenance checklist",
          text: "Steps to prepare your heating system for colder months.",
        },
        {
          link: "",
          blogName: "Essential winter heating maintenance checklist",
          text: "Steps to prepare your heating system for colder months.",
        },
      ],
    },
    {
      slug: "bricklaying",
      blogsList: [
        {
          link: "",
          blogName: "Top tips for maintaining brick & block structures",
          text: "Learn how to keep your brickwork in top condition.",
        },
        {
          link: "",
          blogName: "Choosing the right bricks for your home",
          text: "Explore various brick types and their benefits.",
        },
        {
          link: "",
          blogName: "The importance of retaining walls in landscaping",
          text: "Understand how retaining walls improve safety and aesthetics.",
        },
        {
          link: "",
          blogName: "The importance of retaining walls in landscaping",
          text: "Understand how retaining walls improve safety and aesthetics.",
        },
      ],
    },
    {
      slug: "cabinet-making",
      blogsList: [
        {
          link: "",
          blogName: "Top trends in kitchen cabinet design",
          text: "Explore the latest trends in kitchen cabinetry for modern homes.",
        },
        {
          link: "",
          blogName: "How to choose the right materials for custom cabinets",
          text: "A guide to selecting materials based on quality, durability, and budget.",
        },
        {
          link: "",
          blogName: "Maximizing space with custom wardrobes",
          text: "Learn how custom wardrobes can enhance storage and functionality.",
        },
        {
          link: "",
          blogName: "Maximizing space with custom wardrobes",
          text: "Learn how custom wardrobes can enhance storage and functionality.",
        },
      ],
    },
    {
      slug: "carpentry",
      blogsList: [
        {
          link: "",
          blogName: "Top trends in deck design for Australian homes",
          text: "Explore the latest decking designs for outdoor living spaces.",
        },
        {
          link: "",
          blogName: "Choosing the right wood for your carpentry project",
          text: "A guide to selecting the best timber for durability and aesthetics.",
        },
        {
          link: "",
          blogName: "How to maintain your timber structures",
          text: "Tips on preserving the life and look of timber structures.",
        },
        {
          link: "",
          blogName: "How to maintain your timber structures",
          text: "Tips on preserving the life and look of timber structures.",
        },
      ],
    },
    {
      slug: "carpet-and-flooring",
      blogsList: [
        {
          link: "",
          blogName: "Top trends in flooring for modern homes",
          text: "Explore the latest trends in flooring designs and materials.",
        },
        {
          link: "",
          blogName: "How to choose the right flooring for your space",
          text: "A guide to selecting flooring based on style, durability, and budget.",
        },
        {
          link: "",
          blogName: "The benefits of epoxy flooring for garages",
          text: "Learn about the durability and ease of maintenance with epoxy floors.",
        },
        {
          link: "",
          blogName: "The benefits of epoxy flooring for garages",
          text: "Learn about the durability and ease of maintenance with epoxy floors.",
        },
      ],
    },
    {
      slug: "caulking",
      blogsList: [
        {
          link: "",
          blogName: "The importance of caulking for energy efficiency",
          text: "Discover how caulking reduces drafts and lowers energy bills.",
        },
        {
          link: "",
          blogName: "How to choose the right caulk for your home",
          text: "A guide to selecting the best caulk for different surfaces and areas.",
        },
        {
          link: "",
          blogName: "Maintaining caulking in wet areas",
          text: "Tips on preventing mold and keeping caulk in wet areas in top condition.",
        },
        {
          link: "",
          blogName: "Maintaining caulking in wet areas",
          text: "Tips on preventing mold and keeping caulk in wet areas in top condition.",
        },
      ],
    },
    {
      slug: "cleaning-services",
      blogsList: [
        {
          link: "",
          blogName: "Top tips for effective home cleaning",
          text: "Discover how to keep your home clean and organized with minimal effort.",
        },
        {
          link: "",
          blogName: "Why professional carpet cleaning is worth it",
          text: "Learn the benefits of deep carpet cleaning for better hygiene and air quality.",
        },
        {
          link: "",
          blogName: "End of lease cleaning checklist",
          text: "A comprehensive guide to ensure you meet rental cleaning standards before moving out.",
        },
        {
          link: "",
          blogName: "End of lease cleaning checklist",
          text: "A comprehensive guide to ensure you meet rental cleaning standards before moving out.",
        },
      ],
    },
    {
      slug: "concrete-and-paving",
      blogsList: [
        {
          link: "",
          blogName: "Top benefits of exposed aggregate driveways",
          text: "Discover the aesthetic and functional advantages of exposed aggregate surfaces.",
        },
        {
          link: "",
          blogName: "How to choose the right paving for your patio",
          text: "A guide to selecting the best paving materials based on durability and design.",
        },
        {
          link: "",
          blogName: "Concrete maintenance tips for longevity",
          text: "Learn how to maintain concrete surfaces for lasting durability and appearance.",
        },
        {
          link: "",
          blogName: "Concrete maintenance tips for longevity",
          text: "Learn how to maintain concrete surfaces for lasting durability and appearance.",
        },
      ],
    },
    {
      slug: "conveyancing",
      blogsList: [
        {
          link: "",
          blogName: "Understanding the Conveyancing Process in Australia",
          text: "A step-by-step guide to how conveyancing works in residential property transactions.",
        },
        {
          link: "",
          blogName: "Top Tips for First Home Buyers",
          text: "Essential advice for navigating the legal and financial aspects of buying your first home.",
        },
        {
          link: "",
          blogName: "What to Look for in a Conveyancer",
          text: "Key traits and qualifications to consider when hiring a conveyancer for property transactions.",
        },
        {
          link: "",
          blogName: "What to Look for in a Conveyancer",
          text: "Key traits and qualifications to consider when hiring a conveyancer for property transactions.",
        },
      ],
    },
    {
      slug: "blinds-and-curtains",
      blogsList: [
        {
          link: "",
          blogName: "Top tips for choosing the right blinds for your home",
          text: "Explore different styles and their benefits to find the perfect fit.",
        },
        {
          link: "",
          blogName: "How awnings improve energy efficiency",
          text: "Learn how awnings can reduce energy costs and enhance comfort.",
        },
        {
          link: "",
          blogName: "The benefits of motorized blinds in modern homes",
          text: "Discover how automation enhances convenience and safety.",
        },
        {
          link: "",
          blogName: "The benefits of motorized blinds in modern homes",
          text: "Discover how automation enhances convenience and safety.",
        },
      ],
    },
    {
      slug: "decking-and-gazebos",
      blogsList: [
        {
          link: "",
          blogName: "Top decking materials for Australian homes",
          text: "Explore popular decking materials and their benefits for outdoor spaces.",
        },
        {
          link: "",
          blogName: "Why you need a gazebo in your backyard",
          text: "Discover the advantages of adding a gazebo for relaxation and aesthetics.",
        },
        {
          link: "",
          blogName: "Tips for designing the perfect veranda",
          text: "A guide to creating a functional, stylish veranda for your home.",
        },
        {
          link: "",
          blogName: "Tips for designing the perfect veranda",
          text: "A guide to creating a functional, stylish veranda for your home.",
        },
      ],
    },
    {
      slug: "demolition-and-excavation",
      blogsList: [
        {
          link: "",
          blogName: "Top safety tips for demolition projects",
          text: "Learn essential safety practices for handling demolition work.",
        },
        {
          link: "",
          blogName: "How to prepare for land excavation",
          text: "A guide to site preparation, soil testing, and excavation requirements.",
        },
        {
          link: "",
          blogName: "Benefits of professional asbestos removal",
          text: "Understand the importance of safe asbestos removal in demolition projects.",
        },
        {
          link: "",
          blogName: "Benefits of professional asbestos removal",
          text: "Understand the importance of safe asbestos removal in demolition projects.",
        },
      ],
    },
    {
      slug: "electrical",
      blogsList: [
        {
          link: "",
          blogName: "Top tips for electrical safety in your home",
          text: "Learn essential safety tips for maintaining safe electrical systems at home.",
        },
        {
          link: "",
          blogName: "Benefits of LED lighting for energy savings",
          text: "Explore how LED lighting can lower your energy bills and improve lighting quality.",
        },
        {
          link: "",
          blogName: "How to prepare for a switchboard upgrade",
          text: "A guide to understanding when and why you might need a switchboard upgrade.",
        },
        {
          link: "",
          blogName: "How to prepare for a switchboard upgrade",
          text: "A guide to understanding when and why you might need a switchboard upgrade.",
        },
      ],
    },
    {
      slug: "engineering-services",
      blogsList: [
        {
          link: "",
          blogName: "Top engineering innovations in construction",
          text: "Discover the latest advancements shaping the construction industry.",
        },
        {
          link: "",
          blogName: "How to choose the right engineer for your project",
          text: "Essential factors to consider when hiring an engineer.",
        },
        {
          link: "",
          blogName: "The role of engineers in sustainable building design",
          text: "Learn how engineers contribute to energy efficiency and sustainability.",
        },
        {
          link: "",
          blogName: "The role of engineers in sustainable building design",
          text: "Learn how engineers contribute to energy efficiency and sustainability.",
        },
      ],
    },
    {
      slug: "fence-and-gates",
      blogsList: [
        {
          link: "",
          blogName: "How to choose the right fence for your home",
          text: "Explore various fencing materials and designs for residential properties.",
        },
        {
          link: "",
          blogName: "Top benefits of automatic gates",
          text: "Learn how electric gates enhance security and convenience.",
        },
        {
          link: "",
          blogName: "Safety standards for pool fencing in australia",
          text: "Understand the legal requirements for pool fencing and child safety.",
        },
        {
          link: "",
          blogName: "Safety standards for pool fencing in australia",
          text: "Understand the legal requirements for pool fencing and child safety.",
        },
      ],
    },
    {
      slug: "glazing-and-screens",
      blogsList: [
        {
          link: "",
          blogName: "Top glass types for energy-efficient homes",
          text: "Explore the benefits of double glazing, tinted glass, and energy-efficient window installations.",
        },
        {
          link: "",
          blogName: "How to choose the right shower screen for your bathroom",
          text: "Learn about framed, semi-frameless, and frameless shower screens.",
        },
        {
          link: "",
          blogName: "Safety standards for glass balustrades",
          text: "Understand compliance requirements for installing glass balustrades in residential and commercial spaces.",
        },
        {
          link: "",
          blogName: "Safety standards for glass balustrades",
          text: "Understand compliance requirements for installing glass balustrades in residential and commercial spaces.",
        },
      ],
    },
    {
      slug: "garden-and-landscaping",
      blogsList: [
        {
          link: "",
          blogName: "Top landscaping trends for 2024",
          text: "Explore the latest designs, plants, and hardscape elements trending in landscaping this year.",
        },
        {
          link: "",
          blogName: "How to choose the right plants for your garden",
          text: "Tips for selecting the best plants based on soil type, climate, and aesthetics.",
        },
        {
          link: "",
          blogName: "Benefits of installing an irrigation system",
          text: "Learn how irrigation systems save water, reduce costs, and improve garden health.",
        },
        {
          link: "",
          blogName: "Benefits of installing an irrigation system",
          text: "Learn how irrigation systems save water, reduce costs, and improve garden health.",
        },
      ],
    },
    {
      slug: "handyman",
      blogsList: [
        {
          link: "",
          blogName: "Top maintenance tasks for your home",
          text: "Learn the essential maintenance jobs every homeowner should complete annually.",
        },
        {
          link: "",
          blogName: "How to choose the right handyman for your needs",
          text: "Tips for selecting the best handyman based on job requirements and skills.",
        },
        {
          link: "",
          blogName: "DIY vs. hiring a handyman: What’s worth doing yourself?",
          text: "Understand which tasks you can tackle and when it’s best to call a professional.",
        },
        {
          link: "",
          blogName: "DIY vs. hiring a handyman: What’s worth doing yourself?",
          text: "Understand which tasks you can tackle and when it’s best to call a professional.",
        },
      ],
    },
    {
      slug: "inspection-and-surveyors",
      blogsList: [
        {
          link: "",
          blogName: "Why you need a building inspection before buying a home",
          text: "Learn about the benefits and requirements of pre-purchase building inspections.",
        },
        {
          link: "",
          blogName: "How to read a land survey report",
          text: "A guide to understanding the basics of land survey reports for buyers and developers.",
        },
        {
          link: "",
          blogName: "Common issues found during asbestos inspections",
          text: "Understand the risks and requirements of asbestos inspections in buildings.",
        },
        {
          link: "",
          blogName: "Common issues found during asbestos inspections",
          text: "Understand the risks and requirements of asbestos inspections in buildings.",
        },
      ],
    },
    {
      slug: "security-and-automation",
      blogsList: [
        {
          link: "",
          blogName: "Top smart home devices for 2024",
          text: "Explore the latest trends in home automation and security technologies.",
        },
        {
          link: "",
          blogName: "How to choose the right security system for your home",
          text: "A guide to selecting the best security system based on property type and budget.",
        },
        {
          link: "",
          blogName: "Benefits of smart lighting for energy savings",
          text: "Learn how automated lighting systems can improve energy efficiency.",
        },
        {
          link: "",
          blogName: "Benefits of smart lighting for energy savings",
          text: "Learn how automated lighting systems can improve energy efficiency.",
        },
      ],
    },
    {
      slug: "insulation-services",
      blogsList: [
        {
          link: "",
          blogName: "Top benefits of upgrading your home insulation",
          text: "Discover the energy-saving, comfort-enhancing, and cost-reducing benefits of improved insulation.",
        },
        {
          link: "",
          blogName: "How to choose the right insulation for your home",
          text: "Learn about different types of insulation and their best applications.",
        },
        {
          link: "",
          blogName: "Understanding r-values in insulation",
          text: "A guide to insulation r-values and how they impact energy efficiency.",
        },
        {
          link: "",
          blogName: "Understanding r-values in insulation",
          text: "A guide to insulation r-values and how they impact energy efficiency.",
        },
      ],
    },
    {
      slug: "interior-design",
      blogsList: [
        {
          link: "",
          blogName: "Top trends in interior design for 2024",
          text: "Discover the latest styles, materials, and colors shaping modern interior design.",
        },
        {
          link: "",
          blogName: "How to choose the right interior designer",
          text: "Tips for selecting a designer who matches your style, budget, and project needs.",
        },
        {
          link: "",
          blogName:
            "Sustainable interior design: eco-friendly choices for your home",
          text: "Learn how to incorporate sustainable practices into your interior design.",
        },
        {
          link: "",
          blogName:
            "Sustainable interior design: eco-friendly choices for your home",
          text: "Learn how to incorporate sustainable practices into your interior design.",
        },
      ],
    },
    {
      slug: "locksmiths",
      blogsList: [
        {
          link: "",
          blogName: "How to choose the right lock for your home",
          text: "Explore various lock types, including deadbolts, smart locks, and keyless entry systems.",
        },
        {
          link: "",
          blogName: "Benefits of a master key system for offices",
          text: "Learn about the advantages of master key systems for enhanced security and control in commercial properties.",
        },
        {
          link: "",
          blogName: "What to do during a lockout",
          text: "A step-by-step guide to handle home or office lockouts effectively and safely.",
        },
        {
          link: "",
          blogName: "What to do during a lockout",
          text: "A step-by-step guide to handle home or office lockouts effectively and safely.",
        },
      ],
    },
    {
      slug: "painting-and-decorating",
      blogsList: [
        {
          link: "",
          blogName: "Top color trends in interior design for 2024",
          text: "Explore popular colors, finishes, and painting techniques for homes and offices.",
        },
        {
          link: "",
          blogName: "How to choose the right paint for your home",
          text: "Tips for selecting paints based on durability, color, and room type.",
        },
        {
          link: "",
          blogName: "DIY vs. professional painting: What to consider",
          text: "Understand the pros and cons of DIY painting compared to hiring professionals.",
        },
        {
          link: "",
          blogName: "DIY vs. professional painting: What to consider",
          text: "Understand the pros and cons of DIY painting compared to hiring professionals.",
        },
      ],
    },
    {
      slug: "moving-and-storage",
      blogsList: [
        {
          link: "",
          blogName: "Top tips for a stress-free move",
          text: "Learn how to plan, pack, and move with minimal stress.",
        },
        {
          link: "",
          blogName: "How to choose the right moving company",
          text: "Explore the factors to consider when hiring a professional mover, from services to costs.",
        },
        {
          link: "",
          blogName: "Preparing your home for moving day",
          text: "A step-by-step guide to get your home ready for a smooth moving process.",
        },
        {
          link: "",
          blogName: "Preparing your home for moving day",
          text: "A step-by-step guide to get your home ready for a smooth moving process.",
        },
      ],
    },
    {
      slug: "mold-and-asbestos",
      blogsList: [
        {
          link: "",
          blogName: "How to prevent mould growth in your home",
          text: "Explore best practices for reducing humidity and preventing mould in kitchens, bathrooms, and basements.",
        },
        {
          link: "",
          blogName: "Asbestos: What you need to know for safe removal",
          text: "Learn about asbestos risks, identification, and safe removal processes for homes and commercial buildings.",
        },
        {
          link: "",
          blogName:
            "Understanding the health risks of mould & asbestos exposure",
          text: "Discover the potential health effects of prolonged exposure to mould and asbestos and the importance of professional removal.",
        },
        {
          link: "",
          blogName:
            "Understanding the health risks of mould & asbestos exposure",
          text: "Discover the potential health effects of prolonged exposure to mould and asbestos and the importance of professional removal.",
        },
      ],
    },
    {
      slug: "pest-control",
      blogsList: [
        {
          link: "",
          blogName: "How to prevent termites in your home",
          text: "Learn effective prevention methods to protect your home from termite damage.",
        },
        {
          link: "",
          blogName: "Top tips for a pest-free home",
          text: "Explore simple strategies to keep your property free from common pests year-round.",
        },
        {
          link: "",
          blogName: "Understanding fumigation: when is it necessary?",
          text: "A guide to understanding when and why fumigation might be needed for effective pest control.",
        },
        {
          link: "",
          blogName: "Understanding fumigation: when is it necessary?",
          text: "A guide to understanding when and why fumigation might be needed for effective pest control.",
        },
      ],
    },
    {
      slug: "plastering",
      blogsList: [
        {
          link: "",
          blogName: "Top plastering techniques for modern homes",
          text: "Explore popular plastering techniques and styles for a contemporary look.",
        },
        {
          link: "",
          blogName: "How to repair plaster cracks & holes",
          text: "Learn DIY tips for minor plaster repairs and when to call a professional.",
        },
        {
          link: "",
          blogName: "Skim coating vs. rendering: what’s the difference?",
          text: "Understand the difference between these two common plastering techniques.",
        },
        {
          link: "",
          blogName: "Skim coating vs. rendering: what’s the difference?",
          text: "Understand the difference between these two common plastering techniques.",
        },
      ],
    },
    {
      slug: "plumbing",
      blogsList: [
        {
          link: "",
          blogName: "Top tips for saving on plumbing costs",
          text: "Simple strategies to cut plumbing costs through maintenance and smart choices.",
        },
        {
          link: "",
          blogName: "How to choose the right hot water system",
          text: "Select the best system for your home’s needs and budget.",
        },
        {
          link: "",
          blogName: "Plumbing maintenance checklist for homeowners",
          text: "Essential steps to keep your plumbing running smoothly.",
        },
        {
          link: "",
          blogName: "Plumbing maintenance checklist for homeowners",
          text: "Essential steps to keep your plumbing running smoothly.",
        },
      ],
    },
    {
      slug: "pools-and-spas",
      blogsList: [
        {
          link: "",
          blogName: "Top pool design trends for 2024",
          text: "Discover the latest features, styles, and materials shaping modern pool design.",
        },
        {
          link: "",
          blogName: "How to maintain your spa for year-round use",
          text: "Learn about regular maintenance tips to keep your spa in top condition.",
        },
        {
          link: "",
          blogName: "Ensuring pool safety: what you need to know",
          text: "A guide to understanding safety requirements, from fencing to water quality.",
        },
        {
          link: "",
          blogName: "Ensuring pool safety: what you need to know",
          text: "A guide to understanding safety requirements, from fencing to water quality.",
        },
      ],
    },
    {
      slug: "rendering",
      blogsList: [
        {
          link: "",
          blogName: "Top rendering techniques for modern homes",
          text: "Explore popular rendering techniques, materials, and finishes for contemporary buildings.",
        },
        {
          link: "",
          blogName: "How to maintain rendered walls",
          text: "Tips for cleaning, repairing, and maintaining rendered surfaces to extend their lifespan.",
        },
        {
          link: "",
          blogName: "Benefits of insulated rendering",
          text: "Understand how insulated rendering can improve energy efficiency and reduce heating costs.",
        },
        {
          link: "",
          blogName: "Benefits of insulated rendering",
          text: "Understand how insulated rendering can improve energy efficiency and reduce heating costs.",
        },
      ],
    },
    {
      slug: "renovation-and-restoration",
      blogsList: [
        {
          link: "",
          blogName: "Top trends in home renovation for 2024",
          text: "Discover popular design trends, materials, and techniques for modern renovations.",
        },
        {
          link: "",
          blogName: "How to restore heritage homes safely",
          text: "Tips and techniques for restoring heritage properties while preserving their historical value.",
        },
        {
          link: "",
          blogName: "Budgeting for a kitchen renovation",
          text: "Learn how to plan, budget, and execute a kitchen renovation effectively.",
        },
        {
          link: "",
          blogName: "Budgeting for a kitchen renovation",
          text: "Learn how to plan, budget, and execute a kitchen renovation effectively.",
        },
      ],
    },
    {
      slug: "skip-bins",
      blogsList: [
        {
          link: "",
          blogName: "How to choose the right skip bin size for your project",
          text: "A guide to selecting the right skip bin size based on waste type and project scale.",
        },
        {
          link: "",
          blogName: "5 tips for efficient waste management during renovations",
          text: "Learn how to handle waste effectively during home renovations and construction projects.",
        },
        {
          link: "",
          blogName: "The importance of recycling in skip bin services",
          text: "Explore the environmental benefits of using skip bins for recycling.",
        },
        {
          link: "",
          blogName: "The importance of recycling in skip bin services",
          text: "Explore the environmental benefits of using skip bins for recycling.",
        },
      ],
    },
    {
      slug: "solar-energy",
      blogsList: [
        {
          link: "",
          blogName: "Top benefits of installing solar panels for your home",
          text: "Explore the cost savings, environmental benefits, and increased property value associated with solar energy.",
        },
        {
          link: "",
          blogName:
            "Understanding solar battery storage: Is it worth the investment?",
          text: "Learn how battery storage can maximize energy usage and provide backup during outages.",
        },
        {
          link: "",
          blogName: "How to choose the right solar system size",
          text: "A guide to selecting the right system size based on energy needs, roof size, and budget.",
        },
        {
          link: "",
          blogName: "How to choose the right solar system size",
          text: "A guide to selecting the right system size based on energy needs, roof size, and budget.",
        },
      ],
    },
    {
      slug: "staircases",
      blogsList: [
        {
          link: "",
          blogName: "Top staircase design trends for 2024",
          text: "Discover the latest materials, designs, and styles shaping modern staircases.",
        },
        {
          link: "",
          blogName: "How to maintain wooden staircases",
          text: "Tips for keeping wooden staircases in top condition, from cleaning to refinishing.",
        },
        {
          link: "",
          blogName: "Safety features every staircase should have",
          text: "Learn about essential safety features like handrails, balustrades, and non-slip finishes.",
        },
        {
          link: "",
          blogName: "Safety features every staircase should have",
          text: "Learn about essential safety features like handrails, balustrades, and non-slip finishes.",
        },
      ],
    },
    {
      slug: "stonemasonry",
      blogsList: [
        {
          "link": "",
          "blogName": "Top stone types for outdoor landscaping",
          "text": "Explore popular natural stones for gardens, patios, and retaining walls."
        },
        {
          "link": "",
          "blogName": "Maintaining your stone features: tips & tricks",
          "text": "Learn about cleaning, sealing, and repairing stonework to preserve its beauty and durability."
        },
        {
          "link": "",
          "blogName": "The art of heritage stonemasonry restoration",
          "text": "Discover how stonemasons restore historical buildings and monuments with traditional techniques."
        },
        {
          "link": "",
          "blogName": "The art of heritage stonemasonry restoration",
          "text": "Discover how stonemasons restore historical buildings and monuments with traditional techniques."
        }
      ]
      ,
    },
    {
      
slug: "tiling",
      blogsList: [
        {
          "link": "",
          "blogName": "Top tile trends for 2024: what’s hot in design",
          "text": "Explore the latest materials, patterns, and designs shaping modern tiling."
        },
        {
          "link": "",
          "blogName": "How to maintain your tiled surfaces",
          "text": "Tips for cleaning, sealing, and maintaining tiles to keep them looking new."
        },
        {
          "link": "",
          "blogName": "Choosing the right tiles for your bathroom",
          "text": "A guide to selecting tiles based on water resistance, durability, and style."
        },
        {
          "link": "",
          "blogName": "Choosing the right tiles for your bathroom",
          "text": "A guide to selecting tiles based on water resistance, durability, and style."
        }
      ]
      ,
    },
    {
      
slug: "waterproofing",
      blogsList: [
        {
          "link": "",
          "blogName": "Why waterproofing is essential for bathrooms",
          "text": "Learn the importance of proper waterproofing in bathrooms and wet areas to prevent leaks and damage."
        },
        {
          "link": "",
          "blogName": "Top tips for waterproofing balconies & decks",
          "text": "Explore best practices and materials for ensuring long-lasting balcony and deck waterproofing."
        },
        {
          "link": "",
          "blogName": "Understanding different types of waterproofing membranes",
          "text": "A guide to various waterproofing products and how to choose the right one for your project."
        },
        {
          "link": "",
          "blogName": "Understanding different types of waterproofing membranes",
          "text": "A guide to various waterproofing products and how to choose the right one for your project."
        }
      ]
      ,
    },
  ];
  const BlogsLg = [
    {
      slug: "architectural",
      blogsList: [
        {
          link: "",
          blogName: "Top trends in modern home architecture",
          text: "Explore the latest design trends in residential architecture.",
        },
        {
          link: "",
          blogName: "The importance of sustainable building design",
          text: "Learn how sustainable designs can enhance efficiency and reduce costs.",
        },
        {
          link: "",
          blogName: "How to choose the right architect for your project",
          text: "Key factors to consider when hiring an architect.",
        },
      ],
    },
    {
      slug: "air-conditioning-and-heating",
      blogsList: [
        {
          link: "",
          blogName: "5 Tips for improving hvac efficiency",
          text: "Maximize system efficiency through regular maintenance and upgrades.",
        },
        {
          link: "",
          blogName: "Choosing the right air conditioning for your home",
          text: "Find the best system based on size, efficiency, and budget.",
        },
        {
          link: "",
          blogName: "Essential winter heating maintenance checklist",
          text: "Steps to prepare your heating system for colder months.",
        },
      ],
    },
    {
      slug: "bricklaying",
      blogsList: [
        {
          link: "",
          blogName: "Top tips for maintaining brick & block structures",
          text: "Learn how to keep your brickwork in top condition.",
        },
        {
          link: "",
          blogName: "Choosing the right bricks for your home",
          text: "Explore various brick types and their benefits.",
        },
        {
          link: "",
          blogName: "The importance of retaining walls in landscaping",
          text: "Understand how retaining walls improve safety and aesthetics.",
        },
      ],
    },
    {
      slug: "cabinet-making",
      blogsList: [
        {
          link: "",
          blogName: "Top trends in kitchen cabinet design",
          text: "Explore the latest trends in kitchen cabinetry for modern homes.",
        },
        {
          link: "",
          blogName: "How to choose the right materials for custom cabinets",
          text: "A guide to selecting materials based on quality, durability, and budget.",
        },
        {
          link: "",
          blogName: "Maximizing space with custom wardrobes",
          text: "Learn how custom wardrobes can enhance storage and functionality.",
        },
      ],
    },
    {
      slug: "carpentry",
      blogsList: [
        {
          link: "",
          blogName: "Top trends in deck design for Australian homes",
          text: "Explore the latest decking designs for outdoor living spaces.",
        },
        {
          link: "",
          blogName: "Choosing the right wood for your carpentry project",
          text: "A guide to selecting the best timber for durability and aesthetics.",
        },
        {
          link: "",
          blogName: "How to maintain your timber structures",
          text: "Tips on preserving the life and look of timber structures.",
        },
      ],
    },
    {
      slug: "carpet-and-flooring",
      blogsList: [
        {
          link: "",
          blogName: "Top trends in flooring for modern homes",
          text: "Explore the latest trends in flooring designs and materials.",
        },
        {
          link: "",
          blogName: "How to choose the right flooring for your space",
          text: "A guide to selecting flooring based on style, durability, and budget.",
        },
        {
          link: "",
          blogName: "The benefits of epoxy flooring for garages",
          text: "Learn about the durability and ease of maintenance with epoxy floors.",
        },
      ],
    },
    {
      slug: "caulking",
      blogsList: [
        {
          link: "",
          blogName: "The importance of caulking for energy efficiency",
          text: "Discover how caulking reduces drafts and lowers energy bills.",
        },
        {
          link: "",
          blogName: "How to choose the right caulk for your home",
          text: "A guide to selecting the best caulk for different surfaces and areas.",
        },
        {
          link: "",
          blogName: "Maintaining caulking in wet areas",
          text: "Tips on preventing mold and keeping caulk in wet areas in top condition.",
        },
      ],
    },
    {
      slug: "cleaning-services",
      blogsList: [
        {
          link: "",
          blogName: "Top tips for effective home cleaning",
          text: "Discover how to keep your home clean and organized with minimal effort.",
        },
        {
          link: "",
          blogName: "Why professional carpet cleaning is worth it",
          text: "Learn the benefits of deep carpet cleaning for better hygiene and air quality.",
        },
        {
          link: "",
          blogName: "End of lease cleaning checklist",
          text: "A comprehensive guide to ensure you meet rental cleaning standards before moving out.",
        },
      ],
    },
    {
      slug: "concrete-and-paving",
      blogsList: [
        {
          link: "",
          blogName: "Top benefits of exposed aggregate driveways",
          text: "Discover the aesthetic and functional advantages of exposed aggregate surfaces.",
        },
        {
          link: "",
          blogName: "How to choose the right paving for your patio",
          text: "A guide to selecting the best paving materials based on durability and design.",
        },
        {
          link: "",
          blogName: "Concrete maintenance tips for longevity",
          text: "Learn how to maintain concrete surfaces for lasting durability and appearance.",
        },
      ],
    },
    {
      slug: "conveyancing",
      blogsList: [
        {
          link: "",
          blogName: "Understanding the Conveyancing Process in Australia",
          text: "A step-by-step guide to how conveyancing works in residential property transactions.",
        },
        {
          link: "",
          blogName: "Top Tips for First Home Buyers",
          text: "Essential advice for navigating the legal and financial aspects of buying your first home.",
        },
        {
          link: "",
          blogName: "What to Look for in a Conveyancer",
          text: "Key traits and qualifications to consider when hiring a conveyancer for property transactions.",
        },
      ],
    },
    {
      slug: "blinds-and-curtains",
      blogsList: [
        {
          link: "",
          blogName: "Top tips for choosing the right blinds for your home",
          text: "Explore different styles and their benefits to find the perfect fit.",
        },
        {
          link: "",
          blogName: "How awnings improve energy efficiency",
          text: "Learn how awnings can reduce energy costs and enhance comfort.",
        },
        {
          link: "",
          blogName: "The benefits of motorized blinds in modern homes",
          text: "Discover how automation enhances convenience and safety.",
        },
      ],
    },
    {
      slug: "decking-and-gazebos",
      blogsList: [
        {
          link: "",
          blogName: "Top decking materials for Australian homes",
          text: "Explore popular decking materials and their benefits for outdoor spaces.",
        },
        {
          link: "",
          blogName: "Why you need a gazebo in your backyard",
          text: "Discover the advantages of adding a gazebo for relaxation and aesthetics.",
        },
        {
          link: "",
          blogName: "Tips for designing the perfect veranda",
          text: "A guide to creating a functional, stylish veranda for your home.",
        },
      ],
    },
    {
      slug: "demolition-and-excavation",
      blogsList: [
        {
          link: "",
          blogName: "Top safety tips for demolition projects",
          text: "Learn essential safety practices for handling demolition work.",
        },
        {
          link: "",
          blogName: "How to prepare for land excavation",
          text: "A guide to site preparation, soil testing, and excavation requirements.",
        },
        {
          link: "",
          blogName: "Benefits of professional asbestos removal",
          text: "Understand the importance of safe asbestos removal in demolition projects.",
        },
      ],
    },
    {
      slug: "electrical",
      blogsList: [
        {
          link: "",
          blogName: "Top tips for electrical safety in your home",
          text: "Learn essential safety tips for maintaining safe electrical systems at home.",
        },
        {
          link: "",
          blogName: "Benefits of LED lighting for energy savings",
          text: "Explore how LED lighting can lower your energy bills and improve lighting quality.",
        },
        {
          link: "",
          blogName: "How to prepare for a switchboard upgrade",
          text: "A guide to understanding when and why you might need a switchboard upgrade.",
        },
      ],
    },
    {
      slug: "engineering-services",
      blogsList: [
        {
          link: "",
          blogName: "Top engineering innovations in construction",
          text: "Discover the latest advancements shaping the construction industry.",
        },
        {
          link: "",
          blogName: "How to choose the right engineer for your project",
          text: "Essential factors to consider when hiring an engineer.",
        },
        {
          link: "",
          blogName: "The role of engineers in sustainable building design",
          text: "Learn how engineers contribute to energy efficiency and sustainability.",
        },
      ],
    },
    {
      slug: "fence-and-gates",
      blogsList: [
        {
          link: "",
          blogName: "How to choose the right fence for your home",
          text: "Explore various fencing materials and designs for residential properties.",
        },
        {
          link: "",
          blogName: "Top benefits of automatic gates",
          text: "Learn how electric gates enhance security and convenience.",
        },
        {
          link: "",
          blogName: "Safety standards for pool fencing in australia",
          text: "Understand the legal requirements for pool fencing and child safety.",
        },
      ],
    },
    {
      slug: "glazing-and-screens",
      blogsList: [
        {
          link: "",
          blogName: "Top glass types for energy-efficient homes",
          text: "Explore the benefits of double glazing, tinted glass, and energy-efficient window installations.",
        },
        {
          link: "",
          blogName: "How to choose the right shower screen for your bathroom",
          text: "Learn about framed, semi-frameless, and frameless shower screens.",
        },
        {
          link: "",
          blogName: "Safety standards for glass balustrades",
          text: "Understand compliance requirements for installing glass balustrades in residential and commercial spaces.",
        },
      ],
    },
    {
      slug: "garden-and-landscaping",
      blogsList: [
        {
          link: "",
          blogName: "Top landscaping trends for 2024",
          text: "Explore the latest designs, plants, and hardscape elements trending in landscaping this year.",
        },
        {
          link: "",
          blogName: "How to choose the right plants for your garden",
          text: "Tips for selecting the best plants based on soil type, climate, and aesthetics.",
        },
        {
          link: "",
          blogName: "Benefits of installing an irrigation system",
          text: "Learn how irrigation systems save water, reduce costs, and improve garden health.",
        },
      ],
    },
    {
      slug: "handyman",
      blogsList: [
        {
          link: "",
          blogName: "Top maintenance tasks for your home",
          text: "Learn the essential maintenance jobs every homeowner should complete annually.",
        },
        {
          link: "",
          blogName: "How to choose the right handyman for your needs",
          text: "Tips for selecting the best handyman based on job requirements and skills.",
        },
        {
          link: "",
          blogName: "DIY vs. hiring a handyman: What’s worth doing yourself?",
          text: "Understand which tasks you can tackle and when it’s best to call a professional.",
        },
      ],
    },
    {
      slug: "inspection-and-surveyors",
      blogsList: [
        {
          link: "",
          blogName: "Why you need a building inspection before buying a home",
          text: "Learn about the benefits and requirements of pre-purchase building inspections.",
        },
        {
          link: "",
          blogName: "How to read a land survey report",
          text: "A guide to understanding the basics of land survey reports for buyers and developers.",
        },
        {
          link: "",
          blogName: "Common issues found during asbestos inspections",
          text: "Understand the risks and requirements of asbestos inspections in buildings.",
        },
      ],
    },
    {
      slug: "security-and-automation",
      blogsList: [
        {
          link: "",
          blogName: "Top smart home devices for 2024",
          text: "Explore the latest trends in home automation and security technologies.",
        },
        {
          link: "",
          blogName: "How to choose the right security system for your home",
          text: "A guide to selecting the best security system based on property type and budget.",
        },
        {
          link: "",
          blogName: "Benefits of smart lighting for energy savings",
          text: "Learn how automated lighting systems can improve energy efficiency.",
        },
      ],
    },
    {
      slug: "insulation-services",
      blogsList: [
        {
          link: "",
          blogName: "Top benefits of upgrading your home insulation",
          text: "Discover the energy-saving, comfort-enhancing, and cost-reducing benefits of improved insulation.",
        },
        {
          link: "",
          blogName: "How to choose the right insulation for your home",
          text: "Learn about different types of insulation and their best applications.",
        },
        {
          link: "",
          blogName: "Understanding r-values in insulation",
          text: "A guide to insulation r-values and how they impact energy efficiency.",
        },
      ],
    },
    {
      slug: "interior-design",
      blogsList: [
        {
          link: "",
          blogName: "Top trends in interior design for 2024",
          text: "Discover the latest styles, materials, and colors shaping modern interior design.",
        },
        {
          link: "",
          blogName: "How to choose the right interior designer",
          text: "Tips for selecting a designer who matches your style, budget, and project needs.",
        },
        {
          link: "",
          blogName:
            "Sustainable interior design: eco-friendly choices for your home",
          text: "Learn how to incorporate sustainable practices into your interior design.",
        },
      ],
    },
    {
      slug: "locksmiths",
      blogsList: [
        {
          link: "",
          blogName: "How to choose the right lock for your home",
          text: "Explore various lock types, including deadbolts, smart locks, and keyless entry systems.",
        },
        {
          link: "",
          blogName: "Benefits of a master key system for offices",
          text: "Learn about the advantages of master key systems for enhanced security and control in commercial properties.",
        },
        {
          link: "",
          blogName: "What to do during a lockout",
          text: "A step-by-step guide to handle home or office lockouts effectively and safely.",
        },
      ],
    },
    {
      slug: "painting-and-decorating",
      blogsList: [
        {
          link: "",
          blogName: "Top color trends in interior design for 2024",
          text: "Explore popular colors, finishes, and painting techniques for homes and offices.",
        },
        {
          link: "",
          blogName: "How to choose the right paint for your home",
          text: "Tips for selecting paints based on durability, color, and room type.",
        },
        {
          link: "",
          blogName: "DIY vs. professional painting: What to consider",
          text: "Understand the pros and cons of DIY painting compared to hiring professionals.",
        },
      ],
    },
    {
      slug: "moving-and-storage",
      blogsList: [
        {
          link: "",
          blogName: "Top tips for a stress-free move",
          text: "Learn how to plan, pack, and move with minimal stress.",
        },
        {
          link: "",
          blogName: "How to choose the right moving company",
          text: "Explore the factors to consider when hiring a professional mover, from services to costs.",
        },
        {
          link: "",
          blogName: "Preparing your home for moving day",
          text: "A step-by-step guide to get your home ready for a smooth moving process.",
        },
      ],
    },
    {
      slug: "mold-and-asbestos",
      blogsList: [
        {
          link: "",
          blogName: "How to prevent mould growth in your home",
          text: "Explore best practices for reducing humidity and preventing mould in kitchens, bathrooms, and basements.",
        },
        {
          link: "",
          blogName: "Asbestos: What you need to know for safe removal",
          text: "Learn about asbestos risks, identification, and safe removal processes for homes and commercial buildings.",
        },
        {
          link: "",
          blogName:
            "Understanding the health risks of mould & asbestos exposure",
          text: "Discover the potential health effects of prolonged exposure to mould and asbestos and the importance of professional removal.",
        },
      ],
    },
    {
      slug: "pest-control",
      blogsList: [
        {
          link: "",
          blogName: "How to prevent termites in your home",
          text: "Learn effective prevention methods to protect your home from termite damage.",
        },
        {
          link: "",
          blogName: "Top tips for a pest-free home",
          text: "Explore simple strategies to keep your property free from common pests year-round.",
        },
        {
          link: "",
          blogName: "Understanding fumigation: when is it necessary?",
          text: "A guide to understanding when and why fumigation might be needed for effective pest control.",
        },
      ],
    },
    {
      slug: "plastering",
      blogsList: [
        {
          link: "",
          blogName: "Top plastering techniques for modern homes",
          text: "Explore popular plastering techniques and styles for a contemporary look.",
        },
        {
          link: "",
          blogName: "How to repair plaster cracks & holes",
          text: "Learn DIY tips for minor plaster repairs and when to call a professional.",
        },
        {
          link: "",
          blogName: "Skim coating vs. rendering: what’s the difference?",
          text: "Understand the difference between these two common plastering techniques.",
        },
      ],
    },
    {
      slug: "plumbing",
      blogsList: [
        {
          link: "",
          blogName: "Top tips for saving on plumbing costs",
          text: "Simple strategies to cut plumbing costs through maintenance and smart choices.",
        },
        {
          link: "",
          blogName: "How to choose the right hot water system",
          text: "Select the best system for your home’s needs and budget.",
        },
        {
          link: "",
          blogName: "Plumbing maintenance checklist for homeowners",
          text: "Essential steps to keep your plumbing running smoothly.",
        },
      ],
    },
    {
      slug: "pools-and-spas",
      blogsList: [
        {
          link: "",
          blogName: "Top pool design trends for 2024",
          text: "Discover the latest features, styles, and materials shaping modern pool design.",
        },
        {
          link: "",
          blogName: "How to maintain your spa for year-round use",
          text: "Learn about regular maintenance tips to keep your spa in top condition.",
        },
        {
          link: "",
          blogName: "Ensuring pool safety: what you need to know",
          text: "A guide to understanding safety requirements, from fencing to water quality.",
        },
      ],
    },
    {
      slug: "rendering",
      blogsList: [
        {
          link: "",
          blogName: "Top rendering techniques for modern homes",
          text: "Explore popular rendering techniques, materials, and finishes for contemporary buildings.",
        },
        {
          link: "",
          blogName: "How to maintain rendered walls",
          text: "Tips for cleaning, repairing, and maintaining rendered surfaces to extend their lifespan.",
        },
        {
          link: "",
          blogName: "Benefits of insulated rendering",
          text: "Understand how insulated rendering can improve energy efficiency and reduce heating costs.",
        },
      ],
    },
    {
      slug: "renovation-and-restoration",
      blogsList: [
        {
          link: "",
          blogName: "Top trends in home renovation for 2024",
          text: "Discover popular design trends, materials, and techniques for modern renovations.",
        },
        {
          link: "",
          blogName: "How to restore heritage homes safely",
          text: "Tips and techniques for restoring heritage properties while preserving their historical value.",
        },
        {
          link: "",
          blogName: "Budgeting for a kitchen renovation",
          text: "Learn how to plan, budget, and execute a kitchen renovation effectively.",
        },
      ],
    },
    {
      slug: "roofing",
      blogsList: [
        {
          link: "",
          blogName: "How to choose the right roofing material for your home",
          text: "Explore the pros and cons of different roofing materials, from metal to tile and shingles.",
        },
        {
          link: "",
          blogName: "Top roof maintenance tips for long-lasting performance",
          text: "Learn about routine maintenance practices to extend your roof’s lifespan.",
        },
        {
          link: "",
          blogName: "Understanding roof insulation: what you need to know",
          text: "A guide to selecting and installing roof insulation for better energy efficiency.",
        },
      ],
    },
    {
      slug: "scaffolding",
      blogsList: [
        {
          link: "",
          blogName: "Top scaffolding safety tips for construction sites",
          text: "Explore essential safety tips to ensure scaffolding safety and compliance.",
        },
        {
          link: "",
          blogName: "Choosing the right type of scaffolding for your project",
          text: "A guide to selecting the best scaffolding system based on your project’s requirements.",
        },
        {
          link: "",
          blogName:
            "Scaffold hire vs. purchase: what’s right for your project?",
          text: "Understand the pros and cons of hiring versus buying scaffolding for construction.",
        },
      ],
    },
    {
      slug: "shopfitting",
      blogsList: [
        {
          link: "",
          blogName: "Top trends in retail fit-outs for 2024",
          text: "Explore the latest designs, materials, and techniques that are reshaping retail interiors.",
        },
        {
          link: "",
          blogName: "How to plan a successful cafe fit-out",
          text: "A guide to designing cafe interiors that attract customers and improve operational flow.",
        },
        {
          link: "",
          blogName: "The importance of custom joinery in shopfitting",
          text: "Learn why custom joinery can enhance functionality and aesthetics in retail and commercial spaces.",
        },
      ],
    },
    {
      slug: "skip-bins",
      blogsList: [
        {
          link: "",
          blogName: "How to choose the right skip bin size for your project",
          text: "A guide to selecting the right skip bin size based on waste type and project scale.",
        },
        {
          link: "",
          blogName: "5 tips for efficient waste management during renovations",
          text: "Learn how to handle waste effectively during home renovations and construction projects.",
        },
        {
          link: "",
          blogName: "The importance of recycling in skip bin services",
          text: "Explore the environmental benefits of using skip bins for recycling.",
        },
      ],
    },
    {
      slug: "solar-energy",
      blogsList: [
        {
          link: "",
          blogName: "Top benefits of installing solar panels for your home",
          text: "Explore the cost savings, environmental benefits, and increased property value associated with solar energy.",
        },
        {
          link: "",
          blogName:
            "Understanding solar battery storage: Is it worth the investment?",
          text: "Learn how battery storage can maximize energy usage and provide backup during outages.",
        },
        {
          link: "",
          blogName: "How to choose the right solar system size",
          text: "A guide to selecting the right system size based on energy needs, roof size, and budget.",
        },
      ],
    },
    {
      slug: "staircases",
      blogsList: [
        {
          link: "",
          blogName: "Top staircase design trends for 2024",
          text: "Discover the latest materials, designs, and styles shaping modern staircases.",
        },
        {
          link: "",
          blogName: "How to maintain wooden staircases",
          text: "Tips for keeping wooden staircases in top condition, from cleaning to refinishing.",
        },
        {
          link: "",
          blogName: "Safety features every staircase should have",
          text: "Learn about essential safety features like handrails, balustrades, and non-slip finishes.",
        },
      ],
    },
    {
      slug: "stonemasonry",
      blogsList: [
        {
          "link": "",
          "blogName": "Top stone types for outdoor landscaping",
          "text": "Explore popular natural stones for gardens, patios, and retaining walls."
        },
        {
          "link": "",
          "blogName": "Maintaining your stone features: tips & tricks",
          "text": "Learn about cleaning, sealing, and repairing stonework to preserve its beauty and durability."
        },
        {
          "link": "",
          "blogName": "The art of heritage stonemasonry restoration",
          "text": "Discover how stonemasons restore historical buildings and monuments with traditional techniques."
        }
      ]
      ,
    },
    {
      
slug: "tiling",
      blogsList: [
        {
          "link": "",
          "blogName": "Top tile trends for 2024: what’s hot in design",
          "text": "Explore the latest materials, patterns, and designs shaping modern tiling."
        },
        {
          "link": "",
          "blogName": "How to maintain your tiled surfaces",
          "text": "Tips for cleaning, sealing, and maintaining tiles to keep them looking new."
        },
        {
          "link": "",
          "blogName": "Choosing the right tiles for your bathroom",
          "text": "A guide to selecting tiles based on water resistance, durability, and style."
        }
      ]
      ,
    },
    {
      
slug: "waterproofing",
      blogsList: [
        {
          "link": "",
          "blogName": "Why waterproofing is essential for bathrooms",
          "text": "Learn the importance of proper waterproofing in bathrooms and wet areas to prevent leaks and damage."
        },
        {
          "link": "",
          "blogName": "Top tips for waterproofing balconies & decks",
          "text": "Explore best practices and materials for ensuring long-lasting balcony and deck waterproofing."
        },
        {
          "link": "",
          "blogName": "Understanding different types of waterproofing membranes",
          "text": "A guide to various waterproofing products and how to choose the right one for your project."
        }
      ]
      ,
    },
  ];
  const categoryDataLg = BlogsLg.filter((item) => item.slug === slug);
  const categoryData = Blogs.filter((item) => item.slug === slug);
  return (
    <>
      {categoryData & categoryDataLg && (
        <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] mt-10 sm:mt-40">
          <Heading variant={"h2"} className={"text-offblack"}>
            Articles & Blogs
          </Heading>
        </div>
      )}
      {/* For Mobile Sreens */}
      {categoryData &&
        categoryData.map((item, index) => {
          return (
            <>
              <div
                key={index}
                className="lg:hidden flex justify-center mx-[5%] lg:mx-auto lg:w-[900px] mt-7 sm:mt-10 lg:mt-20"
              >
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 mx-auto w-fit gap-8 text-ellipsis overflow-hidden h-[600px] sm:h-auto">
                  {item.blogsList.map((e, index) => {
                    return (
                      <BlogCard
                        link={e.link}
                        key={index}
                        blogName={e.blogName
                          .split(/\s+/)
                          .slice(0, 15)
                          .join(" ")}
                        text={e.text}
                        blogTitle={e.blogTitle}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          );
        })}

      {/* For Lg screens */}
      {categoryDataLg &&
        categoryDataLg.map((item, index) => {
          return (
            <>
              <div
                key={index}
                className="hidden lg:flex justify-center mx-[5%] lg:mx-auto lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] mt-20"
              >
                <div className="grid lg:grid-cols-3 mx-auto w-fit gap-8">
                  {item.blogsList.map((e, index) => {
                    return (
                      <BlogCard
                        link={e.link}
                        key={index}
                        blogName={e.blogName
                          .split(/\s+/)
                          .slice(0, 15)
                          .join(" ")}
                        text={e.text}
                        blogTitle={e.blogTitle}
                      />
                    );
                  })}
                </div>
              </div>
            </>
          );
        })}
    </>
  );
};

export default RelatedArticles;
