import React from "react";
import Heading from "../../components/common/Typography/Heading";

const MessagingSystemLayout = () => {
  return (
    <>
      <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] mt-10 sm:mt-20 lg:mt-40">
        <Heading variant={"h2"} className={"text-offblack"}>
          Manage your business with ease
        </Heading>
      </div>
      <div className="mx-[5%] xl:mx-auto h-[500px] sm:h-auto xl:w-[1150px] 2xl:w-[1440px] 2xl:h-[900px] 3xl:w-[1600px] 3xl:h-[1000px] sm:aspect-[1440/900] mt-7 sm:mt-10">
        <img
          src={require("../../assets/img/contact-us.jpg")}
          className="w-full h-full rounded-3xl object-cover"
          alt=""
        />
      </div>
    </>
  );
};

export default MessagingSystemLayout;
