import React from "react";
import Avatar from "../../assets/img/default-image.jpg";
import ChatListItem from "../../components/common/ChatComponents/ChatListItem";
import SpecialText from "../../components/common/Typography/SpecialText";
import InteractiveText from "../../components/common/Typography/InteractiveText";

const ArchiveChats = ({ moveToChat, profileLinkOption }) => {
  const data = [
    {
      image: Avatar,
      Naming: "sender",
      Message:
        "In the tapestry of life, wov  In the In the tapestry of life, wov  In th",
      timing: "5 hours ago",
    },
    {
      image: Avatar,
      Naming: "sender",
      Message:
        "In the tapestry of life, wov  In the In the tapestry of life, wov  In th",
      timing: "5 hours ago",
    },
    {
      image: Avatar,
      Naming: "sender",
      Message:
        "In the tapestry of life, wov  In the In the tapestry of life, wov  In th",
      timing: "5 hours ago",
    },
    {
      image: Avatar,
      Naming: "sender",
      Message:
        "In the tapestry of life, wov  In the In the tapestry of life, wov  In th",
      timing: "5 hours ago",
    },
    {
      image: Avatar,
      Naming: "sender",
      Message:
        "In the tapestry of life, wov  In the In the tapestry of life, wov  In th",
      timing: "5 hours ago",
    },
    {
      image: Avatar,
      Naming: "sender",
      Message:
        "In the tapestry of life, wov  In the In the tapestry of life, wov  In th",
      timing: "5 hours ago",
    },
    {
      image: Avatar,
      Naming: "sender",
      Message:
        "In the tapestry of life, wov  In the In the tapestry of life, wov  In th",
      timing: "5 hours ago",
    },
    {
      image: Avatar,
      Naming: "sender",
      Message:
        "In the tapestry of life, wov  In the In the tapestry of life, wov  In th",
      timing: "5 hours ago",
    },
  ];
  return (
    <>
      <div className="border-b border-b-mediumgray">
        {data.map((e) => {
          return (
            <>
              <div
                className={
                  "border-t border-t-mediumgray hover:bg-pastalblue cursor-pointer"
                }
              >
                <ChatListItem
                  checkBox={true}
                  onClick={moveToChat}
                  profileImage={e.image}
                  profileName={e.Naming}
                  lastMsg={e.Message}
                  timeStamp={e.timing}
                />
              </div>
            </>
          );
        })}
      </div>
      <div className="flex items-center gap-3 mt-4">
        <InteractiveText
          variant={"ActionLink"}
          className={"text-mediumgray hover:text-primaryblue cursor-pointer"}
        >
          Delete
        </InteractiveText>
        <InteractiveText
          variant={"ActionLink"}
          className={"text-mediumgray hover:text-primaryblue cursor-pointer"}
        >
          Block
        </InteractiveText>
        <InteractiveText
          variant={"ActionLink"}
          className={"text-mediumgray hover:text-primaryblue cursor-pointer"}
        >
          Unarchive
        </InteractiveText>
        {profileLinkOption && (
          <InteractiveText
            variant={"ActionLink"}
            className={"text-mediumgray hover:text-primaryblue cursor-pointer"}
          >
            visit profile
          </InteractiveText>
        )}
      </div>
      <SpecialText
        variant={"FootNoteDisclaimer"}
        className={"mt-20 text-center text-lightgray"}
      >
        You've reached the end of the list.
      </SpecialText>
    </>
  );
};

export default ArchiveChats;
