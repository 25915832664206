import React from "react";
import SpecialText from "../../common/Typography/SpecialText";
import Paragraph from "../../common/Typography/Paragraph";

const ChatListItem = ({
  profileImage,
  profileName,
  lastMsg,
  timeStamp,
  onClick,
  checkBox,
}) => {
  return (
    <>
      <div className="flex items-center justify-between w-full py-3 lg:px-3">
        <div className="flex items-center gap-5">
          {checkBox && (
            <label
              className="relative flex items-center rounded-full cursor-pointer"
              htmlFor="link"
            >
              <input
                type="checkbox"
                className="before:content[''] peer relative size-4 cursor-pointer appearance-none rounded-[4px] border border-mediumgray transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primaryblue checked:bg-primaryblue checked:before:bg-primaryblue"
                id="link"
              />
              <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-[40%] left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                <i className="fi fi-rs-check text-[10px]"></i>
              </span>
            </label>
          )}
          <div
            className="flex items-center gap-3 cursor-pointer shrink-0"
            onClick={onClick}
          >
            <img
              src={profileImage}
              alt={profileName}
              className="size-8 lg:size-9 rounded-full"
            />
            <SpecialText variant={"ProfileName"}>{profileName}</SpecialText>
          </div>
        </div>
        <div className="cursor-pointer" onClick={onClick}>
          <Paragraph
            variant={"MainParagraph"}
            className={
              "overflow-hidden text-ellipsis text-offblack w-[50%] h-4 mx-auto"
            }
          >
            {lastMsg}
          </Paragraph>
        </div>
        <div className="shrink-0">
          <SpecialText variant={"DateStamp"} className={"text-offblack"}>
            {timeStamp}
          </SpecialText>
        </div>
      </div>
    </>
  );
};

export default ChatListItem;
